import { Button, InputInLabel } from "@/components/ui";

import { useState } from "react";

import { CheckPhonePopup } from "../../../../../popups";

import { useTranslation } from "react-i18next";

import "./checkPhone.scss";

const CheckPhone = () => {
  const [phoneClient, setPhoneClient] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const handleOpen = () => {
    setIsOpen(true);
  };

  // const handlePhoneClient = (e) => {
  //   const targetValue = e.target.value;
  //   setPhoneClient(targetValue);
  // };

  return (
    <>
      <div className="check__root">
        <span className="p1 color-main width-100">{indi("crosscheck")}</span>

        <p className="check__info">
          {indi("checkteg")}
          <span className="check__try">
            {indi("todayavailable")} 3 {indi("checks")}
          </span>
        </p>

        <div className="check__form">
          <InputInLabel
            type={"tel"}
            id={"phoneClient"}
            placeholder={t("phonenumber")}
            // onChange={(e) => handlePhoneClient(e)}
            maskValue={phoneClient}
            setMaskValue={setPhoneClient}
          >
            {t("phonenumber")}
          </InputInLabel>
          <Button
            size={"s"}
            buttonType={"submit"}
            clazz={"button--tetriary"}
            title={indi("crossnumber")}
            disabled={!phoneClient}
            onClick={handleOpen}
          >
            {indi("crossnumber")}
          </Button>
        </div>
      </div>
      <CheckPhonePopup
        open={isOpen}
        setOpen={setIsOpen}
        phoneClient={phoneClient}
      />
    </>
  );
};

export default CheckPhone;
