import { Button, ButtonLink, Icon, Select, SwitchTheme } from "@/components/ui";

import "./adult.scss";
import { useDispatch, useSelector } from "react-redux";
import { hideAdult, getAdultState } from "@/stores/slices/popupSlice";
import { getUserCity } from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";

const Adult = () => {
  const adultPopupState = useSelector(getAdultState);
  const dispatch = useDispatch();
  const city = useSelector(getUserCity);

  const languages = {
    main_title: "",
    options: [
      { id: 1, link: `/en/${city}`, title: "EN", value: "en" },
      { id: 2, link: `/ru/${city}`, title: "RU", value: "ru" },
      { id: 3, link: `/cz/${city}`, title: "CZ", value: "cz" },
    ],
  };
  const { t } = useTranslation("translation");

  const handleAdult = () => {
    dispatch(hideAdult(false));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <dialog
      open={!!adultPopupState}
      onCancel={(e) => e.preventDefault()}
      aria-modal="true"
      id={"adultPopup"}
      className="adult-popup adult"
    >
      <form method="dialog" className="adult-popup__body">
        <div className="adult-popup__top">
          <Icon spritePath={"18-popup"} clazz={"adult-popup__svg"} />
          <p className="p2">{t("popuptext18")}</p>

          <div className="adult-popup__inner">
            <span className={"adult-popup__title title"}>
              {t("selecttheme")}
            </span>
            <div className="adult-popup__actions">
              <SwitchTheme textLight={t("light")} textDark={t("dark")} />
              <Select
                options={languages.options}
                clazz={"button button-xs button--main"}
                arrowSize={"s"}
                sorted={true}
                name={"language"}
              ></Select>
            </div>
          </div>

          <p className="p2">{t("popupcookies")}</p>

          {/* <div className="adult-popup__cookie">
            <span className={"p2"}>Cookies</span>
            <Toggle checked={cookie} onChange={setCookie} id={"cookie"} />
          </div> */}
        </div>

        <div className="adult-popup__bottom">
          <span className={"adult-popup__title title"}>{t("areyouolder")}</span>
          <div className="adult-popup__box">
            <Button
              size={"m"}
              clazz={"button--primary"}
              buttonType={"submit"}
              onClick={handleAdult}
            >
              {t("yes")}
            </Button>

            <ButtonLink
              href={"https://www.google.com"}
              size={"m"}
              clazz={"button--secondary"}
              buttonType={"submit"}
            >
              {t("no")}
            </ButtonLink>
          </div>
        </div>
      </form>
    </dialog>
  );
};

export default Adult;
