import { Link, useNavigate } from "react-router-dom";

import { ButtonLink, Icon, LogoSvg } from "@/components/ui";

import { setAuthorizationStatus } from "@/stores/slices/popupSlice";
import { setAuthorisationAccessType } from "@/stores/slices/popupSlice";
import { resetAllCounters } from "@/stores/slices/counterFilterSlice";

import "./footer.scss";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserCity,
  getUserCurrentCity,
  getUserLang,
} from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";
import {
  cleanSorted,
  getNotDisabledCheckbox,
  setPageDefault,
  setSearchValue,
  setSorted,
  setSortedModelsByFilter
} from "@/stores/slices/modelsState";
import { resetAll, setSearchFilter } from "@/stores/slices/filterSlice";
import { clearTitle } from "@/stores/slices/titleSlice";
import { getUserType } from "@/stores/slices/userSlice";

const paymentsMethods = [
  { id: 1, icon: "visa", name: "Visa" },
  { id: 2, icon: "master-card", name: "MasterCard" },
  { id: 3, icon: "american-express", name: "AmericanExpress" },
  { id: 4, icon: "discover", name: "Discover" },
  { id: 5, icon: "maestro", name: "Maestro" },
  { id: 6, icon: "jcb", name: "JCB" },
  { id: 7, icon: "paypal", name: "PayPal" },
  { id: 8, icon: "google-pay", name: "Google Pay" },
  { id: 9, icon: "apple-pay", name: "Apple Pay" },
  { id: 10, icon: "union-pay", name: "Union Pay" },
  { id: 11, icon: "elo", name: "Elo" },
  { id: 12, icon: "hipercard", name: "Hipercard" },
  { id: 13, icon: "webmoney", name: "WebMoney" },
  { id: 14, icon: "alipay", name: "Alipay" },
  { id: 15, icon: "bitcoin", name: "Bitcoin" },
];

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation("translation");
  const userType = useSelector(getUserType);

  const city = useSelector(getUserCurrentCity);
  const currentCity = useSelector(getUserCity);
  const lang = useSelector(getUserLang);
  const country = useSelector(getUserCurrentCity).country;

  const postTitleFooter = t("titlefooter")?.replace(
    /%([^%]+)%/g,
    (match, p1) => {
      switch (p1) {
        case "City":
          return city.title;
        case "Country":
          return country;
        default:
          return match;
      }
    }
  );

  const handleLogo = (e) => {
    e.preventDefault();

    navigate(`/${lang}${city}`);

    dispatch(cleanSorted());
    dispatch(resetAll());
    dispatch(clearTitle());
    dispatch(setSearchFilter(false));
    dispatch(setSearchValue(""));
    dispatch(setPageDefault());
    dispatch(setSortedModelsByFilter(""));
    dispatch(resetAllCounters());

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toRegistration = (e) => {
    e.preventDefault();

    navigate(`/${lang}/registration#indi`);
  };

  const toSelection = (e) => {
    e.preventDefault();

    navigate(`/${lang}/${currentCity}/selection`);
  };

  const { keysWithValueTrue } = useSelector(getNotDisabledCheckbox);

  const categoryLinks = [
    {
      id: 1,
      link: "/indi",
      title: t("indi"),
      name: "status",
      forDisabled: "statusIndividual",
    },
    {
      id: 2,
      link: "/elite",
      title: t("elite"),
      name: "status",
      forDisabled: "statusElite",
    },
    {
      id: 3,
      link: "/pornstar",
      title: t("star"),
      name: "status",
      forDisabled: "statusPornstar",
    },
    {
      id: 4,
      link: "/online",
      title: t("online"),
      name: "status",
      forDisabled: "statusOnline",
    },
    {
      id: 8,
      link: "/new",
      title: t("newmodels"),
      name: "status",
      forDisabled: "statusNew",
    },
    {
      id: 11,
      link: "/bdsm",
      title: t("bdsm"),
      name: "status",
      forDisabled: "statusBDSM",
    },
    {
      id: 10,
      link: "/couple",
      title: t("couples"),
      name: "status",
      forDisabled: "genderCouple",
    },
    {
      id: 12,
      link: "/trance",
      title: t("trans"),
      name: "gender",
      forDisabled: "genderTrans",
    },
    {
      id: 13,
      link: "/guys",
      title: t("guys"),
      name: "gender",
      forDisabled: "genderMale",
    },
    {
      id: 5,
      link: "/vchat",
      title: t("videochat"),
      name: "status",
      forDisabled: "tagVideochat",
    },
    {
      id: 55,
      link: "/ero",
      title: t("erocontent"),
      name: "status",
      forDisabled: "tagEro",
    },
    {
      id: 6,
      link: "/verified",
      title: t("verification"),
      name: "status",
      forDisabled: "statusVerification",
    },
    {
      id: 7,
      link: "/healthy",
      title: t("healthy"),
      name: "status",
      forDisabled: "statusHealthControl",
    },
  ];

  const footerLink = (e, link, name) => {
    e.preventDefault();

    dispatch(cleanSorted());
    navigate(`/${lang}/${currentCity}${link}`);

    if (name === "status") {
      dispatch(
        setSorted({
          stateName: "sorted",
          payload: link.slice(1),
          callback: "",
        })
      );
      dispatch(
        setSorted({
          stateName: "sortedByStatus",
          payload: link.slice(1),
          callback: "",
        })
      );
    }
    if (name === "gender") {
      let result = "";
      switch (link.slice(1)) {
        case "guys":
          result = "3";
          break;
        case "trance":
          result = "2";
          break;
        case "couple":
          result = "4";
          break;
        default:
          result = "";
      }
      dispatch(
        setSorted({
          stateName: "sorted",
          payload: link.slice(1),
          callback: "",
        })
      );
      dispatch(
        setSorted({
          stateName: "sortedByGender",
          payload: result,
          callback: "",
        })
      );
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const infoLinks = {
    top_links: [
      { id: 1, link: "/", name: t("mainpage") },
      { id: 2, link: `/${lang}/about`, name: t("about") },
      { id: 3, link: `/guide`, name: t("guide") },
      { id: 4, link: `/${lang}/faq`, name: t("faq") },
      { id: 5, link: "/", name: t("support") },
    ],

    bottom_links: [
      { id: 1, link: "/", name: t("termsofservice") },
      { id: 2, link: "/", name: t("privacypolicy") },
    ],
  };

  return (
    <footer>
      <div className="footer__container">
        <Link
          to={`/`}
          className="logo"
          title={postTitleFooter}
          onClick={(e) => handleLogo(e)}
        >
          <LogoSvg alt={t("altfooter")} />

          <p>Lady4Love - make a sensible choice</p>
        </Link>

        <nav className="footer-categories">
          <h3 className="footer__title">{t("categories")}</h3>
          <div className="footer-categories__body">
            {categoryLinks.map((i) => {
              return (
                <Link
                  to={i.link}
                  key={i.id}
                  title={i.title}
                  className={`footer-categories__link ${
                    keysWithValueTrue?.length > 0 &&
                    keysWithValueTrue.includes(i.forDisabled)
                      ? ""
                      : "_disabled"
                  }`}
                  onClick={(e) => footerLink(e, i.link, i.name)}
                >
                  {i.title}
                </Link>
              );
            })}
            <Link
              href={"/selection"}
              title={"Подборка моделей"}
              size={"xs"}
              class={"footer-categories__link _no-underline"}
              onClick={(e) => toSelection(e)}
            >
              {"Подборка моделей"}
            </Link>
            <ButtonLink
              href={"/registration"}
              title={t("add")}
              size={"xs"}
              clazz={"add-form button_outline--green _no-underline"}
              onClick={(e) => toRegistration(e)}
            >
              {t("add")}
              <Icon size={"s"} spritePath={"plus"} />
            </ButtonLink>
          </div>
        </nav>

        <div className="footer-info">
          <h3 className="footer__title">
            {t("forusers")} <Icon spritePath={"18-plus"} />
          </h3>
          <div className="footer-info__top-links">
            {infoLinks.top_links.map((i) => {
              if (i.id === 5 && userType === "default") {
                return (
                  <Link
                    onClick={() => {
                      dispatch(setAuthorisationAccessType("support"));
                      dispatch(setAuthorizationStatus(true));
                    }}
                    key={i.id}
                    title={i.name}
                    className="footer-categories__link"
                  >
                    {i.name}
                  </Link>
                );
              }

              return (
                <Link
                  to={i.link}
                  key={i.id}
                  title={i.name}
                  className="footer-categories__link"
                >
                  {i.name}
                </Link>
              );
            })}
          </div>
          <div className="footer-info__bottom-links">
            {infoLinks.bottom_links.map((i) => {
              return (
                <Link
                  to={i.link}
                  key={i.id}
                  title={i.name}
                  className="footer-categories__link"
                >
                  {i.name}
                </Link>
              );
            })}
          </div>
          <div className="payment-methods">
            {paymentsMethods.map((i) => {
              return (
                <div
                  className="payment-methods__icon"
                  key={i.id}
                  title={i.name}
                >
                  <Icon type={"payment"} spritePath={i.icon} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
