import { Icon } from "../index";
import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import "./checkbox.scss";
const Checkbox = ({ ...props }) => {
  const {
    id,
    name,
    title,
    checked,
    disabled = false,
    value,
    linkCheckbox = false,
    buttonCheckbox = false,
    clazz,
    clazzLabel,
    border = true,
    flag,
    onChange,
    onClick,
    subTitle = "",
    readOnly = false,
    icon = "",
    iconSize = "",
    radio = false,
  } = props;

  let checkRef = useRef(null);
  const [isChecked, setIsChecked] = useState(checked);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  function handleClick() {
    checkRef.current.click();
  }

  if (linkCheckbox === true) {
    return (
      <a
        className={`checkbox${isChecked ? " checkbox_checked" : ""}${
          isDisabled ? " checkbox_disabled" : ""
        }`}
        tabIndex={isDisabled ? null : 0}
        onClick={() => handleClick()}
        onKeyUp={(e) => {
          if (e.key === "Enter") setIsChecked((prev) => !prev);
        }}
      >
        <input
          tabIndex={1}
          disabled={isDisabled ? true : null}
          id={id}
          type="checkbox"
          name={name ? name : ""}
          onChange={onChange}
          checked={isChecked ? true : null}
          value={value ? value : ""}
          readOnly={readOnly}
        />
        <label
          htmlFor={id}
          onClick={() => (isDisabled ? null : setIsChecked((prev) => !prev))}
          ref={checkRef}
        >
          {title}
        </label>
      </a>
    );
  }

  if (buttonCheckbox === true) {
    return (
      <label
        className={`checkbox-button${
          isDisabled ? " checkbox-button_disabled" : ""
        }${isChecked ? " checkbox-button_checked" : ""}${
          clazz ? ` ${clazz}` : ""
        }`}
        htmlFor={id}
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.key === "Enter") setIsChecked((prev) => !prev);
        }}
      >
        <input
          onClick={() => (isDisabled ? null : setIsChecked((prev) => !prev))}
          tabIndex={1}
          id={id}
          type="checkbox"
          onChange={onChange}
          disabled={isDisabled ? true : null}
          name={name ? name : ""}
          checked={isChecked ? true : null}
          value={value}
          readOnly={readOnly}
        />
        {title}
      </label>
    );
  }

  return (
    <div
      className={`checkbox${isChecked ? " checkbox_checked" : ""}${
        isDisabled ? " checkbox_disabled" : ""
      }${border ? `` : ` _no-border`}${clazz ? ` ${clazz}` : ""}`}
      tabIndex={isDisabled ? null : 0}
      onClick={() => (isDisabled ? null : setIsChecked((prev) => !prev))}
      onKeyUp={(e) => {
        if (e.key === "Enter") setIsChecked((prev) => !prev);
      }}
    >
      <input
        tabIndex={1}
        disabled={isDisabled ? true : null}
        id={id}
        type="checkbox"
        onChange={onChange}
        name={name ? name : ""}
        checked={isChecked ? true : false}
        value={value ? value : ""}
        className={`icon icon--check ${radio ? " radio" : ""}`}
        readOnly={readOnly}
      />
      <label
        htmlFor={id}
        // onClick={() => (isDisabled ? null : setIsChecked((prev) => !prev))}
        onClick={onClick}
        ref={checkRef}
        className={clazzLabel ? clazzLabel : ""}
      >
        {flag ? (
          <Icon
            type={"flag"}
            icon
            clazz={"checkbox-flag"}
            spritePath={`${flag}`}
          />
        ) : null}
        {icon && <Icon icon size={iconSize} spritePath={`${icon}`} />}
        {title}
        {subTitle && <span>{subTitle}</span>}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

export default Checkbox;
