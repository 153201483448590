import { useCallback, useEffect, useRef, useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultRangeState,
  getIsRangeLoad,
  rangeChange,
  rangeState,
  resetRange,
} from "@/stores/slices/filterSlice";
import "./inputRange.scss";
import { showFilter } from "@/stores/slices/showFilterSlice";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";

const InputRangeFilter = (props) => {
  const {
    min,
    max,
    step,
    inputIdLeft,
    inputIdRight,
    title,
    subtitle,
    resetButton = true,
    clazz,
    name,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation("translation");

  const rangeValue = useSelector(rangeState);
  const defaultRangeValue = useSelector(defaultRangeState);
  const filterOpen = useSelector(showFilter);
  const isRangeLoad = useSelector(getIsRangeLoad);
  const [minValue, setMinValue] = useState(rangeValue[name].min);
  const [maxValue, setMaxValue] = useState(rangeValue[name].max);

  const [maxInput, setMaxInput] = useState(maxValue);
  const [minInput, setMinInput] = useState(minValue);

  const [isResetShow, setIsResetShow] = useState(false);

  const [isHover, setIsHover] = useState(false);

  function allValueIsNull(obj) {
    for (let key in obj) {
      if (obj[key].min !== 0 && obj[key].max !== 0) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    setMinValue(rangeValue[name].min);
    setMaxValue(rangeValue[name].max);
    setMinInput(rangeValue[name].min);
    setMaxInput(rangeValue[name].max);
  }, [filterOpen, rangeValue]);

  // useEffect(() => {
  //   if (allValueIsNull(defaultRangeValue)) {
  //     dispatch(rangeChange({ name, min, max }));
  //   }
  // }, [defaultRangeValue]);

  const [edit, setEdit] = useState(false);
  const updateRangeValue = useCallback(
    debounce((value) => {
      if (value.minValue !== value.maxValue) {
        dispatch(
          rangeChange({ name, min: value.minValue, max: value.maxValue })
        );
        const pathname = window.location.pathname;

        if (
          pathname.includes("lk") ||
          pathname.includes("favorites") ||
          pathname.includes("profile") ||
          pathname.includes("registration")
        ) {
          navigate("/");
        }
      }
    }, 750),
    [min, max]
  );

  const handleInput = useCallback(
    (e) => {
      if (!edit) {
        setMaxInput(e.maxValue);
        setMinInput(e.minValue);
      }
      if (isRangeLoad && allValueIsNull(defaultRangeValue) && isHover) {
        updateRangeValue(e);
      }
      if (bodyRef.current && e.minValue + step >= max) {
        bodyRef.current.querySelector(".thumb-left").style.zIndex = 5;
        bodyRef.current.querySelector(".thumb-right").style.zIndex = 1;
      }
      if (bodyRef.current && e.maxValue - step <= min) {
        bodyRef.current.querySelector(".thumb-left").style.zIndex = 1;
        bodyRef.current.querySelector(".thumb-right").style.zIndex = 5;
      }
      setMinValue(e.minValue);
      setMaxValue(e.maxValue);
      setIsResetShow(minValue > min || maxValue < max ? true : false);
    },
    [minValue, maxValue]
  );

  const resetValues = () => {
    // setMinValue(min);
    // setMaxValue(max);
    // setMinInput(min);
    // setMaxInput(max);
    dispatch(resetRange({ name, min, max }));
    setIsResetShow(false);
  };

  const isDisabled = min || max ? false : true;

  const setMinValueInput = (e) => {
    setEdit(true);

    if (Number(e.target.value) > maxInput) {
      // если мин значение введено больше максимально
      setMinValue(maxInput - step);
      setMinInput(e.target.value - step);
      bodyRef.current.querySelector(".thumb-left").style.zIndex = 5;
      return;
    }
    if (Number(e.target.value) > min) {
      e.target.parentElement.classList.add("_active");
    }
    if (Number(e.target.value) <= min) {
      e.target.parentElement.classList.remove("_active");
    }
    if (Number(e.target.value) < min) {
      // если мин значение введено меньше мин
      setMinInput(min);
      setMinValue(e.target.value);
      return;
    }
    setMinValue(Number(e.target.value));
    setMinInput(e.target.value);
    bodyRef.current.querySelector(".thumb-left").style.zIndex = 1;
  };

  const setMaxValueInput = (e) => {
    setEdit(true);
    if (Number(e.target.value) > max) {
      // если максимальное значение введено больше максимального
      setMaxValue(max);
      setMaxInput(e.target.value);
      bodyRef.current.querySelector(".thumb-right").style.zIndex = 1;
      return;
    }
    if (Number(e.target.value) < max) {
      e.target.parentElement.classList.add("_active");
    }
    if (Number(e.target.value) >= max) {
      e.target.parentElement.classList.remove("_active");
    }
    if (Number(e.target.value) < minInput) {
      // если максимальное значение меньше минимального
      setMaxValue(minInput + step);
      setMaxInput(e.target.value);
      bodyRef.current.querySelector(".thumb-right").style.zIndex = 5;
      return;
    }
    setMaxValue(Number(e.target.value));
    setMaxInput(e.target.value);
  };

  const onBlurMinInput = (e) => {
    setEdit(false);
    // if (Number(e.target.value) <= 0) {
    //   // если мин значение введено меньше минимального
    //   setMinValue(min);
    //   setMaxInput(min);
    //   return;
    // }
    // if (Number(e.target.value) > max) {
    //   // если мин значение введено больше максимального
    //   setMinValue(maxValue - step);
    //   setMinInput(maxValue - step);
    //   return;
    // }

    // if(Number(e.target.value) < max && Number(e.target.value) > 0) {
    //   setMinValue(e.target.value);
    //   setMinInput(e.target.value);
    // }
  };

  const onBlurMaxInput = (e) => {
    setEdit(false);
    if (Number(e.target.value) <= 0) {
      // если максимальное значение меньше минимального
      setMaxValue(min);
      setMaxInput(min);
      return;
    }
    if (Number(e.target.value) > max) {
      // если максимальное значение введено больше максимального
      setMaxValue(maxValue);
      setMaxInput(maxValue);
      return;
    }
  };

  const handleKeyPressMax = (event) => {
    if (event.key === "Enter") {
      onBlurMaxInput();
    }
  };

  const handleKeyPressMin = (event) => {
    if (event.key === "Enter") {
      onBlurMinInput();
    }
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const bodyRef = useRef(null);

  return (
    <div className={`range${clazz ? ` ${clazz}` : ""}`}>
      <div
        ref={bodyRef}
        className={"range__body"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={"range__head"}>
          <div className={"range__title"}>
            {title}
            {subtitle ? <span>{subtitle}</span> : ""}
          </div>
          {resetButton && isResetShow ? (
            <button
              type="button"
              onClick={resetValues}
              className={"range__default"}
            >
              {t("reset")}
            </button>
          ) : null}
        </div>
        {isRangeLoad && (
          <MultiRangeSlider
            min={min}
            max={max}
            step={step}
            stepOnly={!!step}
            className={`range__input ${min && max && min === max && "equal"}`}
            ruler={false}
            label={false}
            disabled={isDisabled}
            minValue={minValue}
            maxValue={maxValue}
            onInput={(e) => {
              handleInput(e);
            }}
          />
        )}
        <div className={"range__inputs"}>
          <label className={`range__label`} htmlFor={inputIdLeft}>
            <input
              id={inputIdLeft}
              type="tel"
              step={step}
              value={minInput}
              autoComplete="off"
              name={name}
              disabled={isDisabled}
              onChange={(e) => setMinValueInput(e)}
              onBlur={(e) => onBlurMinInput(e)}
              onKeyDown={(e) => handleKeyPressMin(e)}
            />
            {minInput}
          </label>

          <label className={`range__label`} htmlFor={inputIdRight}>
            <input
              id={inputIdRight}
              type="tel"
              step={step}
              value={maxInput}
              autoComplete="off"
              name={name}
              disabled={isDisabled}
              onChange={(e) => setMaxValueInput(e)}
              onBlur={(e) => onBlurMaxInput(e)}
              onKeyDown={(e) => handleKeyPressMax(e)}
            />
            {maxInput}
          </label>
        </div>
      </div>
    </div>
  );
};
// type optionsType = {
//     maxValue: number
//     minValue: number
//     setMaxValue: any
//     setMinValue: any
//     size: string
//     color: string
//     min: number
//     max: number
// }
//
// type propsType = {
//     options: optionsType
//     children: React.ReactNode
// }
export default InputRangeFilter;
