import {
  CountriesFilter,
  CheckPhone,
  Rules,
  InfoForm,
} from "../model/pageComponents/index.js";

import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";

import {
  DeleteProfilePopup,
  ChangePasswordPopup,
  ActivateProfilePopup,
} from "../../../popups";

import { useEffectWindowSize } from "../../../../hooks";

import {
  Button,
  InputInLabel,
  ButtonLink,
  Loader,
  Toggle,
} from "@/components/ui";

import { useState, useEffect } from "react";

import { makeRequest } from "@/services/makeRequest.js";

import showToast from "../../../toast/Toast.js";

import "./accountModel.scss";

const GoToAncketaComponent = ({ profiles_count }) => {
  const { t: indi } = useTranslation("indi");

  switch (!!profiles_count) {
    case true: {
      return (
        <span className={"title"}>{`${indi(
          "yourads"
        )}: ${profiles_count}`}</span>
      );
    }

    default: {
      return <span className={"title"}>{indi("addads")}</span>;
    }
  }
};

const AccountModel = () => {
  const [data, setData] = useState({});
  const {
    phone,
    balance,
    profiles_count = 0,
    email_verified_at,
    profiles_status,
  } = data;

  const [isLoading, setIsLoading] = useState(true);
  const [valueLoad, setValueLoad] = useState(false);

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [isOpenActivate, setIsOpenActivate] = useState(false);

  const [status, setStatus] = useState(profiles_status);

  const windowWidth = useEffectWindowSize();
  const { t: indi } = useTranslation("indi");

  const { t } = useTranslation("translation");

  const isSmallTablet = windowWidth < 1000.98;
  const isMobile = windowWidth < 767.98;
  const isMobileExtra = windowWidth < 420;

  const goToAncketa = isSmallTablet ? indi("toads") : t("browseprofiles");

  const goToBank = isSmallTablet ? indi("tobalance") : indi("gotobalance");

  const isActiveProfile = isMobileExtra
    ? indi("activate")
    : indi("activatepro");

  const token = localStorage.getItem("user");

  const { register, setValue } = useForm();

  const handleOpenDelete = () => {
    setIsOpenDelete(true);
  };

  const handleOpenPassword = () => {
    setIsOpenPassword(true);
  };

  const handleOpenActivate = () => {
    setIsOpenActivate(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setValueLoad(false);
      setIsLoading(true);
      try {
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        console.log("data", data);
        if (data) {
          const result = data.data;
          setStatus(result.profiles_status);

          setData(result);
        }

        setIsLoading(false);
        setValueLoad(true);
      } catch (error) {}
    };

    token && fetchData();
  }, [token]);

  const handleActivate = async () => {
    try {
      const route = `user/set-profiles-status`;
      const method = "PUT";
      const payload = {
        status: !status,
      };

      await makeRequest({ route, method, payload });
      setStatus((prev) => !prev);

      showToast({
        message: indi("success"),
        variant: "succes",
      });
    } catch (error) {
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setValueLoad(false);
    setValue("phone", String(phone));
    setValueLoad(true);
  }, [setValue, phone]);

  console.log("valueLoad", valueLoad);

  return (
    <main>
      {isOpenDelete && (
        <DeleteProfilePopup open={isOpenDelete} setOpen={setIsOpenDelete} />
      )}
      {isOpenPassword && (
        <ChangePasswordPopup
          open={isOpenPassword}
          setOpen={setIsOpenPassword}
        />
      )}
      {isOpenActivate && (
        <ActivateProfilePopup
          open={isOpenActivate}
          setOpen={setIsOpenActivate}
          setStatus={setStatus}
          status={status}
        />
      )}

      <section className={"account-page__container"}>
        <h1>{t("profuser")}</h1>
        <div className={"account-page__root"}>
          {isLoading ? (
            <Loader />
          ) : (
            <div className={"account-page__main"}>
              <div className="account-page__identity">
                <h2>{indi("personaldata")}</h2>
                <form className="account-page__identity__form">
                  <InputInLabel
                    type={"tel"}
                    id={"phone"}
                    placeholder={t("phonenumber")}
                    disabled
                    register={{ ...register("phone") }}
                    maskValue={phone}
                    valueLoad={valueLoad}
                  >
                    {t("phonenumber")}
                  </InputInLabel>
                  {!isMobile && (
                    <span>
                      {indi("forphonechange")} <a href="/">{indi("toadmin")}</a>
                    </span>
                  )}

                  <Button
                    size={"s"}
                    clazz={"button_outline--green-accent w-160"}
                    onClick={handleOpenPassword}
                  >
                    {indi("changepass")}
                  </Button>
                </form>
                {isMobile && (
                  <span>
                    {indi("forphonechange")} <a href="/">{indi("toadmin")}</a>
                  </span>
                )}
              </div>

              <hr />

              {isSmallTablet && (
                <>
                  <Rules />
                  <hr />
                </>
              )}

              <span>{indi("placefornotices")}</span>

              <InfoForm data={data} />

              {!email_verified_at && (
                <div className="account-page__confirm">
                  <span className="p2 color-main">{indi("toconfemail")}</span>
                  <Button
                    size={"s"}
                    clazz={"button_outline--green-accent w-160"}
                  >
                    {indi("reconf")}
                  </Button>
                </div>
              )}

              <div className={"account-page__info"}>
                <div className={"account-page__link-block"}>
                  <GoToAncketaComponent profiles_count={profiles_count} />
                  <ButtonLink
                    href={"profiles"}
                    clazz={"td-none button_outline--green-accent w-160"}
                    size={"s"}
                  >
                    {goToAncketa}
                  </ButtonLink>
                </div>

                <div className={"account-page__link-block"}>
                  <span className={"title"}>
                    {indi("yourbalance")}
                    <span className={"account-page__cash"}>
                      {` ${Math.round(balance)}€`}
                    </span>
                  </span>
                  <ButtonLink
                    href={"balance"}
                    clazz={"td-none button_outline--green-accent w-160"}
                    size={"s"}
                  >
                    {goToBank}
                  </ButtonLink>
                </div>
              </div>

              <hr />

              <div className="account-page__info">
                <h2>{indi("usefulfeatures")}</h2>
                <CheckPhone />
                <CountriesFilter data={data} />
              </div>
              <div className="account-page__removebtn">
                <div className="account-page__removebtn__active">
                  <h3 className="mb-0">{isActiveProfile}</h3>
                  <Toggle
                    id={"isActiveProfile"}
                    checked={!!status}
                    // onChange={onChangeStatus}
                    onClick={status ? handleOpenActivate : handleActivate}
                  />
                </div>

                <Button
                  type="button"
                  clazz={"button--error"}
                  size={"l"}
                  onClick={handleOpenDelete}
                >
                  {indi("delete")}
                </Button>
              </div>
            </div>
          )}
          {!isSmallTablet && <Rules />}
        </div>
      </section>
    </main>
  );
};

export default AccountModel;
