import {
  setForgotPasswordStatus,
  setForgotPasswordEmailStatus,
  getForgotPasswordEmailStatus,
  setForgotPasswordPhoneStatus,
  getForgotPasswordStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RestorePasswordPopupPhone from "./RestorePhonePopup";
import { useTranslation } from "react-i18next";
import { getUserLang } from "@/stores/slices/userSlice";
import { useState } from "react";
import { makeRequest } from "@/services/makeRequest";

const RestorePasswordPopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");

  const [codeValue, setCodeValue] = useState("");
  const [codeError, setCodeError] = useState("");
  const [email, setEmail] = useState("");

  const lang = useSelector(getUserLang);

  const forgotPassState = useSelector(getForgotPasswordStatus);
  const forgotPassEmailState = useSelector(getForgotPasswordEmailStatus);
  const formSchema = object().shape({
    login: string()
      .required("Логин обязателен")
      .email("Введён некорректный логин"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const onSubmit = (data, e) => {
    e.preventDefault();
    dispatch(setForgotPasswordEmailStatus(true));
    dispatch(setForgotPasswordStatus(false));
    console.log(data);
    reset();
  };

  const onClose = () => {
    reset();
    dispatch(setForgotPasswordStatus(false));
  };

  const navigate = useNavigate();
  const navigateTo = () => {
    dispatch(setForgotPasswordStatus(false));
    navigate(`/${lang}/registration`);
  };

  const handleComplete = () => {
    const fetchData = async () => {
      try {
        const route = `auth/password/verify`;
        const method = "POST";
        const payload = {
          email: email,
          code: codeValue,
        };

        const data = await makeRequest({ route, method, payload });
      } catch (error) {
        const status = error.response.status;
        if (status === 422 || status === 404) {
          setCodeError("Неверный код");
        }
      }
    };

    fetchData();
  };

  return (
    <>
      {!!forgotPassState && (
        <dialog
          id={"forgotPasswordPopup"}
          open={!!forgotPassState}
          onClick={() => onClose()}
          className="popup-form"
        >
          {!!forgotPassState ? (
            <Helmet>
              <html className={"lock"}></html>
            </Helmet>
          ) : null}
          <form
            onClick={(e) => e.stopPropagation()}
            onSubmit={handleSubmit(onSubmit)}
            className="popup-form__body"
          >
            <Button onClick={() => onClose()} clazz={"button__close"}>
              <Icon size={"l"} spritePath={"close"} />
            </Button>
            <h2 className="popup-form__title text-center fz-28 mb-12">
              {t("passwordrecovery")}
            </h2>
            <div className="popup-form__inner">
              <InputInLabel
                clazz={`${errors.login ? " _error" : ""}`}
                id={"loginForgotPass"}
                type={"email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"example@google.com"}
                register={{ ...register("login") }}
                autocomplete={"login"}
              >
                {(errors.login && (
                  <span className={"input-label__error"}>
                    {errors.login.message}
                  </span>
                )) || <span>{t("loginuser")}</span>}
              </InputInLabel>

              <Button size={"l"} buttonType={"submit"} clazz={"button--green"}>
                {" "}
                {t("recoverpassword")}
              </Button>
            </div>
            <div className="popup-form__inner gap-0">
              <hr />
              <div className="popup-form__box popup-form__bottom-link">
                <span>{t("noemailaccess")}</span>
                <button
                  type={"button"}
                  onClick={() => dispatch(setForgotPasswordPhoneStatus(true))}
                >
                  {t("getsmscode")}
                </button>
              </div>

              <div className="popup-form__box popup-form__bottom-link">
                <span>{t("noaccount")}</span>
                <button type={"button"} onClick={() => navigateTo()}>
                  {t("register")}
                </button>
              </div>
            </div>
          </form>
        </dialog>
      )}

      {!!forgotPassEmailState && (
        <dialog
          id={"forgotPasswordEmailPopup"}
          open={!!forgotPassEmailState}
          onClick={() => dispatch(setForgotPasswordEmailStatus(false))}
          className="popup-form forgot-email-popup"
        >
          {!!forgotPassEmailState ? (
            <Helmet>
              <html className={"lock"}></html>
            </Helmet>
          ) : null}

          <div
            onClick={(e) => e.stopPropagation()}
            className="popup-form__body"
          >
            <Button
              onClick={() => dispatch(setForgotPasswordEmailStatus(false))}
              clazz={"button__close"}
            >
              <Icon size={"l"} spritePath={"close"} />
            </Button>

            <p className={"fz-18 text-center lh-22 color-main"}>
              Если указанный email зарегистрирован на портале, то на него придёт
              уникальный код для смены пароля и доступа к аккаунту.
            </p>

            <InputInLabel
              clazz={`${codeError ? " error" : ""}`}
              id={"newpass-email"}
              type={"number"}
              placeholder={"Код"}
              value={codeValue}
              onChange={(e) => setCodeValue(e.target.value)}
            >
              {(codeError && (
                <span className={"input-label__error"}>{codeError}</span>
              )) || <span>Email</span>}
            </InputInLabel>
            <Button
              onClick={() => handleComplete()}
              size={"l"}
              clazz={"button--green"}
              disabled={!codeValue}
            >
              Ок
            </Button>
          </div>
        </dialog>
      )}

      <RestorePasswordPopupPhone />
    </>
  );
};

export default RestorePasswordPopup;
