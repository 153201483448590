import { useTranslation } from "react-i18next";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import { Checkbox, Skeleton } from "@/components/ui";
import { useEffect, useState } from "react";

import "./favorite.scss";
import { makeRequest } from "@/services/makeRequest";
import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";
import GirlCard from "../../girlCard/GirlCard";

const Favorite = () => {
  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");
  const token = localStorage.getItem("user");

  const [disabled, setDisabled] = useState(token ? false : true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDisabled(token ? false : true);
  }, [token]);

  const favoriteCheckbox = [
    {
      id: 23,
      title: indi("favorites"),
      name: "favorites",
      value: "favorites",
      checked: false,
      disabled: false,
    },
    {
      id: 22,
      title: "Просмотры",
      name: "favorites",
      value: "watches",
      checked: false,
      disabled: disabled,
    },
    {
      id: 33,
      title: "Заметки",
      name: "favorites",
      value: "pornstar",
      checked: false,
      disabled: disabled,
    },
    {
      id: 44,
      title: t("reviews"),
      name: "favorites",
      value: "reviews",
      checked: false,
      disabled: disabled,
    },
    {
      id: 66,
      title: "Жалобы",
      name: "favorites",
      value: "complaint",
      checked: false,
      disabled: disabled,
    },

    {
      id: 160,
      title: t("videochats"),
      name: "favorites",
      value: "vchat",
      checked: false,
      disabled: disabled,
    },
    {
      id: 181,
      title: t("erocontent"),
      name: "favorites",
      value: "ero",
      checked: false,
      disabled: disabled,
    },
    {
      id: 55,
      title: indi("myorder"),
      name: "favorites",
      value: "myorder",
      checked: false,
      disabled: disabled,
    },
    {
      id: 77,
      title: indi("demos"),
      name: "favorites",
      value: "demos",
      checked: false,
      disabled: disabled,
    },
  ];

  const lang = useSelector(getUserLang);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleCheckbox = (index) => {
    setActiveIndex(index);
  };

  const [data, setData] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const dataForDefault = JSON.parse(localStorage.getItem('favorites'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(false);
        const route = "user/favorites";
        const method = "GET";

        const result = await makeRequest({ route, method });

        if (result) {
          setData(result.data);
          setIsLoading(true);
        }
      } catch (error) {}
    };

    token && lang && fetchData();
  }, [lang, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(false);
        if(!!!dataForDefault?.length) {
          setEmptyData(true);
          setIsLoading(true);

          return
        }
        const route = "profiles";
        const method = "GET";
        const payload = {
          slug: dataForDefault?.join(',')
        }

        const result = await makeRequest({ route, method, payload });

        if (result) {
          setData(result.profiles);
          setIsLoading(true);
        }
      } catch (error) {}
    };

    !token && lang && fetchData();
  }, [token, lang])

  const skeletons = [...Array(12)].map((_, index) => <Skeleton key={index} />);

  return (
    <main>
      <section className="navigation__container favorites-page">
        <h1>История взаимодействий с анкетами</h1>
        <nav className="navigation__nav">
          {favoriteCheckbox.map((i, index) => (
            <Checkbox
              key={i.value}
              id={i.id}
              checked={index === activeIndex}
              title={i.title}
              value={i.value}
              name={i.name}
              disabled={i.disabled}
              onChange={() => handleCheckbox(index)}
            />
          ))}
        </nav>

        <div className="grid-cards">
          {isLoading &&
            data.map((data, index) => {
              return !!data ? (
                <GirlCard props={data} size={"m"} key={data.id} />
              ) : (
                <Skeleton key={index}></Skeleton>
              );
            })}
          {isLoading && emptyData && <h3>У вас еще нет анкет в избранном</h3>}
          {!isLoading && skeletons}
        </div>
      </section>
    </main>
  );
};
export default Favorite;
