import "./upAd.scss";

import { Button, Icon } from "@/components/ui";
import { useState } from "react";

import {
  MakeEliteAdPopup,
  PromotionTablePopup,
  RemoveEliteAdPopup,
} from "../../../../../../../popups";
import { useTranslation } from "react-i18next";

const UpAd = ({
  status,
  current_position,
  is_elite,
  is_top,
  city,
  slug,
  name,
}) => {
  const [isOpenConnect, setIsOpenConnect] = useState(false);
  const [isOpenElite, setIsOpenElite] = useState(false);

  const [isRemoveElite, setIsRemoveElite] = useState(false);

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation();

  const handleOpenConnect = () => {
    setIsOpenConnect(true);
  };

  const handleOpenRemoveElite = () => {
    setIsRemoveElite(true);
  };

  const handleOpenElite = () => {
    setIsOpenElite(true);
  };

  return (
    <>
      <div className={`upad ${!status ? "not_active" : ""}`}>
        {!!city && (
          <span className="title_h4 mb-0">
            {indi("positionin")?.replace(/%([^%]+)%/g, "")}{" "}
            <button onClick={handleOpenConnect} className="upad__link--city">
              {city}
              <Icon size={"s"} spritePath={"arrow-up-right"} clazz={"_green"} />
            </button>
          </span>
        )}
        <div className="upad__updown__about">
          <div className="upad__updown">
            <div className="upad__updown__calc" onClick={handleOpenConnect}>
              <Button clazz={"button--secondary"} square={true} size={"2xs"}>
                <Icon size={"2xs"} spritePath={"chevrone-up"} />
              </Button>
              <input
                value={current_position}
                type={"number"}
                className={"upad__input fz-18 color-main fw-500"}
              />
              <Button clazz={"button--secondary"} square={true} size={"2xs"}>
                <Icon size={"2xs"} spritePath={"chevrone-down"} />
              </Button>
            </div>
            {is_top ? (
              <div className={"upad__status__top"}>
                {indi("ad")} {indi("intop")}
              </div>
            ) : (
              <Button
                clazz={`button_outline--spec-green`}
                size={"xs"}
                onClick={handleOpenConnect}
              >
                {indi("upto")} {indi("intop")}
              </Button>
            )}
          </div>
          <div class="vertical-line"></div>

          <div className="upad__updown">
            {is_elite ? (
              <div className={`upad__status__elite`}>{t("Status")} ELITE</div>
            ) : (
              <Button
                clazz={`button_outline--spec-yellow`}
                onClick={handleOpenElite}
                size={"xs"}
              >
                {indi("buyelite")} ELITE
              </Button>
            )}

            {!is_elite ? (
              <span className="mt-8 fw-500 fz-13 text-center color-main">
                +50% {indi("toviews")}
              </span>
            ) : (
              <button
                className={`mt-8 upad__updown__deactivate`}
                onClick={handleOpenRemoveElite}
              >
                {indi("deactive")}
              </button>
            )}
          </div>
        </div>
      </div>

      {isOpenConnect && (
        <PromotionTablePopup
          open={isOpenConnect}
          setOpen={setIsOpenConnect}
          position={current_position}
          city={city}
          slug={slug}
        />
      )}

      {isRemoveElite && (
        <RemoveEliteAdPopup
          open={isRemoveElite}
          setOpen={setIsRemoveElite}
          slug={slug}
          name={name}
        />
      )}

      {isOpenElite && (
        <MakeEliteAdPopup open={isOpenElite} setOpen={setIsOpenElite} />
      )}
    </>
  );
};

export default UpAd;
