import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";

import {
  Button,
  InputInLabel,
  Select,
  Input,
  Icon,
  Checkbox,
  Loader,
  SelectCountry,
} from "@/components/ui";
import { number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import "./main.scss";
import { useState, useEffect, useRef, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import Map from "../../map/Map.js";
import {
  getOneProfileAddress,
  getOneProfileCoords,
  setOneProfileAddress,
  setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice.js";
import {
  getLanguagesWords,
  getNationalistWords,
  getUserCountry,
  getUserLang,
} from "@/stores/slices/userSlice.js";
import { makeRequest } from "@/services/makeRequest.js";
import debounce from "lodash.debounce";

const Main = ({
  changeStep,
  step,
  rootElem,
  formData,
  setFormData,
  setAvailableSteps,
  countries,
  setCountries,
  setData
}) => {
  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");

  const options = {
    readyTravel: [
      { id: 94, title: t("no"), value: "no" },
      { id: 93, title: t("nearcity"), value: "nearby-city" },
      { id: 33, title: t("incountry"), value: "country" },
      { id: 32, title: t("ineurope"), value: "europe" },
      { id: 31, title: t("inworld"), value: "world" },
    ],
    ethnos: [
      { id: 99, title: t("white"), value: "white" },
      { id: 98, title: t("asian"), value: "asian" },
      { id: 97, title: t("african"), value: "african" },
      { id: 96, title: t("arabic"), value: "arabic" },
      { id: 95, title: t("indian"), value: "indian" },
      { id: 94, title: t("tanned"), value: "mulatto" },
      { id: 93, title: t("mixed"), value: "mixed" },
      { id: 93, title: t("latin"), value: "latin" },
    ],
  };
  // const { phone, whatsapp, telegram } = userData;

  const dispatch = useDispatch();
  const address = useSelector(getOneProfileAddress);
  const coords = useSelector(getOneProfileCoords);
  const [langs, setLangs] = useState([]);
  const [langsCode, setLangsCode] = useState([]);
  const [formCity, setFormCity] = useState({
    id: "",
    title: "",
    icon: "",
  });
  const langsArr = useSelector(getLanguagesWords);
  const currentCountry = useSelector(getUserCountry);
  const [activeCountry, setActiveCountry] = useState("cz");
  const [currentActive, setCurrentActive] = useState("");

  useEffect(() => {
    if (formCity.id) {
      setCurrentActive(formCity.id);
    }
  }, [formCity]);

  const [isProfileAlreadyCreate, setIsProfileAlreadyCreate] = useState(
    formData.isAlreadyCreate ? formData.isAlreadyCreate : false
  );
  const token = localStorage.getItem("user");

  const [isLoading, setIsLoading] = useState(
    formData.isAlreadyCreate ? false : true
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        if (data) {
          setData(data.data)
          const result = data.data;
          setPhoneValues(result.phone);
          setWaValues(String(result.whatsapp ?? ""));
          setValue("phone", result.phone);
          setValue("whatsapp", result.whatsapp);
          setValue("telegram", result.telegram);
        }
        setIsLoading(false);
      } catch (error) {}
    };

    token && !isProfileAlreadyCreate && fetchData();
  }, [token]);

  const [langsError, setLangsError] = useState("");
  const [cityError, setCityError] = useState("");

  const [phoneValue, setPhoneValues] = useState(
    formData.phone ? formData.phone : ""
  );

  const [waValue, setWaValues] = useState(
    formData.whatsapp ? formData.whatsapp : ""
  );

  const [slugValue, setSlugValue] = useState(
    formData.slug ? formData.slug : ""
  );

  const formSchema = object().shape({
    name: string().required(),
    nick: string().required(t("required")),
    age: number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      )
      .nullable()
      .required(t("required"))
      .min(18, `${indi("minage")} 18`)
      .max(99, `${indi("maxage")} 99`)
      .nullable(true),
    tel: string().test("phone-is-empty", t("phonenumerrequired"), function () {
      return !!phoneValue;
    }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [langPopup, setLangPopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [isAddressActive, setIsAddressActive] = useState(false);

  useEffect(() => {
    if (!!langsArr.length) {
      const filteredLanguages = langsArr.filter((language) =>
        formData.languages?.includes(language.id)
      );
      setLangs(filteredLanguages);

      const filteredLangsCode = filteredLanguages.map(
        (lang) => lang.short_name
      );
      setLangsCode(filteredLangsCode);
    }
  }, [langsArr]);

  const setAgeValue = (e) => {
    const value = parseInt(e.target.value, 10);

    if (isNaN(value)) {
      setValue("age", "");
      return;
    }

    if (value < 18) {
      setValue("age", "18");
    } else if (value > 99) {
      setValue("age", "99");
    } else {
      setValue("age", value);
    }
  };

  useEffect(() => {
    if (countries.length > 0) {
      const filteredCity = countries[0][activeCountry].cities.filter(
        (city) => city.id === formData.city
      );
      if (filteredCity[0]) {
        setFormCity({ ...filteredCity[0], icon: activeCountry });
      }
    }
  }, []);

  const [selectedCity, setSelectedCity] = useState(
    formData.city
      ? countries[0][activeCountry].cities.filter(
          (item) => item.id === formData.city
        )[0].name
      : ""
  );

  const validateData = () => {
    setLangsError("");
    setCityError("");
    if (langs.length === 0) {
      setLangsError(t("required"));
    }
    if (!selectedCity) {
      setCityError(t("required"));
    }
  };

  const nationalsArr = useSelector(getNationalistWords);
  const transformedCountries =
    nationalsArr?.length > 0 &&
    nationalsArr.map((country) => ({
      icon: country.code.toUpperCase(),
      name: country.name,
      title: country.name,
      id: country.id,
    }));

  const [ethnos, setEthnos] = useState("");
  const [nationality, setNationality] = useState(
    !!transformedCountries.length
      ? transformedCountries.filter((i) => i.id === formData.nationality)[0]
          ?.title
      : ""
  );

  const [readyTravel, setReadyTravel] = useState("");

  const lang = useSelector(getUserLang);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `cities`;
        const method = "GET";
        const payload = {
          lang,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data;
          setCountries(result);
        }
      } catch (error) {}
    };

    lang && token && !isProfileAlreadyCreate && fetchData();
  }, [lang]);
  useEffect(() => {
    console.log("formData", formData);
    if (formData.name) {
      // setValueLoad(false);
      setValue("name", formData.name);
      setValue("nick", formData.nick);
      setValue("age", formData.age);
      setValue("telegram", formData.telegram);

      setReadyTravel(formData.ready_travel);
      setEthnos(formData.skin_color);
      // setPhoneValues(String(formData.phone));
      // setValue("phone", String(formData.phone));
      // setWaValues(String(formData.whatsapp));
      // setValue("whatsapp", formData.whatsapp);
      // setValueLoad(true);
    }
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    const fetchData = async () => {
      try {
        const route = isProfileAlreadyCreate
          ? `user/profile/${formData.slug}`
          : `user/profile`;
        const method = isProfileAlreadyCreate ? "PUT" : "POST";
        const payload = {
          ...(!!waValue.replace(/[+]/g, "") && {
            whatsapp: String(waValue.replace(/[" "+]/g, "")),
          }),
          ...(!!data.telegram && { telegram: data.telegram }),
          name: data.name,
          nick: data.nick.substring(data.nick.lastIndexOf("/") + 1),
          slug: slugValue,
          // phone: data.phone
          //   ? data.phone
          //   : Number(phoneValue.replace(/[" "+]/g, "")),
          phone: Number(phoneValue.replace(/[" "+]/g, "")),
          age: data.age,
          languages: langs.map((item) => item.id),
          // city: currentCountry.filter(
          //   (item) => item.title.toLowerCase() === selectedCity.toLowerCase()
          // )[0].id,
          city: countries[0][activeCountry].cities.filter(
            (item) => item.name.toLowerCase() === selectedCity.toLowerCase()
          )[0].id,
          ...(!!address && address[0] && { address: address[0] }),
          ...(!!coords?.latitude && { latitude: coords?.latitude }),
          ...(!!coords?.longitude && { longitude: coords?.longitude }),
          ...(!!ethnos && { skin_color: ethnos }),
          ...(!!nationality && {
            nationality: transformedCountries.filter(
              (i) => i.name === nationality
            )[0].id,
          }),
          ...(!!readyTravel && { ready_travel: readyTravel }),
        };

        const result = await makeRequest({ route, method, payload });
        if (result.status) {
          setFormData({
            ...payload,
            isAlreadyCreate: true,
          });
          changeStep((prev) => prev + 1);
          rootElem.scrollIntoView({
            behavior: "smooth",
          });
          if (!isProfileAlreadyCreate) {
            setIsProfileAlreadyCreate(true);
          }
          setAvailableSteps((prev) => [...prev, 2]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    token && fetchData();
  };

  const handleLangOpen = (e) => {
    setLangPopup(prev => !prev);
  };

  const [bounds, setBounds] = useState("");

  const removeLang = (code) => {
    const updateLangs = langs.filter((item) => item.short_name !== code);
    const updateLangsCode = langsCode.filter((item) => item !== code);

    setLangs(updateLangs);
    setLangsCode(updateLangsCode);
  };

  const toggleLang = (item) => {
    const lang = {
      id: Number(item.id),
      short_name: item.short_name,
      name: item.name,
    };
    const langIndex = langs.findIndex(
      (item) => item.short_name === lang.short_name
    );

    if (langIndex === -1) {
      setLangs([...langs, lang]);
      setLangsCode([...langsCode, item.short_name]);
    } else if (langIndex !== -1) {
      const updatedLangs = [...langs];
      updatedLangs.splice(langIndex, 1);
      setLangs(updatedLangs);
      const resultArr = [...langsCode];
      resultArr.splice(langIndex, 1);
      setLangsCode(resultArr);
    }
  };

  const handleLocationClick = () => {
    setIsAddressActive(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const { latitude, longitude } = position.coords;
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
          );
          const { city, road, house_number } = response.data.address;
          dispatch(
            setOneProfileAddress(
              `${city && city}, ${road} ${
                house_number ? ", " + house_number : ""
              }`
            )
          );
          dispatch(setOneProfileCoords({ latitude, longitude }));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target) && 
      !selectRef.current.classList.contains('select__body')
    ) {
      setLangPopup(false);
      event.stopPropagation();
    }
  };
  const selectRef = useRef(null);

  const ErrorMessage = ({ message }) => {
    return <span class="error create-error">{message}</span>;
  };

  const [nickValue, setNickValue] = useState(
    formData.nick ? `https://lady4love.com/${formData.nick}` : ""
  );

  const createSlug = useCallback(
    debounce((value) => {
      const fetchData = async () => {
        try {
          const route = `user/profile/slug/${value}`;
          const method = "GET";

          const data = await makeRequest({ route, method });
          if (data) {
            if (data.status) {
              checkNickIsAvailable(value);
              setValue("nick", value);
              setSlugValue(value);
            }
            if (data.slug) {
              checkNickIsAvailable(data.slug);
              setValue("nick", data.slug);
              setSlugValue(data.slug);
            }
          }
        } catch (error) {}
      };

      token && fetchData();
    }, 1000),
    []
  );

  const createProfileSlug = (e) => {
    createSlug(e.target.value);
  };
  const checkNick = useCallback(
    debounce((value) => {
      const fetchData = async () => {
        try {
          const route = `user/profile/check-slug/${value}`;
          const method = "GET";

          const data = await makeRequest({ route, method });
          if (data) {
            const status = data.status;
            if (!status) {
              const alternative = data.alternative;
              setError("nick", {
                message: `${indi("taken")} ${alternative}`,
              });
            } else {
              clearErrors("nick");
            }
          }
        } catch (error) {}
      };

      token && fetchData();
    }, 750),
    []
  );

  const checkNickIsAvailable = (value) => {
    // let inputValue = e.target.value;
    let nick = value.substring(value.lastIndexOf("/") + 1);
    nick = nick.replace(/[^a-zA-Z0-9-]/g, "");
    if (nick === "lady4lovecom") {
      setNickValue("");
    } else {
      setNickValue(`https://lady4love.com/${nick}`);
    }
    checkNick(nick);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <h2>{indi("main")}</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`create-page__form main`}
      >
        <InputInLabel
          type={"text"}
          id={"create-name"}
          placeholder={""}
          register={{ ...register("name") }}
          clazz={`_required ${errors.name && "_error"}`}
          onChange={(e) => createProfileSlug(e)}
        >
          {indi("nameofad")}
          {errors.name && <ErrorMessage message={t("required")} />}
        </InputInLabel>

        <InputInLabel
          type={"text"}
          id={"create-nick"}
          placeholder={"https://lady4love.com/Vash-Nic"}
          register={{ ...register("nick") }}
          clazz={`_required ${errors.nick && "_error"}`}
          onChange={(e) => checkNickIsAvailable(e.target.value)}
          value={nickValue}
        >
          {"Nick"}
          {errors.nick && <ErrorMessage message={errors.nick.message} />}
        </InputInLabel>
        <InputInLabel
          type={"number"}
          id={"create-age"}
          placeholder={"18-99"}
          register={{ ...register("age") }}
          clazz={`_required ${errors.age && "_error"}`}
        >
          {t("age")}
          {errors.age && <ErrorMessage message={errors.age.message} />}
        </InputInLabel>
        <InputInLabel
          type={"tel"}
          id={"create-phone"}
          placeholder={"+XXX XXX XXX XXX"}
          register={{ ...register("phone") }}
          autocomplete={"tel"}
          clazz={`_required ${errors.tel && "_error"}`}
          maskValue={phoneValue}
          setMaskValue={setPhoneValues}
          valueLoad={isLoading}
        >
          {t("phonenumber")}
          {errors.phone && <ErrorMessage message={t("required")} />}
        </InputInLabel>
        <InputInLabel
          type={"tel"}
          id={"create-whatsapp"}
          placeholder={""}
          register={{ ...register("whatsapp") }}
          autocomplete={"whatsapp"}
          maskValue={waValue}
          setMaskValue={setWaValues}
        >
          {"Whatsapp"}
        </InputInLabel>
        <InputInLabel
          type={"text"}
          id={"create-telegram"}
          placeholder={""}
          register={{ ...register("telegram") }}
          autocomplete={"telegram"}
        >
          {indi("telegram")}
        </InputInLabel>
        <Select
          onChange={setNationality}
          name={"create-nationalist"}
          options={transformedCountries?.length > 0 && transformedCountries}
          clazz={"button button-xs button--main"}
          clazzSvg={"select__flag"}
          arrowSize={"m"}
          isSearch={true}
          searchPlaceHolder={indi("searchfield")}
          type={"flag"}
          subTitle={t("nationality")}
          defaultTitle={nationality}
          withoutTitle={true}
          postApi={true}
          hideFlag={nationality ? false : true}
          withoutIcon={nationality ? false : true}
        />
        <Select
          onChange={setEthnos}
          name={"create-ethnos"}
          options={options.ethnos}
          clazz={"button button-xs button--main"}
          arrowSize={"m"}
          subTitle={t("ethnicity")}
          defaultTitle={
            formData.skin_color
              ? options.ethnos.filter(
                  (item) => item.value === formData.skin_color
                )[0]?.title
              : ""
          }
          withoutTitle={true}
          postApi={true}
        />
        <Select
          onChange={setReadyTravel}
          name={"create-readyTravel"}
          options={options.readyTravel}
          clazz={"button button-xs button--main"}
          arrowSize={"m"}
          subTitle={t("travel")}
          defaultTitle={
            formData.ready_travel
              ? options.readyTravel.filter(
                  (item) => item.value === formData.ready_travel
                )[0]?.title
              : ""
          }
          withoutTitle={true}
          postApi={true}
        />
        {/* <div className={`create__select-lang ${langPopup && "_active"}`}> */}
        <div  onClick={(e) => handleLangOpen(e)} className={`select button button-xs button--main _required ${langPopup ? "select_active" : ""}`} >
          {/* <Button
            clazz={`button--secondary justify-center _required ${
              langsError ? "_error" : ""
            }`}
            size={"s"}
            onClick={() => handleLangOpen()}
          > */}
            <span className="select__subtitle">{indi("languages")}</span>
            <Icon
              clazz={"select__arrow"}
              size={"m"}
              spritePath={"chevrone-down"}
            />
            {langsError && <ErrorMessage message={langsError} />}
          {/* </Button> */}

          {langPopup && (
            <div
              ref={selectRef}
              className={`text-center select__body`}
            >
              <label for="citiesSearch" className="select__search">
                <Input
                  type="search"
                  placeholder={indi("searchfield")}
                  id="citiesSearch"
                  clazz={"search__input"}
                  name={"create-cities"}
                  value={searchValue}
                  setValue={setSearchValue}
                />
              </label>

              <div className="d-flex mt-8 mb-12 justify-sb">
                <Checkbox
                  border={false}
                  flag={"EN"}
                  key={"EN"}
                  name={
                    !!langsArr.length
                      ? langsArr.filter((lang) => lang.short_name === "en")[0]
                          .name
                      : ""
                  }
                  id={"EN"}
                  value={
                    !!langsArr.length
                      ? langsArr.filter((lang) => lang.short_name === "en")[0]
                          .name
                      : ""
                  }
                  title={"EN"}
                  checked={langsCode.includes("en")}
                  onChange={() =>
                    toggleLang({
                      id: 2,
                      short_name: "en",
                      name: !!langsArr.length
                        ? langsArr.filter((lang) => lang.short_name === "en")[0]
                            .name
                        : "",
                    })
                  }
                />
                <Checkbox
                  border={false}
                  flag={"CZ"}
                  key={"CZ"}
                  name={
                    !!langsArr.length
                      ? langsArr.filter((lang) => lang.short_name === "cz")[0]
                          .name
                      : ""
                  }
                  id={"CZ"}
                  value={
                    !!langsArr.length
                      ? langsArr.filter((lang) => lang.short_name === "cz")[0]
                          .name
                      : ""
                  }
                  title={"CZ"}
                  checked={langsCode.includes("cz")}
                  onChange={() =>
                    toggleLang({
                      id: 3,
                      short_name: "cz",
                      name: !!langsArr.length
                        ? langsArr.filter((lang) => lang.short_name === "cz")[0]
                            .name
                        : "",
                    })
                  }
                />
                <Checkbox
                  border={false}
                  flag={"RU"}
                  key={"RU"}
                  name={
                    !!langsArr.length
                      ? langsArr.filter((lang) => lang.short_name === "ru")[0]
                          .name
                      : ""
                  }
                  id={"RU"}
                  value={
                    !!langsArr.length
                      ? langsArr.filter((lang) => lang.short_name === "ru")[0]
                          .name
                      : ""
                  }
                  checked={langsCode.includes("ru")}
                  title={"RU"}
                  onChange={() =>
                    toggleLang({
                      id: 1,
                      short_name: "ru",
                      name: !!langsArr.length
                        ? langsArr.filter((lang) => lang.short_name === "ru")[0]
                            .name
                        : "",
                    })
                  }
                />
              </div>

              <div className="dropdown__search-select">
                {langsArr?.length > 0 &&
                langsArr.filter((item) =>
                  item.name.toLowerCase().includes(searchValue.toLowerCase())
                ).length > 0 ? (
                  langsArr
                    .filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    )
                    .map((item) => (
                      <Checkbox
                        border={false}
                        flag={item.short_name.toUpperCase()}
                        key={item.short_name.toUpperCase()}
                        name={item.name}
                        id={item.id}
                        value={item.short_name}
                        title={item.name}
                        checked={
                          langsCode.includes(item.short_name) ? true : false
                        }
                        onChange={() => toggleLang(item)}
                      />
                    ))
                ) : (
                  <span className="p2">{indi("nolanguages")}</span>
                )}
              </div>
            </div>
          )}
        </div>

        {langs.length > 0 && (
          <div className="create-lang--current">
            {langs.map((lang) => (
              <div class="select__item" tabindex="0">
                {lang.name}
                <i
                  class={`icon-flag icon-flag-m ${lang.short_name.toUpperCase()}`}
                ></i>
                <i
                  class={`icon icon-m icon--close`}
                  onClick={() => removeLang(lang.short_name)}
                ></i>
              </div>
            ))}
          </div>
        )}
        <div className={`create-page__city-block ${cityError ? "_error" : ""}`}>
          {/* <Select
            name={"city-create"}
            options={currentCountry}
            clazz={"select-city _required"}
            clazzSvg={"select__flag"}
            arrowSize={"m"}
            type={"flag"}
            postApi={formCity?.icon ? false : true}
            subTitle={indi("yourcity")}
            defaultTitle={formCity?.title}
            withoutIcon={!selectedCity}
            withoutTitle={formCity?.title ? false : true}
            setValue={setSelectedCity}
          /> */}
          <SelectCountry
            data={countries}
            name={"city-create"}
            options={currentCountry}
            // clazz={"select-city _required"}
            clazz={"_required"}
            clazzSvg={"select__flag"}
            arrowSize={"m"}
            type={"flag"}
            subTitle={indi("yourcity")}
            defaultTitle={formCity?.name}
            withoutIcon={!selectedCity}
            setValue={setSelectedCity}
            currentActiveIndex={currentActive}
            setActiveCountry={setActiveCountry}
            activeCountry={activeCountry}
            setActive={setCurrentActive}
          />
          {cityError && <ErrorMessage message={cityError} />}
        </div>

        <div
          className={`search filter__map-input ${
            selectedCity ? "" : "_disabled"
          }`}
        >
          <span className={"filter__map-title"}>{t("youraddress")}</span>
          <label
            htmlFor="geosuggest__input-create"
            className="search__label header__search"
          >
            <Input
              type="text"
              placeholder={indi("fulladdress")}
              name="location-create"
              id="geosuggest__input-create"
              clazz={"header__search-input"}
              valueFromSearch={address}
              bounds={bounds}
            />
          </label>
          <Button
            title={t("chekmyplace")}
            onClick={() => handleLocationClick()}
            name={"location"}
            square={true}
            buttonType={"button"}
            size={"l"}
            clazz={
              isAddressActive
                ? "search__icon button--tetriary active"
                : "search__icon button--tetriary"
            }
          >
            <Icon size={"xl"} spritePath={"location"} />
          </Button>
        </div>
        <span className="create-page__noted">
          {indi("displayonsite")}
          <span className="create-page__noted-colored">
            {" "}
            +<span>35</span>% {indi("toviews")}, +<span>15</span>%{" "}
            {indi("toorders")}
          </span>
        </span>
        {selectedCity && <Map city={selectedCity} setBounds={setBounds} />}
        <div className="create-form__buttons">
          <Button
            size={"l"}
            square={false}
            clazz="button--secondary"
            disabled={step === 1 ? true : false}
          >
            {indi("back")}
          </Button>
          <Button
            onClick={() => validateData()}
            buttonType={"submit"}
            size={"l"}
            square={false}
            clazz="button--green"
          >
            {!isProfileAlreadyCreate ? indi("create") : indi("continue")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default Main;
