import { useEffect, useState } from "react";
import { Button, Tippy } from "@/components/ui";
import { useForm } from "react-hook-form";
import { RegistrationPopup } from "../../popups";
import {
  setRegistrationStatus,
  getRegistrationStatus,
} from "@/stores/slices/popupSlice";
import { setUserType } from "@/stores/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

import "./registrationPage.scss";
import { useTranslation } from "react-i18next";

const RegistrationPage = () => {
  const { register, handleSubmit, getValues } = useForm({
    mode: "onSubmit",
  });
  const dispatch = useDispatch();

  const registrationState = useSelector(getRegistrationStatus);

  const { t } = useTranslation("translation");

  let responce;
  useEffect(() => {}, []);

  const [dataName, setDataName] = useState();

  const onSubmit = (data, e) => {
    e.preventDefault();
    let result = getValues("account");
    responce = setUserType(result).payload;
    setDataName(result);
    dispatch(setRegistrationStatus(true));
  };
  const isIndi = window.location.hash === "#indi" ? "indi" : "user";

  useEffect(() => {
    if (isIndi === "indi") {
      const element = document.querySelector(".registration__account");

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }

      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    }
  }, []);

  const [active, setActive] = useState({
    user: isIndi === "user" ? true : false,
    indi: isIndi === "indi" ? true : false,
    agency: false,
    photograph: false,
  });

  return (
    <main>
      <section className={"registration__container"}>
        <h1 className={"text-center mb-40 mb-24-mob"}>{t("registration")}</h1>
        <h2>{t("chooseaccount")}</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="registration__account"
        >
          <div className="registration__radio-group">
            <label
              htmlFor="user"
              className={`registration__radio${active.user ? " _active" : ""}`}
              onClick={() =>
                setActive({
                  user: true,
                  indi: false,
                  agency: false,
                  photograph: false,
                })
              }
            >
              <span>{t("client")}</span>
              <input
                {...register("account")}
                id={"user"}
                type="radio"
                value={"user"}
                defaultChecked={active.user}
              />
              <p className={"p1"}>{t("advantages")}</p>
            </label>

            <label
              htmlFor="indi"
              className={`registration__radio${active.indi ? " _active" : ""}`}
              onClick={() =>
                setActive({
                  user: false,
                  indi: true,
                  agency: false,
                  photograph: false,
                })
              }
            >
              <span>{t("indireg")}</span>
              <input
                {...register("account")}
                id={"indi"}
                type="radio"
                value={"indi"}
                defaultChecked={active.indi}
              />
              <p className={"p1"}>{t("registerandearn")}</p>
            </label>

            <label
              htmlFor="agency"
              className={`registration__radio${
                active.agency ? " _active" : ""
              }`}
              onClick={() =>
                setActive({
                  user: false,
                  indi: false,
                  agency: true,
                  photograph: false,
                })
              }
            >
              <span>{t("agencybrothel")}</span>
              <input
                {...register("account")}
                id={"agency"}
                type="radio"
                value={"agency"}
                defaultChecked={active.agency}
              />
              <p className={"p1"}>{t("groupsandbrothels")}</p>
            </label>

            {/* <label
                htmlFor="photograph"
                className={`registration__radio${
                  active.photograph ? " _active" : ""
                }`}
                onClick={() =>
                  setActive({
                    user: false,
                    indi: false,
                    agency: false,
                    photograph: true,
                  })
                }
              >
                <span>{t("photographer")}</span>
                <input
                  {...register("account")}
                  id={"photograph"}
                  type="radio"
                  value="photograph"
                  defaultChecked={active.photograph}
                />
                <p className={"p1"}>{t("eroticphotoshoots")}</p>
              </label> */}
          </div>

          <div className="registration__box">
            <div
              className={`registration__description${
                active.user ? " _active" : ""
              }`}
            >
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("garantie")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("videochatswithmodel")} <span>{t("preintro")}</span>
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("accesstoerocontent")} – <span>{t("explicitphotos")}</span>
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("ability")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("severaltopick")} <span>{t("pickbest")}</span>
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("savefilterspecific")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("contactedmodels")} <span>{t("avoidconfusion")}</span>
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("addtofav")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("reviewscomments")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("complainandblock")}
              </p>

              <Tippy title={t("advantages")}>
                <div className="fake-checkbox registration__fake-checkbox">
                  <div className={"fake-checkbox__icon"}>
                    <i className={"icon icon-l icon--include"}></i>
                  </div>
                  {t("invitestoparties")}
                </div>
              </Tippy>
            </div>

            <div
              className={`registration__description${
                active.indi ? " _active" : ""
              }`}
            >
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("freeregpost")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("recommendations")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("moderatorsupport")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("importprofile")}
                <span>{t("createagain")}</span>
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("severalprofiles")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("storiesupload")} <span>{t("promoteprofile")}</span>
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("earnchatsandero")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("receiveorders")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("getverified")}
                <Tippy title={t("check")} className={"registration__tippy"}>
                  {"«" + t("verification") + "»"}
                </Tippy>
                {t("freeorcall")}
              </p>

              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("healthcontroldiscount")}
                <Tippy title={t("chekckid")} className={"registration__tippy"}>
                  {"«" + t("healthy") + "»"}
                </Tippy>
              </p>

              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("detailedstatistics")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("freebackcheck")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("photocatalogue")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("hideinsearch")}
              </p>

              <div className="fake-checkbox registration__fake-checkbox">
                <div className={"fake-checkbox__icon"}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                {t("elitehighlight")}
              </div>
            </div>

            <div
              className={`registration__description${
                active.agency ? " _active" : ""
              }`}
            >
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("novatcharge")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("operators")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("autotranslategen")} <span>{t("addfunctions")}</span>.
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("profilepositions")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("profilestats")}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("fasttrackverify" + t("videoverify"))}
              </p>
              <p className={"p1"}>
                <i className={"icon icon-l icon--include"}></i>
                {t("sexpartiesorg")}
              </p>

              <div className="fake-checkbox registration__fake-checkbox">
                <div className={"fake-checkbox__icon"}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                {t("elitehighlight")}
              </div>

              <span>{t("indifeatures")}</span>

              <div className="registration__inner">
                <div className="registration__content">
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("freeregpost")}
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("recommendations")}
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("moderatorsupport")}
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("importprofile")}
                    <span> {t("createagain")}</span>
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("severalprofiles")}
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("storiesupload")} <span>{t("promoteprofile")}</span>
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("earnchatsandero")}
                  </p>
                </div>

                <div className="registration__content">
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("receiveorders")}
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("getverified")}
                    <Tippy title={t("check")} className={"registration__tippy"}>
                      {"«" + t("verification") + "»"}
                    </Tippy>
                    {t("freeorcall")}
                  </p>

                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("healthcontroldiscount")}
                    <Tippy
                      title={t("chekckid")}
                      className={"registration__tippy"}
                    >
                      {"«" + t("healthy") + "»"}
                    </Tippy>
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("detailedstatistics")}
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("freebackcheck")}
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("photocatalogue")}
                  </p>
                  <p className={"p1"}>
                    <i className={"icon icon-l icon--include"}></i>
                    {t("hideinsearch")}
                  </p>
                </div>
              </div>
            </div>

            {/* <div
                  className={`registration__description${
                      active.photograph ? " _active" : ""
                  }`}
              >
                <p className={"p1"}>
                  <i className={'icon icon-l icon--include'}></i>
                  {t('reganddisplay')}
                </p>
                <p className={"p1"}>
                  <i className={'icon icon-l icon--include'}></i>
                  {t('webportfolio')}{" "}
                  <span>
                  {t('videopres')}
                </span>
                </p>
                <p className={"p1"}>
                  <i className={'icon icon-l icon--include'}></i>
                  {t('ratingsreviews')}
                </p>

                <div className="fake-checkbox registration__fake-checkbox">
                  <div className={"fake-checkbox__icon"}>
                    <i className={'icon icon-l icon--include'}></i>
                  </div>
                  {t('regandearnnow')}
                </div>
              </div> */}
            <Button buttonType={"submit"} size={"l"} clazz={"button--green"}>
              {t("registration")}
            </Button>
          </div>
        </form>
      </section>

      {!!registrationState && (
        <RegistrationPopup text={active} dataName={dataName} />
      )}
    </main>
  );
};

export default RegistrationPage;
