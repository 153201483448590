import { Helmet } from "react-helmet";
import { Button, Icon, Loader } from "../ui";

interface PopupContainerProps {
  open: boolean;
  setOpen: any;
  children: any;
  isForm?: boolean;
  handleSubmit?: any;
  onSubmit?: any;
  title: string | any;
  handleCloseCustom?: () => void;
  size?: "default" | "edit" | "medium" | "large" | "superLarge";
  clazz: string;
  isLoading: boolean;
}

const PopupContainer = (props: PopupContainerProps) => {
  const {
    open,
    setOpen,
    isForm = true,
    handleSubmit,
    title,
    children,
    handleCloseCustom,
    size = "default",
    clazz,
    onSubmit,
    isLoading,
  } = props;

  const popupSize = {
    default: "",
    edit: "__edit",
    medium: "__medium",
    large: "__large",
    superLarge: "__super__large",
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!handleSubmit && !onSubmit) {
    return (
      <dialog open={open} onClick={handleCloseCustom || handleClose}>
        {open ? (
          <Helmet>
            <html className={"lock"}></html>
          </Helmet>
        ) : null}

        {isLoading && (
          <div className="loader__overlay">
            <Loader height={100} />
          </div>
        )}

        <form
          onClick={(e) => e.stopPropagation()}
          className={`popup-form__body${popupSize[size]} ${clazz || ""}`}
        >
          <Button
            onClick={handleCloseCustom || handleClose}
            clazz={"button__close"}
          >
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          <div className="popup-form__inner">
            <h3 className="title_h3">{title}</h3>
            {children}
          </div>
        </form>
      </dialog>
    );
  }

  if (isForm) {
    return (
      <dialog open={open} onClick={handleCloseCustom || handleClose}>
        {open ? (
          <Helmet>
            <html className={"lock"}></html>
          </Helmet>
        ) : null}

        {isLoading && (
          <div className="loader__overlay">
            <Loader height={100} />
          </div>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          onClick={(e) => e.stopPropagation()}
          className={`popup-form__body${popupSize[size]} ${clazz || ""}`}
        >
          <Button
            onClick={handleCloseCustom || handleClose}
            clazz={"button__close"}
          >
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          <div className="popup-form__inner">
            <h3 className="title_h3">{title}</h3>
            {children}
          </div>
        </form>
      </dialog>
    );
  }

  return (
    <dialog open={open} onClick={handleCloseCustom || handleClose}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <div
        onClick={(e) => e.stopPropagation()}
        className={`popup-form__body${popupSize[size]} ${clazz || ""}`}
      >
        <Button
          onClick={handleCloseCustom || handleClose}
          clazz={"button__close"}
        >
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner">
          <h3 className="title_h3">{title}</h3>
          {children}
        </div>
      </div>
    </dialog>
  );
};

export default PopupContainer;
