import { useRef, useState } from "react";
import { Icon } from "@/components/ui";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

export const Image = ({
  photo,
  index,
  movePhoto,
  isVideo = false,
  isStepComplete,
}) => {
  const { t: indi } = useTranslation("indi");
  const [, drag] = useDrag({
    type: isVideo ? "VIDEO" : "PHOTO",
    item: { type: isVideo ? "VIDEO" : "PHOTO", index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: isVideo ? "VIDEO" : "PHOTO",
    hover(item, monitor) {
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      movePhoto(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const dragRef = useRef(null);
  drag(drop(dragRef));

  return (
    <div ref={dragRef} className={`${isVideo ? "" : "create-page__upload-image"}`}>
      {photo ? (
        <>
          {isVideo ? (
            <video width="340px" height="190px" controls>
              <source src={photo} type="video/mp4" />
            </video>
          ) : (
            <img src={photo} alt={""} />
          )}
         
        </>
      ) : (
        <div
          className={`input--upload`}
          
        >
          <input
            type="file"
            multiple="multiple"
          ></input>
          <Icon clazz={"_green"} size={"l"} spritePath={"plus"} />
        </div>
      )}
    </div>
  );
};
