import { VideoContainer } from "./pageComponents";
import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import { useTranslation } from "react-i18next";

import "./videochatHistoryPage.scss";
import { Loader } from "@/components/ui";

const VideochatHistoryPage = () => {
  const token = localStorage.getItem("user");

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation("translation");

  const Res = () => {
    switch (true) {
      case isLoading: {
        return <Loader />;
      }

      case !!data.length: {
        return data?.map((i, index) => {
          return <VideoContainer key={index} data={i} />;
        });
      }

      default: {
        return (
          <h3 className="text-center mt-12">У Вас еще не было видеочатов</h3>
          //перевести
        );
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/calls`;
        const method = "GET";

        const data = await makeRequest({ route, method });

        setData(data.data);
        setIsLoading(false);
      } catch (error) {}
    };

    token && fetchData();
  }, [token]);

  return (
    <main>
      <section className={"videoHistiry-page__container"}>
        <h1>{t("videochats")}</h1>
        <Res />
      </section>
    </main>
  );
};

export default VideochatHistoryPage;
