import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PhotoUpload from "../UploadZone";

export const Video = ({ data, setData, show, setFiles }) => {
  const movePhoto = (dragIndex, hoverIndex) => {
    const draggedPhoto = data.files[dragIndex];
    const newPhotos = [...data.files];
    newPhotos.splice(dragIndex, 1);
    newPhotos.splice(hoverIndex, 0, draggedPhoto);
    setData({ files: newPhotos });
  };
  return (
    <DndProvider backend={HTML5Backend}>
      {show === "video" && (
        <PhotoUpload
          data={data}
          setData={setData}
          accept={{
            'video/mp4': ['.mp4'],
            'video/quicktime': ['.mov'],
            'video/x-ms-wmv': ['.wmv'],
            'video/x-msvideo': ['.avi'],
            'video/x-matroska': ['.mkv'],
          }}
          type="video"
          movePhoto={movePhoto}
        />
      )}
    </DndProvider>
  );
};
