import "./button.scss";

const Button = (props) => {
  const {
    children,
    size,
    clazz,
    buttonType = "button",
    title,
    square = false,
    onClick,
    disabled = false,
    counter,
  } = props;

  if (square === true) {
    return (
      <button
        onClick={onClick}
        type={buttonType ? buttonType : ""}
        title={title ? title : null}
        disabled={disabled}
        data-counter={counter}
        className={`button-square${size ? ` button-square-${size}` : ""}${
          clazz ? ` ${clazz}` : ""
        }${disabled ? " disabled" : ""}${counter < 1 ? " empty" : ""}`}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      onClick={onClick}
      type={buttonType ? buttonType : ""}
      title={title ? title : null}
      disabled={disabled}
      data-counter={counter}
      className={`button${size ? ` button-${size}` : ""}${
        clazz ? ` ${clazz}` : ""
      }${disabled ? " _disabled" : ""}${counter < 1 ? " empty" : ""}`}
    >
      {children}
    </button>
  );
};

// Button.propTypes = {
//   square: PropTypes.bool,
//   children: PropTypes.node,
//   size: PropTypes.string,
//   buttonType: PropTypes.string,
//   clazz: PropTypes.string,
// };
export default Button;
