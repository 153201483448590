import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserCountry } from "@/stores/slices/userSlice";

export function useCityChange() {
  const navigate = useNavigate();
  const location = useLocation();
  const allCities = useSelector(getUserCountry);
  const slugOfAllCities = allCities.map((city) => city.slug);

  const changeCity = (newCity) => {
    const { pathname, search } = location;
    const pathParts = pathname.split("/");
    const cityIndex = pathParts.findIndex((part) =>
      slugOfAllCities.includes(part)
    );

    if (cityIndex !== -1) {
      pathParts[cityIndex] = newCity;
      const newPath = pathParts.join("/") + search;
      navigate(newPath);
    }
  };

  return changeCity;
}

export default useCityChange;
