import { Link, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";

import { Icon } from "../index";

import "./avatar.scss";

const Avatar = ({
  size = "xxxl",
  avatarLink,
  profileStatus,
  onlyIcon = false,
}) => {
  const navigate = useNavigate();
  const lang = useSelector(getUserLang);

  const toLk = (e) => {
    e.preventDefault();
    navigate(`/${lang}${avatarLink}`);
  };

  if (profileStatus === "diamond") {
    return (
      <Link
        to={avatarLink}
        className={`avatar avatar-size-${size}`}
        onClick={(e) => toLk(e)}
      >
        <div className="avatar__icon">
          <Icon spritePath={"diamond"} size={size} />
        </div>
        {!onlyIcon && <span className={"avatar__text p2"}></span>}
      </Link>
    );
  }

  return (
    <Link
      to={avatarLink}
      className={`avatar avatar-size-${size} avatar--${profileStatus}`}
      onClick={(e) => toLk(e)}
    >
      <div className="avatar__icon">
        <Icon spritePath={"user"} size={size} />
      </div>
      {!onlyIcon && <span className={"avatar__text p2"}></span>}
    </Link>
  );
};

export default Avatar;
