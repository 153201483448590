import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanSorted,
  setAllModels,
  setPageDefault,
  setSearchValue,
  setSortedModelsByFilter,
  setStories,
} from "@/stores/slices/modelsState";

import "./select.scss";
import {
  getUserCurrentCity,
  setCity,
  setCurrentCity,
} from "@/stores/slices/userSlice";
import { showFilter } from "@/stores/slices/showFilterSlice";
import { resetAll, setSearchFilter } from "@/stores/slices/filterSlice";
import { clearTitle } from "@/stores/slices/titleSlice";
import { resetAllCounters } from "@/stores/slices/counterFilterSlice";
import useCityChange from "../../../hooks/useCityChange";
import { useNavigate } from "react-router-dom";

const SelectH1 = (props) => {
  const {
    mainClazz = "select",
    clazz,
    options,
    setValue = undefined,
    onChange = false,
    paramsOnChange = false,
    defaultTitle = "",
  } = props;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const filterOpen = useSelector(showFilter);
  const currentCity = useSelector(getUserCurrentCity);
  const changeCity = useCityChange();

  let [title, setTitle] = useState(
    defaultTitle ? defaultTitle : !!options.length ? options[0].title : ""
  );
  let [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    if (currentCity) {
      setTitle(currentCity.title);
    }
  }, [currentCity]);
  useEffect(() => {
    setTitle(currentCity.title);
  }, [filterOpen]);

  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target) &&
      !event.target.classList.contains("select__search")
    ) {
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  function setValues(i, paramsOnChange) {
    setTitle(i.title);
    !!setValue && setValue(i.title);
    if (onChange) {
      if (paramsOnChange) {
        onChange(i, paramsOnChange);
      } else {
        onChange(i.value ? i.value : i.name);
      }
    }
  }

  const handleClick = (i) => {
    setValues(i, paramsOnChange);
    const pathname = window.location.pathname;
    dispatch(setPageDefault());
    dispatch(setAllModels([]));
    dispatch(setStories([]));
    if (
      pathname.includes("lk") ||
      pathname.includes("favorites") ||
      pathname.includes("profile") ||
      pathname.includes("registration")
    ) {
      navigate("/");
    }
    changeCity(i.slug);
    dispatch(
      setCurrentCity({
        id: i.id,
        title: i.title,
        icon: i.icon,
        highlight: i.highlight,
        slug: i.slug,
      })
    );

    dispatch(setCity(i.slug));
    if (!filterOpen) {
      dispatch(setAllModels([]));
    }
    dispatch(resetAll());
    dispatch(setSearchFilter(false));
    dispatch(cleanSorted());
    dispatch(setSearchValue(""));
    dispatch(setSortedModelsByFilter(""));
    dispatch(clearTitle());
    dispatch(resetAllCounters());
  };

  const searchedData =
    searchVal && options
      ? options.filter((item) => {
          return item.title?.toLowerCase().includes(searchVal.toLowerCase());
        })
      : options;

  return (
    <span
      className={`${mainClazz}${clazz ? ` ${clazz}` : ""}${
        isOpen ? " select_active" : ""
      }`}
      onClick={() => setIsOpen((prev) => !prev)}
      ref={selectRef}
    >
      {title}

      {isOpen && (
        <span className="select__body">
          {!!options &&
            searchedData.map((i, index) => {
              return (
                <span
                  key={index + 1}
                  onClick={() => handleClick(i)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") handleClick(i);
                  }}
                  className={`select__item`}
                >
                  {i.title || i.name}
                </span>
              );
            })}
        </span>
      )}
    </span>
  );
};

export default SelectH1;
