import "./rules.scss";

import { Icon } from "@/components/ui";

import { useEffectWindowSize } from "../../../../../../hooks";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";

const ContainerBlock = (props) => {
  const windowWidth = useEffectWindowSize();

  const isSmallTablet = windowWidth < 1000.98;

  const size = isSmallTablet ? "5xl" : "6xl";

  const { text, spritePath, href } = props;
  return (
    <a href={href} className={"td-none rules__block"}>
      <Icon size={size} spritePath={spritePath} />
      <span>{text}</span>
    </a>
  );
};

const Rules = () => {
  const { t } = useTranslation("indi");
  const { t: d } = useTranslation("translation");
  const lang = useSelector(getUserLang);

  return (
    <div className={"rules__root"}>
      <h2>{d("guide")}</h2>
      <div className={"rules__main"}>
        <ContainerBlock
          text={t("findout")}
          spritePath={"instruction"}
          href={`/guide`}
        />
        <ContainerBlock
          text={t("opportunities")}
          spritePath={"euro"}
          href={`/guide/rules`}
        />
      </div>
      <span className={"rules__secure"}>
        <Icon size={"l"} spritePath={"secure"} />
        <span>{t("secured")}</span>
      </span>
    </div>
  );
};

export default Rules;
