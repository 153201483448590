import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mapModel: null,
    isMapShow: false,
    profilesCount: 0,
    oneModelPosition: null,
    gmapsApiReady: false
};

export const mapModel = createSlice({
    name: "mapModel",
    initialState,
    reducers: {
        setGmapsApiReady: (state, action) => {
            state.gmapsApiReady = action.payload
        },
        setOneModelPosition: (state, action) => {
            state.oneModelPosition = action.payload
        },
        setProfileCount: (state, action) => {
            state.profilesCount = action.payload
        },
        setMapModel: (state, action) => {
            state.mapModel = action.payload
        },
        setMapShow: (state, action) => {
            state.isMapShow = action.payload
        }
    },
});

export const {  setGmapsApiReady, setMapModel, setMapShow, setProfileCount, setOneModelPosition } = mapModel.actions;

export const getMapModel = (state) => state.mapModel.mapModel;
export const getMapIsShow = (state) => state.mapModel.isMapShow;
export const getProfilesCount = (state) => state.mapModel.profilesCount;
export const getOneModelPosition = (state) => state.mapModel.oneModelPosition;
export const getGmapsApiReady = (state) => state.mapModel.gmapsApiReady;

export default mapModel.reducer;