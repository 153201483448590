import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { Button, Checkbox, Dropdown } from "@/components/ui";
import {
  getUserCity,
  getUserLang,
  themeMode,
} from "@/stores/slices/userSlice.js";
import { useEffectWindowSize } from "../../hooks/index.js";
import { getSelectedCount } from "@/stores/slices/agencyFilterSlice";
import GirlCard from "../girlCard/GirlCard";

import "./agencyFilter.scss";
import baseUrl from "@/services/apiConfig.js";
import { setViewingStatus } from "@/stores/slices/popupSlice";
import { useTranslation } from "react-i18next";

const AgencyFilter = ({ props }) => {
  const { profiles_statistic, profiles } = props;

  const { t } = useTranslation("translation");
  const widthScreen = useEffectWindowSize();
  const dispatch = useDispatch();
  const theme = useSelector(themeMode);
  const lang = useSelector(getUserLang);
  const city = useSelector(getUserCity);

  const selectedModelsCount = useSelector(getSelectedCount);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (lang) {
      axios
        .get(`${baseUrl}profiles/new/1&lang=${lang}`)
        .then((response) => setData(response.data.profiles));
    }
  }, [lang, city.id]);

  const filterNavigationMore = {
    main_title: "Быстрый фильтр",
    options: [
      {
        id: "agencyEliteGirl",
        title: "Elite",
        value: "42",
        count: "1",
      },
      {
        id: "agencyVideochat",
        title: t("videochat"),
        value: "2",
        count: "2",
      },
      {
        id: "agencyVerify",
        title: t("verification"),
        value: "",
        count: profiles_statistic?.verify,
      },
      {
        id: "agencyHealth",
        title: t("healthy") + " - 57",
        value: "4",
        count: "4",
      },
      {
        id: "agencyStar",
        title: t("star") + " - 12",
        value: "5",
        count: "5",
      },
      {
        id: "agencyOnline",
        title: t("online") + " - 5",
        value: "6",
        count: profiles_statistic?.online && profiles_statistic?.online,
      },
      {
        id: "agencyNew",
        title: t("newmodels") + " - 8",
        value: "7",
        count: "7",
      },
      {
        id: "agencyCouples",
        title: t("couples") + " - 13",
        value: "8",
        count: "8",
      },
      {
        id: "agencyBDSM",
        title: t("bdsm") + " - 1",
        value: "9",
        count: "9",
      },
      {
        id: "agencyTrance",
        title: t("trans"),
        value: "10",
        count: "10",
      },
      {
        id: "agencyGuys",
        title: t("guys"),
        value: "11",
        count: "11",
      },
    ],
  };

  return (
    <section id={"models"} className="agency-filter __container">
      <div className="agency-filter__top mb-24">
        {profiles_statistic?.total && (
          <h3 className={"mb-0"}>
            {profiles_statistic?.total} {t("agencymodels")}
          </h3>
        )}
        <a href={"#orderToLook"} className={"hover-line color-green"}>
          Позвать несколько моделей на просмотр
        </a>
      </div>

      <div className="agency-filter__nav mb-48 mb-24-mob">
        {widthScreen > 1399.98 ? (
          filterNavigationMore.options.map((data) => {
            return (
              <Checkbox key={data.count} id={data.id} title={data.title} />
            );
          })
        ) : widthScreen > 1200.98 ? (
          <>
            {filterNavigationMore.options.slice(0, 7).map((data) => {
              return (
                <Checkbox key={data.count} id={data.id} title={data.title} />
              );
            })}

            <Dropdown
              title={filterNavigationMore.main_title}
              size={"xs"}
              clazzWrapper={"navigation__more"}
              leftIcon={"filter2"}
            >
              {filterNavigationMore.options.slice(7).map((i) => {
                return <Checkbox key={i.count} id={i.id} title={i.title} />;
              })}
            </Dropdown>
          </>
        ) : widthScreen > 1000.98 ? (
          <>
            {filterNavigationMore.options.slice(0, 5).map((data) => {
              return (
                <Checkbox key={data.count} id={data.id} title={data.title} />
              );
            })}

            <Dropdown
              title={filterNavigationMore.main_title}
              size={"xs"}
              clazzWrapper={"navigation__more"}
              leftIcon={"filter2"}
            >
              {filterNavigationMore.options.slice(5).map((i) => {
                return <Checkbox key={i.count} id={i.id} title={i.title} />;
              })}
            </Dropdown>
          </>
        ) : widthScreen > 600.98 ? (
          <>
            {filterNavigationMore.options.slice(0, 3).map((data) => {
              return (
                <Checkbox key={data.count} id={data.id} title={data.title} />
              );
            })}

            <Dropdown
              title={filterNavigationMore.main_title}
              size={"xs"}
              clazzWrapper={"navigation__more"}
              leftIcon={"filter2"}
            >
              {filterNavigationMore.options.slice(3).map((i) => {
                return <Checkbox key={i.count} id={i.id} title={i.title} />;
              })}
            </Dropdown>
          </>
        ) : (
          <Dropdown
            title={filterNavigationMore.main_title}
            size={"xs"}
            clazzWrapper={"navigation__more"}
            leftIcon={"filter2"}
          >
            {filterNavigationMore.options.map((i) => {
              return <Checkbox key={i.count} id={i.id} title={i.title} />;
            })}
          </Dropdown>
        )}
      </div>

      {profiles && (
        <div className={"grid-cards"}>
          {profiles.slice(0, 8).map((data) => (
            <GirlCard props={data} key={data.id} checkbox />
          ))}
        </div>
      )}

      <Button
        type="button"
        clazz={"navigation__show-more button--secondary"}
        size={"l"}
      >
        {t("showmore")}
      </Button>

      <div id={"orderToLook"} className="agency-filter__box">
        <div className="agency-filter__inner">
          <span className="title title_h3 mb-12">{t("demomodels")}</span>
          <p className={"p1 color-700"}>
            Вы можете заказать на выезд несколько моделей для просмотра, чтобы
            при встрече выбрать лучшую
          </p>
        </div>

        <div className="agency-filter__inner">
          <div className={"agency-filter__counter"}>
            <span className="agency-filter__count">
              {t("numberofmodels")}
              <span className={"title title_h2"}>{selectedModelsCount}</span>
            </span>
            <Checkbox
              id={"agencyFilterAll"}
              name={"all"}
              clazzLabel={"p-0"}
              title={t("selectall")}
            />
          </div>

          <div className={"d-flex fd-column gap-12"}>
            <span>{t("priceforone")}</span>
            <span className={"title title_h2"}>20 € </span>
          </div>

          <div className={"d-flex fd-column gap-12"}>
            <span>{t("priceforselected")}</span>
            <span className={"title title_h2"}>
              {selectedModelsCount * 20} €
            </span>
          </div>
        </div>
        <Button
          size={"l"}
          clazz={"button--green"}
          disabled={selectedModelsCount > 0 ? false : true}
          onClick={() => dispatch(setViewingStatus(true))}
        >
          {t("order")}
        </Button>
      </div>
    </section>
  );
};

export default AgencyFilter;
