import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Icon, InputInLabel } from "@/components/ui";
import { useTranslation } from "react-i18next";
import PhotoUpload from "../UploadZone";

export const Ero = ({
  data,
  setData,
  dataVideo,
  setDataVideo,
  show,
  setFiles,
  setPrice,
  eroPrice,
  errorPrice,
  setError,
  setDesc,
  eroDesc,
  eroPriceRef
}) => {
  const { t } = useTranslation("translation");
  const { t:indi } = useTranslation("indi");

  const ImportantBlock = () => {
    return (
      <div className="attention-box important">
        <span className="error">{indi("important") + "!"}</span>
        <span>{indi("pesonalresp")}</span>
        <a target="_blank" href="/ru/guide/rules" className="green">
          {indi("portalules")}
        </a>
        <span>{indi("notsanction")}</span>
        <br></br>
        <div className="important-icons">
          <span className={"girl-card__icon"} title={t("verification")}>
            <Icon spritePath={"verify-fill"} size={"l"} />
            {t("verification")}
          </span>
          <span className="ml-6">{t("or")}</span>
          <span className={"girl-card__icon ml-6 mr-6"} title={t("healthy")}>
            <Icon spritePath={"health-fill"} size={"l"} />
            {t("healthy")}
          </span>
        </div>
          <span>{indi("toget")}</span>
      </div>
    );
  };
  const movePhoto = (dragIndex, hoverIndex) => {
    const draggedPhoto = data.files[dragIndex];
    const newPhotos = [...data.files];
    newPhotos.splice(dragIndex, 1);
    newPhotos.splice(hoverIndex, 0, draggedPhoto);
    setData({ files: newPhotos });
  };
  const movePhotoVideo = (dragIndex, hoverIndex) => {
    const draggedPhoto = dataVideo[dragIndex];
    const newPhotos = [...dataVideo];
    newPhotos.splice(dragIndex, 1);
    newPhotos.splice(hoverIndex, 0, draggedPhoto);
    setDataVideo(newPhotos);
  };
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {show === "ero" && (
          <>
            <h3 className="upload-ero--title">{indi('erophoto')}</h3>
            <PhotoUpload
              movePhoto={movePhoto}
              data={data}
              setData={setData}
              accept={{
                "image/jpeg": [".jpg", ".jpeg"],
                "image/png": [".png"],
                "image/webp": [".webp"],
              }}
              type="photo"
              addText={false}
            />
            <h3 className="upload-ero--title">{indi('erovid')}</h3>
            <PhotoUpload
              movePhoto={movePhotoVideo}
              data={dataVideo}
              setData={setDataVideo}
              accept={{
                "video/mp4": [".mp4"],
                "video/quicktime": [".mov"],
                "video/x-ms-wmv": [".wmv"],
                "video/x-msvideo": [".avi"],
                "video/x-matroska": [".mkv"],
              }}
              type="video"
              addText={false}
            />
            <ImportantBlock />
            {(!!data.files.length ||
              !!dataVideo.files.length) && (
              <div className="upload-ero__info" ref={eroPriceRef}>
                <h3 className="upload-ero--title">{indi("accessprice")}</h3>
                <div className="upload-ero__recommendation">
                  <InputInLabel
                    type={"number"}
                    id={"upload-price"}
                    value={eroPrice}
                    placeholder={"5 €"}
                    clazz={`${errorPrice ? "_error" : ""} _required`}
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setError(false);
                    }}
                  >
                    {"Сумма в €"}
                    {errorPrice && (
                      <span class="error create-error">
                        {t('required')}
                      </span>
                    )}
                  </InputInLabel>
                  <div>
                    <span>{indi("recommendedprice")}: 5-10 € </span>
                    <span>{indi("maxviews")}</span>
                  </div>
                </div>
                <span className="upload-ero__watch color-600">
                  {indi("thisamount")}
                  <Icon size={"s"} spritePath={"info"} />
                  <span className="upload-ero__watch-notification">
                    {indi("vipfree")}
                  </span>
                </span>
                <h3 className="upload-ero--title">{indi("descero")}</h3>
                <span className="mb-16 color-600">{indi("adddescero")}</span>
                <InputInLabel
                  type={"text"}
                  id={"upload-desc"}
                  value={eroDesc}
                  placeholder={
                    "На фото я обнажённая, видео демонстрирует мою потрясающую фигуру. Одна фотография – моя киска крупным планом."
                  }
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                >
                  {indi("desc")}
                </InputInLabel>
              </div>
            )}
          </>
        )}
      </DndProvider>
    </>
  );
};
