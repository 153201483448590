import { useEffect, useMemo, useRef, useState } from "react";
import "./services.scss";
import { number, object, string } from "yup";
import MultiRangeSlider from "multi-range-slider-react";

import {
  Button,
  Checkbox,
  Icon,
  InputInLabel,
  Loader,
  Select,
} from "@/components/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { getServicesWords } from "@/stores/slices/userSlice";
import { makeRequest } from "@/services/makeRequest";

const data = {
  for: [
    { id: 65, title: "Мужчин", value: "man" },
    { id: 64, title: "Девушек", value: "women" },
    { id: 63, title: "Пары", value: "couples" },
    { id: 62, title: "Группе", value: "groups" },
  ],
};

const SelectWithButtons = ({ buttons, buttonValue, defaultValue }) => {
  const [selectedButton, setSelectedButton] = useState(defaultValue);

  const handleButtonClick = (value) => {
    const isIdAlreadySelected = selectedButton.includes(value);
    if (isIdAlreadySelected) {
      setSelectedButton((prev) =>
        prev.filter((buttonId) => buttonId !== value)
      );
      buttonValue((prev) => prev.filter((buttonId) => buttonId !== value));
    } else {
      setSelectedButton((prev) => [...prev, value]);
      buttonValue((prev) => [...prev, value]);
    }
  };

  return (
    <div className="create-page__input-container w-100">
      <div className="create-page__input-buttons">
        {buttons.map((i) => (
          <label
            className={`checkbox-button${
              selectedButton.includes(i.value) ? " checkbox-button_checked" : ""
            }`}
            htmlFor={i.id}
            tabIndex={0}
          >
            <input
              onClick={() => handleButtonClick(i.value)}
              tabIndex={1}
              id={i.id}
              name={i.name}
              checked={selectedButton === i.value}
              value={i.value}
            />
            {i.title}
          </label>
        ))}
      </div>
    </div>
  );
};

const ServicesSection = ({ title, data, servicesData }) => {
  const createIdsArray = (servicesArray) => {
    const idsArray = [];

    servicesArray.forEach((service) => {
      idsArray.push(service.id);
    });

    return idsArray;
  };

  const idsArray = createIdsArray(servicesData);

  const [isActive, setIsActive] = useState(idsArray);

  const middleIndex = Math.ceil(data.length / 2);
  const firstColumn = data.slice(0, middleIndex);
  const secondColumn = data.slice(middleIndex);

  const handleCheckboxChange = (item) => {
    if (isActive.includes(item.id)) {
      const objIndex = servicesData.findIndex((obj) => obj.id === item.id);
      servicesData.splice(objIndex, 1);
      setIsActive(isActive.filter((id) => id !== item.id));
    } else {
      servicesData.push({
        id: item.id,
      });
      setIsActive([...isActive, item.id]);
    }
  };

  const Column = ({ data, servicesArr }) => {
    const addNoted = (item, e) => {
      const value = e.target.value;
      const objectToAddNote = servicesArr?.filter(
        (obj) => obj.id === item.id
      )[0];
      objectToAddNote.note = value;
    };
    const addPrice = (item, e) => {
      const value = e.target.value;
      const objectToAddNote = servicesArr?.filter(
        (obj) => obj.id === item.id
      )[0];
      objectToAddNote.additional_cost = value;
    };

    const addTag = (item, e) => {
      const checked = e.target.previousElementSibling.checked;
      const objectToAddNote = servicesArr?.filter(
        (obj) => obj.id === item.id
      )[0];
      objectToAddNote.as_tag = !checked;
    };

    return (
      <div className="form-services__section--column">
        {!!data.length &&
          data.map((item) => {
            return (
              <div className="form-services__section--item" key={item.id}>
                <Checkbox
                  title={item.name}
                  checked={!!isActive.includes(item.id)}
                  disabled={false}
                  onClick={() => handleCheckboxChange(item)}
                />
                {isActive.includes(item.id) && (
                  <Checkbox
                    key={title}
                    title={"Важное"}
                    checked={
                      servicesArr?.filter((obj) => obj.id === item.id)[0]
                        ?.as_tag
                    }
                    clazz={"_no-border services-category"}
                    disabled={false}
                    onClick={(e) => addTag(item, e)}
                  />
                )}
                <div
                  className={`form-services__section--comment ${
                    isActive.includes(item.id) ? "_open" : ""
                  }`}
                >
                  <InputInLabel
                    type={"text"}
                    id={`create-comment-${item.id}`}
                    placeholder={""}
                    value={
                      servicesArr?.filter((obj) => obj.id === item.id)[0]?.note
                    }
                    onChange={(e) => addNoted(item, e)}
                  >
                    {"Комментарий"}
                  </InputInLabel>
                  <InputInLabel
                    type={"number"}
                    id={`create-comment-price--${item.id}`}
                    placeholder={"15 €"}
                    value={
                      servicesArr?.filter((obj) => obj.id === item.id)[0]
                        ?.additional_cost
                    }
                    onChange={(e) => addPrice(item, e)}
                  >
                    {"Доп. плата"}
                  </InputInLabel>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="form-services__section">
      <div className="form-services__section--title">
        <span>{title}</span>
      </div>
      <div className="form-services__section--checkbox">
        <Column servicesArr={servicesData} data={firstColumn} />
        <Column servicesArr={servicesData} data={secondColumn} />
      </div>
    </div>
  );
};

const Services = ({
  setAvailableSteps,
  changeStep,
  step,
  rootElem,
  formData,
  setFormData,
}) => {
  const formSchema = object().shape({});
  const token = localStorage.getItem("user");

  const servicesData = useMemo(
    () => (formData.services ? formData.services : []),
    []
  );

  const formDataServicesFor = [
    formData.for_couples && "couples",
    formData.for_groups && "groups",
    formData.for_men && "man",
    formData.for_women && "women",
  ].filter((item) => item);
  const [servicesFor, setServicesFor] = useState(
    formData ? formDataServicesFor : []
  );

  const backStep = () => {
    changeStep((prev) => prev - 1);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [descValue, setDescValue] = useState(
    formData.desc ? formData.desc : ""
  );
  const [isDescLoad, setIsDescLoad] = useState(false);
  const [isPreferLoad, setIsPreferLoad] = useState(false);
  const [preferValue, setPreferValue] = useState(
    formData.prefer ? formData.prefer : ""
  );

  const generateText = () => {
    const fetchData = async (type) => {
      if (type === "preferences") {
        setIsPreferLoad(true);
      } else {
        setIsDescLoad(true);
      }
      try {
        const route = `user/${formData.slug}/get-${type}`;

        const method = "GET";

        const result = await makeRequest({ route, method });
        if (result) {
          if (type === "preferences") {
            setPreferValue(result[type]);
          } else {
            setDescValue(result[type]);
          }
        }
      } catch (error) {}
      if (type === "preferences") {
        setIsPreferLoad(false);
      } else {
        setIsDescLoad(false);
      }
    };

    fetchData("preferences");
    fetchData("description");
  };

  const onSubmit = (data) => {
    const fetchData = async () => {
      try {
        const route = `user/profile/${formData.slug}`;

        const method = "PUT";
        const payload = {
          services: servicesData,
          for_men: servicesFor.includes("man") ? 1 : 0,
          for_women: servicesFor.includes("women") ? 1 : 0,
          for_couples: servicesFor.includes("couples") ? 1 : 0,
          for_groups: servicesFor.includes("groups") ? 1 : 0,
          ...(!!descValue && { description: descValue }),
          ...(!!preferValue && { preference: preferValue }),
        };

        const result = await makeRequest({ route, method, payload });
        if (result.status) {
          setAvailableSteps((prev) => [...prev, 5]);
          changeStep((prev) => prev + 1);
          setFormData((prevData) => ({
            ...prevData,
            ...payload,
            desc: descValue,
            prefer: preferValue,
          }));
          rootElem.scrollIntoView({
            behavior: "smooth",
          });
        }
      } catch (error) {}
    };

    token && fetchData();
  };

  const services = useSelector(getServicesWords);

  const servicesArray =
    !!Object.entries(services) &&
    Object.keys(services).map((key) => {
      const item = services[key];

      return {
        title: item.title,
        services: Object.keys(item.services).map((serviceKey) => {
          const service = item.services[serviceKey];
          return {
            name: service.name,
            id: parseInt(serviceKey),
            as_tag: true,
          };
        }),
      };
    });

  const validateData = () => {};
  return (
    <>
      <h2>Услуги</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`create-page__form services`}
      >
        {/* <div className="form-services__top-item">
          <span>Гендер/ориентация</span>
          <SelectWithButtons type={'radio'} buttonValue={setOrientation} buttons={gender === "1" ? data.orientation1 : gender === "3" ||  gender === "2"  ? data.orientation2 : data.orientation1} >
            <Select
              onChange={setGender}
              name={"create-gender"}
              options={options.gender}
              clazz={"button button-xs button--main"}
              arrowSize={"m"}
            />
          </SelectWithButtons>
        </div> */}
        <div className="form-services__top-item services-for">
          <span>Оказываю услуги для</span>
          <SelectWithButtons
            type={"checkbox"}
            buttonValue={setServicesFor}
            buttons={data.for}
            withoutSelect={true}
            defaultValue={servicesFor}
          ></SelectWithButtons>
        </div>
        <span className="form-services__desc">
          По каждой услуге Вы можете написать краткий комментарий или
          дополнение, а также установить доп. цену, если эта услуга не входит в
          стандартный набор. Кроме того, Вы можете отметить до 5-ти своих услуг
          - как самые важные, которые будут выделены цветом, а также попадут в
          отображение Вашей малой анкеты.
        </span>
        {servicesArray?.map((item, index) => {
          return (
            <ServicesSection
              servicesData={servicesData}
              title={item.title}
              data={item.services}
              key={index}
            />
          );
        })}
        <div className="form-services__preference">
          <span className="form-services__preference--title">
            Описание и предпочтения
          </span>
          <div className="form-services__preference--block">
            <span>
              Вы можете сгенерировать текст автоматически искусственным
              интеллектом на основании параметров внешности и оказываемых услуг
            </span>
            <Button
              buttonType={"button"}
              size={"l"}
              square={false}
              clazz="button--primary"
              onClick={() => generateText()}
            >
              {"Сгенерировать текст"}
            </Button>
          </div>
          <div className="form-services__preference--inputs">
            {!isDescLoad ? (
              <InputInLabel
                type={"textarea"}
                id={"create-AI"}
                placeholder={"Я..."}
                value={descValue}
              >
                {"Опишите себя"}
              </InputInLabel>
            ) : (
              <Loader />
            )}
            {!isPreferLoad ? (
              <InputInLabel
                type={"textarea"}
                id={"create-AI2"}
                placeholder={"Я люблю когда..."}
                value={preferValue}
              >
                {"Ваши предпочтения в сексе и на свиданиях"}
              </InputInLabel>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <div className="create-form__buttons">
          <Button
            size={"l"}
            square={false}
            clazz="button--secondary"
            onClick={() => backStep()}
          >
            {"Назад"}
          </Button>
          <Button
            onClick={() => validateData()}
            buttonType={"submit"}
            size={"l"}
            square={false}
            clazz="button--green"
          >
            {"Продолжить"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default Services;
