import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import img1 from "../../../../assets/img/pick-profile/img1.webp";
import { ButtonLink, Icon } from "@/components/ui";
import img2 from "../../../../assets/img/pick-profile/img2.webp";
import img3 from "../../../../assets/img/pick-profile/img3.webp";
import { Link } from "react-router-dom";

const AccountAgency = () => {
  return (
    <main>
      <section className={"profile-page__container"}>
        <h1 className="title profile-page__title">
          Страница аккаунта агентства
        </h1>
        <h3 className={"title profile-page__subtitle"}>
          Standart / Elite Lady
        </h3>

        <Swiper
          className={"profile-page__slider"}
          modules={[Navigation]}
          spaceBetween={12}
          freeMode={true}
          width={392}
          slidesPerView={3}
          breakpoints={{
            0: {
              width: 224,
              slidesPerView: 1,
            },
            767.98: {
              width: 392,
              slidesPerView: 1,
            },
            1399.98: {
              slidesPerView: 3,
              width: "auto",
            },
          }}
        >
          <SwiperSlide>
            <div className="profile-page__img">
              <img src={img1} alt="" />
            </div>
            <p className={"p1"}>
              Элитные анкеты выделяются в списке анкет лэйблом Elite Lady
              (показать знак), который притягивает внимание
            </p>
            <div className={"fake-checkbox"}>
              <div className={"fake-checkbox__icon"}>
                <Icon spritePath={"check"} size={"m"} />
              </div>
              <p className={"fake-checkbox__text p1"}>
                На 25% больше посетителей и открытий контактов
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="profile-page__img">
              <img src={img2} alt="" />
            </div>
            <p className={"p1"}>
              Этот же знак будет присутствовать и в Вашей анкете, рядом с
              именем, подчёркивая Ваш статус
            </p>
            <div className={"fake-checkbox"}>
              <div className={"fake-checkbox__icon"}>
                <Icon spritePath={"check"} size={"m"} />
              </div>
              <p className={"fake-checkbox__text p1"}>
                На 10% больше заказов от богатых клиентов
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="profile-page__img">
              <img src={img3} alt="" />
            </div>
            <p className={"p1"}>
              Только элитные анкеты отображаются на отдельной странице{" "}
              <Link to={"#"}>https://lady4love.com/elitelady</Link>, которую
              посещают преимущественно VIP-посетители
            </p>
            <div className={"fake-checkbox"}>
              <div className={"fake-checkbox__icon"}>
                <Icon spritePath={"check"} size={"m"} />
              </div>
              <p className={"fake-checkbox__text p1"}>
                Более 30 000 посетителей в месяц
              </p>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="profile-page__bottom">
          <div className={"profile-page__box"}>
            <ButtonLink size={"l"} clazz={"button--secondary"}>
              Оставить Стандарт
            </ButtonLink>
            <span>Бесплатно</span>
          </div>
          <div className={"profile-page__box"}>
            <ButtonLink size={"l"} clazz={"button--green"}>
              Выбрать Elite Lady
            </ButtonLink>
            <div className="profile-page__wrapper">
              <span className={"profile-page__old-price"}>50 €</span>
              <span>25 €</span>
              <p className={"p1"}>Первый месяц</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AccountAgency;
