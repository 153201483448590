import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useLadyService from "@/services/LadyService";
import { useEffect, useState } from "react";
import {
  getAllModels,
  getStories,
  getAllSorted,
  setStoriesId,
  setStories,
} from "@/stores/slices/modelsState";
import { filterPlace, isFilterSearch } from "@/stores/slices/filterSlice";
import { setStoriesStatus } from "@/stores/slices/popupSlice";
import { getUserCurrentCity, getUserLang } from "@/stores/slices/userSlice";
import { makeRequest } from "@/services/makeRequest";
import { useTranslation } from "react-i18next";

import "./storiesSlider.scss";

const StoriesSlider = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");

  const valueNavigation = useSelector(getAllSorted);
  const isFilterValue = useSelector(isFilterSearch);
  const valuePlace = useSelector(filterPlace);
  const [isRenderElem, setIsRenderElem] = useState(true);

  useEffect(() => {
    if (valueNavigation.length > 0 || valuePlace.length || isFilterValue) {
      setIsRenderElem(false);
    } else {
      setIsRenderElem(true);
    }
  }, [valueNavigation, isFilterValue]);

  const lang = useSelector(getUserLang);
  const city = useSelector(getUserCurrentCity);
  const [page, setPage] = useState(1);

  const stories = useSelector(getStories);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `stories/${city.id}`;
        const method = "GET";
        const payload = {
          lang,
          page,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.stories;
          dispatch(setStories(result));
        }
      } catch (error) {}
    };

    lang && page && city.id && stories.length === 0 && fetchData();
  }, [lang, page, city.id]);

  const StoriesCircle = (props) => {
    const [isHover, setIsHover] = useState(false);
    const { photos, name, age } = props;
    return (
      <>
        <button
          onMouseLeave={() => setIsHover(false)}
          onMouseEnter={() => setIsHover(true)}
          className="stories-circle__button"
        >
          <span className={`stories-circle__image${isHover ? " _hover" : ""}`}>
            <img src={photos} alt="" />
          </span>
        </button>
        <Link
          onMouseLeave={() => setIsHover(false)}
          onMouseEnter={() => setIsHover(true)}
          className="stories-circle__name hover-line"
        >
          {name}
        </Link>
        <span
          onClick={(e) => e.stopPropagation()}
          className="stories-circle__age"
        >
          {age} {t("years")}
        </span>
      </>
    );
  };

  if (isRenderElem && !!stories.length) {
    return (
      <Swiper
        className={"stories-circle"}
        breakpoints={{
          0: {
            spaceBetween: 30,
            slidesPerView: "auto",
          },
          1399.98: {
            slidesPerView: 7,
            spaceBetween: 0,
          },
        }}
      >
        {stories?.length > 0 &&
          stories.map((i, index) => {
            return (
              <SwiperSlide
                onClick={() =>
                  dispatch(
                    setStoriesStatus(true),
                    dispatch(setStoriesId(index))
                  )
                }
                key={i.id}
                className={`stories-circle__item`}
              >
                <StoriesCircle
                  photos={i.profile_photo}
                  name={i.name}
                  age={i.age}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    );
  }
};

export default StoriesSlider;
