import { number, object, ref, string } from "yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setRegistrationStatus,
  getRegistrationStatus,
  setAuthorizationStatus,
  setForgotPasswordStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getUserLang,
  setApiUserReady,
  setUserId,
  setUserType,
} from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import baseUrl from "@/services/apiConfig";

const RegistrationPopup = (props) => {
  const { dataName } = props;

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");
  const [formErrors, setFormErrors] = useState({});
  const [phoneValue, setPhoneValue] = useState("");

  const formSchema = object().shape({
    email: string().email().required(t("emailisnes")),
    // phone: number().typeError(t('phonenumerrequired')).required(),
    phone: string().test(
      "phone-is-empty",
      t("phonenumerrequired"),
      function () {
        return !!phoneValue.replace("+", "");
      }
    ),
    password: string().min(6, t("min6")).required(),
    password_confirmation: string()
      .min(6, t("min6"))
      .required()
      .oneOf([ref("password")], t("passdonot")),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onClose = () => {
    reset();
    setPhoneValue("");
    dispatch(setRegistrationStatus(false));
    setFormErrors({});
  };

  const dispatch = useDispatch();
  const registrationState = useSelector(getRegistrationStatus);

  const changeText = () => {
    const data = props.text;
    if (data.user) {
      return t("client");
    }
    if (data.indi) {
      return t("indireg");
    }
    if (data.agency) {
      return "Агенство";
    }
    // if (data.photograph) {
    //   return t("photographer");
    // }
  };

  const navigate = useNavigate();

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (!phoneValue) {
      return;
    }

    axios
      .post(`${baseUrl}auth/register`, {
        user_type: dataName,
        phone: phoneValue.replace(/[" "+]/g, ""),
        email: data.email,
        password: data.password,
      })
      .then((response) => {
        if (response.status === 201) {
          axios
            .post(`${baseUrl}auth/login`, {
              identity: data.email,
              password: data.password,
            })
            .then((response) => {
              if (response.status === 200) {
                const token = response.data.token;
                localStorage.setItem("user", token);
                axios
                  .request({
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                    method: "GET",
                    url: `${baseUrl}user/me`,
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      const userType = response.data.data.user_type;
                      const userId = response.data.data.id;
                      dispatch(setUserId(userId));
                      if (userType) {
                        dispatch(setUserType(userType));

                        if (userType === "indi") {
                          navigate(`/lk/${userType}/profiles`);
                        } else {
                          navigate(`/lk/${userType}`);
                        }
                      }

                      try {
                        const currentToken = localStorage.getItem("user");

                        const arrFavorites = JSON.parse(
                          localStorage.getItem("favorites")
                        );

                        if (!!arrFavorites.length) {
                          axios
                            .request({
                              headers: {
                                Authorization: `Bearer ${currentToken}`,
                              },
                              method: "POST",
                              url: `${baseUrl}user/favorites/import`,
                              data: {
                                profiles: arrFavorites,
                              },
                            })
                            .then((response) => {
                              if (response.status === 200) {
                                localStorage.removeItem("favorites");
                              }
                            });
                        }
                      } catch (error) {}
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                onClose();
              }
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {
        const errorStatus = error.response.status;
        if (errorStatus === 400) {
          // const errorMessage = error.response.data.message
          // toast.error(errorMessage);
          setFormErrors({
            login: {
              type: "is-email-or-phone",
              message: indi("userexist"),
            },
          });
        }
      });
  };

  const navigateToAuthorisation = () => {
    reset();
    dispatch(setAuthorizationStatus(true));
    dispatch(setRegistrationStatus(false));
  };

  return (
    <dialog
      open={!!registrationState}
      onClick={() => onClose()}
      className="popup-form registration-popup"
    >
      {!!registrationState ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(onSubmit)}
        className="popup-form__body"
      >
        <Button onClick={() => onClose()} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>
        <div className="popup-form__inner text-center">
          <h2 className="popup-form__title registration-popup__title">
            {t("registration")}
          </h2>
          <span className="title_h3">
            <span className={"color-600"}>{t("chooseaccount") + ":"}</span>{" "}
            {changeText()}
          </span>
        </div>
        <div className="popup-form__inner">
          <InputInLabel
            clazz={`${errors.email ? " error" : ""}`}
            id={"loginRegistration"}
            type={"email"}
            placeholder={"example@google.com"}
            register={{ ...register("email") }}
            autocomplete={"email"}
          >
            {(errors.email && (
              <span className={"input-label__error"}>
                {errors.email.message}
              </span>
            )) || <span>Email</span>}
          </InputInLabel>

          <InputInLabel
            clazz={`${errors.phone ? " error" : ""}`}
            id={"phoneRegistration"}
            title={t("pass")}
            type={"tel"}
            register={{ ...register("phone") }}
            autocomplete={"tel"}
            maskValue={phoneValue}
            setMaskValue={setPhoneValue}
          >
            {(errors.phone && (
              <span className={"input-label__error"}>
                {errors.phone.message}
              </span>
            )) || <span>{t("phonenumber")}</span>}
          </InputInLabel>

          <InputInLabel
            clazz={`${errors.password ? " error" : ""}`}
            id={"passRegistration"}
            title={t("pass")}
            type={"password"}
            placeholder={"**********"}
            register={{ ...register("password") }}
            autocomplete={"new-password"}
          />

          <InputInLabel
            clazz={`${errors.password_confirmation ? " error" : ""}`}
            id={"passConfirmRegistration"}
            title={t("repeatpassword")}
            type={"password"}
            placeholder={"**********"}
            register={{ ...register("password_confirmation") }}
            autocomplete={"new-password"}
          />

          {(formErrors.login || errors.password_confirmation) && (
            <span className={"popup-form__error"}>
              {formErrors.login?.message ||
                errors.password_confirmation.message}
            </span>
          )}

          <Button size={"l"} buttonType={"submit"} clazz={"button--green"}>
            {" "}
            {t("register")}
          </Button>
          <p className={"text-center fz-12"}>
            {t("pressreg")}{" "}
            <Link to={"/agreement"}>{t("termsofserviceagree")}</Link>
          </p>
          <p className={"text-center fz-12"}>
            {""} <Link to={"/gdpr"}>{t("privacypolicy")}</Link>
          </p>
        </div>
        <hr />
        <div className="popup-form__box popup-form__bottom-link">
          <span className={"text-center fz-12 color-500"}>
            {t("haveaccount")}
          </span>
          <button type={"button"} onClick={() => navigateToAuthorisation()}>
            {t("login")}
          </button>
        </div>
      </form>
    </dialog>
  );
};
export default RegistrationPopup;
