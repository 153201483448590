import example1 from "../../../../../../../assets/img/examplePhotos/verExamplePlus.svg";
import unilabs20 from "../../../../../../../assets/img/unilabs20.svg";
import unilabs20light from "../../../../../../../assets/img/unilabs20light.svg";

import { Button, Icon, UploadZone } from "@/components/ui";
import ExampleImage from "../exampleImage/ExampleImage";
import WaitingAdmin from "../waitingAdmin/WaitingAdmin";
import VerApprove from "../verApprove/VerApprove";

import "./verHealth.scss";

import { useSelector } from "react-redux";
import { themeMode } from "@/stores/slices/userSlice";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { DiscountToHealthPopup } from "../../../../../../popups";

const VerHealth = ({ healthRef }) => {
  const theme = useSelector(themeMode);
  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");

  const src = theme === "dark" ? unilabs20light : unilabs20;

  const [image, setImage] = useState(null);
  const [imageHealth, setImageHealth] = useState(null);

  const [isOpenDiscount, setIsOpenDiscount] = useState(false);

  const handleOpenDiscount = () => {
    setIsOpenDiscount(true);
  };

  return (
    <>
      {isOpenDiscount && (
        <DiscountToHealthPopup
          open={isOpenDiscount}
          setOpen={setIsOpenDiscount}
        />
      )}
      <div className="health control" ref={healthRef}>
        <div className="health__root">
          <div className="d-flex fd-column">
            <div className="health__title">
              <Icon spritePath={"health-fill"} size={"m"} clazz={"_green"} />
              <span className="title">{t("healthy")}</span>
            </div>
            <span className="color-main mt-12">{indi("healthcontrolrec")}</span>
            <a href="/" className="green mt-12">
              {indi("learnmoreabout")}
            </a>
            <span className="p1 color-main mt-16">{indi("confrimid")}</span>
            <span className="mt-4">{indi("uploadid")}</span>
          </div>
          <div className="d-flex fd-column">
            <img src={src} alt="unilabs" className="h-48 color-main" />
            <span className="color-main mt-12">{indi("discountstds")}</span>
            <Button
              title={indi("qrcodediscount")}
              clazz={"button--primary justify-center mt-16"}
              size={"s"}
              onClick={handleOpenDiscount}
            >
              {indi("qrcodediscount")}
            </Button>
          </div>
        </div>

        {!image ? (
          <div className="verads__photo mt-32">
            <div className="verads__examplePhoto">
              {indi("example")}
              <img src={example1} alt={indi("example")} />
            </div>
            <UploadZone
              setData={setImage}
              mainText={indi("uploadphoto")}
              subText={indi("photodisplayid")}
            />
          </div>
        ) : (
          <div className="verads__photo mt-32">
            <ExampleImage image={image} setImage={setImage} />
            <WaitingAdmin />
          </div>
        )}
        {!imageHealth ? (
          <div className="verads__photo mt-32">
            <div className="verads__examplePhoto">
              {indi("example")}
              <img src={example1} alt={indi("example")} />
            </div>
            <UploadZone
              setData={setImageHealth}
              mainText={indi("uploadhealtres")}
              subText={indi("monthlychecks")}
            />
          </div>
        ) : (
          <div className="verads__photo mt-32">
            <ExampleImage image={imageHealth} setImage={setImageHealth} />
            <WaitingAdmin />
          </div>
        )}
        <div className="verads__photo mt-32">
          {/* <ExampleImage data={image} setData={setImage} /> */}
          <VerApprove
            spritePath={"health-fill"}
            textGreen={t("healthy")}
            text={`${indi("forallads")} ${indi("until")} ${"25.04.2024"}`}
          />
        </div>
      </div>
    </>
  );
};

export default VerHealth;
