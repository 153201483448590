import { Icon } from "@/components/ui";
import "./verHeader.scss";

import { useEffectWindowSize } from "../../../../../../../hooks";
import { useTranslation } from "react-i18next";

const VerHeader = (props) => {
  const { scrollToVer, scrollToHealt, data } = props;
  const windowWidth = useEffectWindowSize();

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");

  const { profiles_count } = data;

  const isSqure = windowWidth < 500;

  const Ver = () => {
    switch (true) {
      case windowWidth >= 1210: {
        return (
          <div className="ver__block__info">
            <span className="p2 color-green">
              {indi("adsver")} ({profiles_count})
            </span>
            <span className="p3 color-600">
              {indi("valid")} {indi("until")} 21.05.2024
            </span>
          </div>
        );
      }

      default: {
        return (
          <span className="p3 color-green ml-4">
            {indi("until")} 21.05.2024
          </span>
        );
      }
    }
  };

  const Health = () => {
    switch (true) {
      case windowWidth >= 1210: {
        return (
          <div className="ver__block__info">
            <span className="p2 color-green">{t("healthy")}</span>
            <span className="p3 color-red-700">
              {indi("not")} {indi("valid")}
            </span>
          </div>
        );
      }

      default: {
        return (
          <span className="p3 color-red-700 ml-4">
            {indi("not")} {indi("valid")}
          </span>
        );
      }
    }
  };

  const Money = () => {
    switch (true) {
      case windowWidth >= 1210: {
        return (
          <div className="ver__block__info">
            <span className="p2 color-green">{indi("incomesmeth")}</span>
            <span className="p3 color-600">{indi("getmoneyonline")}</span>
          </div>
        );
      }

      default: {
        return (
          <span className="p3 color-green ml-4">{indi("moneyfromads")}</span>
        );
      }
    }
  };

  return (
    <div className="ver__root">
      <div onClick={scrollToVer} className={"td-none ver__block__control"}>
        <Icon spritePath={"verify-fill"} size={"l"} clazz={"_green"} />
        <Ver />
      </div>

      <div onClick={scrollToHealt} className={"td-none ver__block__control"}>
        <Icon spritePath={"health-fill"} size={"l"} clazz={"_red"} />
        <Health />
      </div>

      <div className={"td-none ver__block__money"}>
        <Icon spritePath={"euro"} size={"l"} clazz={"_green"} />
        {!isSqure && <Money />}
      </div>
    </div>
  );
};

export default VerHeader;
