import {useState} from "react";
import {useTranslation} from "react-i18next";
const ShowMoreText = (props) => {
    const {
        children,
        clazz,
        min = 151,
        max  = 250
    } = props

    const [readMore, setReadMore] = useState(false)
    const { t } = useTranslation("translation");
    return (
        <p className={`p2${clazz ? ` ${clazz}` : ''}`}>
            {readMore ? children.substring(0, max) : `${children.substring(0, min)}... `}
            <button className={'color-main'} onClick={() => setReadMore(!readMore)}>
                {readMore ? "" : ` ${t('moreinfo')}`}
            </button>
        </p>
    );
}

export default ShowMoreText