import { Button, Icon, RatingStars } from "@/components/ui";
import {
  setAddReviewStatus,
  setAppealStatus,
} from "@/stores/slices/popupSlice";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import "./reviews.scss";
import useLadyService from "@/services/LadyService";

const Reviews = ({ props }) => {
  // нужно в комплекте с компонентом добавить попапы <Appeal/> и <AddReview/>
  const {
    clazz,
    rating,
    rating_atmosphere,
    rating_charm,
    rating_communication,
    rating_emotions,
    rating_quality,
    name,
    reviews,
    reviews_count,
  } = props;

  const { dispatch, userType } = useLadyService();

  // console.log('reviews', props)

  const { t } = useTranslation("translation");

  const [loadMore, setLoadMore] = useState(false);
  return (
    <>
      <section id={"reviews"} className={`reviews${clazz ? ` ${clazz}` : ""}`}>
        <div className="reviews__top">
          <div className={"d-flex gap-12"}>
            <Button
              onClick={() => dispatch(setAppealStatus(true))}
              title={t("complain")}
              size={"m"}
              clazz={"button_outline--black"}
            >
              {t("complain")}
            </Button>

            <Button
              title={t("writereview")}
              onClick={() => dispatch(setAddReviewStatus(true))}
              clazz={"button--secondary "}
              size={"m"}
            >
              {t("writereview")}
            </Button>
          </div>

          <h3 className={"fz-28 fz-20-mob"}>
            {t("customerreviews")}
            <span> - {reviews_count ? reviews_count : "0"}</span>
          </h3>
        </div>

        <div className="reviews__stars  color-main mb-36">
          <div className="reviews__total">
            <span>{rating}</span>
            <RatingStars gold={true} value={rating} readOnly={true} />

            <span>
              {t("reviewses")} - {reviews_count ? reviews_count : "0"}
            </span>
          </div>

          <div className="reviews__items">
            <div className="reviews__item">
              <RatingStars gold={true} value={rating_charm} readOnly={true} />
              {t("attractiveness")}
            </div>

            <div className="reviews__item">
              <RatingStars gold={true} value={rating_quality} readOnly={true} />
              {t("qualityservices")}
            </div>

            <div className="reviews__item">
              <RatingStars
                gold={true}
                value={rating_communication}
                readOnly={true}
              />
              {t("communication")}
            </div>

            <div className="reviews__item">
              <RatingStars
                gold={true}
                value={rating_emotions}
                readOnly={true}
              />
              {t("sensibility")}
            </div>

            <div className="reviews__item">
              <RatingStars
                gold={true}
                value={rating_atmosphere}
                readOnly={true}
              />
              {t("atmosphere")}
            </div>
          </div>
        </div>

        {reviews && reviews.length > 0 ? (
          <div className="reviews__comments">
            {reviews.slice(0, 3).map((data) => (
              <ReviewComment props={{ name, ...data }} />
            ))}

            {reviews && reviews.length > 3 ? (
              loadMore ? (
                reviews
                  .slice(3)
                  .map((data) => <ReviewComment props={{ name, ...data }} />)
              ) : (
                <Button
                  onClick={() => setLoadMore(true)}
                  title={t("showmore")}
                  clazz={"button--secondary"}
                  size={"m"}
                >
                  {t("showmore")}
                </Button>
              )
            ) : null}
          </div>
        ) : null}
      </section>
    </>
  );
};

const ReviewComment = ({ props }) => {
  const { from, body, score, created_at, updated_at, answer } = props;

  const { t } = useTranslation("translation");

  const { formattedDate } = useLadyService();

  return (
    <div className="reviews__comment color-main">
      <div className="reviews__header d-flex gap-16 mb-8 align-center">
        <div className="reviews__name d-flex gap-8 align-center">
          {from?.tariff?.data[0]?.slug === "platinum" && (
            <Icon spritePath={"diamond"} size={"l"} />
          )}
          {from?.name && <span>{from.name}</span>}
          {score && <RatingStars gold={true} value={score} readOnly={true} />}
        </div>

        <span className="reviews__day p2 color-600">
          {formattedDate(created_at)}
        </span>
      </div>

      <p className={"mb-16 mb-8-mob"}>{props.body}</p>

      {answer && (
        <div className="reviews__answer mb-24">
          <p className="reviews__model-name color-green fz-18 fz-15-mob mb-8">
            {props.name}
          </p>
          <p className="reviews__model-text">{answer.body}</p>
        </div>
      )}
    </div>
  );
};

export default Reviews;
