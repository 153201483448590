import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { useDispatch, useSelector } from "react-redux";
import { useStopwatch, useTimer } from "react-timer-hook";
import { getUserID } from "@/stores/slices/userSlice";
import {
  getVideoCallModalStatus,
  getVideoCallRoom,
  cancelVideoCall,
  getVideoCallFreeTime,
  getVideoCallMaxTime,
  getLadyForCall,
  setVideoCallRoom,
} from "@/stores/slices/videoCallSlice";
import { useEffectWindowSize } from "../../hooks";
import img1 from "../../assets/img/model/1.webp";
import "./videoChat.scss";
import { Controller, useForm } from "react-hook-form";
import { Rating, Star } from "@smastrom/react-rating";
import { useParams } from "react-router-dom";

const VideoChat = () => {
  const dispatch = useDispatch();
  const modalStatus = useSelector(getVideoCallModalStatus);
  const roomID = useSelector(getVideoCallRoom);
  const userID = useSelector(getUserID);
  const ladyForCall = useSelector(getLadyForCall);
  const maxTime = useSelector(getVideoCallMaxTime);
  const freeTime = useSelector(getVideoCallFreeTime);
  const widthScreen = useEffectWindowSize();

  const userStream = useRef();
  const refVideo = useRef();
  const peerRef = useRef();
  const webSocketRef = useRef();
  const [partnerVideo, setPartnerVideo] = useState(null);
  const [startVideochat, setStartVideochat] = useState(false);
  const [startCall, setStartCall] = useState(false);
  const [callEnd, setCallEnd] = useState(false);
  const [callError, setCallError] = useState(false);
  const [callCloseError, setCallCloseError] = useState(false);
  const [notAnswered, setNotAnswered] = useState(false);
  const [guestVideoEnabled, setGuestVideoEnabled] = useState(true);
  const [guestBarEnabled, setGuestBarEnabled] = useState(false);
  const [pricePerMin, setCallPricePerMin] = useState(0);
  const [price, setCallPrice] = useState(0);
  const [stopwatchSecondOffset, setStopwatchSecondOffset] = useState(
    new Date()
  );
  const [videoEnabled, setVideoEnabled] = useState(true);
  const { room } = useParams();
  useEffect(() => {
    if (room !== undefined && room !== roomID) {
      dispatch(setVideoCallRoom({ room_id: room }));
    }
  }, [room]);
  const { control } = useForm({
    mode: "onBlur",
    defaultValues: {
      text: "",
      services: 0,
      athmosphere: 0,
      chatting: 0,
      attractiveness: 0,
      emotionality: 0,
    },
  });

  const RatingComponent = (props) => {
    const myStyles = {
      itemShapes: Star,
      activeFillColor: "#FFCC4A",
      inactiveFillColor: "#333333",
    };
    const { name } = props;
    return (
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (clazz) => clazz > 0,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Rating
            className={"rating-stars"}
            value={value}
            isRequired
            spaceInside="none"
            onChange={onChange}
            onBlur={onBlur}
            itemStyles={myStyles}
          />
        )}
      />
    );
  };

  useEffect(() => {
    const getCallError = (message) => {
      switch (message) {
        case "user_close":
          if (guestBarEnabled) {
            return "В настоящий момент модель не может принять Ваш видеозвонок";
          } else {
            return "Клиент завершил звонок";
          }
        case "camera_access":
          setStopwatchSecondOffset(new Date());
          return "В настоящий момент модель не может принять Ваш видеозвонок";
        case "wait_end":
          setStopwatchSecondOffset(new Date());
          return "В настоящий момент модель не может принять Ваш видеозвонок";
        case "dont_access":
          return "У вас нет доступа к этому звонку или его не существует";
      }
    };
    const getMedia = async () => {
      const openCamera = async () => {
        const allDevices = await navigator.mediaDevices.enumerateDevices();
        const cameras = allDevices.filter(
          (device) => device.kind === "videoinput"
        );
        if (cameras.length === 0) {
          console.error("No devices found");
          return null;
        }
        const constraints = {
          audio: true,
          video: videoEnabled ? { deviceId: cameras[0].deviceId } : false,
        };
        return navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            return stream;
          })
          .catch((error) => {
            console.error("Ошибка доступа к медиа-устройствам:", error);
            return null;
          });
      };

      openCamera().then((stream) => {
        const token = localStorage.getItem("user");
        if (webSocketRef.current !== undefined) {
          console.log(webSocketRef.current);
          return;
        }
        webSocketRef.current = new WebSocket(
          `wss://${process.env.REACT_APP_CALL_URL}/join?roomID=${roomID}&token=${token}`
        );
        webSocketRef.current.onopen = () => {
          if (stream === null) {
            setCallError("Нет доступа к камере");
            setCallCloseError("camera_access");
            setNotAnswered(true);
            return;
          }
          setStartCall(new Date());
          webSocketRef.current.send(JSON.stringify({ join: true }));
          userStream.current = stream;

          webSocketRef.current.addEventListener("message", async (e) => {
            const message = JSON.parse(e.data);

            if (message.join) {
              callUser();
            }

            if (message.offer) {
              console.log("Receiving offer");
              setStartVideochat(true);
              setStopwatchSecondOffset(new Date());
              handleOffer(message.offer);
            }

            if (message.answer) {
              setStartVideochat(true);
              setStopwatchSecondOffset(new Date());
              peerRef.current.setRemoteDescription(
                new RTCSessionDescription(message.answer)
              );
              console.log("video_status2", videoEnabled);
              webSocketRef.current.send(
                JSON.stringify({ type: "video_status", status: videoEnabled })
              );
            }

            if (message.iceCandidate) {
              console.log("Receiving and Adding ICE Candidate");
              try {
                await peerRef.current.addIceCandidate(message.iceCandidate);
              } catch (err) {
                console.log("Error Receiving ICE Candidate", err);
              }
            }

            if (message.type === "call_end" || message.type === "call_error") {
              if (message.type === "call_error") {
                setCallError(message.message);
              } else {
                console.log("error", message.reason);
                setCallError(getCallError(message.reason));
              }
              onClickClose();
            }

            if (message.type === "video_status") {
              setGuestVideoEnabled(message.status);
            }

            if (message.is_guest) {
              setGuestBarEnabled(message.is_guest);
            }

            if (message.price) {
              setCallPricePerMin(message.price);
            }
          });
        };
      });
    };

    if (modalStatus) {
      setCallEnd(false);
      setNotAnswered(false);
      setStopwatchSecondOffset(new Date());
      setCallError(false);
      setStartVideochat(false);
      getMedia();
    }
  }, [modalStatus, roomID, userID]);

  useEffect(() => {
    if (webSocketRef.current !== undefined) {
      webSocketRef.current.addEventListener("message", async (e) => {
        const message = JSON.parse(e.data);
        if (message.answer) {
          console.log("video_status", videoEnabled);
          webSocketRef.current.send(
            JSON.stringify({ type: "video_status", status: videoEnabled })
          );
        }
      });
    }
  }, [videoEnabled]);

  useEffect(() => {
    //console.log("partnerVideo.current", partnerVideo)
    if (partnerVideo !== null && partnerVideo !== undefined) {
      refVideo.current.srcObject = partnerVideo;
    }
  }, [partnerVideo]);

  const closeCamera = async () => {
    if (userStream.current) {
      userStream.current.getTracks().forEach((track) => {
        if (track.readyState === "live") {
          track.stop();
        }
      });
    }
  };

  const handleOffer = async (offer) => {
    console.log("Received Offer, Creating Answer");
    peerRef.current = createPeer();

    await peerRef.current.setRemoteDescription(
      new RTCSessionDescription(offer)
    );

    userStream.current.getTracks().forEach((track) => {
      peerRef.current.addTrack(track, userStream.current);
    });

    const answer = await peerRef.current.createAnswer();
    await peerRef.current.setLocalDescription(answer);

    webSocketRef.current.send(
      JSON.stringify({ answer: peerRef.current.localDescription })
    );
  };

  const callUser = () => {
    console.log("Calling Other User");
    peerRef.current = createPeer();

    userStream.current.getTracks().forEach((track) => {
      peerRef.current.addTrack(track, userStream.current);
    });

    // Обновленный поток отправляется после изменения состояния видео
    webSocketRef.current.send(
      JSON.stringify({
        offer: peerRef.current.localDescription,
      })
    );
  };

  const createPeer = () => {
    console.log("Creating Peer Connection");
    const peer = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:stun.relay.metered.ca:80",
        },
        {
          urls: "turn:standard.relay.metered.ca:80",
          username: "dd41ff40517c1c1a921b3a52",
          credential: "x+5Oogy2z2lno+ca",
        },
        {
          urls: "turn:standard.relay.metered.ca:80?transport=tcp",
          username: "dd41ff40517c1c1a921b3a52",
          credential: "x+5Oogy2z2lno+ca",
        },
        {
          urls: "turn:standard.relay.metered.ca:443",
          username: "dd41ff40517c1c1a921b3a52",
          credential: "x+5Oogy2z2lno+ca",
        },
        {
          urls: "turns:standard.relay.metered.ca:443?transport=tcp",
          username: "dd41ff40517c1c1a921b3a52",
          credential: "x+5Oogy2z2lno+ca",
        },
      ],
    });

    peer.onnegotiationneeded = handleNegotiationNeeded;
    peer.onicecandidate = handleIceCandidateEvent;
    peer.ontrack = (e) => {
      handleTrackEvent(e);
    };
    return peer;
  };

  const handleNegotiationNeeded = () => {
    console.log("Creating Offer");
    peerRef.current
      .createOffer()
      .then((offer) => peerRef.current.setLocalDescription(offer))
      .then(() => {
        setTimeout(() => {
          console.log("Candidates processing not ended. Ending it...");
          webSocketRef.current.send(
            JSON.stringify({ offer: peerRef.current.localDescription })
          );
        }, 1000);
      })
      .catch((e) => console.log(e));
  };

  const handleIceCandidateEvent = (e) => {
    console.log("Found Ice Candidate");
    if (e.candidate) {
      webSocketRef.current.send(JSON.stringify({ iceCandidate: e.candidate }));
    }
  };

  const handleTrackEvent = (e) => {
    setPartnerVideo(e.streams[0]);
  };

  async function onClickClose(closeModal = false, reason = "") {
    await closeCamera();
    if (webSocketRef.current) {
      if (callCloseError) {
        reason = callCloseError;
      }
      webSocketRef.current.send(JSON.stringify({ close: true, reason }));
    }
    setPartnerVideo(null);
    peerRef.current = null;
    setStartVideochat(false);
    if (closeModal) {
      await setVideoEnabled(true);
      await dispatch(cancelVideoCall());
      if (!guestBarEnabled) {
        document.location.href = "/";
      }
    }
    setCallEnd(true);
  }

  useEffect(() => {
    if (callEnd) {
      const currentData = new Date();
      const minutes = Math.floor(
        (currentData - stopwatchSecondOffset) / 1000 / 60
      );
      setCallPrice(minutes * pricePerMin + 1);
    }
  }, [callEnd]);

  const disableCamera = async () => {
    if (userStream.current) {
      await userStream.current.getVideoTracks().forEach((track) => {
        track.enabled = !videoEnabled;
      });
    }
    if (startCall) {
      console.log("video_status1", !videoEnabled);
      webSocketRef.current.send(
        JSON.stringify({ type: "video_status", status: !videoEnabled })
      );
    }
    setVideoEnabled(!videoEnabled);
  };

  // Timer components
  const Timer = () => {
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(startCall.getSeconds() + 120);
    const { seconds, minutes } = useTimer({
      autoStart: true,
      expiryTimestamp,
      onExpire: () => {
        setNotAnswered(true);
        setCallError(
          "В настоящий момент модель не может принять Ваш видеозвонок"
        );
        setStopwatchSecondOffset(new Date());
        onClickClose(false, "wait_end");
      },
    });

    const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
    const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return (
      <>
        {minuteTime}:{secondTime}
      </>
    );
  };

  const Stopwatch = () => {
    const stopwatchOffset = new Date();
    const secondsOffset = Math.floor(
      (stopwatchOffset - stopwatchSecondOffset) / 1000
    );
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + secondsOffset);

    const { seconds, minutes } = useStopwatch({
      autoStart: startVideochat,
      offsetTimestamp: stopwatchOffset,
      onExpire: () => {},
    });

    const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
    const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return (
      <>
        {minuteTime}:{secondTime}
      </>
    );
  };
  const VideoButton = () => {
    return (
      <Button
        size={"l"}
        square={true}
        title={"Отключить видео / Включить видео"}
        onClick={() => disableCamera()}
        clazz={`button--secondary videochat__video-button${
          !videoEnabled ? " _active" : ""
        }`}
      >
        <Icon
          spritePath={`${!videoEnabled ? "video-off" : "video"}`}
          size={"s"}
        />
      </Button>
    );
  };

  const VideochatBarDesktop = () => {
    if (widthScreen > 1000.98) {
      return (
        <div className="videochat__bar">
          <div className="videochat__timer">
            <p className={"p1"}>Время звонка</p>
            <span className={"title_h2 color-green"}>
              <Stopwatch />
            </span>
          </div>
          {guestBarEnabled && !callEnd ? (
            <>
              <div className="popup-form__inner">
                <div>
                  <p className={"p1 text-center "}>Максимальный лимит</p>
                  <span className={"color-white"}>{maxTime}</span>
                </div>
                <div>
                  <p className={"p2 text-center color-green"}>
                    Бесплатно (включено в тариф)
                  </p>
                  <span className={"color-green"}>{freeTime}</span>
                </div>
              </div>

              <VideoButton />
            </>
          ) : null}
          <Button
            size={"l"}
            clazz={"button--secondary videochat__bar-close"}
            onClick={() => onClickClose(callEnd)}
          >
            {" "}
            {callEnd ? <>Закрыть</> : <>Завершить</>}
          </Button>
        </div>
      );
    }

    return (
      <div className="videochat__bar">
        <div className="videochat__box">
          <div className="videochat__timer">
            <p className={"p1"}>Время звонка</p>
            <span className={"title_h2 color-green"}>
              <Stopwatch />
            </span>
          </div>
          {guestBarEnabled ? (
            <>
              <VideoButton />
            </>
          ) : null}
          <Button
            size={"l"}
            clazz={"button--secondary videochat__bar-close"}
            onClick={() => onClickClose(callEnd)}
          >
            {" "}
            {callEnd ? <>Закрыть</> : <>Завершить</>}
          </Button>
        </div>
        {guestBarEnabled ? (
          <>
            <div className="popup-form__inner">
              <div>
                <p className={"p1 text-center "}>Максимальный лимит</p>
                <span className={"color-white"}>{maxTime}</span>
              </div>
              <div>
                <p className={"p2 text-center color-green"}>
                  Бесплатно (включено в тариф)
                </p>
                <span className={"color-green"}>{freeTime}</span>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  };
  return modalStatus ? (
    <dialog open={!!modalStatus} className="popup-form videochat">
      {!!modalStatus && (
        <Helmet>
          <html className="lock"></html>
        </Helmet>
      )}

      <Button onClick={onClickClose} clazz="button__close">
        <Icon size="l" spritePath="close" />
      </Button>
      {callEnd ? (
        <>
          <div
            onClick={(e) => e.stopPropagation()}
            className="videochat__preview"
          >
            {guestBarEnabled ? (
              <>
                <div className="popup-form__inner">
                  <img
                    className="videochat__image"
                    src={ladyForCall?.photos[0]}
                    alt=""
                  />
                  <span className="p1 text-center color-green">
                    {ladyForCall?.name}
                  </span>
                </div>
              </>
            ) : null}
            {guestBarEnabled ? (
              <>
                {" "}
                {callError ? (
                  <>
                    <h2 className="text-center">{callError}</h2>
                  </>
                ) : (
                  <>
                    <h2 className="text-center">Видеозвонок завершен </h2>
                    <div className="stars-box">
                      <span>
                        {"Оцените качество звонка"}
                        <RatingComponent name={"videochat"} />
                      </span>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {callError ? (
                  <>
                    <h2 className="text-center">{callError}</h2>
                  </>
                ) : (
                  <>
                    <div className="lady-end-call-bar">
                      <p>Ваш баланс будет пополнен на </p>
                      <p>{price} €</p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <VideochatBarDesktop />
        </>
      ) : (
        <>
          {startVideochat ? (
            <>
              <video playsInline autoPlay ref={refVideo}></video>
              {!guestVideoEnabled ? (
                <>
                  <div className={"videoDisabled"}>
                    <Icon spritePath={"video-off"} />
                    <p>Видеокамера клиента выключена</p>
                  </div>
                </>
              ) : null}
              <VideochatBarDesktop />
            </>
          ) : (
            <>
              {guestBarEnabled ? (
                <>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="videochat__preview"
                  >
                    <div className="popup-form__inner">
                      <img
                        className="videochat__image"
                        src={ladyForCall?.photos[0]}
                        alt=""
                      />
                      <span className="p1 text-center color-green">
                        {ladyForCall?.name}
                      </span>
                    </div>
                    {notAnswered ? (
                      <>
                        <h2 className="text-center">{callError}</h2>
                      </>
                    ) : (
                      <>
                        <h2 className="videochat__dotted">Вызываем</h2>
                        <div className="videochat__timer">
                          <p className="p1">Ждём ещё</p>
                          <span className="title_h2 color-green">
                            <Timer />
                          </span>
                        </div>
                      </>
                    )}
                  </div>

                  <span className="d-flex gap-8">
                    {notAnswered ? (
                      <>
                        {" "}
                        <Button
                          size="l"
                          clazz="button--secondary"
                          onClick={onClickClose}
                        >
                          Закрыть
                        </Button>
                      </>
                    ) : (
                      <>
                        <VideoButton />{" "}
                        <Button
                          size="l"
                          clazz="button--secondary"
                          onClick={onClickClose}
                        >
                          Отменить
                        </Button>
                      </>
                    )}
                  </span>
                </>
              ) : (
                <>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="videochat__preview"
                  >
                    {callError ? (
                      <>
                        <h2>{callError}</h2>
                      </>
                    ) : (
                      <>
                        {" "}
                        <h2 className="videochat__dotted">Соединяем</h2>
                      </>
                    )}
                  </div>
                  <span className="d-flex gap-8">
                    <Button
                      size="l"
                      clazz="button--secondary"
                      onClick={onClickClose}
                    >
                      Отменить
                    </Button>
                  </span>
                </>
              )}
            </>
          )}
        </>
      )}
    </dialog>
  ) : null;
};

export default VideoChat;
