import { ButtonLink, Tippy } from "@/components/ui";

import { useDispatch } from "react-redux";
import { setUserStatus } from "@/stores/slices/userSlice";
import "./tariffsPage.scss";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../header/Header";
import { useEffectWindowSize } from "../../../hooks";
import Footer from "../../footer/Footer";
import { useTranslation } from "react-i18next";

const TariffsPage = () => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();

  const [tab, setTab] = useState("1");
  const widthScreen = useEffectWindowSize();
  function changeTabOnHover(num) {
    if (widthScreen > 767.98) {
      setTab(num);
    }
  }
  return (
    <main>
      <section className={"tariffs-page__container"}>
        <Helmet>
          <html className={"overflow-clip"}></html>
        </Helmet>
        <h1 className={"mb-40 mb-24-mob"}>{t("selecttariff")}</h1>

        <div data-active-tariff={tab} className="tariffs-table">
          <div className="tariffs-table__header">
            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__heading"}>{t("options")}</div>
              <div className="tariffs-table__row tariffs-table__buttons">
                <button
                  onClick={() => setTab("1")}
                  type={"button"}
                  className={`tariffs-table__silver${
                    tab === "1" && widthScreen < 767.98 ? " _active" : ""
                  }${widthScreen > 767.98 ? " _active" : ""}
                                            }`}
                >
                  Silver
                </button>
                <button
                  onClick={() => setTab("2")}
                  type={"button"}
                  className={`tariffs-table__gold${
                    tab === "2" && widthScreen < 767.98 ? " _active" : ""
                  }`}
                >
                  Gold
                </button>
                <button
                  onClick={() => setTab("3")}
                  type={"button"}
                  className={`tariffs-table__platinum${
                    tab === "3" && widthScreen < 767.98 ? " _active" : ""
                  }`}
                >
                  Platinum
                </button>
              </div>
            </div>
          </div>

          <div className="tariffs-table__body">
            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>{t("guaranteeanon")}</div>
              <div className="tariffs-table__row">
                <div onMouseEnter={() => changeTabOnHover(1)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>
                {t("favotitemodels")}
              </div>
              <div className="tariffs-table__row">
                <div onMouseEnter={() => changeTabOnHover(1)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>
                {t("notificationsnew")}
              </div>
              <div className="tariffs-table__row">
                <div onMouseEnter={() => changeTabOnHover(1)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>
                {t("retentionfilters")}
              </div>
              <div className="tariffs-table__row">
                <div onMouseEnter={() => changeTabOnHover(1)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>{t("reviewsandcom")}</div>
              <div className="tariffs-table__row">
                <div onMouseEnter={() => changeTabOnHover(1)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>
            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>{t("complaints")}</div>
              <div className="tariffs-table__row">
                <div onMouseEnter={() => changeTabOnHover(1)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>
                {t("videochatswithmodel")}
              </div>
              <div className="tariffs-table__row">
                <div onMouseEnter={() => changeTabOnHover(1)}>
                  3 €/{t("min")}
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  2 €/{t("min")}
                </div>
                <Tippy
                  onMouseEnter={() => changeTabOnHover(3)}
                  title={`${t("include")} 30 ${t("minit")} ${t("next")} – 3 €`}
                >
                  {t("free")}*
                </Tippy>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>
                Доступ к эротическому контенту
              </div>
              <div className="tariffs-table__row">
                <div onMouseEnter={() => changeTabOnHover(1)}>{t("paid")}</div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  {t("discount") + "50%"}
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>{t("free")}</div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>{t("orderescort")}</div>
              <div className="tariffs-table__row">
                <div
                  onMouseEnter={() => changeTabOnHover(1)}
                  className={"_gray"}
                >
                  —
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>{t("inviteseveral")}</div>
              <div className="tariffs-table__row">
                <div
                  onMouseEnter={() => changeTabOnHover(1)}
                  className={"_gray"}
                >
                  —
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>{t("verifytags")}</div>
              <div className="tariffs-table__row">
                <div
                  onMouseEnter={() => changeTabOnHover(1)}
                  className={"_gray"}
                >
                  —
                </div>
                <div onMouseEnter={() => changeTabOnHover(2)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>
                {t("invitestoparties")}
              </div>
              <div className="tariffs-table__row">
                <div
                  onMouseEnter={() => changeTabOnHover(1)}
                  className={"_gray"}
                >
                  —
                </div>
                <div
                  onMouseEnter={() => changeTabOnHover(2)}
                  className={"_gray"}
                >
                  —
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>{t("vipprofile")}</div>
              <div className="tariffs-table__row">
                <div
                  onMouseEnter={() => changeTabOnHover(1)}
                  className={"_gray"}
                >
                  —
                </div>
                <div
                  onMouseEnter={() => changeTabOnHover(2)}
                  className={"_gray"}
                >
                  —
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>

            <div className="tariffs-table__wrapper">
              <div className={"tariffs-table__title"}>{t("authenticity")}</div>
              <div className="tariffs-table__row">
                <div
                  onMouseEnter={() => changeTabOnHover(1)}
                  className={"_gray"}
                >
                  —
                </div>
                <div
                  onMouseEnter={() => changeTabOnHover(2)}
                  className={"_gray"}
                >
                  —
                </div>
                <div onMouseEnter={() => changeTabOnHover(3)}>
                  <i className={"icon icon-l icon--include"}></i>
                </div>
              </div>
            </div>
          </div>

          <div className="tariffs-table__bottom">
            <div
              onMouseEnter={() => changeTabOnHover(1)}
              className="tariffs-table__box"
            >
              <p>{t("free")}</p>
              <ButtonLink
                href={"/"}
                onClick={() => dispatch(setUserStatus("silver"))}
                size={"l"}
                clazz={"button--green"}
              >
                {t("select")}
              </ButtonLink>
            </div>

            <div
              onMouseEnter={() => changeTabOnHover(2)}
              className="tariffs-table__box"
            >
              <p>25 €/{t("month")}</p>
              <ButtonLink
                href={"/"}
                onClick={() => dispatch(setUserStatus("gold"))}
                size={"l"}
                clazz={"button--green"}
              >
                {t("select")}
              </ButtonLink>
            </div>

            <div
              onMouseEnter={() => changeTabOnHover(3)}
              className="tariffs-table__box"
            >
              <p>
                33 € <span>100 €</span>
              </p>
              <ButtonLink
                href={"/"}
                onClick={() => dispatch(setUserStatus("platinum"))}
                size={"l"}
                clazz={"button--green"}
              >
                {t("select")}
              </ButtonLink>
              <p className={"p2"}>
                <span className={"title fz-22 fz-20-mob mb-8"}>
                  {t("soperaction")}
                </span>
                {t("promotion")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TariffsPage;
