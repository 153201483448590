import { useEffect, useState } from "react";
import "./scheduleInput.scss";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from 'moment';

const ScheduleInput = ({ clazz, setValue, onChange = false, defStart = "", defEnd = "" }) => {
  const [startTime, setStartTime] = useState(defStart ? moment(defStart, "HH:mm") : null);
  const [endTime, setEndTime] = useState(defEnd ? moment(defEnd, "HH:mm") : null);

  useEffect(() => {
    if (defStart) {
      setStartTime(moment(defStart, "HH:mm"));
    } else {
      setStartTime(null);
    }
    if (defEnd) {
      setEndTime(moment(defEnd, "HH:mm"));
    } else {
      setEndTime(null);
    }
  }, [defStart, defEnd]);

  const handleStartTimeChange = (value) => {
    setStartTime(value);
    setValue(prev => ({
      ...prev,
      start: value ? value.format("HH:mm") : ""
    }));
    onChange && onChange();
  };

  const handleEndTimeChange = (value) => {
    setEndTime(value);
    setValue(prev => ({
      ...prev,
      end: value ? value.format("HH:mm") : ""
    }));
    onChange && onChange();
  };

  return (
    <div className={`date-picker--wrapper ${clazz ? clazz : ""}`}>
      <TimePicker
        placeholder={"от 11:30"}
        showSecond={false}
        value={startTime}
        onChange={handleStartTimeChange}
        clearIcon={null}
      />
      <TimePicker
        placeholder={"до 00:00"}
        showSecond={false}
        value={endTime}
        onChange={handleEndTimeChange}
        clearIcon={null}
      />
    </div>
  );
};

export default ScheduleInput;
