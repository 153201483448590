import { useDispatch, useSelector } from "react-redux";
import {
  getSuccessAddReviewStatus,
  getSuccessAppealStatus,
  setAddReviewStatus,
  setSuccessAddReviewStatus,
  setSuccessAppealStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";

const SuccessReviewMassage = () => {
  const dispatch = useDispatch();
  const SuccessAppealValue = useSelector(getSuccessAppealStatus);
  const { lang } = useLadyService();
  const { t } = useTranslation("translation");

  return (
    <dialog
      open={!!SuccessAppealValue}
      onClick={() => dispatch(setSuccessAppealStatus(false))}
      id={"addAppeal"}
      className="popup-form"
    >
      {!!SuccessAppealValue ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        method={"POST"}
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button
          onClick={() => dispatch(setSuccessAppealStatus(false))}
          clazz={"button__close"}
        >
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner text-center">
          <h3>{t("successreview")}</h3>
          <p className={"color-600 p2"}>
            {t("successappealtext")}{" "}
            <Link to={`/${lang}/rules`}>{t("portalrules")}</Link>
          </p>
        </div>
      </form>
    </dialog>
  );
};

export default SuccessReviewMassage;
