import { Checkbox, Button, InputInLabel, Icon } from "@/components/ui";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";

import { useTranslation } from "react-i18next";

import "./countriesFilter.scss";
import { makeRequest } from "@/services/makeRequest";

import showToast from "../../../../../toast/Toast";

const CountriesFilter = ({ data }) => {
  const { blocked_countries } = data;

  const [isFull, setIsFull] = useState(false);
  const [query, setQuery] = useState("");
  const [countriesData, setCountriesData] = useState([]);

  const [countries, setCountries] = useState([]);
  const [checkedCountries, setCheckedCountries] = useState([]);

  const { t: indi } = useTranslation("indi");

  const lang = useSelector(getUserLang);

  const options = isFull ? countries : countries.slice(0, 12);

  const handleChange = (event) => {
    const term = event.target.value;
    setQuery(term);
    const filteredCountries = countriesData.filter((word) => {
      return word?.name?.toLowerCase().startsWith(term.toLowerCase());
    });
    setCountries(filteredCountries);
  };

  const hanldleFull = () => {
    setIsFull(true);
  };

  const hanldleClear = () => {
    setCountries(countriesData);
    setQuery("");
  };

  const handleCountrySelect = (code, title) => {
    const index = countries.findIndex((country) => country.code === code);

    const updatedCountries = [...countries];

    updatedCountries[index] = {
      ...updatedCountries[index],
      checked: !updatedCountries[index].checked,
    };

    setCountries(updatedCountries);

    const newCheckedValue = updatedCountries[index].checked;

    const updatedCheckedCountries = [...checkedCountries];
    if (newCheckedValue) {
      updatedCheckedCountries.push(code);
    } else {
      const indexToRemove = updatedCheckedCountries.indexOf(code);
      if (indexToRemove !== -1) {
        updatedCheckedCountries.splice(indexToRemove, 1);
      }
    }
    setCheckedCountries(updatedCheckedCountries);

    const fetchData = async () => {
      try {
        const variant = newCheckedValue ? "include" : "exclude";
        const route = `countries/${variant}/${code}`;
        const method = newCheckedValue ? "POST" : "DELETE";
        const payload = {
          code,
        };

        await makeRequest({ route, method, payload });
        showToast({
          message: `${indi("accessscountry")} ${title} ${
            variant === "exclude" ? indi("open") : indi("close")
          }`,
          variant: "succes",
        });
      } catch (error) {}
    };

    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `countries`;
        const method = "GET";
        const payload = { lang };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.data;

          const sortedCountries = result
            .sort((a, b) => {
              const aChecked = blocked_countries.includes(a.code);
              const bChecked = blocked_countries.includes(b.code);
              if (aChecked && !bChecked) {
                return -1;
              } else if (!aChecked && bChecked) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((country) => {
              if (blocked_countries.includes(country.code)) {
                country.checked = true;
              }
              return country;
            });

          setCountriesData(!!sortedCountries.length ? sortedCountries : result);
          setCountries(!!sortedCountries.length ? sortedCountries : result);
        }
      } catch (error) {}
    };

    fetchData();
  }, [blocked_countries, lang]);

  return (
    <div className="filter__root">
      <span className="p1 color-main width-100">{indi("restrict")}</span>
      <p>{indi("invisible")}</p>
      {isFull && (
        <div className="filter__search">
          <InputInLabel
            placeholder={indi("writecountry")}
            clazz={"w-100"}
            value={query}
            onChange={handleChange}
          >
            {indi("country")}
          </InputInLabel>

          <Button
            square={true}
            size={"s"}
            buttonType={"button"}
            onClick={hanldleClear}
            disabled={!query}
          >
            <Icon size={"l"} spritePath={"close"} />
          </Button>
        </div>
      )}
      {!!options.length ? (
        <div className="filter-countries__body">
          {options?.map((i) => {
            const { code, name: title, checked } = i;
            const name = code.toUpperCase();

            return (
              <Checkbox
                border={false}
                flag={name}
                key={code}
                name={name}
                id={code}
                value={name}
                checked={checked}
                title={title}
                onChange={() => handleCountrySelect(code, title)}
              />
            );
          })}
        </div>
      ) : (
        <span className="p2">По Вашему запросу нет стран</span>
      )}
      {!isFull && (
        <Button
          title={"Показать все страны"}
          clazz={"button--secondary justify-center"}
          size={"s"}
          onClick={hanldleFull}
        >
          {indi("showallcountries")}
        </Button>
      )}
    </div>
  );
};

export default CountriesFilter;
