import { useTranslation } from "react-i18next";
import PopupContainer from "../PopupContainer.tsx";
import { Button } from "@/components/ui";
import { useState } from "react";

import statusElite from "../../../assets/img/status/elite.webp";
import { t } from "i18next";

const RemoveEliteAdPopup = (props) => {
  const { open = true, setOpen, slug: slugProfile, name } = props;

  const { t: indi } = useTranslation("indi");

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    const fetchData = async () => {
      try {
        //   const route = `user/profile/${slugProfile}/add-friend`;
        //   const method = "POST";
        //   const payload = {
        //     profiles: value,
        //   };
        //   await makeRequest({ route, method, payload });
        //   onClose();
      } catch (error) {}
    };

    fetchData();
  };

  const liOptions = [<li>{indi("highad")}</li>, <li>{indi("placein")}</li>];

  const Tilte = () => {
    return <>{indi("deactive")} Elite?</>;
  };

  return (
    <PopupContainer isLoading={isLoading} {...props} title={<Tilte />}>
      <picture className="text-center">
        <source srcSet={statusElite} media="(min-width: 767.98px)" />
        <img src={statusElite} alt="" />
      </picture>

      <span>
        {indi("stauselite")}: <ul>{liOptions.map((i) => i)}</ul>
      </span>

      <span className="p1 color-main text-center">
        {indi("eliteallow")}
        <p className="p1 color-green text-center mt-4">
          +50% {indi("toviews")} {t("and")} +20% {indi("toorders")}
        </p>
      </span>

      <span className="color-main">
        <p className="color-red-700">Важно!</p>
        {indi("eliteforad")} <span className="color-green">{name}</span>{" "}
        {indi("paiduntil")} 25.04.2024. {indi("ifdeactivate")}.
      </span>

      <div className="popup-form__buttons">
        <Button
          size={"l"}
          buttonType={"submit"}
          clazz={"button--secondary justify-center"}
          onClick={(e) => onSubmit(e)}
        >
          {indi("deactive")}
        </Button>

        <Button
          size={"l"}
          clazz={"button--green justify-center"}
          onClick={handleClose}
        >
          {indi("keep")} elite
        </Button>
      </div>
    </PopupContainer>
  );
};

export default RemoveEliteAdPopup;
