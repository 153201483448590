import {
  getAddReviewStatus,
  setAddReviewStatus,
  setSuccessAddReviewStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { useDispatch, useSelector } from "react-redux";
import { Rating, Star } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { Controller, useForm } from "react-hook-form";
import { makeRequest } from "@/services/makeRequest";

import showToast from "../../toast/Toast";

import "./addReview.scss";
import { useTranslation } from "react-i18next";
import SuccessReviewMassage from "./SuccessReviewMassage";

const AddReview = ({ id, reviewable_type }) => {
  const dispatch = useDispatch();
  const AddReviewValue = useSelector(getAddReviewStatus);

  const { t } = useTranslation();
  const { t: indi } = useTranslation("indi");

  const { register, handleSubmit, reset, control } = useForm({
    mode: "onBlur",
    defaultValues: {
      reviewable_type,
      reviewable_id: id,
      body: "",
      score_quality: 0,
      score_atmosphere: 0,
      score_communication: 0,
      score_charm: 0,
      score_emotions: 0,
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        const route = `reviews`;
        const method = "PUT";
        const payload = {
          ...data,
        };

        await makeRequest({ route, method, payload });
        showToast({
          message: t("successreview"),
          variant: "succes",
        });
      } catch (error) {
        showToast({
          message: indi("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
    reset();
    dispatch(setSuccessAddReviewStatus(true));
    dispatch(setAddReviewStatus(false));
  };

  const onClose = () => {
    reset();
    dispatch(setAddReviewStatus(false));
  };
  const starsStyles = {
    itemShapes: Star,
  };

  const RatingComponent = (props) => {
    const { name } = props;
    return (
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (clazz) => clazz > 0,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Rating
            className={"rating-stars rating-stars--gold"}
            value={value}
            isRequired
            spaceInside="none"
            onChange={onChange}
            onBlur={onBlur}
            itemStyles={starsStyles}
          />
        )}
      />
    );
  };

  return (
    <>
      <dialog
        open={!!AddReviewValue}
        onClick={() => onClose()}
        id={"addReview"}
        className="popup-form"
      >
        {!!AddReviewValue ? (
          <Helmet>
            <html className={"lock"}></html>
          </Helmet>
        ) : null}

        <form
          method={"POST"}
          onClick={(e) => e.stopPropagation()}
          onSubmit={handleSubmit(onSubmit)}
          className="popup-form__body gap-16"
        >
          <Button onClick={() => onClose()} clazz={"button__close"}>
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          <div className="popup-form__inner">
            <h3>{t("writereview")}</h3>
            <div className="stars-box">
              <span>
                {t("qualityservices")}
                <RatingComponent name={"score_quality"} />
              </span>
              <span>
                {t("atmosphere")}
                <RatingComponent name={"score_atmosphere"} />
              </span>
              <span>
                {t("communication")}
                <RatingComponent name={"score_communication"} />
              </span>
              <span>
                {t("attractiveness")}
                <RatingComponent name={"score_charm"} />
              </span>
              <span>
                {t("sensibility")}
                <RatingComponent name={"score_emotions"} />
              </span>
            </div>
          </div>

          <div className="popup-form__inner">
            <InputInLabel
              register={{ ...register("body") }}
              type={"textarea"}
              id={"AddReviewText"}
            >
              {t("textreview")}
            </InputInLabel>

            <Button
              size={"l"}
              buttonType={"submit"}
              clazz={"button--green"}
              onClick={() => dispatch(setAddReviewStatus(false))}
            >
              {t("send")}
            </Button>
          </div>
        </form>
      </dialog>

      <SuccessReviewMassage />
    </>
  );
};

export default AddReview;
