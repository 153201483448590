import "./attentionBox.scss";
import React from "react";
import { Icon } from "@/components/ui";
import { useTranslation } from "react-i18next";

const AttentionBox = ({ children, type }) => {
  const { t: indi } = useTranslation("indi");
  const { t: guide } = useTranslation("guide");

  return (
    <div className={`attention-box${type && ` ${type}`}`}>
      {type === "important" && (
        <span className={"attention-box__word"}>{indi("important")}! </span>
      )}

      {(type === "advice" || type === "warning") && (
        <span
          className={`attention__icon${
            type === "advice" ? " advice" : " warning"
          }`}
        >
          <Icon
            spritePath={type === "advice" ? "warning" : "check-thin"}
            clazz={type === "advice" ? `_green` : ""}
            size={`xl`}
          />
          {type === "advice" ? guide("i-tips") : guide("i-uniquefunc")}
        </span>
      )}
      {children}
    </div>
  );
};

export default AttentionBox;
