import { useDispatch, useSelector } from "react-redux";
import { getUserLang, setLang } from "@/stores/slices/userSlice";
import { useEffect } from "react";
import containsKeyword from "../helper/containsKeyword";

// const urlKeywords = ['agencies', 'agency', 'profile', 'lk', 'videochat', 'party', 'registration'];

const useDefiningLang = () => {
  const dispatch = useDispatch();

  const languages = ["ru", "en", "cz"];

  const lang = useSelector(getUserLang);
  useEffect(() => {
    if (!lang) {
      const url = window.location.pathname;
      const segments = url.split("/");
      const langSegment = segments[1];
      if (langSegment && containsKeyword(langSegment, languages)) {
        dispatch(setLang(langSegment));
      } else {
        const userLanguage = navigator.language || navigator.userLanguage;
        let updateLang = "";
        switch (userLanguage.slice(0, 2)) {
          case "en":
            updateLang = "en";
            break;
          case "ru":
            updateLang = "ru";
            break;
          case "cs":
            updateLang = "cz";
            break;
          default:
            updateLang = "en";
        }
        dispatch(setLang(updateLang));
      }
    }
  }, []);
};

export default useDefiningLang;
