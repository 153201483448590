import GuideIndi from "./indi/GuideIndi";
import "./guidePage.scss";

const Guide = () => {

  return(
    <GuideIndi/>
  )
}

export default Guide