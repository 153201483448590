import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavoriteCounterStatus,
  getUserCity,
  getUserCountry,
  getUserCurrentCity,
  getUserLang,
  getUserStatus,
  getUserType,
  setReadyToOrder,
  setReadyToVideochat,
  setUserStatus,
  setUserType,
  setFavoriteCounter
} from "@/stores/slices/userSlice";
import { useEffectWindowSize } from "../../hooks";
import {
  Avatar,
  Button,
  ButtonLink,
  CheckboxFilter,
  Dropdown,
  Icon,
  InputNick,
  InputRangeFilter,
  Select,
  Toggle,
  SwitchTheme,
  BurgerMenu,
  LogoSvg,
} from "@/components/ui";
import {
  cleanSorted,
  setPageDefault,
  setSearchValue,
  setSortedModelsByFilter,
} from "@/stores/slices/modelsState";
import { clearTitle } from "@/stores/slices/titleSlice";
import { resetAllCounters } from "@/stores/slices/counterFilterSlice";

import { show, showShort } from "@/stores/slices/showFilterSlice";
import { setAuthorizationStatus } from "@/stores/slices/popupSlice";

import { setAuthorisationAccessType } from "@/stores/slices/popupSlice";
import {
  allFilter,
  resetAll,
  defaultRangeState,
  setSearchFilter,
  rangeState,
} from "@/stores/slices/filterSlice";
import { themeMode } from "@/stores/slices/userSlice";
import { toast } from "react-toastify";
import logoDarkScrolled from "../../assets/img/logo-scroll--dark.svg";
import logoLightScrolled from "../../assets/img/logo-scroll--light.svg";
import "./header.scss";
import { useTranslation } from "react-i18next";
import { selectActiveTabId, setActiveTabId } from "@/stores/slices/tabSlice";
import { makeRequest } from "@/services/makeRequest";
import { getUserOrderStatus } from "@/stores/slices/userSlice";
import { getUserVideoStatus } from "@/stores/slices/userSlice";

import showToast from "../toast/Toast";

const Header = (props) => {
  const { profilePage = false } = props;

  const widthScreen = useEffectWindowSize();
  const type = useSelector(getUserType);
  //console.log("type", type);
  // const type = 'user'
  // const type = 'photograph'
  // const type = 'model'
  // const type = 'agency'
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const lang = useSelector(getUserLang);
  const currentCity = useSelector(getUserCurrentCity);
  const city = useSelector(getUserCity);

  const activeTabId = useSelector(selectActiveTabId);

  const { t } = useTranslation();
  const { t: indi } = useTranslation("indi");

  const postAltLogo = t("altlogo")?.replace(/%([^%]+)%/g, currentCity?.title);

  const currentCountry = useSelector(getUserCountry);

  const handleTabClick = (tabId) => {
    dispatch(setActiveTabId(tabId));
  };

  var titleLang = "";
  switch (lang) {
    case "en":
      titleLang = "EN";
      break;
    case "ru":
      titleLang = "RU";
      break;
    case "cz":
      titleLang = "CZ";
      break;
    default:
      titleLang = "EN";
  }

  const languages = {
    main_title: "",
    options: [
      { id: 1, link: `/en/${city}`, title: "EN", value: "en" },
      { id: 2, link: `/ru/${city}`, title: "RU", value: "ru" },
      { id: 3, link: `/cz/${city}`, title: "CZ", value: "cz" },
    ],
  };

  const options_place = {
    main_title: t("place"),
    options: [
      {
        id: 1,
        title: t("incall"),
        name: "place",
        value: "incall",
        count: "1",
        checked: false,
        disabled: false,
      },
      {
        id: 2,
        title: t("outcall"),
        name: "place",
        count: "outcall",
        value: "2",
        checked: false,
        disabled: false,
      },
      {
        id: 3,
        title: t("brothel"),
        name: "place",
        value: "agency",
        count: "3",
        checked: false,
        disabled: false,
      },
    ],
  };

  const infoLinks = [
    { id: 1, link: `/${lang}/about`, name: t("about") },
    { id: 2, link: `/guide`, name: t("guide") },
    { id: 3, link: `/${lang}/faq`, name: t("faq") },
    { id: 4, link: "#", name: t("support") },
  ];

  const favoriteCount = useSelector(getFavoriteCounterStatus);

  const showFilter = () => {
    setIsOpenMenu(false);
    dispatch(show());
  };

  const showShortFilter = () => {
    setIsOpenMenu(false);
    dispatch(showShort());
  };

  const toFavorites = (e) => {
    e.preventDefault();

    navigate(`/${lang}/favorites`);
  };

  const allStateFilter = useSelector(allFilter);
  const currentRangeState = useSelector(rangeState);
  const defaultRangeValue = useSelector(defaultRangeState);

  const { accordionSelected, range, ...fieldsToCheck } = allStateFilter;
  const hasNonEmptyFilters = Object.values(fieldsToCheck).some(
    (filter) => Array.isArray(filter) && filter.length > 0
  );

  function allValueIsNull(obj) {
    for (let key in obj) {
      if (obj[key].min !== 0 && obj[key].max !== 0) {
        return true;
      }
    }
    return false;
  }

  const [hasNonEmptyRange, setHasNonEmptyRange] = useState(false);
  useEffect(() => {
    if (allValueIsNull(defaultRangeValue) && allValueIsNull(range)) {
      setHasNonEmptyRange(
        range.price.min > defaultRangeValue.price.min ||
          range.price.max < defaultRangeValue.price.max
          ? true
          : range.age.min > defaultRangeValue.age.min &&
            range.age.max < defaultRangeValue.age.max
          ? true
          : false
      );
    }
  }, [defaultRangeValue, currentRangeState]);

  const toRegistration = (e) => {
    e.preventDefault();

    navigate(`/${lang}/registration#indi`);
  };
  // Add and Remove Class on scroll
  const [scrolledClass, setScrolledClass] = useState("");

  useEffect(() => {
    if (widthScreen > 767.98) {
      window.addEventListener("scroll", () => {
        if (window.scrollY < 1) {
          setScrolledClass("");
        } else {
          setScrolledClass(" _scroll");
        }
      });
    }
  }, [widthScreen, window]);

  const theme = useSelector(themeMode);

  const getThemeForLogo = () => {
    if (theme === "dark") {
      return logoDarkScrolled;
    } else {
      return logoLightScrolled;
    }
  };

  const logOut = () => {
    dispatch(setUserType("default"));
    dispatch(setUserStatus("silver"));
    dispatch(setFavoriteCounter(0))
    localStorage.removeItem("user");
    navigate(`/`);
  };

  const toLk = (key) => {
    console.log("key");
    navigate(`/lk/${key}`);
  };

  const profileStatus = useSelector(getUserStatus);
  const handleLogo = (e) => {
    e.preventDefault();

    navigate(`/${lang}/${city}`);

    dispatch(cleanSorted());
    dispatch(resetAll());
    dispatch(clearTitle());
    dispatch(setSearchFilter(false));
    dispatch(setSearchValue(""));
    dispatch(setPageDefault());
    dispatch(setSortedModelsByFilter(""));
    dispatch(resetAllCounters());

    if (scrolledClass) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const Logo = () => (
    <Link
      to={`/`}
      title={t("titlelogo")}
      className="logo"
      onClick={(e) => handleLogo(e)}
    >
      {scrolledClass === " _scroll" && widthScreen > 767.98 ? (
        <img src={getThemeForLogo()} alt={postAltLogo} />
      ) : (
        <LogoSvg alt={postAltLogo} />
      )}
    </Link>
  );

  var avatarLink = "";
  switch (type) {
    case "indi":
      avatarLink = `/lk/indi/profiles`;
      break;
    // case "photograph":
    //   avatarLink = `/lk/photograph`;
    //   break;
    case "agency":
      avatarLink = `/lk/agency`;
      break;
    case "user":
      avatarLink = `/lk/user`;
      break;
    default:
      avatarLink = `/`;
  }

  const SelectCity = () => (
    <Select
      name={"city"}
      options={currentCountry.length > 0 && currentCountry}
      clazz={"select-city"}
      clazzSvg={"select__flag"}
      arrowSize={"m"}
      defaultTitle={""}
      withoutTitle={true}
      postApi={true}
      withoutIcon={currentCountry.length > 0 ? false : true}
      type={"flag"}
    />
  );

  const LogOutButton = (size = "xs", square = false) => (
    <Button
      onClick={() => logOut()}
      size={size}
      square={square}
      clazz="user__log-out"
    >
      <Icon size={"l"} spritePath={"logout"} />
    </Button>
  );

  const LogInButton = (size = "s", square = false) => (
    <Button
      onClick={() => dispatch(setAuthorizationStatus(true))}
      size={size}
      square={square}
      clazz="button--green user__log-in"
    >
      {t("login")}
    </Button>
  );

  const UserNotification = (size = "s") => (
    <Button
      size={widthScreen < 767.98 ? "xxs" : size}
      square={true}
      clazz={`user__notification${
        widthScreen > 767.98 ? " button--tetriary" : ""
      }`}
      counter={12}
    >
      <Icon size={"l"} spritePath={"notify"} />
    </Button>
  );

  const FavoriteButton = (clazz) => (
    <ButtonLink
      title={indi("favorites")}
      href="/favorites"
      size={clazz}
      square={true}
      clazz="button--tetriary user__favorite"
      counter={favoriteCount}
      onClick={(e) => toFavorites(e)}
    >
      <Icon size={"l"} spritePath={`favorite`} />
    </ButtonLink>
  );

  const InstructionLink = (clazz) => (
    <ButtonLink
      href={`/guide`}
      title={t("guide")}
      size={"xs"}
      clazz={`add-form${
        clazz ? ` ${clazz}` : ""
      } button_outline--green _no-underline`}
    >
      <Icon size={"m"} spritePath={"instruction-cont"} />
      {widthScreen > 1199.98 ? t("guide") : null}
    </ButtonLink>
  );

  const DropdownLanguage = () => (
    <Select
      options={languages.options}
      clazz={"button button-xs button--main"}
      arrowSize={"s"}
      sorted={true}
      name={"language"}
    ></Select>
  );

  const topLinksTabs = {
    default: [
      { id: 1, name: t("about"), href: `/${lang}/about`, count: 0 },
      { id: 2, name: t("guide"), href: `/guide`, count: 0 },
      { id: 3, name: t("faq"), href: `/${lang}/faq`, count: 0 },
      { id: 4, name: t("support"), href: `#`, count: 0 },
    ],
    // photograph: [
    //   { id: 1, name: t("profuser"), href: "#", count: 0 },
    //   { id: 2, name: t("photo"), href: "#", count: 0 },
    //   { id: 3, name: t("videofotograph"), href: "#", count: 0 },
    //   { id: 4, name: t("interior"), href: "#", count: 0 },
    //   { id: 5, name: t("reviews"), href: "#", count: 0 },
    // ],
    user: [
      { id: 1, name: t("profuser"), href: `#` },
      { id: 2, name: t("subandfilters"), href: `#`, count: 0 },
      { id: 3, name: t("history"), href: `#`, count: 0 },
      { id: 4, name: t("balance"), href: `#`, count: 0, wallet: 20 },
    ],
    agency: [
      { id: 1, name: t("profuser"), href: `#` },
      { id: 2, name: t("myads"), href: `#`, count: 0 },
      { id: 3, name: t("reviews"), href: `#`, count: 0 },
      { id: 3, name: t("myoperator"), href: `#` },
      { id: 3, name: t("myparty"), href: `#` },
      { id: 3, name: t("mystat"), href: `#` },
      { id: 4, name: t("balance"), href: `#`, wallet: 20 },
    ],
    indi: [
      { id: 1, name: t("profuser"), href: "/lk/indi" },
      { id: 2, name: t("myads"), href: "/lk/indi/profiles", count: 0 },
      { id: 3, name: t("reviews"), href: "/lk/indi/reviews", count: 0 },
      { id: 4, name: t("mystat"), href: "/lk/indi/statistic" },
      { id: 5, name: t("balance"), href: "/lk/indi/balance", wallet: 20 },
    ],
  };

  const HeaderTabs = () => {
    let userType = () => {
      // eslint-disable-next-line default-case
      switch (type) {
        case "user": {
          return topLinksTabs.user;
        }

        // case "photograph": {
        //   return topLinksTabs.photograph;
        // }

        case "indi": {
          return topLinksTabs.indi;
        }

        case "agency": {
          return topLinksTabs.agency;
        }
      }
    };

    return userType().map((i) => {
      return (
        <Link
          data-counter={i.count}
          key={i.id}
          className={`header__tab p2${i.count < 1 ? " empty" : ""} ${
            activeTabId === i.id ? "_active" : ""
          }`}
          to={i.href}
          onClick={() => handleTabClick(i.id)}
        >
          {i.name}
          {i.wallet ? <span className="color-green"> {i.wallet}€</span> : null}
        </Link>
      );
    });
  };

  const FloatBurgerMenu = () => {
    const headerIcons = {
      indi: [
        {
          id: 11,
          icon: "video",
          name: t("videochat"),
          link: "#",
        },
        {
          id: 22,
          icon: "pay-content",
          name: indi("myorder"),
          link: "#",
        },
        {
          id: 6,
          icon: "user-circle",
          name: t("profuser"),
          onClick:
            type === "indi"
              ? () => toLk("indi")
              : () => dispatch(setAuthorizationStatus(true)),
        },
        {
          id: 1,
          icon: "home-main",
          name: t("mainpage"),
        },
        {
          id: 2,
          icon: "catalog",
          name: indi("catalogue"),
          onClick: () => showShortFilter(),
        },
        {
          id: 3,
          icon: "filter",
          name: t("filter"),
          onClick: () => showFilter(),
        },
        {
          id: 4,
          icon: "map",
          name: t("onmap"),
        },
        {
          id: 5,
          icon: "favorite",
          clazz: "user__favorite",
          name: indi("favorites"),
          counter: favoriteCount,
          link: "/favorites",
        },
        // {
        //   id: 7,
        //   icon: "camera",
        //   name: t("photographer"),
        //   link: "#",
        // },
        {
          id: 7,
          icon: "instruction-cont",
          name: t("guide"),
          link: `/guide`,
        },
      ],
      indiPage: [
        {
          id: 1,
          icon: "video",
          name: t("videochat"),
          link: "#",
        },
        {
          id: 2,
          icon: "pay-content",
          name: indi("myorder"),
          link: "#",
        },
        {
          id: 3,
          icon: "user-circle",
          name: t("profuser"),
          onClick:
            type === "indi"
              ? () => toLk("indi")
              : () => dispatch(setAuthorizationStatus(true)),
        },
        {
          id: 4,
          icon: "user-id",
          name: t("myads"),
          link: "#",
        },
        {
          id: 5,
          icon: "star",
          name: t("reviews"),
          link: "#",
        },
        {
          id: 6,
          icon: "stat",
          name: t("mystat"),
          link: "#",
        },
        {
          id: 7,
          icon: "map",
          name: t("balance"),
          link: "#",
        },
        // {
        //   id: 8,
        //   icon: "camera",
        //   name: t("photographer"),
        //   link: "#",
        // },
        {
          id: 8,
          icon: "instruction-cont",
          name: t("guide"),
          link: `/guide`,
        },
      ],
      agency: [
        {
          id: 11,
          icon: "video",
          name: t("videochat"),
          link: "#",
        },
        {
          id: 22,
          icon: "pay-content",
          name: indi("myorder"),
          link: "#",
        },
        {
          id: 33,
          icon: "casting",
          name: indi("demos"),
          link: "#",
        },
        {
          id: 6,
          icon: "user-circle",
          name: t("profuser"),
          onClick:
            type === "agency"
              ? () => toLk("agency")
              : () => dispatch(setAuthorizationStatus(true)),
        },
        {
          id: 1,
          icon: "home-main",
          name: t("mainpage"),
        },
        {
          id: 2,
          icon: "catalog",
          name: indi("catalogue"),
          onClick: () => showShortFilter(),
        },
        {
          id: 3,
          icon: "filter",
          name: t("filter"),
          onClick: () => showFilter(),
        },
        {
          id: 4,
          icon: "map",
          name: t("onmap"),
        },
        {
          id: 5,
          icon: "favorite",
          clazz: "user__favorite",
          name: indi("favorites"),
          counter: favoriteCount,
        },
        // {
        //   id: 7,
        //   icon: "camera",
        //   name: t("photographer"),
        //   link: "#",
        // },
        {
          id: 6,
          icon: "instruction-cont",
          name: t("guide"),
          link: `/guide`,
        },
      ],
      agencyPage: [
        {
          id: 1,
          icon: "video",
          name: t("videochat"),
          link: "#",
        },
        {
          id: 2,
          icon: "pay-content",
          name: indi("myorder"),
          link: "#",
        },
        {
          id: 3,
          icon: "casting",
          name: indi("demos"),
          link: "#",
        },
        {
          id: 33,
          icon: "user-circle",
          name: t("profuser"),
          onClick:
            type === "agency"
              ? () => toLk("agency")
              : () => dispatch(setAuthorizationStatus(true)),
        },
        {
          id: 4,
          icon: "user-id",
          name: t("myads"),
          link: "#",
        },
        {
          id: 5,
          icon: "star",
          name: t("reviews"),
          link: "#",
        },
        {
          id: 66,
          icon: "operator",
          name: indi("myoperator"),
          link: "#",
        },
        {
          id: 77,
          icon: "party",
          name: indi("myparty"),
          link: "#",
        },

        {
          id: 6,
          icon: "stat",
          name: t("mystat"),
          link: "#",
        },
        {
          id: 7,
          name: t("balance"),
          link: "#",
          wallet: 20,
        },
        // {
        //   id: 8,
        //   icon: "camera",
        //   name: t("photographer"),
        //   link: "#",
        // },
        {
          id: 8,
          icon: "instruction-cont",
          name: t("guide"),
          link: `/guide`,
        },
      ],
      default: [
        {
          id: 1,
          icon: "home-main",
          name: t("mainpage"),
          link: "/",
        },
        {
          id: 2,
          icon: "catalog",
          name: indi("catalogue"),
          onClick: () => showShortFilter(),
        },
        {
          id: 3,
          icon: "filter",
          name: t("filter"),
          onClick: () => showFilter(),
        },
        {
          id: 4,
          icon: "map",
          name: t("onmap"),
        },
        {
          id: 5,
          icon: "favorite",
          clazz: "user__favorite",
          name: indi("favorites"),
          counter: favoriteCount,
        },
        {
          id: 6,
          icon: "person",
          name: t("profuser"),
          onClick: () => dispatch(setAuthorizationStatus(true)),
        },
      ],
    };

    const checkPageByUserType = () => {
      if (profilePage && type === "indi") {
        return headerIcons.indiPage;
      } else if (!profilePage && type === "indi") {
        return headerIcons.indi;
      } else if (profilePage && type === "agency") {
        return headerIcons.agencyPage;
      } else if (!profilePage && type === "agency") {
        return headerIcons.agency;
      } else {
        return headerIcons.default;
      }
    };

    let linksByType = () => {
      switch (type) {
        case "user":
          return topLinksTabs.user;
        // case "photograph":
        //   return topLinksTabs.photograph;
        default:
          return topLinksTabs.default;
      }
    };

    return (
      <div className={`users${isOpenMenu ? " _active" : ""}`}>
        <BurgerMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
        <div className={"users__nav"}>
          {checkPageByUserType().map((i) => {
            if (i.wallet) {
              return (
                <ButtonLink
                  href={i.link}
                  key={i.id}
                  title={i.name}
                  size={"l"}
                  clazz="menu__link _no-underline"
                  counter={i.counter}
                  onClick={() => setIsOpenMenu((prev) => !prev)}
                >
                  <span className={"menu__wallet"}>{i.wallet}€</span>
                  <span>{i.name}</span>
                </ButtonLink>
              );
            }

            if (i.link) {
              return (
                <ButtonLink
                  href={i.link}
                  key={i.id}
                  title={i.name}
                  size={"l"}
                  clazz={`menu__link _no-underline${
                    i.clazz ? ` ${i.clazz}` : ""
                  }`}
                  counter={i.counter}
                  onClick={() => setIsOpenMenu((prev) => !prev)}
                >
                  <Icon size={"xl"} clazz={i.clazz} spritePath={i.icon} />
                  <span>{i.name}</span>
                </ButtonLink>
              );
            }

            if (i.onClick) {
              return (
                <Button
                  onClick={i.onClick}
                  key={i.id}
                  title={i.name}
                  size={"l"}
                  clazz="menu__link"
                  counter={i.counter}
                >
                  <Icon size={"xl"} clazz={i.clazz} spritePath={i.icon} />
                  <span>{i.name}</span>
                </Button>
              );
            }

            return (
              <Button
                onClick={() => setIsOpenMenu((prev) => !prev)}
                key={i.id}
                title={i.name}
                size={"l"}
                clazz="menu__link"
                counter={i.counter}
              >
                <Icon size={"xl"} spritePath={i.icon} />
                <span>{i.name}</span>
              </Button>
            );
          })}
        </div>

        {type === "user" || type === "photograph" || type === "default" ? (
          <div className="users__links">
            {linksByType().map((i) => {
              if (i.name === t("support") && type === "default") {
                return (
                  <ButtonLink
                    onClick={() => {
                      dispatch(setAuthorisationAccessType("support"));
                      dispatch(setAuthorizationStatus(true));
                    }}
                    href={i.href}
                    title={i.name}
                    key={i.id}
                    children={i.name}
                    clazz={"hover-line"}
                  >
                    {i.name}
                  </ButtonLink>
                );
              }

              return (
                <ButtonLink
                  onClick={() => setIsOpenMenu((prev) => !prev)}
                  href={i.href}
                  title={i.name}
                  key={i.id}
                  children={i.name}
                  clazz={"hover-line"}
                />
              );
            })}
          </div>
        ) : null}

        <div className="users__bottom">
          {!profilePage ? null : (
            <ButtonLink
              title={t("backtoweb")}
              href={"/"}
              size={"m"}
              clazz={"button--tetriary td-none width-100"}
            >
              {indi("backtoweb")}
            </ButtonLink>
          )}
          {type !== "default" ? (
            <Button
              title={t("logout")}
              onClick={() => logOut()}
              clazz="user__log-out"
            >
              {indi("logout")}
              <Icon size={"m"} spritePath={"logout"} />
            </Button>
          ) : null}
        </div>
      </div>
    );
  };

  const HeaderFilters = () => {
    return (
      <form className="header__filters">
        {widthScreen > 1199.98 ? (
          <>
            <InputNick
              type="search"
              placeholder={t("modelname")}
              id="headerSearch"
              clazz={"search__input"}
              name={"nickname"}
              scrolledClass={scrolledClass}
            />

            <Dropdown
              title={t("place")}
              name={"place"}
              clazzWrapper={"header__filter--place"}
              size={"s"}
            >
              {options_place.options.map((i) => {
                return (
                  <CheckboxFilter
                    options={options_place}
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    value={i.value}
                    count={i.count}
                    title={i.title}
                    checked={i.checked}
                    disabled={i.disabled}
                  />
                );
              })}
            </Dropdown>

            <Dropdown
              title={t("price")}
              name={"price"}
              clazzWrapper={"header__filter--price"}
              size={"s"}
            >
              <InputRangeFilter
                min={defaultRangeValue.price.min}
                max={defaultRangeValue.price.max}
                step={10}
                clazz={"range--price"}
                inputIdLeft={"minPrice"}
                inputIdRight={"maxPrice"}
                name={"price"}
                title={`${t("price")},`}
                subtitle={` €/${t("hour")}`}
              />
            </Dropdown>

            <Dropdown
              title={t("age")}
              name={"age"}
              size={"s"}
              clazzWrapper={"header__filter--age"}
            >
              <InputRangeFilter
                min={defaultRangeValue.age.min}
                max={defaultRangeValue.age.max}
                step={1}
                clazz={"range--age"}
                inputIdLeft={"minAge"}
                inputIdRight={"maxAge"}
                title={`${t("age")},`}
                name={"age"}
                subtitle={` ${t("years")}`}
              />
            </Dropdown>
          </>
        ) : null}

        <Button
          onClick={() => dispatch(show())}
          size={"s"}
          clazz={"header__filter"}
        >
          {t("filter")}
          <Icon size={"m"} spritePath={"filter"} />
          {(hasNonEmptyFilters || hasNonEmptyRange) && (
            <span className="count">!</span>
          )}
        </Button>

        {(hasNonEmptyFilters || hasNonEmptyRange) && (
          <Button
            onClick={() => {
              dispatch(resetAll());
              dispatch(setSearchFilter(false));
              dispatch(cleanSorted());
              dispatch(setSortedModelsByFilter(""));
              dispatch(clearTitle());
              dispatch(resetAllCounters());
              dispatch(setPageDefault());
            }}
            title={t("reset")}
            clazz="header__reset"
          >
            {t("reset")}
          </Button>
        )}
      </form>
    );
  };

  const MenuNavBar = () => {
    const headerIconsMenuBar = {
      indiPage: [
        {
          id: 1,
          icon: "user-circle",
          name: t("profuser"),
          onClick:
            type === "indi"
              ? () => toLk("indi")
              : () => dispatch(setAuthorizationStatus(true)),
        },
        {
          id: 2,
          icon: "user-id",
          name: t("myads"),
          link: "#",
        },
        {
          id: 3,
          icon: "star",
          name: t("reviews"),
          link: "#",
        },
        {
          id: 4,
          icon: "stat",
          name: t("mystat"),
          link: "#",
        },
        {
          id: 5,
          name: t("balance"),
          link: "#",
          wallet: 20,
        },
        {
          id: 6,
          icon: "undo-left",
          name: indi("backtomain"),
          link: "/",
        },
      ],
      agencyPage: [
        {
          id: 1,
          icon: "user-circle",
          name: t("profuser"),
          onClick:
            type === "agency"
              ? () => toLk("agency")
              : () => dispatch(setAuthorizationStatus(true)),
        },
        {
          id: 2,
          icon: "user-id",
          name: t("myads"),
          link: "#",
        },
        {
          id: 3,
          icon: "star",
          name: t("reviews"),
          link: "#",
        },
        {
          id: 5,
          icon: "stat",
          name: t("mystat"),
          link: "#",
        },
        {
          id: 6,
          name: t("balance"),
          link: "#",
          wallet: 20,
        },
        // {
        //   id: 7,
        //   icon: "camera",
        //   name: t("photographer"),
        //   link: "#",
        // },
      ],
      default: [
        {
          id: 1,
          icon: "home-main",
          name: t("mainpage"),
          link: "/",
        },
        {
          id: 2,
          icon: "catalog",
          name: indi("catalogue"),
          onClick: () => showShortFilter(),
        },
        {
          id: 3,
          icon: "filter",
          name: t("filter"),
          onClick: () => showFilter(),
        },
        {
          id: 4,
          icon: "map",
          name: t("onmap"),
        },
        {
          id: 5,
          icon: "favorite",
          clazz: "user__favorite",
          name: indi("favorites"),
          counter: favoriteCount,
        },
        {
          id: 6,
          icon: "person",
          name: t("profuser"),
          onClick:
            type === "agency"
              ? () => toLk("agency")
              : type === "indi"
              ? () => toLk("indi")
              : () => dispatch(setAuthorizationStatus(true)),
        },
      ],
    };
    const MenuNavBarLinksByUserType = () => {
      if (profilePage && type === "indi") {
        return headerIconsMenuBar.indiPage;
      } else if (profilePage && type === "agency") {
        return headerIconsMenuBar.agencyPage;
      } else {
        return headerIconsMenuBar.default;
      }
    };

    return (
      <div className="menu__body">
        {MenuNavBarLinksByUserType()
          .slice(0, 6)
          .map((i) => {
            if (i.wallet) {
              return (
                <ButtonLink
                  href={i.link}
                  key={i.id}
                  title={i.name}
                  size={"l"}
                  clazz={`menu__link _no-underline${
                    i.clazz ? ` ${i.clazz}` : ""
                  }`}
                  counter={i.counter}
                >
                  <span className={"menu__wallet"}>{i.wallet}€</span>
                  <span>{i.name}</span>
                </ButtonLink>
              );
            }

            return (
              <Button
                onClick={i.onClick}
                key={i.id}
                title={i.name}
                size={"l"}
                counter={i.counter}
                clazz={`menu__link${i.clazz ? ` ${i.clazz}` : ""}`}
              >
                <Icon size={"l"} clazz={i.clazz} spritePath={i.icon} />
                <span>{i.name}</span>
              </Button>
            );
          })}
      </div>
    );
  };

  const UserWrapperDesktop = () => {
    if ((type === "indi" || type === "agency") && profilePage) {
      return (
        <div className="user__wrapper">
          {UserNotification("s")}

          <ButtonLink size={"s"} clazz={"button--tetriary td-none"} href="/">
            {t("mainpage")}
          </ButtonLink>

          {LogOutButton()}
        </div>
      );
    }

    return (
      <div className="user__wrapper">
        {UserNotification("s")}

        {FavoriteButton("s")}

        <Avatar size="xxxl" avatarLink={avatarLink} onlyIcon />

        {LogOutButton()}
      </div>
    );
  };
  // варианты шапок
  const DefaultHeaderContent = () => {
    if (widthScreen > 1199.98)
      return (
        <>
          <div className="header__top">
            <ButtonLink
              href={`/registration`}
              onClick={(e) => toRegistration(e)}
              title={t("add")}
              size={"xs"}
              clazz={"add-form button_outline--green _no-underline"}
            >
              {t("add")}
              <Icon size={"s"} spritePath={"plus"} />
            </ButtonLink>

            <Dropdown
              buttonClass={"_no-fill"}
              size={"xs"}
              title={t("forusers")}
            >
              {infoLinks.map((i) => {
                if (i.id === 4 && type === "default") {
                  return (
                    <ButtonLink
                      onClick={() => {
                        dispatch(setAuthorisationAccessType("support"));
                        dispatch(setAuthorizationStatus(true));
                      }}
                      clazz={"language"}
                      title={i.name}
                      size={"xs"}
                      key={i.id}
                      children={i.name}
                    />
                  );
                }

                return (
                  <ButtonLink
                    href={i.link}
                    clazz={"language"}
                    title={i.name}
                    size={"xs"}
                    key={i.id}
                    children={i.name}
                  />
                );
              })}
            </Dropdown>

            {DropdownLanguage()}

            <SwitchTheme type={"switch"} />
          </div>
          <div className="header__bottom">
            {Logo()}

            {SelectCity()}

            {HeaderFilters()}

            <div className="user__wrapper">
              {FavoriteButton("s")}

              {LogInButton()}
            </div>
          </div>
        </>
      );

    if (widthScreen > 767.98)
      return (
        <div className="header__bottom">
          {Logo()}

          {SelectCity()}
          {HeaderFilters()}

          <div className="user__wrapper">
            {FavoriteButton("s")}

            <ButtonLink
              href={`/registration`}
              onClick={(e) => toRegistration(e)}
              title={t("add")}
              size={"s"}
              clazz={"add-form button_outline--green _no-underline"}
            >
              <Icon size={"l"} spritePath={"plus"} />
            </ButtonLink>

            <Button
              onClick={() => dispatch(setAuthorizationStatus(true))}
              size={"s"}
              clazz="button--green user__log-in"
            >
              {t("login")}
            </Button>

            <BurgerMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
            <SwitchTheme type={"switch"} />
            {FloatBurgerMenu()}
          </div>
        </div>
      );

    return (
      <div className="header__top">
        <div className="user__wrapper">
          <BurgerMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />

          {DropdownLanguage()}
        </div>

        {Logo()}

        <div className="user__wrapper">
          <ButtonLink
            href={`/registration`}
            onClick={(e) => toRegistration(e)}
            title={t("add")}
            size={"xs"}
            clazz={"add-form button_outline--green _no-underline"}
          >
            <Icon size={"l"} spritePath={"plus"} />
          </ButtonLink>

          <SwitchTheme type={"switch"} />
        </div>

        {FloatBurgerMenu()}
        {MenuNavBar()}
      </div>
    );
  };

  const UserOrPhotographHeaderContent = () => {
    if (widthScreen > 1199.98)
      return (
        <>
          <div className="header__top">
            <div className="header__tabs">{HeaderTabs()}</div>

            {InstructionLink()}

            {DropdownLanguage()}

            <SwitchTheme type={"switch"} />
          </div>

          <div className="header__bottom">
            {Logo()}

            {SelectCity()}

            {HeaderFilters()}

            {UserWrapperDesktop()}
          </div>
        </>
      );

    if (widthScreen > 767.98)
      return (
        <div className="header__bottom">
          {Logo()}

          {SelectCity()}

          {HeaderFilters()}

          <div className="user__wrapper">
            {UserNotification()}

            <ButtonLink
              href={`/guide`}
              title={t("add")}
              size={"s"}
              clazz={"add-form button_outline--green _no-underline"}
            >
              <Icon size={"m"} spritePath={"instruction-cont"} />
            </ButtonLink>

            <BurgerMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
            <SwitchTheme type={"switch"} />
            {FloatBurgerMenu()}
          </div>
        </div>
      );

    return (
      <>
        <div className="header__top">
          <div className="user__wrapper">
            <BurgerMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
            {DropdownLanguage()}
          </div>

          {Logo()}

          <div className="user__wrapper">
            {UserNotification("xs")}
            <SwitchTheme type={"switch"} />
          </div>

          {FloatBurgerMenu()}
          {MenuNavBar()}
        </div>
      </>
    );
  };

  const isVideo = useSelector(getUserVideoStatus);
  const isRes = useSelector(getUserOrderStatus);

  const onChangeVideochat = async () => {
    try {
      dispatch(setReadyToVideochat(Number(!isVideo)));

      const route = `user/set-call-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isVideo),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isVideo)));
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const onChangeOrder = async () => {
    try {
      dispatch(setReadyToOrder(Number(!isRes)));
      const route = `user/set-booking-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isRes),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isRes)));
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const ModelOrAgencyHeaderContent = () => {
    let variationsOfContent = () => {
      if (type === "indi") {
        return (
          <>
            <Toggle
              link={"/lk/indi/videochats"}
              title={t("videochat")}
              counter={5}
              id={"readyToVideochat"}
              checked={!!isVideo}
              onChange={onChangeVideochat}
            />

            <Toggle
              link={"/lk/indi/reservations"}
              title={indi("myorder")}
              counter={2}
              id={"readyToOrder"}
              checked={!!isRes}
              onChange={onChangeOrder}
            />
          </>
        );
      }
      return (
        <>
          <ButtonLink
            href={"#"}
            title={t("videochat")}
            size={"xs"}
            clazz={"button_outline--black _no-underline"}
            counter={3}
          >
            <Icon size={"m"} spritePath={"video"} />
            {t("videochat")}
          </ButtonLink>

          <ButtonLink
            href={"#"}
            title={indi("myorder")}
            size={"xs"}
            clazz={"button_outline--black _no-underline"}
            counter={2}
          >
            <Icon size={"m"} spritePath={"pay-content"} />
            {indi("myorder")}
          </ButtonLink>

          <ButtonLink
            href={"#"}
            title={indi("demos")}
            size={"xs"}
            clazz={"button_outline--black _no-underline"}
            counter={2}
          >
            <Icon size={"m"} spritePath={"casting"} />
            {indi("demos")}
          </ButtonLink>
        </>
      );
    };

    if (widthScreen > 1199.98)
      return (
        <>
          <div className="header__top">
            {/* <ButtonLink
              href={"#"}
              title={t("photographer")}
              size={"xs"}
              clazz={"add-form button_outline--green _no-underline"}
            >
              <Icon size={"m"} spritePath={"camera"} />
              {t("photographer")}
            </ButtonLink> */}

            {InstructionLink()}

            {variationsOfContent()}

            <ButtonLink
              href={"#"}
              title={t("support")}
              size={"xs"}
              clazz={"button--tetriary _no-underline"}
              counter={0}
              // square={true}
            >
              <Icon size={"m"} spritePath={"operator"} />
              {t("support")}
            </ButtonLink>

            {DropdownLanguage()}

            <SwitchTheme type={"switch"} />
          </div>

          <div className="header__bottom">
            {Logo()}

            {profilePage === true ? (
              <div className={"header__tabs"}>{HeaderTabs()}</div>
            ) : (
              <>
                {SelectCity()}
                {HeaderFilters()}
              </>
            )}

            {UserWrapperDesktop()}
          </div>
        </>
      );

    if (widthScreen > 767.98)
      return (
        <>
          <div className="header__top">
            {Logo()}

            {SelectCity()}

            {HeaderFilters()}

            <div className="user__wrapper">
              {variationsOfContent()}

              <ButtonLink
                href={"#"}
                title={t("support")}
                size={"s"}
                clazz={"button--tetriary _no-underline"}
                counter={0}
                square={true}
              >
                <Icon size={"m"} spritePath={"operator"} />
                {/* {t("support")} */}
              </ButtonLink>

              {UserNotification()}

              <BurgerMenu
                isOpenMenu={isOpenMenu}
                setIsOpenMenu={setIsOpenMenu}
              />
              <SwitchTheme type={"switch"} />
              {FloatBurgerMenu()}
            </div>
          </div>
        </>
      );

    return (
      <>
        <div className="header__top">
          <div className="user__wrapper">
            <BurgerMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
            {DropdownLanguage()}
          </div>

          {Logo()}

          <div className="user__wrapper">
            {UserNotification("xs")}
            <SwitchTheme type={"switch"} />
          </div>

          {FloatBurgerMenu()}
          {MenuNavBar()}
        </div>

        <div className="header__bottom">{variationsOfContent()}</div>
      </>
    );
  };

  return (
    <header
      className={`${headerClassByUserType()}${
        scrolledClass && widthScreen > 767.98 ? ` ${scrolledClass}` : ""
      }`}
    >
      <div className="header__container">{headerContentByUserType()}</div>
    </header>
  );

  function headerClassByUserType() {
    switch (type) {
      // case "photograph":
      case "user": {
        return "header--type-one";
      }
      case "agency":
      case "indi": {
        return "header--type-two";
      }
      default: {
        return "";
      }
    }
  }

  function headerContentByUserType() {
    switch (type) {
      // case "photograph":
      case "user": {
        return UserOrPhotographHeaderContent();
      }
      case "agency":
      case "indi": {
        return ModelOrAgencyHeaderContent();
      }
      default: {
        return DefaultHeaderContent();
      }
    }
  }
};
export default Header;
