import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { ref, string, object } from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewPassStatus,
  setNewPassStatus,
  setRegistrationStatus,
} from "@/stores/slices/popupSlice";

const NewPassPopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const statusPopup = useSelector(getNewPassStatus);

  const formSchema = object().shape({
    password: string().min(6, t("min6")).required(),
    password_confirmation: string()
      .min(6, t("min6"))
      .required()
      .oneOf([ref("password")], t("passdonot")),
  });

  const navigate = useNavigate();

  const handleCloseDelete = () => {
    dispatch(setNewPassStatus(false));
  };

  const handleBack = () => {
    dispatch(setNewPassStatus(false));
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `auth/password/set`;
        const method = "PUT";
        const payload = {
          password: data.password,
          password_confirmation: data.password_confirmation,
        };

        const resData = await makeRequest({ route, method, payload });
        console.log(resData);
        // if(resData) {
        //     dispatch(setNewPassStatus(false));
        //     dispatch(setRegistrationStatus(true));
        // }
      } catch (error) {}
    };

    fetchData();
  };

  return (
    <dialog open={!!statusPopup} onClick={handleCloseDelete}>
      {!!statusPopup ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        method={"PUT"}
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(onSubmit)}
        className="popup-form__body gap-16"
      >
        <Button onClick={handleCloseDelete} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>
        <h2>{"Новый пароль"}</h2>

        <div className="popup-form__inner">
          <InputInLabel
            clazz={`${errors.password ? " error" : ""}`}
            id={"passRegistration"}
            title={t("pass")}
            type={"password"}
            placeholder={"**********"}
            register={{ ...register("password") }}
            autocomplete={"new-password"}
          />

          <InputInLabel
            clazz={`${errors.password_confirmation ? " error" : ""}`}
            id={"passConfirmRegistration"}
            title={t("repeatpassword")}
            type={"password"}
            placeholder={"**********"}
            register={{ ...register("password_confirmation") }}
            autocomplete={"new-password"}
          />
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l"}
            clazz={"button--green justify-center"}
            buttonType={"submit"}
          >
            {"Применить"}
          </Button>

          <Button
            size={"l"}
            buttonType={"button"}
            clazz={"button--error justify-center"}
            onClick={() => handleBack()}
          >
            {indi("back")}
          </Button>
        </div>
      </form>
    </dialog>
  );
};

export default NewPassPopup;
