import { Icon, Video } from "@/components/ui";
import { Swiper, SwiperSlide } from "swiper/react";
import { HashNavigation, Navigation, Thumbs } from "swiper/modules";
import React, { useEffect, useRef, useState } from "react";
import {
  setAuthorisationAccessType,
  setAuthorizationStatus,
  setErocontentAccessStatus,
  setGalleryIndexImg,
  setGalleryStatus,
} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";

const TabSliderModel = ({ clazz, name, media, ero }) => {
  const { t } = useTranslation("translation");

  const { dispatch, windowWidth, userType, setAvialableForUser } =
    useLadyService();

  const sliderRef = useRef(null);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [sliderIndex, setSliderIndex] = useState(0);

  const [nameValue, setNameValue] = useState(null);
  useEffect(() => {
    setNameValue(name);
  }, [name]);

  const handleClick = () => {
    if (userType === "default") {
      dispatch(setAuthorizationStatus(true));
      dispatch(setAuthorisationAccessType("ero"));
    } else {
      dispatch(setErocontentAccessStatus(true));
    }
  };

  const sliderParams = {
    className: `${clazz}__slider tab-slider`,
    slidesPerView: 1,
    spaceBetween: 10,
    modules: [Thumbs, Navigation, HashNavigation],
    hashNavigation: {
      watchState: true,
      replaceState: true,
    },
    ref: sliderRef,
    thumbs: {
      swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
    },
    navigation: {
      nextEl: `.${clazz}__button-next`,
      prevEl: `.${clazz}__button-prev`,
    },

    onActiveIndexChange: function () {
      let index = this.realIndex;
      setSliderIndex(index);
      let current_data = this.slides[index].dataset.hash;
      setCurrentIndex(current_data);
    },

    onTransitionStart: function () {
      if (windowWidth < 600.98) {
        let videos = document.querySelectorAll(
          ".model-main__slider .stories__slide .video video"
        );
        Array.prototype.forEach.call(videos, function (video) {
          video.pause();
        });
      }
    },

    onTransitionEnd: function () {
      if (windowWidth < 600.98) {
        let index = this.realIndex;
        let slide = document.getElementsByClassName("stories__slide")[index];
        let slideVideo = slide.getElementsByTagName("video")[0];
        if (slideVideo != null || slideVideo !== undefined) {
          slideVideo.load();
        }
      }
    },
  };

  const tabsBtns = [
    {
      title: t("photo"),
      icon: "image",
      link: "photo",
    },

    {
      title: t("videofotograph"),
      icon: "film",
      link: "video",
    },

    {
      title: t("interior"),
      icon: "home",
      link: "interior",
    },

    {
      title: t("erocontent"),
      icon: "pants",
      ero: true,
      link: "ero",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(tabsBtns[0].link);

  const ShowPrivate = () => (
    <div className={"d-flex gap-8 fd-column align-center color-white"}>
      <Icon size={"m"} spritePath={"eye-off"} />
      <span className={"color-main"}>{t("show")}</span>
    </div>
  );

  const tabsBtnsTemplate = (btn) => (
    <a
      href={`#${btn.link}`}
      className={`tabs__nav-btn${
        currentIndex === btn.link ? " tabs__nav-btn--active" : ""
      }${btn.ero ? " tabs__nav-btn--erocontent" : ""}`}
    >
      <Icon size={"s"} spritePath={btn.icon} />

      {btn.ero
        ? windowWidth > 767.98
          ? btn.title
          : btn.title.substring(0, 3)
        : btn.title}
    </a>
  );

  const MediaTemplate = (media, index, hash, type) => {
    const result = () => {
      dispatch(setGalleryIndexImg(sliderIndex));
      dispatch(setGalleryStatus(true));
    };

    return (
      <SwiperSlide
        className={`model-mail__slider stories__slide${
          type === "ero" ? " model-main__private-slide" : ""
        }`}
        data-hash={hash}
        key={index}
        onClick={
          type === "ero"
            ? () => handleClick()
            : windowWidth > 600.98
            ? () => result()
            : null
        }
      >
        {type === "ero" ? ShowPrivate() : null}

        {type === "video" ? (
          <>
            {windowWidth > 600.98 ? (
              <Icon spritePath={"round-video-triangle"} />
            ) : null}
            <Video
              playButton={windowWidth < 600.98}
              controls={windowWidth < 600.98}
              src={media}
              clazz={"model-main__slider-video"}
              title={`${t("altvideoprofile")} ${nameValue}`}
              alt={`${t("altvideoprofile")} ${nameValue}`}
            />
          </>
        ) : (
          <img
            src={media}
            className={"img-contain"}
            title={`${t("altphotoprofile")} ${nameValue}`}
            alt={`${t("altphotoprofile")} ${nameValue}`}
          />
        )}
      </SwiperSlide>
    );
  };

  const ThumbTemplate = (data, index, type) => (
    <SwiperSlide key={index + 1} className={"stories__slide"}>
      {type === "video" ? (
        <>
          <Icon size={"xl"} spritePath={"round-video-triangle"} />
          <Video
            playButton={false}
            clazz={"model-main__slider-video"}
            src={data}
            title={`${t("altvideoprofile")} ${nameValue}`}
            alt={`${t("altvideoprofile")} ${nameValue}`}
          />
        </>
      ) : (
        <img
          src={data}
          className={"img-contain"}
          title={`${t("altphotoprofile")} ${nameValue}`}
          alt={`${t("altphotoprofile")} ${nameValue}`}
        />
      )}
    </SwiperSlide>
  );

  return (
    <div className="tabs">
      <div className="tabs__nav mb-8">
        {media && media.photo && media.photo.length > 0
          ? tabsBtnsTemplate(tabsBtns[0])
          : null}

        {media && media.video && media.video.length > 0
          ? tabsBtnsTemplate(tabsBtns[1])
          : null}

        {media && media.interior && media.interior.length > 0
          ? tabsBtnsTemplate(tabsBtns[2])
          : null}

        {ero && ero.data && ero.data.photo && ero.data.photo.length > 0
          ? tabsBtnsTemplate(tabsBtns[3])
          : null}
      </div>

      <Swiper {...sliderParams}>
        {media && media.photo && media.photo.length > 0
          ? media.photo.map((data, index) =>
              MediaTemplate(data, index, tabsBtns[0].link)
            )
          : null}

        {media && media.video && media.video.length > 0
          ? media.video.map((data, index) =>
              MediaTemplate(data, index, tabsBtns[1].link, "video")
            )
          : null}

        {media && media.interior && media.interior.length > 0
          ? media.interior.map((data, index) =>
              MediaTemplate(data, index, tabsBtns[2].link)
            )
          : null}

        {ero && ero.data && ero.data.photo && ero.data.photo.length > 0
          ? ero.data.photo.map((data, index) =>
              MediaTemplate(data, index, tabsBtns[3].link, "ero")
            )
          : null}

        {ero && ero.data && ero.data.video && ero.data.video.length > 0
          ? ero.data.video.map((data, index) =>
              MediaTemplate(data, index, tabsBtns[3].link, "ero")
            )
          : null}
      </Swiper>

      {(media && media.photo && media.photo.length > 0) ||
      (media && media.video && media.video.length > 0) ||
      (media && media.interior && media.interior.length > 0) ||
      (media && media.ero && media.ero.length > 0) ? (
        <div className={`${clazz}__thumb`}>
          <button
            className={`swiper-button-prev ${clazz}__button-prev`}
          ></button>

          <Swiper
            className={"swiper-thumbs"}
            modules={[Thumbs]}
            watchSlidesProgress
            onSwiper={setThumbsSwiper}
            slidesPerView={4}
            spaceBetween={4}
          >
            {media && media.photo && media.photo.length > 0
              ? media.photo.map((data, index) => ThumbTemplate(data, index))
              : null}

            {media && media.video && media.video.length > 0
              ? media.video.map((data, index) =>
                  ThumbTemplate(data, index, "video")
                )
              : null}

            {media && media.interior && media.interior.length > 0
              ? media.interior.map((data, index) => ThumbTemplate(data, index))
              : null}

            {ero && ero.data && ero.data.photo && ero.data.photo.length > 0
              ? ero.data.photo.map((data, index) => ThumbTemplate(data, index))
              : null}

            {ero && ero.data && ero.data.video && ero.data.video.length > 0
              ? ero.data.video.map((data, index) => ThumbTemplate(data, index))
              : null}
          </Swiper>

          <button
            className={`swiper-button-next ${clazz}__button-next`}
          ></button>
        </div>
      ) : null}
    </div>
  );
};

export default TabSliderModel;
