import './iconFlag.scss'
import './iconPaymentMethod.scss'
import './icon.scss'

const Icon = (props) => {
  const {
    spritePath,
    clazz,
    size,
    type,
    title
  } = props

  switch (type) {
    case 'flag':
      return <i title={title} className={`icon-flag${size? ` icon-flag-${size}` : ''} ${spritePath}${clazz? ` ${clazz}` : ''}`}></i>
    case 'payment':
      return <i title={title} className={`icon-payment${size? ` icon-payment-${size}` : ''} ${spritePath}${clazz? ` ${clazz}` : ''}`}></i>
    default:
      // return <i title={title} className={`icon${size? ` icon-${size}` : ''} icon--${spritePath}${clazz? ` ${clazz}` : ''}`}></i>
      return <i title={title} className={`icon${size? ` icon-${size}` : ''} icon--${spritePath}${clazz? ` ${clazz}` : ''}`}></i>
  }
}

export default Icon