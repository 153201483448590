import Helmet from "react-helmet";
import { Button, Checkbox, Icon, InputInLabel, Loader } from "@/components/ui";
import { useTranslation } from "react-i18next";
import showToast from "../../toast/Toast";

import { useState, useEffect, useCallback } from "react";
import { makeRequest } from "@/services/makeRequest";
import { getUserLang } from "@/stores/slices/userSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

const Res = ({
  isLoading,
  options,
  value,
  handleAddChange,
  search,
  isDataEmpty,
}) => {
  switch (true) {
    case isLoading: {
      return <Loader />;
    }

    case !!options.length: {
      return options.map((i) => {
        const { photos, id, name, slug: slugGf } = i;

        return (
          <div key={id} className="agency-block">
            <img src={photos[0]} alt="girl" />
            <Link className="agency-block__link-to">{name}</Link>
            <Checkbox
              // radio={true}
              border={false}
              checked={id === value}
              onChange={() => handleAddChange(id)}
            />
          </div>
        );
      });
    }

    case !search: {
      return (
        <span className="w-100 text-center">Начните вводить имя модели</span>
      );
    }

    case isDataEmpty: {
      return (
        <span className="w-100 text-center">
          По данному запросу ничего не найдено
        </span>
      );
    }

    default: {
      return <Loader />;
    }
  }
};

const LinkWithGirlfriend = (props) => {
  const { open, setOpen, slug: slugProfile, city_id } = props;
  const { t: indi } = useTranslation("indi");

  // const token = localStorage.getItem("user");
  const lang = useSelector(getUserLang);

  const [search, setSearch] = useState("");
  // const [debouncedSearch, setDebouncedSearch] = useState("");

  // const [query, setQuery] = useState("");

  const [options, setOptions] = useState([]);

  // const [value, setValue] = useState([]);
  const [value, setValue] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  // const handleAddChange = (id) => {
  //   setValue((prevValue) => {
  //     if (prevValue.includes(id)) {
  //       return prevValue.filter((item) => item !== id);
  //     } else {
  //       return [...prevValue, id];
  //     }
  //   });
  // };

  const handleAddChange = (slugAgency) => {
    if (slugAgency === value) {
      setValue(null);
    } else {
      setValue(slugAgency);
    }
  };

  const handleFilter = useCallback((e) => {
    setIsDataEmpty(false);
    const val = e.currentTarget.value;
    setSearch(val);
    // setDebouncedSearch(val);
  }, []);

  const onClose = () => {
    // setValue([]);
    setValue(null);
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        const route = `user/profile/${slugProfile}/add-friend`;
        const method = "POST";
        const payload = {
          // profiles: value,
          profiles: [value],
        };

        await makeRequest({ route, method, payload });
        onClose();
        showToast({
          message: indi("success"),
          variant: "succes",
        });
      } catch (error) {
        showToast({
          message: indi("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  const fetchData = useCallback(
    debounce(async ({ searchValue }) => {
      try {
        if (!!searchValue) {
          setIsLoading(true);
          const route = `profiles`;
          const method = "GET";
          const payload = {
            lang,
            city: city_id,
            limit: 1000,
            search: searchValue,
          };

          const { profiles } = await makeRequest({ route, method, payload });
          setIsDataEmpty(!profiles.length);
          setOptions(profiles);

          setIsLoading(false);
        } else {
          setOptions([]);
          setIsLoading(false);
        }
      } catch (error) {}
    }, 1000),
    []
  );

  useEffect(() => {
    if (!!search.length) {
      fetchData({ searchValue: search });
    } else {
      fetchData({ searchValue: null });
      setOptions([]);
    }
  }, [fetchData, search]);

  return (
    <dialog open={open} onClick={onClose}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        onClick={(e) => e.stopPropagation()}
        onSubmit={onSubmit}
        className="popup-form__body gap-24"
      >
        <Button onClick={onClose} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner text-center">
          <h3>{indi("connectgf")}</h3>

          <span className={"color-main"}>{indi("linktogirl")}</span>
        </div>

        <InputInLabel
          placeholder={"Поиск модели"}
          // onChange={(e) => setQuery(e.currentTarget.value)}
          onChange={handleFilter}
          // value={query}
          value={search}
        />

        {/* {isLoading ? (
          <Loader />
        ) : (
          <div className="agency-block__content">
            {options.map((i) => {
              const { photos = [], id, name } = i;

              return (
                <div key={id} className="agency-block">
                  <img
                    src={!!photos.length ? photos[0] : null}
                    alt={name}
                    title={name}
                  />

                  <Link className="agency-block__link-to">{name}</Link>
                  <Checkbox
                    border={false}
                    onChange={() => handleAddChange(id)}
                    checked={value.includes(id)}
                  />
                </div>
              );
            })}
          </div>
        )} */}

        <div className="agency-block__content">
          <Res
            isLoading={isLoading}
            options={options}
            value={value}
            handleAddChange={handleAddChange}
            search={search}
            isDataEmpty={isDataEmpty}
          />
        </div>

        <Button
          size={"l"}
          // disabled={!value.length}
          buttonType={"submit"}
          disabled={!value}
          clazz={"button--green justify-center"}
        >
          {indi("confirm")}
        </Button>
      </form>
    </dialog>
  );
};

export default LinkWithGirlfriend;
