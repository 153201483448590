import { Icon } from "../index";
import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSorted,
  getNotDisabledCheckbox,
  getPage,
  setAllModels,
  setPageDefault,
  setSorted,
} from "@/stores/slices/modelsState";
import { Link, useNavigate } from "react-router-dom";

import "./checkbox.scss";
import {
  filterGender,
  filterStatus,
  isFilterSearch,
  toggleState,
} from "@/stores/slices/filterSlice";
import { setDefaultTitle, setTitle } from "@/stores/slices/titleSlice";
import { showShortFilter } from "@/stores/slices/showFilterSlice";
import { getUserCity, getUserLang } from "@/stores/slices/userSlice";

const Checkbox = ({ ...props }) => {
  const {
    id,
    name,
    title,
    translateKey,
    checked,
    disabled = true,
    value,
    clazz,
    border = true,
    flag,
    path,
    isLink = false,
    filter_disabled = "",
  } = props;

  const dispatch = useDispatch();

  let checkRef = useRef(null);

  const [isChecked, setIsChecked] = useState(checked);
  const city = useSelector(getUserCity);

  const { keysWithValueTrue } = useSelector(getNotDisabledCheckbox);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setIsDisabled(true);
    if (keysWithValueTrue && keysWithValueTrue.length > 0) {
      const checkbox = keysWithValueTrue.find(
        (item) => item === filter_disabled
      );
      if (checkbox) {
        setIsDisabled(false);
      }
    }
  }, [keysWithValueTrue, city]);

  const sortedValue = useSelector(getAllSorted);
  const filterStatusValue = useSelector(filterStatus);
  const filterGenderValue = useSelector(filterGender);
  const page = useSelector(getPage);
  const isFilterSelected = useSelector(isFilterSearch);
  const isShortFilterShow = useSelector(showShortFilter);

  const handleChange = (e) => {
    setIsChecked((prev) => !prev);
    const value = e.target.value;
    const title = e.target.getAttribute("data-title");
    const path = e.target.getAttribute("data-path");
    if (page !== 1) {
      dispatch(setPageDefault());
    }
    dispatch(setAllModels([]));
    if (!isFilterSelected || isShortFilterShow) {
      if (name === "status") {
        dispatch(
          setSorted({
            stateName: "sorted",
            payload: value,
            callback: updateUrl,
          })
        );
        dispatch(
          setSorted({
            stateName: "sortedByStatus",
            payload: value,
            callback: "",
          })
        );
      }
      if (name === "gender") {
        dispatch(
          setSorted({
            stateName: "sorted",
            payload: path,
            callback: updateUrl,
          })
        );
        dispatch(
          setSorted({
            stateName: "sortedByGender",
            payload: value,
            callback: "",
          })
        );
      }
      dispatch(setTitle(title));
    }
    if (name === "status" && (isFilterSelected || isShortFilterShow)) {
      dispatch(toggleState({ value, StateName: "status" }));
    }
    if (name === "gender" && (isFilterSelected || isShortFilterShow)) {
      dispatch(toggleState({ value, StateName: "gender" }));
    }
  };

  const lang = useSelector(getUserLang);
  const navigate = useNavigate();

  const updateUrl = (sortedValue) => {
    if (sortedValue.length > 0) {
      const result = `/${lang}/${city}/` + sortedValue.join("-");
      navigate(`${result}`);
    } else {
      navigate(`/${lang}/${city}`);
    }
    // if(sortedValue.length > 0) {
    //   const str = sortedValue.join("-")
    //   const lastIndex = str.lastIndexOf("-");
    //   const result = str.substring(lastIndex + 1);
    //   const url = window.location.pathname;
    //   if(url === '/') {
    //     navigate(`${url}${result}`)
    //   } else {
    //     navigate(`${url}/${result}`)
    //   }
    // } else {
    //   navigate(`/${lang}/${city}`)
    // }
  };

  useEffect(() => {
    if (!isFilterSelected) {
      const idExistsInSelectedCheckboxes =
        sortedValue.includes(value) || sortedValue.includes(path);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "status" && isFilterSelected) {
      const idExistsInSelectedCheckboxes = filterStatusValue.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "gender" && isFilterSelected) {
      const idExistsInSelectedCheckboxes = filterGenderValue.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
  }, [sortedValue]);

  if (isLink === true) {
    return (
      <Link to={"#"} title="">
        <label
          className={`checkbox-button ${
            isDisabled ? " checkbox-button_disabled" : ""
          }${isChecked ? " checkbox-button_checked" : ""}${
            clazz ? ` ${clazz}` : ""
          }`}
          htmlFor={id}
          tabIndex={0}
        >
          <input
            tabIndex={1}
            id={id}
            type="checkbox"
            disabled={isDisabled ? true : null}
            name={name ? name : ""}
          />
          {title}
        </label>
      </Link>
    );
  } else {
    return (
      <div
        className={`checkbox${isChecked ? " checkbox_checked" : ""}${
          isDisabled ? " checkbox_disabled" : ""
        }${border ? `` : ` _no-border`}${clazz ? ` ${clazz}` : ""}`}
        tabIndex={isDisabled ? null : 0}
        //   onClick={() => handleClick()}
        onKeyUp={(e) => {
          if (e.key === "Enter") setIsChecked((prev) => !prev);
        }}
      >
        <input
          tabIndex={1}
          disabled={isDisabled ? true : null}
          id={id}
          type="checkbox"
          onChange={handleChange}
          name={name ? name : ""}
          checked={isChecked ? true : null}
          value={value ? value : ""}
          data-title={translateKey}
          data-disabled={filter_disabled}
          data-path={path}
        />
        {/* <Icon clazz={"checkbox-icon"} spritePath={"check"} /> */}
        <label
          htmlFor={id}
          // onClick={() => (isDisabled ? null : setIsChecked((prev) => !prev))}
          ref={checkRef}
        >
          {flag ? (
            <Icon
              type={"flag"}
              clazz={"checkbox-flag"}
              spritePath={`${flag}`}
            />
          ) : null}

          {title}
        </label>
      </div>
    );
  }
};

Checkbox.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

export default Checkbox;
