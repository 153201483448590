import { Link } from "react-router-dom";
import { Checkbox, Icon } from "@/components/ui";
import img1 from "../../../../../../assets/img/pick-profile/img1.webp";
import img2 from "../../../../../../assets/img/pick-profile/img2.webp";
import img3 from "../../../../../../assets/img/pick-profile/img3.webp";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const StatusElite = () => {
  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const [activeCheckbox, setActiveCheckbox] = useState("standart");

  return (
    <div className="guide__profile-root">
      <h3>Статус Elite Lady для вашей анкеты</h3>

      <p>
        {indi("highlightelite")}{" "}
        <span className="green">
          (+50% {indi("toviews")} {t("and")} + 25% {indi("toorders")})
        </span>{" "}
        {indi("welathyorders")}.
      </p>

      <div className={"guide__profile-cards p3"}>
        <div className={"guide-card"}>
          <div className="guide-card__img">
            <img src={img1} alt="" />
          </div>
          <p>
            Элитные анкеты выделяются в списке анкет лэйблом Elite Lady, который
            притягивает внимание
          </p>
          <div className={"fake-checkbox"}>
            <div className={"fake-checkbox__icon"}>
              <Icon spritePath={"check"} size={"xs"} />
            </div>
            <p className={"fake-checkbox__text"}>
              На 25% больше контактов с вашей анкетой
            </p>
          </div>
        </div>

        <div className={"guide-card p3"}>
          <div className="guide-card__img">
            <img src={img2} alt="" />
          </div>
          <p>
            Этот же знак будет присутствовать и в Вашей анкете, рядом с именем,
            подчёркивая Ваш статус
          </p>
          <div className={"fake-checkbox"}>
            <div className={"fake-checkbox__icon"}>
              <Icon spritePath={"check"} size={"xs"} />
            </div>
            <p className={"fake-checkbox__text"}>
              На 10% больше заказов от богатых клиентов
            </p>
          </div>
        </div>

        <div className={"guide-card p3"}>
          <div className="guide-card__img">
            <img src={img3} alt="" />
          </div>
          <p>
            Только элитные анкеты отображаются на отдельной странице{" "}
            <Link to={`/elitelady`}>https://lady4love.com/elitelady</Link>,
            которую посещают преимущественно VIP-посетители
          </p>
          <div className={"fake-checkbox"}>
            <div className={"fake-checkbox__icon"}>
              <Icon spritePath={"check"} size={"xs"} />
            </div>
            <p className={"fake-checkbox__text"}>
              Более 30 000 посетителей в месяц
            </p>
          </div>
        </div>
      </div>
      <div className="profile-page__bottom">
        <div
          className={"profile-page__box"}
          onClick={() => setActiveCheckbox("standart")}
        >
          <div className="profile-page__box-grey">
            <Checkbox
              size={"l"}
              checked={activeCheckbox === "standart"}
              clazz={"button--secondary"}
              title={"Оставить Стандарт"}
            ></Checkbox>
          </div>

          <span>Бесплатно</span>
        </div>
        <div
          className={"profile-page__box"}
          onClick={() => setActiveCheckbox("elite")}
        >
          <div className="profile-page__box-green">
            <Checkbox
              size={"l"}
              checked={activeCheckbox === "elite"}
              clazz={"button--green"}
              title={"Выбрать Elite Lady"}
            ></Checkbox>
          </div>

          <div className="profile-page__wrapper">
            <span className={"profile-page__old-price"}>50 €</span>
            <span>25 €</span>
            <p className={"p1"}>Первый месяц</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusElite;
