import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";

const DeleteApPopup = (props) => {
  const { open, setOpen, profileSlug, setData, setCount } = props;

  const { t: indi } = useTranslation("indi");

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user/profile/${profileSlug}`;
        const method = "DELETE";

        await makeRequest({ route, method });

        setCount((prev) => prev - 1);
        setData((prevState) => {
          const newPrevState = prevState.filter(
            (item) => item.slug !== profileSlug
          );

          return newPrevState;
        });

        showToast({
          message: `Анкета ${profileSlug} удалена`,
          variant: "succes",
        });
        setOpen(false);
      } catch (error) {
        console.log("error", error);
        showToast({
          message: indi("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <dialog open={open} onClick={handleCloseDelete}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        method={"DELETE"}
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button onClick={handleCloseDelete} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner text-center">
          <h3>Действительно удалить анкету?</h3>

          <span className={"color-700"}>
            Это процедура необратимая. Анкета будет удалена с сервера. Вместо
            удаления советуем временно деактивировать анкету, тогда все данные
            сохранятся, просто временно не будут показываться.
          </span>
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {indi("cancel")}
          </Button>

          <Button
            size={"l"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={onSubmit}
          >
            {indi("yesdelete")}
          </Button>
        </div>
      </form>
    </dialog>
  );
};

export default DeleteApPopup;
