import Helmet from "react-helmet";
import { Button, Checkbox, Icon, Loader } from "@/components/ui";
import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";

import statusElite from "../../../assets/img/status/elite.webp";

import "./makeEliteAd.scss";

const MakeEliteAdPopup = (props) => {
  const { open, setOpen, slug: slugProfile } = props;
  const { t: indi } = useTranslation("indi");

  const token = localStorage.getItem("user");

  const { lang } = useLadyService();

  const [options, setOptions] = useState([]);

  const [value, setValue] = useState([]);
  const [isLoading, seiIsLoading] = useState(true);

  const handleAddChange = (id) => {
    setValue((prevValue) => {
      if (prevValue.includes(id)) {
        return prevValue.filter((item) => item !== id);
      } else {
        return [...prevValue, id];
      }
    });
  };

  const onClose = () => {
    setValue([]);
    setOpen(false);
  };

  const onSubmit = () => {
    const fetchData = async () => {
      try {
        const route = `user/profile/${slugProfile}/add-friend`;
        const method = "POST";
        const payload = {
          profiles: value,
        };

        await makeRequest({ route, method, payload });
        onClose();
      } catch (error) {}
    };

    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        seiIsLoading(true);
        const route = `user/profiles`;
        const method = "GET";
        const payload = {
          lang,
          limit: 1000,
        };

        const { profiles } = await makeRequest({ route, method, payload });
        setOptions(profiles);
        seiIsLoading(false);
      } catch (error) {}
    };

    token && fetchData();
  }, [token, lang]);

  return (
    <dialog open={open} onClick={onClose}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        method={"POST"}
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body__super__large"
      >
        <Button onClick={onClose} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner text-center">
          <div className="elite-block__title">
            <h3 className="mb-0">Сделать анкету</h3>
            <picture>
              <source srcSet={statusElite} media="(min-width: 767.98px)" />
              <img src={statusElite} alt="" />
            </picture>
          </div>

          <span className={"p1"}>
            Получите элитный лэйбл и сможете выделяться среди прочих, а также
            отображаться на вкладке “Элитные”, которую посещают VIP-клиенты.{" "}
            <span className={"color-green"}>
              (+50% к просмотрам и + 25% к заказам){" "}
            </span>
          </span>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div className="elite-block__content">
            {options.map((i) => {
              const { main_photo, id, name } = i;

              return (
                <div key={id} className="elite-block">
                  <img src={main_photo} alt={name} title={name} />

                  <Link className="elite-block__link-to">{name}</Link>
                  <Checkbox
                    border={false}
                    onChange={() => handleAddChange(id)}
                    checked={value.includes(id)}
                  />
                </div>
              );
            })}
          </div>
        )}

        <Button
          size={"l"}
          disabled={!value.length}
          onClick={onSubmit}
          clazz={"button--green justify-center mt-auto"}
        >
          {indi("confirm")}
        </Button>
      </form>
    </dialog>
  );
};

export default MakeEliteAdPopup;
