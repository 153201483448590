import { useEffect, useRef, useState } from "react";
import { number, object, string } from "yup";
import MultiRangeSlider from "multi-range-slider-react";

import {
  Button,
  ButtonLink,
  Checkbox,
  Icon,
  InputInLabel,
  ScheduleInput,
  Select,
  Toggle,
} from "@/components/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import "./confirmation.scss";
import { UpAd } from "../../../../model/profiles/card/components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VerAds from "../../../../model/profiles/pageComponets/verAds/VerAds";
import VerHealth from "../../../../model/profiles/pageComponets/verHealth/VerHealth";
import { VerificationAndHealthCheck } from "../../../../model/profiles/pageComponets";
import GirlCard from "../../../../../../girlCard/GirlCard";
import { makeRequest } from "@/services/makeRequest";
import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";

const textTop1 = `Включите видеочат – разрешение клиентам перед заказом позвонить Вам онлайн с видео.`;
const textBottom1 = `+50% к заказам +2Е за каждую минуту видеочата `;
const textTop2 = `Включите Онлайн-заказ. Вы сможете получать заказы онлайн с гарантированной предоплатой.`;
const textBottom2 = `+75% к просмотрам и + 25% к заказам`;
const textTop3 = `Добавьте Эроконтент и зарабатывайте на просмотрах`;
const textBottom3 = "+ 50% к просмотрам";
const textTop4 = `Верифицируйте фотографии в анкете и получите важный статус «Верифицировано»`;
const textBottom4 = "+50% к просмотрам и + 50% к заказам";
const textTop5 = `Получите важное преимущество – статус «Контроль здоровья»`;
const textBottom5 = "+25% к просмотрам и + 50% к заказам";
const textTop6 = `Свяжите свою анкету с подружкой и Ваши анкеты будут отображаться для клиентов, которые ищут пары`;
const textBottom6 = "+10% к просмотрам, + 5% к заказам";

const ToggleItem = ({ textTop, textBottom, toggleTitle }) => {
  const { t } = useTranslation("translation");

  return (
    <div className="form-confirmation__toggle">
      <div className="form-confirmation__toggle-left">
        <Toggle titleDefault={toggleTitle} />
        <ButtonLink
          href={`#`}
          size={"s"}
          clazz={"button_outline--black _no-underline"}
        >
          {t("moreinfo")}
        </ButtonLink>
      </div>
      <div className="form-confirmation__toggle-right">
        <span>{textTop}</span>
        <span className="green">{textBottom}</span>
      </div>
    </div>
  );
};

const IconItem = ({ textTop, textBottom, IconPath, buttonTitle }) => {
  return (
    <div className="form-confirmation__toggle">
      <div className="form-confirmation__toggle-left buttonIcon">
        <ButtonLink
          href={`#`}
          size={"s"}
          clazz={"button_outline--green-accent"}
        >
          <Icon clazz={"green"} size={"l"} spritePath={IconPath} />

          {buttonTitle}
        </ButtonLink>
      </div>
      <div className="form-confirmation__toggle-right">
        <span>{textTop}</span>
        <span className="green">{textBottom}</span>
      </div>
    </div>
  );
};

const Confirmation = ({
  setAvailableSteps,
  formData,
  changeStep,
  step,
  rootElem,
}) => {
  const { t } = useTranslation("translation");
  const formSchema = object().shape({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const { t:indi } = useTranslation("indi");

  const onSubmit = (data) => {
    console.log(data);
  };

  const [profileData, setProfileData] = useState({});

  const validateData = () => {};
  const token = localStorage.getItem("user");
  const lang = useSelector(getUserLang);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `profiles/short/${formData.slug}`;

        const method = "GET";
        const payload = {
          lang,
        };

        const result = await makeRequest({ route, method, payload });
        if (result) {
          setProfileData(result.data);
          rootElem.scrollIntoView({
            behavior: "smooth",
          });
        }
      } catch (error) {}
    };
    token && fetchData();
  }, []);

  return (
    <>
      <h2>Проверка и подтверждение анкеты</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`create-page__form confirmation`}
      >
        <div className="d-flex gap-16">
          <div className="form-confirmation__left">
            {profileData?.id && (
              <GirlCard props={profileData} size={"m"} key={profileData.id} />
            )}
          </div>
          <div className="form-confirmation__right">
            <div className="form-confirmation__right-top">
              <UpAd />
              <div className="form-confirmation__right--text">
                <span>
                  Анкета получит статус NEW <br></br>
                  <span className="green">(+50% к просмотрам)</span> и будет
                  отображаться в Новых по городу{" "}
                  <span className="green">Прага</span> в течение 14 дней.
                </span>
                <span>
                  Подробнее о других статусах и возможности их получения{" "}
                  <Link className="green text-right">смотрите здесь</Link>
                </span>
              </div>
            </div>
            <ToggleItem
              toggleTitle={t("videochat")}
              textTop={textTop1}
              textBottom={textBottom1}
            />
            <ToggleItem
              toggleTitle={t("onlineorder")}
              textTop={textTop2}
              textBottom={textBottom2}
            />
            <IconItem
              buttonTitle={indi('addero')}
              IconPath={"pants"}
              textTop={textTop3}
              textBottom={textBottom3}
            />
            <IconItem
              buttonTitle={t('verification')}
              IconPath={"verify-fill"}
              textTop={textTop4}
              textBottom={textBottom4}
            />
            <IconItem
              buttonTitle={t("healthy")}
              IconPath={"health-fill"}
              textTop={textTop5}
              textBottom={textBottom5}
            />
            <IconItem
              buttonTitle={indi("connectgf")}
              IconPath={"girlfriend"}
              textTop={textTop6}
              textBottom={textBottom6}
            />
          </div>
        </div>
        <div className="create-form__buttons">
          <Button
            onClick={() => validateData()}
            buttonType={"submit"}
            size={"l"}
            square={false}
            clazz="button--green button-confirmation"
          >
            {indi('publish')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default Confirmation;
