import {Rating, Star} from "@smastrom/react-rating";

import './ratingStars.scss'

const RatingStars = (props) => {
    const {
        value,
        clazz,
        readOnly = true,
        gold
    } = props

    const myStyles = {
        itemShapes: Star,
    }

    return (
        // <span className="rating__items">
        //         {rating.map(star => {
        //
        //         })}
        //
        //
		// 		<Icon spritePath={'star-rating'} className="rating__item icon icon--star-rating rating__item--active"/>
		// 		<Icon spritePath={'star-rating'} className="rating__item icon icon--star-rating rating__item--active"/>
		// 		<Icon spritePath={'star-rating'} className="rating__item icon icon--star-rating rating__item--active"/>
		// 		<Icon spritePath={'star-rating'} className="rating__item icon icon--star-rating rating__item--active"/>
        //     <Icon spritePath={'star-rating'} className="rating__item icon icon--star-rating">
		// 		<span className="icon icon--star-rating" style="clip-path: inset(0 50% 0 0);"></span>
        //     </Icon>
        //
        // </span>

            <Rating
                transition="none"
                spaceInside="none"
                radius="none"
                className={`rating-stars${clazz ? ` ${clazz}` : '' }${gold ? ` rating-stars--gold` : '' }`}
                readOnly={readOnly}
                value={value}
                itemStyles={myStyles}
                orientation="horizontal"
            />
    )
}

export default RatingStars