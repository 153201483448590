import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";

const ActivateProfilePopup = (props) => {
  const { open, setOpen, setStatus, status } = props;

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user/set-profiles-status`;
        const method = "PUT";
        const payload = {
          status: !status,
        };

        await makeRequest({ route, method, payload });
        setStatus((prev) => !prev);
        setOpen(false);

        showToast({
          message: indi("success"),
          variant: "succes",
        });
      } catch (error) {
        showToast({
          message: indi("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <dialog open={open} onClick={handleCloseDelete}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        // method={"DELETE"}
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button onClick={handleCloseDelete} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner text-center">
          <h3>{indi("realywant")}</h3>

          <span className={"color-700"}>{indi("alladsdeactivate")}</span>
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {indi("cancel")}
          </Button>

          <Button
            size={"l"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={onSubmit}
          >
            {t("yes")}, {indi("deactivate")}
          </Button>
        </div>
      </form>
    </dialog>
  );
};

export default ActivateProfilePopup;
