import { Icon } from "@/components/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import moment from "moment/moment";

const ModelIcons = ({ verified_at, healthy_at }) => {
  const { t } = useTranslation("translation");
  const { getDayMonthYear } = useLadyService();

  return (
    (healthy_at || verified_at) && (
      <div className="girl-card__icons">
        {verified_at && (
          <span
            className={"girl-card__icon"}
            title={`${t("photosverified")}. ${t(
              "lastcheck"
            )}: ${getDayMonthYear(verified_at)}`}
          >
            <Icon spritePath={"verify-fill"} size={"l"} />
            <span>{t("verified1")}</span>
          </span>
        )}
        {healthy_at && (
          <span
            className={"girl-card__icon"}
            title={`${t("modeltests")}. ${t("lastcheck")}: ${getDayMonthYear(
              healthy_at
            )}`}
          >
            <Icon spritePath={"health-fill"} size={"l"} />
            <span>{t("healthy")}</span>
          </span>
        )}
      </div>
    )
  );
};

export default ModelIcons;
