import { Icon } from "../index";
import { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import "./inputInLabel.scss";
import "./dateInput.scss";
import { maskList } from "../../../helper/mask_list";
import { useSelector } from "react-redux";
import { getRegistrationStatus } from "@/stores/slices/popupSlice";

// Этот инпут работает в связке с React Hook Form
const InputInLabel = (props) => {
  const {
    onChange,
    id,
    title,
    clazz,
    register,
    name,
    children,
    type = "text",
    placeholder,
    size = "",
    checked,
    onClick,
    autocomplete,
    disabled,
    value,
    maskValue = "",
    setMaskValue,
    valueLoad,
    onBlur,
  } = props;

  const [phoneMaskValue, setPhoneMaskValue] = useState(maskValue);

  const phoneRef = useRef(null);

  useEffect(() => {
    if (phoneRef.current) {
      phoneMask.call(phoneRef.current)
      setTimeout(() => {
        setPhoneMaskValue(maskValue);
        phoneMask.call(phoneRef.current)

        // phoneRef.current.focus();
        // phoneRef.current.click();
        // phoneRef.current.blur();
      }, 300);
    }
  }, [phoneRef, valueLoad]);

  const registrationState = useSelector(getRegistrationStatus);
  const phoneMask = function () {
    let matrix = "+###############";
    maskList.forEach((item) => {
      let code = item.code.replace(/[\s#]/g, ""),
        phone = this.value.replace(/[\s#-)(]/g, "");

      if (phone.includes(code)) {
        matrix = item.code;
      }
    });

    let i = 0,
      val = this.value.replace(/\D/g, "");
    const value = matrix.replace(/(?!\+)./g, function (a) {
      return /[#\d]/.test(a) && i < val.length
        ? val.charAt(i++)
        : i >= val.length
        ? ""
        : a;
    });

    if (value && value !== "+") {
      setPhoneMaskValue(value);
      !!setMaskValue && setMaskValue(value);
    }
  };

  useEffect(() => {
    if (registrationState) {
      setPhoneMaskValue("");
    }
  }, [registrationState]);

  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    if (!!phoneRef?.current) {
      const inputId = phoneRef?.current.id;
      if (inputId) {
        let inputs = document.querySelectorAll(`#${inputId}`);
        inputs.forEach((input) => {
          if (!input.value) input.value = "+";
          input.addEventListener("input", phoneMask);
          input.addEventListener("focus", phoneMask);
          input.addEventListener("blur", phoneMask);
          input.addEventListener("change", phoneMask);
        });
      }
    }
  }, [phoneRef]);

  const hours = /^([0-4])/;
  const digit = /^([0-9])/;
  const minuts = /^([0-6])/;
  const mask = [hours, digit, ":", minuts, digit];

  const variants = () => {
    switch (type) {
      case "password":
        return (
          <>
            <span className={`${disabled ? "disabled" : ""}`}>{title}</span>
            <input
              {...register}
              placeholder={placeholder}
              type={showPass ? "text" : "password"}
              id={id}
              className={`input-label__password ${disabled ? "disabled" : ""}`}
              autoComplete={autocomplete}
              disabled={disabled}
            />
            <button
              className={`input-label__show-pass${showPass ? " _show" : ""}`}
              type={"button"}
              onClick={() => setShowPass(!showPass)}
            >
              <Icon size={"l"} spritePath={showPass ? "eye-off" : "eye"} />
            </button>
            {children}
          </>
        );
      case "tel":
        return (
          <>
            {children}
            <InputMask
              maskPlaceholder={""}
              placeholder={"+420 000 000 000"}
              {...register}
              type={"tel"}
              value={phoneMaskValue}
              ref={phoneRef}
              id={id}
              className={`${disabled ? "disabled" : ""}`}
              autoComplete={autocomplete}
              onChange={onChange}
              // disabled={disabled}
            />
          </>
        );
      case "textarea":
        return (
          <>
            {children}
            <textarea
              {...register}
              placeholder={placeholder}
              id={id}
              autoComplete={autocomplete}
              disabled={disabled}
              value={value}
            />
          </>
        );
      case "time":
        return (
          <>
            {children}
            <InputMask
              {...register}
              mask={mask}
              maskPlaceholder={""}
              id={id}
              onChange={onChange}
              autoComplete={autocomplete}
              placeholder={"18:00"}
              disabled={disabled}
              className={clazz}
            />
          </>
        );
      case "radio":
        return (
          <>
            <input
              {...register}
              id={id}
              type={"radio"}
              name={name}
              value={value}
              onChange={() => onChange}
              checked={checked}
              disabled={disabled}
            />
            <span className={`${disabled ? "disabled" : ""}`}>{children}</span>
          </>
        );

      case "checkbox":
        return (
          <>
            <input
              {...register}
              id={id}
              type={"checkbox"}
              name={name}
              value={value}
              checked={checked}
              className={"icon icon--include"}
              disabled={disabled}
              // value={value}
              onChange={onChange}
            />
            {children}
          </>
        );
      default:
        return (
          <>
            {children}
            <input
              {...register}
              placeholder={placeholder}
              type={type}
              id={id}
              value={value}
              autoComplete={autocomplete}
              onChange={onChange}
              disabled={disabled}
              className={disabled ? "disabled" : null}
            />
          </>
        );
    }
  };

  const classVariantByType = () => {
    switch (type) {
      case "password":
        return ` input-label__password  ${disabled ? "disabled" : ""}`;
      case "radio":
        return ` input-label__radio  ${disabled ? "disabled" : ""}`;
      case "checkbox":
        return ` input-label__checkbox ${disabled ? "disabled" : ""}`;
      default:
        return "";
    }
  };

  return (
    <label
      htmlFor={id}
      onClick={onClick}
      className={`input-label${
        clazz ? ` ${clazz}` : ""
      }${classVariantByType()}${size} ${disabled ? "disabled" : ""}`}
    >
      {variants()}
    </label>
  );
};

export default InputInLabel;
