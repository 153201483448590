import "./waitingAdmin.scss";

import { Button } from "@/components/ui";

import { useTranslation } from "react-i18next";

const WaitingAdmin = () => {
  const { t: indi } = useTranslation("indi");

  const sentence = indi("waitforapproval");
  const sentencesArray = sentence.split(". ");
  const firstSentence = sentencesArray[0];
  const secondSentence = sentencesArray[1];

  return (
    <div className="waiting">
      <span className="p1 color-main">{firstSentence}</span>
      <span className="p2 color-main">{secondSentence}</span>
      <span className="mt-20">{indi("fasttrackver")}</span>
      <div className="waiting__fast">
        <span className="title fz-22">{indi("pricever")}</span>
        <Button clazz={"ver__block button--primary"} size="s">
          {indi("fasttrack")}
        </Button>
      </div>
    </div>
  );
};

export default WaitingAdmin;
