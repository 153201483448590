import Navigation from "../navigation/Navigation";
import NewGirlsSlider from "../sliders/newGrilsSlider/NewGirlsSlider";
import GirlMap from "../girlMap/GirlMap";
import AboutService from "../aboutService/AboutService";
import BestCityAgencySlider from "../sliders/bestCityAgencySlider/BestCityAgencySlider";
import BenefitsBlock from "../benefitsBlock/BenefitsBlock";
import StoriesComponent from "../stories/StoriesComponent";
import { useDispatch, useSelector } from "react-redux";
import { getStatusSorted, getStories } from "@/stores/slices/modelsState";
import { useEffect, useState } from "react";
import useLadyService from "@/services/LadyService";
import { setVideoCallRoom } from "@/stores/slices/videoCallSlice";
import { useParams } from "react-router-dom";

const MainPage = () => {
  const dispatch = useDispatch();
  const { navigate, lang, city } = useLadyService();
  const { room } = useParams();
  const stories = useSelector(getStories);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const navigationParams = useSelector(getStatusSorted);

  useEffect(() => {
    if (room) {
      dispatch(setVideoCallRoom({ room_id: room }));
    }
  }, [dispatch, room]);

  useEffect(() => {
    if (lang && city && navigationParams.length === 0) {
      if (lang === "en" && city === "prague") {
        navigate(`/`);
      } else {
        navigate(`/${lang}/${city}`);
      }
    }
  }, [lang, city]);

  return (
    <>
      <main>
        <Navigation setIsDataEmpty={setIsDataEmpty} isDataEmpty={isDataEmpty} />
        {/*<NewGirlsSlider />*/}
        {!isDataEmpty && <GirlMap />}
        <AboutService />
        <BestCityAgencySlider />
        <BenefitsBlock />{" "}
      </main>

      {!!stories.length && (
        <StoriesComponent
          favoriteButton
          descriptionText
          profileLink
          stories={stories}
        />
      )}
    </>
  );
};

export default MainPage;
