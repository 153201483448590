import { useTranslation } from "react-i18next";

const StatPage = () => {
  const { t: indi } = useTranslation("indi");

  return (
    <main>
      <section className={"reviews-page__container"}>
        <h1>{indi("mystat")}</h1>
        <h3 className="text-center mt-12">{indi("soon")}</h3>
      </section>
    </main>
  );
};

export default StatPage;
