import { configureStore } from "@reduxjs/toolkit";
import showFilterReducer from "@/stores/slices/showFilterSlice";
import userSlice from "./slices/userSlice";
import filter from "@/stores/slices/filterSlice";
import popupReducer from "@/stores/slices/popupSlice";
import models from "@/stores/slices/modelsState";
import title from "@/stores/slices/titleSlice";
import filterPopup from "@/stores/slices/filterPopupSlice";
import counterFilter from "@/stores/slices/counterFilterSlice";
import mapModel from "@/stores/slices/mapModelSlice";
import videoCall from "@/stores/slices/videoCallSlice";
import agencyFilterSlice from "./slices/agencyFilterSlice";
import oneProfileSlice from "./slices/oneProfileSlice";
import tabsReducer from "./slices/tabSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    show_filter: showFilterReducer,
    filter: filter,
    agencyFilter: agencyFilterSlice,
    popups: popupReducer,
    tabs: tabsReducer,
    models,
    title,
    counterFilter,
    filterPopup,
    mapModel,
    videoCall,
    oneProfile: oneProfileSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
