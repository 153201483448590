import girl1 from "../assets/img/model/1.webp";
import girl2 from "../assets/img/model/2.webp";
import girl3 from "../assets/img/model/4.webp";
import girl4 from "../assets/img/model/3.webp";
import girl5 from "../assets/img/model/5.webp";
import girl6 from "../assets/img/model/6.webp";
import girl7 from "../assets/img/model/7.webp";
import girl8 from "../assets/img/model/8.webp";

import imgBlur1 from '../assets/img/img_blur1.webp'
import imgBlur2 from '../assets/img/img_blur2.webp'

const girls_slider_db = [
    {
        id: 1,
        name: 'Марина',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '20',
        photos:[ girl1],
        media: girl1,
        hash: 'photo',
    },
    {
        id: 2,
        name: 'Катрина',
        address: null,
        age: '25',
        photos:[ girl2],
        media: girl2,
        hash: 'photo',
    },
    {
        id: 3,
        name: 'Linda',
        address: null,
        age: '25',
        photos:[ girl3],
        media: girl3,
        hash: 'photo',
    },
    {
        id: 4,
        name: 'Алина',
        address: null,
        age: '19',
        photos:[ girl4],
        media: girl4,
        hash: 'video',
        video: true,
    },
    {
        id: 5,
        name: 'Мари',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '25',
        photos:[ girl5],
        media: girl5,
        hash: 'video',
        video: true,
    },
    {
        id: 6,
        name: 'Оленька',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '26',
        photos:[ girl6],
        media: girl6,
        hash: 'video',
        video: true,
    },
    {
        id: 7,
        name: 'Натали',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '20',
        photos:[ girl7],
        media: girl7,
        hash: 'interior',
    },
    {
        id: 8,
        name: 'Mistress&domination Linna',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '23',
        photos:[ girl8],
        media: girl8,
        hash: 'interior',

    },
    {
        id: 9,
        name: 'Мари',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '25',
        photos:[ girl5],
        media: girl5,
        hash: 'interior',

    },
    {
        id: 10,
        name: 'Оленька',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '26',
        photos:[ imgBlur1 ],
        hash: 'ero',
        private:true,

    },
    {
        id: 11,
        name: 'Натали',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '20',
        photos:[ imgBlur2],
        hash: 'ero',
        private:true,
    },
    {
        id: 12,
        name: 'Катрина',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '25',
        photos:[ imgBlur1],
        hash: 'ero',
        private:true,
    },
]

export default girls_slider_db