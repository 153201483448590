import interior1 from '../assets/img/agency/interior1.webp'
import interior2 from '../assets/img/agency/interior2.webp'

const interior_slider_db = [
    {
        id: 1,
        name: 'Интерьер1',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '20',
        photos:[interior1],
        hash: 'photo',
    },
    {
        id: 2,
        name: 'Интерьер2',
        address: null,
        age: '25',
        photos:[ interior2],
        hash: 'photo',
    },
    {
        id: 3,
        name: 'Интерьер3',
        address: null,
        age: '25',
        photos:[ interior1],
        hash: 'photo',
    },
    {
        id: 4,
        name: 'Интерьер4',
        address: null,
        age: '19',
        photos:[ interior2],
    },
    {
        id: 5,
        name: 'Интерьер5',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '25',
        photos:[ interior1],
    },
    {
        id: 6,
        name: 'Интерьер6',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '26',
        photos:[ interior2],
    },
    {
        id: 7,
        name: 'Интерьер7',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '20',
        photos:[ interior1],
        hash: 'interior',
    },
    {
        id: 8,
        name: 'Интерьер8',
        address: '2 км от вас (Прага, Ческе Будьёвице)',
        age: '23',
        photos:[ interior2],
        hash: 'interior',

    },
]

export default interior_slider_db