import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "@/components/ui";
import { ResContainer } from "./pageComponents";
import { makeRequest } from "@/services/makeRequest";

import "./reservationPage.scss";

const ReservationPage = () => {
  const token = localStorage.getItem("user");

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { t: indi } = useTranslation("indi");

  const Res = () => {
    switch (true) {
      case isLoading: {
        return <Loader />;
      }

      case !!data.length: {
        return data?.map((i, index) => {
          return <ResContainer key={index} data={i} />;
        });
      }

      default: {
        return <h3 className="text-center mt-12">{indi("younoorder")}</h3>;
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `bookings`;
        const method = "GET";

        const data = await makeRequest({ route, method });

        setData(data.data);
        setIsLoading(false);
      } catch (error) {}
    };

    token && fetchData();
  }, [token]);

  return (
    <main>
      <section className={"reservation-page__container"}>
        <h1>{indi("myorder")}</h1>
        <Res />
      </section>
    </main>
  );
};

export default ReservationPage;
