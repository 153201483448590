import { useState } from "react";
import "./services.scss";

import { Button, Checkbox, InputInLabel, Loader, Icon } from "@/components/ui";
import { useForm } from "react-hook-form";
import showToast from "../../../../../../toast/Toast";

import useLadyService from "@/services/LadyService";

import { useSelector } from "react-redux";
import { getServicesWords } from "@/stores/slices/userSlice";
import { makeRequest } from "@/services/makeRequest";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

const data = {
  for: [
    { id: 65, title: "Мужчин", value: "man" },
    { id: 64, title: "Девушек", value: "women" },
    { id: 63, title: "Пары", value: "couples" },
    { id: 62, title: "Группе", value: "groups" },
  ],
};

const SelectWithButtons = ({ buttons, buttonValue, defaultValue }) => {
  const [selectedButton, setSelectedButton] = useState(defaultValue);

  const handleButtonClick = (value) => {
    const isIdAlreadySelected = selectedButton.includes(value);
    if (isIdAlreadySelected) {
      setSelectedButton((prev) =>
        prev.filter((buttonId) => buttonId !== value)
      );
      buttonValue((prev) => prev.filter((buttonId) => buttonId !== value));
    } else {
      setSelectedButton((prev) => [...prev, value]);
      buttonValue((prev) => [...prev, value]);
    }
  };

  return (
    <div className="create-page__input-container w-100">
      <div className="create-page__input-buttons">
        {buttons.map((i) => (
          <label
            className={`checkbox-button${
              selectedButton.includes(i.value) ? " checkbox-button_checked" : ""
            }`}
            htmlFor={i.id}
            tabIndex={0}
          >
            <input
              onClick={() => handleButtonClick(i.value)}
              tabIndex={1}
              id={i.id}
              name={i.name}
              checked={selectedButton === i.value}
              value={i.value}
            />
            {i.title}
          </label>
        ))}
      </div>
    </div>
  );
};

const ServicesSection = ({ title, data, servicesData }) => {
  const createIdsArray = (servicesArray) => {
    const idsArray = [];

    servicesArray.forEach((service) => {
      idsArray.push(service.id);
    });

    return idsArray;
  };

  const idsArray = createIdsArray(servicesData);

  const [isActive, setIsActive] = useState(idsArray);

  const middleIndex = Math.ceil(data.length / 2);
  const firstColumn = data.slice(0, middleIndex);
  const secondColumn = data.slice(middleIndex);

  const handleCheckboxChange = (item) => {
    if (isActive.includes(item.id)) {
      const objIndex = servicesData.findIndex((obj) => obj.id === item.id);
      servicesData.splice(objIndex, 1);
      setIsActive(isActive.filter((id) => id !== item.id));
    } else {
      servicesData.push({
        id: item.id,
      });
      setIsActive([...isActive, item.id]);
    }
  };

  const Column = ({ data, servicesArr }) => {
    const addNoted = (item, e) => {
      const value = e.target.value;
      const objectToAddNote = servicesArr?.filter(
        (obj) => obj.id === item.id
      )[0];
      objectToAddNote.note = value;
    };
    const addPrice = (item, e) => {
      const value = e.target.value;
      const objectToAddNote = servicesArr?.filter(
        (obj) => obj.id === item.id
      )[0];
      objectToAddNote.additional_cost = value;
    };

    const addTag = (item, e) => {
      const checked = e.target.previousElementSibling.checked;
      const objectToAddNote = servicesArr?.filter(
        (obj) => obj.id === item.id
      )[0];
      objectToAddNote.as_tag = !checked;
    };

    return (
      <div className="form-services__section--column">
        {!!data.length &&
          data.map((item) => {
            return (
              <div className="form-services__section--item" key={item.id}>
                <Checkbox
                  title={item.name}
                  checked={!!isActive.includes(item.id)}
                  disabled={false}
                  onClick={() => handleCheckboxChange(item)}
                />
                {isActive.includes(item.id) && (
                  <Checkbox
                    key={title}
                    title={"Важное"}
                    checked={
                      servicesArr?.filter((obj) => obj.id === item.id)[0]
                        ?.as_tag
                    }
                    clazz={"_no-border services-category"}
                    disabled={false}
                    onClick={(e) => addTag(item, e)}
                  />
                )}
                <div
                  className={`form-services__section--comment ${
                    isActive.includes(item.id) ? "_open" : ""
                  }`}
                >
                  <InputInLabel
                    type={"text"}
                    id={`create-comment-${item.id}`}
                    placeholder={""}
                    value={
                      servicesArr?.filter((obj) => obj.id === item.id)[0]?.note
                    }
                    onChange={(e) => addNoted(item, e)}
                  >
                    {"Комментарий"}
                  </InputInLabel>
                  <InputInLabel
                    type={"number"}
                    id={`create-comment-price--${item.id}`}
                    placeholder={"15 €"}
                    value={
                      servicesArr?.filter((obj) => obj.id === item.id)[0]
                        ?.additional_cost
                    }
                    onChange={(e) => addPrice(item, e)}
                  >
                    {"Доп. плата"}
                  </InputInLabel>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="form-services__section">
      <div className="form-services__section--title">
        <span>{title}</span>
      </div>
      <div className="form-services__section--checkbox">
        <Column servicesArr={servicesData} data={firstColumn} />
        <Column servicesArr={servicesData} data={secondColumn} />
      </div>
    </div>
  );
};

const ServicesPopup = (props) => {
  const { open, setOpen, data: girlData, setData } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const {
    slug,
    services: servicesArray,
    for_couples,
    for_groups,
    for_men,
    for_women,
    description,
    preferences,
  } = girlData;

  const token = localStorage.getItem("user");

  const [servicesFor, setServicesFor] = useState(() => {
    const types = [];
    if (for_men) {
      types.push("man");
    }
    if (for_women) {
      types.push("women");
    }
    if (for_couples) {
      types.push("couples");
    }
    if (for_groups) {
      types.push("groups");
    }
    return types;
  });

  const { handleSubmit } = useForm({
    mode: "onSubmit",
  });

  const { lang } = useLadyService();

  const [descValue, setDescValue] = useState(description);
  const [preferValue, setPreferValue] = useState(preferences);
  const [isDescLoad, setIsDescLoad] = useState(false);
  const [isPreferLoad, setIsPreferLoad] = useState(false);

  const createServicesArray = (incomingArray) => {
    const servicesArray = [];

    incomingArray.forEach((category) => {
      category.services.forEach((service) => {
        const newService = {
          id: service.id,
        };

        if (service.tag !== false) {
          newService.as_tag = service.tag;
        }

        if (service.note !== null) {
          newService.note = service.note;
        }

        if (service.additional_cost !== null) {
          newService.additional_cost = service.additional_cost;
        }

        servicesArray.push(newService);
      });
    });

    return servicesArray;
  };

  const servicesArrayData = createServicesArray(servicesArray);

  const generateText = () => {
    const fetchData = async (type) => {
      if (type === "preferences") {
        setIsPreferLoad(true);
      } else {
        setIsDescLoad(true);
      }
      try {
        const route = `user/${slug}/get-${type}`;

        const method = "GET";

        const result = await makeRequest({ route, method });
        if (result) {
          if (type === "preferences") {
            setPreferValue(result[type]);
          } else {
            setDescValue(result[type]);
          }
        }
      } catch (error) {}
      if (type === "preferences") {
        setIsPreferLoad(false);
      } else {
        setIsDescLoad(false);
      }
    };

    fetchData("preferences");
    fetchData("description");
  };

  const onSubmit = () => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const route = `user/profile/${slug}`;
        const method = "PUT";
        const payload = {
          lang,
          isFull: true,
          services: servicesArrayData,
          for_men: servicesFor.includes("man") ? 1 : 0,
          for_women: servicesFor.includes("women") ? 1 : 0,
          for_couples: servicesFor.includes("couples") ? 1 : 0,
          for_groups: servicesFor.includes("groups") ? 1 : 0,
          ...(!!descValue && { description: descValue }),
          ...(!!preferValue && { preference: preferValue }),
        };

        const responseData = await makeRequest({ route, method, payload });
        const keys = [
          "services",
          "for_men",
          "for_women",
          "for_couples",
          "for_groups",
        ];

        setData((prevState) => {
          for (const key of keys) {
            if (responseData.profile.hasOwnProperty(key)) {
              prevState[key] = responseData.profile[key];
            }
          }
          return { ...prevState };
        });
        setIsLoading(false);

        handleClose();
        showToast({
          message: indi("success"),
          variant: "succes",
        });
      } catch (error) {
        showToast({
          message: indi("oops"),
          variant: "error",
        });
      }
    };

    token && fetchData();
  };

  const services = useSelector(getServicesWords);

  const servicesArrayOptions =
    !!Object.entries(services) &&
    Object.keys(services).map((key) => {
      const item = services[key];

      return {
        title: item.title,
        services: Object.keys(item.services).map((serviceKey) => {
          const service = item.services[serviceKey];
          return {
            name: service.name,
            id: parseInt(serviceKey),
          };
        }),
      };
    });

  return (
    <>
      {isLoading && (
        <div className="loader__overlay">
          <Loader height={100} />
        </div>
      )}
      <dialog open={open} onClick={handleClose}>
        {open ? (
          <Helmet>
            <html className={"lock"}></html>
          </Helmet>
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          onClick={(e) => e.stopPropagation()}
          className={`popup-form__body__edit`}
        >
          {/* <div className="popup-form__inner"> */}
          <h3 className={`create-page__main--title mb-0`}>Услуги</h3>

          <Button onClick={handleClose} clazz={"button__close"}>
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          <div className="form-services__top-item services-for">
            <span>Оказываю услуги для</span>
            <SelectWithButtons
              type={"checkbox"}
              buttonValue={setServicesFor}
              buttons={data.for}
              withoutSelect={true}
              defaultValue={servicesFor}
            />
          </div>
          <span className="form-services__desc">
            По каждой услуге Вы можете написать краткий комментарий или
            дополнение, а также установить доп. цену, если эта услуга не входит
            в стандартный набор. Кроме того, Вы можете отметить до 5-ти своих
            услуг - как самые важные, которые будут выделены цветом, а также
            попадут в отображение Вашей малой анкеты.
          </span>
          {servicesArrayOptions?.map((item, index) => {
            return (
              <ServicesSection
                servicesData={servicesArrayData}
                title={item.title}
                data={item.services}
                key={index}
              />
            );
          })}
          <div className="form-services__preference">
            <span className="form-services__preference--title">
              Описание и предпочтения
            </span>
            <div className="form-services__preference--block">
              <span>
                Вы можете сгенерировать текст автоматически искусственным
                интеллектом на основании параметров внешности и оказываемых
                услуг
              </span>
              <Button
                buttonType={"button"}
                size={"l"}
                square={false}
                clazz="button--primary"
                onClick={() => generateText()}
              >
                {"Сгенерировать текст"}
              </Button>
            </div>
            <div className="form-services__preference--inputs">
              {!isDescLoad ? (
                <InputInLabel
                  type={"textarea"}
                  id={"create-AI"}
                  placeholder={"Я..."}
                  value={descValue}
                >
                  {"Опишите себя"}
                </InputInLabel>
              ) : (
                <Loader />
              )}
              {!isPreferLoad ? (
                <InputInLabel
                  type={"textarea"}
                  id={"create-AI2"}
                  placeholder={"Я люблю когда..."}
                  value={preferValue}
                >
                  {"Ваши предпочтения в сексе и на свиданиях"}
                </InputInLabel>
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <div className="create-form__buttons">
            <Button
              size={"l"}
              square={false}
              clazz="button--secondary"
              onClick={handleClose}
            >
              Отменить
            </Button>
            <Button
              buttonType={"submit"}
              size={"l"}
              square={false}
              clazz="button--green"
            >
              Сохранить
            </Button>
          </div>
          {/* </div> */}
        </form>
      </dialog>
    </>
  );
};

export default ServicesPopup;
