import {
  VerificationAndHealthCheck,
  VerHeader,
  AddHeader,
} from "./pageComponets";
import { useRef, useState, useCallback } from "react";
import { useEffect } from "react";
import AdCard from "./card/AdCard";
import { makeRequest } from "@/services/makeRequest";

import { ImportAdPopup } from "../../../../popups";

import { useSelector } from "react-redux";

import "./profilesPage.scss";
import { useTranslation } from "react-i18next";

import { Button, Icon, Loader } from "@/components/ui";

import { useEffectWindowSize } from "../../../../../hooks";

import { getUserLang } from "@/stores/slices/userSlice";

const Res = ({
  isLoading,
  options,
  view,
  variant,
  healthRef,
  verRef,
  filteredOptions,
  setData,
  setCount,
}) => {
  const { t: indi } = useTranslation("indi");

  switch (true) {
    case isLoading: {
      return <Loader />;
    }

    case !!filteredOptions.length: {
      return (
        <>
          <div className={`profiles-page__grid-ads${variant}`}>
            {filteredOptions.map((data) => {
              return (
                <AdCard
                  data={data}
                  view={view}
                  setData={setData}
                  setCount={setCount}
                />
              );
            })}
          </div>
          <VerificationAndHealthCheck verRef={verRef} healthRef={healthRef} />
        </>
      );
    }

    case !filteredOptions.length: {
      return <span className="w-100 text-center">Таких нет</span>;
    }

    case !options.length: {
      return <span className="w-100 text-center">{indi("zeroads")}</span>;
    }

    default: {
      return <Loader />;
    }
  }
};

const ProfilesPage = () => {
  const verRef = useRef(null);
  const healthRef = useRef(null);
  const [dataInfo, setDataInfo] = useState({});
  const { profiles_count } = dataInfo;
  const [count, setCount] = useState(profiles_count);
  const [isLoadingInfo, setIsLoadingInfo] = useState(true);
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState("");

  const filteredOptions = !!search
    ? data.filter((data) =>
        data.name.toLowerCase().includes(search.toLowerCase())
      )
    : data;

  const handleFilter = useCallback((e) => {
    const val = e.currentTarget.value;
    setSearch(val);
  }, []);

  const lang = useSelector(getUserLang);

  const windowWidth = useEffectWindowSize();

  const [view, setView] = useState("standart");

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation();

  const [isOpenImport, setIsOpenImport] = useState(false);

  const handleOpenImport = () => {
    setIsOpenImport(true);
  };

  const scrollToVer = () => {
    if (verRef.current) {
      verRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToHealt = () => {
    if (healthRef.current) {
      healthRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  let variant;
  switch (true) {
    case windowWidth < 1251: {
      variant = " small";
      break;
    }
    case view === "standart": {
      variant = "";
      break;
    }

    default:
    case view !== "standart": {
      variant = " small";
      break;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingInfo(true);
      try {
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });

        if (data) {
          const result = data.data;
          setCount(result.profiles_count);
          setDataInfo(result);
        }

        setIsLoadingInfo(false);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const route = `user/profiles`;

        const method = "GET";
        const payload = {
          lang,
          limit: 1000,
        };

        const { profiles } = await makeRequest({
          route,
          method,
          payload,
        });

        setData(profiles);

        setIsLoading(false);
      } catch (error) {}
    };

    !!lang && fetchData();
  }, [lang]);

  return (
    <main>
      {isOpenImport && (
        <ImportAdPopup open={isOpenImport} setOpen={setIsOpenImport} />
      )}
      <section className={"profiles-page__container"}>
        <div className={"profiles-page__header"}>
          <h1>
            {indi("myadsall")}: {count}
          </h1>

          <VerHeader
            scrollToVer={scrollToVer}
            scrollToHealt={scrollToHealt}
            isLoading={isLoadingInfo}
            data={dataInfo}
          />
        </div>

        <AddHeader handleOpenImport={handleOpenImport} />

        <div className="profiles-page view">
          <Button
            onClick={() => setView("standart")}
            clazz={"profiles-page view__btn"}
          >
            <Icon
              size={"l"}
              spritePath={"longer-view"}
              clazz={view === "standart" ? "" : "_gray"}
            />
            <span className={`p2 ${view === "standart" ? "color-main" : ""}`}>
              {indi("standartview")}
            </span>
          </Button>
          <Button
            onClick={() => setView("small")}
            clazz={"profiles-page view__btn"}
          >
            <Icon
              size={"l"}
              spritePath={"small-view"}
              clazz={view === "small" ? "" : "_gray"}
            />
            <span className={`p2 ${view === "small" ? "color-main" : ""}`}>
              {indi("minview")}
            </span>
          </Button>

          <div className="profiles-page__filter w-100">
            <label className="profiles-page__filter__label">
              <input
                placeholder={"Поиск анкеты"}
                value={search}
                className={`profiles-page__filter__input`}
                onChange={handleFilter}
                type={"search"}
              />
              {!!search && (
                <Button
                  title={t("reset")}
                  onClick={() => setSearch("")}
                  clazz="profiles-page__filter__close button_no-fill"
                >
                  <Icon size={"m"} spritePath={"close"} />
                </Button>
              )}
            </label>
          </div>
        </div>

        <Res
          isLoading={isLoading}
          options={data}
          view={view}
          variant={variant}
          verRef={verRef}
          healthRef={healthRef}
          search={search}
          filteredOptions={filteredOptions}
          setData={setData}
          setCount={setCount}
        />
      </section>
    </main>
  );
};

export default ProfilesPage;
