export function plural(lang = "en", n) {
  let idx;
  if (n % 10 === 1 && n % 100 !== 11) {
    idx = 0;
  } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    idx = 1;
  } else {
    idx = 2;
  }
  return lang === "ru"
    ? formsRu[idx]
    : lang === "en"
    ? formsEn[idx]
    : lang === "cz"
    ? formsCz[idx]
    : "";
}

export const formsRu = ["анкету", "анкеты", "анкет"];
export const formsEn = ["profile", "profiles", "profile"];
export const formsCz = ["profil", "profily", "profilů"];
