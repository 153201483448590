import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedCount: 0,
};

const agencyFilterSlice = createSlice({
    name: "agencyFilter",
    initialState,
    reducers: {
        plusSelectedCount: (state) => {
            state.selectedCount += 1;
        },

        minusSelectedCount: (state) => {
            state.selectedCount -= 1;
        },
    },
});

export const {
    plusSelectedCount,
    minusSelectedCount,
} = agencyFilterSlice.actions;

export const getSelectedCount = (state) => state.agencyFilter.selectedCount;


export default agencyFilterSlice.reducer;