import { useEffect, useState } from "react";
import "./appearance.scss";
import { object } from "yup";
import MultiRangeSlider from "multi-range-slider-react";
import bodyShape from "../../../../../../../assets/img/body_shape.svg";
import bodyShapeLight from "../../../../../../../assets/img/body_shape-light.svg";
import { Button, Checkbox, Icon, InputInLabel, Select } from "@/components/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { getNationalistWords } from "@/stores/slices/userSlice";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";

const ErrorMessage = ({ message }) => {
  return <span class="error create-error">{message}</span>;
};

const SelectWithButtons = ({
  children,
  withoutSelect = false,
  buttons,
  buttonValue,
  type = "checkbox",
  defaultValue,
}) => {
  const [selectedButton, setSelectedButton] = useState(
    type === "radio"
      ? defaultValue ?? null
      : !!defaultValue?.length
      ? defaultValue
      : []
  );

  const handleButtonClick = (value) => {
    if (type === "radio") {
      setSelectedButton(value);
      buttonValue(value);
    } else {
      const isIdAlreadySelected = selectedButton.includes(value);
      if (isIdAlreadySelected) {
        setSelectedButton((prev) => prev.filter((i) => i !== value));
        buttonValue((prev) => prev.filter((i) => i !== value));
      } else {
        setSelectedButton((prev) => [...prev, value]);
        buttonValue((prev) => [...prev, value]);
      }
    }
  };

  return (
    <div className="create-page__input-container">
      {!withoutSelect && children}
      <div className="create-page__input-buttons">
        {buttons.map((i) => (
          <label
            className={`checkbox-button${
              type === "radio"
                ? selectedButton === i.value
                  ? " checkbox-button_checked"
                  : ""
                : selectedButton.includes(i.value)
                ? " checkbox-button_checked"
                : ""
            }`}
            htmlFor={i.id}
            tabIndex={0}
          >
            <input
              onClick={() => handleButtonClick(i.value)}
              tabIndex={1}
              id={i.id}
              type={type}
              name={i.name}
              checked={selectedButton === i.value}
              value={i.value}
            />
            {i.title}
          </label>
        ))}
      </div>
    </div>
  );
};

const CreateRange = ({ min, max, defaultValue, setData }) => {
  const [value, setValue] = useState(defaultValue ?? 0);
  const [maxValue, setMaxValue] = useState(defaultValue ?? 0);

  return (
    <div className="create-range">
      <input
        type="number"
        value={value}
        onChange={(e) => setMaxValue(e.target.value)}
      ></input>
      <MultiRangeSlider
        min={min}
        max={max}
        maxValue={maxValue}
        minValue={0}
        step={1}
        stepOnly={true}
        className={"create-range__input"}
        ruler={false}
        label={false}
        onInput={(e) => {
          setData(e.maxValue);
          setValue(e.maxValue);
          setMaxValue(e.maxValue);
        }}
      />
    </div>
  );
};

const Appearance = ({
  setAvailableSteps,
  changeStep,
  step,
  rootElem,
  formData,
  setFormData,
}) => {
  const formSchema = object().shape({});
  const token = localStorage.getItem("user");

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const options = {
    tatu: [
      { id: 92, title: t("none"), value: "no" },
      { id: 91, title: t("few"), value: "few" },
      { id: 90, title: t("many"), value: "many" },
    ],
    smoking: [
      { id: 89, title: t("none"), value: "no" },
      { id: 88, title: t("rarely"), value: "sometimes" },
      { id: 87, title: t("often"), value: "regularly" },
    ],
    ethnos: [
      { id: 99, title: t("white"), value: "white" },
      { id: 98, title: t("asian"), value: "asian" },
      { id: 97, title: t("african"), value: "african" },
      { id: 96, title: t("arabic"), value: "arabic" },
      { id: 95, title: t("indian"), value: "indian" },
      { id: 94, title: t("tanned"), value: "mulatto" },
      { id: 93, title: t("mixed"), value: "mixed" },
      { id: 93, title: t("latin"), value: "latin" },
    ],
    breast: [
      { id: 1, title: "0(A)", value: "AA" },
      { id: 2, title: "1(A)", value: "A" },
      { id: 3, title: "2(C)", value: "B" },
      { id: 4, title: "3(C)", value: "C" },
      { id: 5, title: "4(D)", value: "D" },
      { id: 6, title: "5+(D)", value: "E" },
    ],
    penis: [
      { id: 101, title: "15", value: "15" },
      { id: 102, title: "16", value: "16" },
      { id: 103, title: "17", value: "17" },
      { id: 104, title: "18", value: "18" },
      { id: 105, title: "19", value: "19" },
      { id: 106, title: "20", value: "20" },
      { id: 107, title: "21", value: "21" },
      { id: 108, title: "22", value: "22" },
      { id: 109, title: "23", value: "23" },
      { id: 110, title: "24", value: "24" },
      { id: 111, title: "25", value: "25" },
    ],
    hair: [
      { id: 7, title: t("blond"), value: "blonde" },
      { id: 8, title: t("brown"), value: "brown" },
      { id: 8, title: t("brunette"), value: "brunette" },
      { id: 10, title: t("ginger"), value: "red" },
    ],
    hairIntim: [
      {
        id: "create-shaved",
        title: t("shaved"),
        value: "shaved",
      },
      {
        id: "create-haircut",
        title: t("cut"),
        value: "haircut",
      },
      {
        id: "create-intimNature",
        title: t("natural"),
        value: "natural",
      },
    ],
    eyes: [
      {
        id: "create-eyes-blue",
        title: t("blue"),
        value: "blue",
      },
      {
        id: "create-eyes-green",
        title: t("green"),
        value: "green",
      },
      {
        id: "create-eyes-brown",
        title: t("brownyies"),
        value: "brown",
      },
      {
        id: "create-eyes-grey",
        title: t("gray"),
        value: "gray",
      },
    ],
    leaps: [
      {
        id: "create-leaps-type",
        title: t("natural"),
        value: "natural",
      },
      {
        id: "create-leaps-type",
        title: t("enhanced"),
        value: "pumped_up",
      },
    ],


    gender: [
      { id: 67, title: t("woman"), value: "female" },
      { id: 68, title: t("guys"), value: "male" },
      { id: 68, title: t("trans"), value: "trans" },
      { id: 69, title: t("couples"), value: "couple" },
    ],
  };


  const data = {
    breastType: [
      {
        id: "create-nature",
        title: t("natural"),
        name: "breastType",
        value: "nature",
      },
      {
        id: "create-sil",
        title: t("silicone"),
        name: "breastType",
        value: "sil",
      },
    ],
    hairType: [
      {
        id: "create-short",
        title: t("short"),
        name: "hairType",
        value: "short",
      },
      {
        id: "create-middle",
        title: t("mediuma"),
        name: "hairType",
        value: "medium",
      },
      {
        id: "create-long",
        title: t("long"),
        name: "hairType",
        value: "long",
      },
    ],
    orientation1: [
      { id: 56, title: t("homo"), value: "straight" },
      { id: 27, title: t("lesbi"), value: "lesbian" },
      { id: 54, title: t("bi"), value: "bisexual" },
    ],
    orientation2: [
      { id: 87, title: t("homo"), value: "straight" },
      { id: 86, title: t("homo"), value: "homosexual" },
      { id: 85, title: t("bi"), value: "bisexual" },
    ],
    eyesType: [
      {
        id: "create-eyes-lenses",
        title: t("lenses"),
        name: "eyes-type",
        value: "lenses",
      },
      {
        id: "create-eyes-glasses",
        title: t("glasses"),
        name: "eyes-type",
        value: "glasses",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const { theme } = useLadyService();

  const [breastType, setBreastType] = useState(
    formData.breast ? formData.breast : ""
  );

  // const [isBreastSil, setIsBreastSil] = useState(
  //   formData.silicone ? formData.silicone : false
  // );

  const [breastCategory, setBreastCategory] = useState(
    formData.breastCategory ? formData.breastCategory : ""
  );

  const [hairType, setHairType] = useState(formData.hair ? formData.hair : "");
  const [hairLength, setHairLength] = useState(
    formData.hair_length ? formData.hair_length : ""
  );
  const [hairIntim, setHairIntim] = useState(
    formData.intim_hairstyle ? formData.intim_hairstyle : ""
  );
  const [eyesColor, setEyesColor] = useState(
    formData.eyes ? formData.eyes : ""
  );
  const [eyesType, setEyesType] = useState([
    formData.have_glasses ? "glasses" : "",
    formData.have_lenses ? "lenses" : "",
  ]);
  const [lips, setLips] = useState(formData.lips ? formData.lips : "");
  const [tatoo, setTatoo] = useState(formData.tatoo ? formData.tatoo : "");
  const [piercing, setPiercing] = useState(
    formData.piercing ? formData.piercing : ""
  );
  const [smoking, setSmoking] = useState(
    formData.smoking ? formData.smoking : ""
  );
  const [shoulders, setShoulders] = useState(
    formData.shoulders ? formData.shoulders : 0
  );
  const [hips, setHips] = useState(formData.hips ? formData.hips : 0);
  const [waist, setWaist] = useState(formData.waist ? formData.waist : 0);
  const [orientation, setOrientation] = useState(
    formData.orientation ? formData.orientation : ""
  );
  const [gender, setGender] = useState(formData.gender ? formData.gender : "");
  const [penis, setPenis] = useState(
    formData.penis_size ? formData.penis_size : ""
  );
  const onSubmit = (data) => {
    const fetchData = async () => {
      try {
        const route = `user/profile/${formData.slug}`;

        const method = "PUT";
        const payload = {
          city: formData.city,
          ...(!!data.height && { height: data.height }),
          ...(!!data.weight && { weight: data.weight }),
          ...(!!data.perfume && { perfume: data.perfume }),
          ...(!!breastType && { breast: breastType }),
          // ...(!!isBreastSil && { silicone: isBreastSil === "sil" }),
          ...(!!breastCategory && { silicone: breastCategory === "sil" }),
          ...(!!penis && { penis_size: penis }),
          ...(!!hairType && { hair: hairType }),
          ...(!!hairLength && { hair_length: hairLength }),
          ...(!!hairIntim && { intim_hairstyle: hairIntim }),
          ...(!!eyesColor && { eyes: eyesColor }),
          have_glasses: eyesType.includes("glasses") ? 1 : 0,
          have_lenses: eyesType.includes("lenses") ? 1 : 0,
          ...(!!lips && { lips: lips }),
          ...(!!orientation && { orientation: orientation }),
          ...(!!gender && { gender: gender }),
          ...(!!tatoo && { tatoo: tatoo }),
          ...(!!piercing && { piercing: piercing }),
          ...(!!smoking && { smoking: smoking }),
          ...(!!shoulders && { shoulders: String(shoulders) }),
          ...(!!hips && { hips: String(hips) }),
          ...(!!waist && { waist: String(waist) }),
        };

        const result = await makeRequest({ route, method, payload });
        if (result.status) {
          setAvailableSteps((prev) => [...prev, 4]);
          changeStep((prev) => prev + 1);
          setFormData((prevData) => ({
            ...prevData,
            ...payload,
            breastCategory: breastCategory,
          }));

          rootElem.scrollIntoView({
            behavior: "smooth",
          });
        }
      } catch (error) {}
    };

    token && fetchData();
  };

  const backStep = () => {
    changeStep((prev) => prev - 1);
  };

  useEffect(() => {
    console.log(formData);
    if (!!formData) {
      setValue("height", formData.height);
      setValue("weight", formData.weight);
      setValue("perfume", formData.perfume);
    }
  }, []);
  const validateData = () => {};

  const sortedGender = options.gender.sort((a, b) => {
    if (a.value === formData.gender) {
      return -1;
    }
    if (b.value === formData.gender) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <h2>{indi("params")}</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`create-page__form appearance`}
      >
        <div className="form-services__top-item">
          <span>{indi("gender")}</span>
          <SelectWithButtons
            defaultValue={formData.orientation}
            type={"radio"}
            buttonValue={setOrientation}
            buttons={
              gender === "female"
                ? data.orientation1
                : gender === "male" || gender === "trans"
                ? data.orientation2
                : data.orientation1
            }
          >
            <Select
              onChange={setGender}
              name={"create-gender"}
              options={formData.gender ? sortedGender : options.gender}
              clazz={"button button-xs button--main"}
              arrowSize={"m"}
            />
            {(gender === "male" || gender === "trans") && (
              <Select
                onChange={setPenis}
                name={"create-gender-add"}
                options={options.penis}
                clazz={"button button-xs button--main"}
                defaultTitle={
                  penis
                    ? options.penis.filter((item) => item.value === penis)[0]
                        .title
                    : ""
                }
                arrowSize={"m"}
                withoutTitle={true}
                postApi={true}
                subTitle={`${t("penissize")}, ${t("cm")}`}
              />
            )}
          </SelectWithButtons>
        </div>
        <div className="form-appearance__top-main">
          <div className="form-appearance__left">
            <div className="form-appearance__top">
              <InputInLabel
                type={"number"}
                id={"create-height"}
                value={null}
                placeholder={"150"}
                register={{ ...register("height") }}
                clazz={`${errors.age && "_error"}`}
              >
                {`${t("height")}, ${t("cm")}`}
                {`${t("height")}, ${t("cm")}`}
                {errors.height && (
                  <ErrorMessage message={errors.height.message} />
                )}
              </InputInLabel>
              <InputInLabel
                type={"number"}
                id={"create-weight"}
                value={null}
                placeholder={"50"}
                register={{ ...register("weight") }}
                clazz={`${errors.age && "_error"}`}
              >
                {`${t("weight")}, ${t("kg")}`}
                {`${t("weight")}, ${t("kg")}`}
                {errors.weight && (
                  <ErrorMessage message={errors.weight.message} />
                )}
              </InputInLabel>
              {gender !== "male" && (
                <SelectWithButtons
                  // defaultValue={formData.silicone ? "sil" : "nature"}
                  defaultValue={formData.breastCategory ? formData.breastCategory : ""}
                  buttonValue={setBreastCategory}
                  buttons={data.breastType}
                  type={"radio"}
                >
                  <Select
                    onChange={setBreastType}
                    name={"create-breast"}
                    options={options.breast}
                    clazz={"button button-xs button--main"}
                    arrowSize={"m"}
                    defaultTitle={""}
                    withoutTitle={true}
                    postApi={true}
                    subTitle={t("breastsize")}
                  />
                </SelectWithButtons>
              )}
            </div>
            <div className="form-appearance__bottom">
              <SelectWithButtons
                defaultValue={formData.hair_length}
                buttonValue={setHairLength}
                buttons={data.hairType}
                type={"radio"}
              >
                <Select
                  onChange={setHairType}
                  name={"create-hair"}
                  options={options.hair}
                  clazz={"button button-xs button--main"}
                  arrowSize={"m"}
                  defaultTitle={
                    hairType
                      ? options.hair.filter(
                          (item) => item.value === hairType
                        )[0].title
                      : ""
                  }
                  withoutTitle={true}
                  postApi={true}
                  subTitle={t("hair")}
                />
              </SelectWithButtons>
              <Select
                onChange={setHairIntim}
                name={"create-hairintim"}
                options={options.hairIntim}
                clazz={"button button-xs button--main"}
                arrowSize={"m"}
                subTitle={t("pubichair")}
                defaultTitle={
                  hairIntim
                    ? options.hairIntim.filter(
                        (item) => item.value === hairIntim
                      )[0].title
                    : ""
                }
                withoutTitle={true}
                postApi={true}
              />
              <SelectWithButtons
                defaultValue={eyesType}
                buttonValue={setEyesType}
                buttons={data.eyesType}
              >
                <Select
                  onChange={setEyesColor}
                  name={"create-eyes"}
                  options={options.eyes}
                  clazz={"button button-xs button--main"}
                  arrowSize={"m"}
                  subTitle={t("eyes")}
                  defaultTitle={
                    eyesColor
                      ? options.eyes.filter(
                          (item) => item.value === eyesColor
                        )[0].title
                      : ""
                  }
                  withoutTitle={true}
                  postApi={true}
                />
              </SelectWithButtons>
              <Select
                onChange={setLips}
                name={"create-hairintim"}
                options={options.leaps}
                clazz={"button button-xs button--main"}
                arrowSize={"m"}
                subTitle={t("lips")}
                defaultTitle={
                  lips
                    ? options.leaps.filter((item) => item.value === lips)[0]
                        .title
                    : ""
                }
                withoutTitle={true}
                postApi={true}
              />
            </div>
          </div>
          <div className="form-appearance__right">
            <span className="mt-12">Пропорции</span>
            {/* <Icon size={"11xl"} spritePath={"body"} /> */}
            <img src={theme === "light" ? bodyShapeLight : bodyShape} alt="" />
            <div className="form-appearance__right-range">
              <CreateRange
                min={0}
                max={140}
                setData={setShoulders}
                defaultValue={shoulders}
              />
              <CreateRange
                min={0}
                max={120}
                setData={setWaist}
                defaultValue={waist}
              />
              <CreateRange
                min={0}
                max={160}
                setData={setHips}
                defaultValue={hips}
              />
            </div>
          </div>
        </div>
        <div className="form-appearance__grid">
          {/* <Select
            onChange={setEthnos}
            name={"create-ethnos"}
            options={options.ethnos}
            clazz={"button button-xs button--main"}
            arrowSize={"m"}
            subTitle={"Этнос (цвет кожи)"}
            defaultTitle={""}
            withoutTitle={true}
            postApi={true}
          />
          <Select
            onChange={setNationality}
            name={"create-nationalist"}
            options={transformedCountries?.length > 0 && transformedCountries}
            clazz={"button button-xs button--main create-nations"}
            clazzSvg={"select__flag"}
            arrowSize={"m"}
            isSearch={true}
            searchPlaceHolder={"Поиск по национальностям"}
            type={"flag"}
            subTitle={"Национальность"}
            defaultTitle={""}
            withoutTitle={true}
            postApi={true}
            withoutIcon={true}
          />
          <Select
            onChange={setReadyTravel}
            name={"create-readyTravel"}
            options={options.readyTravel}
            clazz={"button button-xs button--main"}
            arrowSize={"m"}
            subTitle={"Готова к путешествиям"}
            defaultTitle={""}
            withoutTitle={true}
            postApi={true}
          /> */}
          <Select
            onChange={setTatoo}
            name={"create-tatu"}
            options={options.tatu}
            clazz={"button button-xs button--main"}
            arrowSize={"m"}
            subTitle={t("tattoos")}
            defaultTitle={
              tatoo
                ? options.tatu.filter((item) => item.value === tatoo)[0].title
                : ""
            }
            withoutTitle={true}
            postApi={true}
          />
          <Select
            onChange={setPiercing}
            name={"create-pirsing"}
            options={options.tatu}
            clazz={"button button-xs button--main"}
            arrowSize={"m"}
            subTitle={t("piercing")}
            defaultTitle={
              piercing
                ? options.tatu.filter((item) => item.value === piercing)[0]
                    .title
                : ""
            }
            withoutTitle={true}
            postApi={true}
          />
          <Select
            onChange={setSmoking}
            name={"create-smoking"}
            options={options.smoking}
            clazz={"button button-xs button--main"}
            arrowSize={"m"}
            subTitle={t("smoking")}
            defaultTitle={
              smoking
                ? options.smoking.filter((item) => item.value === smoking)[0]
                    .title
                : ""
            }
            withoutTitle={true}
            postApi={true}
          />
          <InputInLabel
            type={"text"}
            id={"create-perfume"}
            placeholder={"Вкусные, сладкие"}
            register={{ ...register("perfume") }}
          >
            {t("myperfume")}
            {t("myperfume")}
          </InputInLabel>
        </div>

        <div className="create-form__buttons">
          <Button
            size={"l"}
            square={false}
            clazz="button--secondary"
            onClick={() => backStep()}
          >
            {indi("back")}
          </Button>
          <Button
            onClick={() => validateData()}
            buttonType={"submit"}
            size={"l"}
            square={false}
            clazz="button--green"
          >
            {indi("continue")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default Appearance;
