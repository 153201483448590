import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import {
  getErocontentAccessStatus,
  setErocontentAccessStatus,
} from "@/stores/slices/popupSlice";
import { Button, Icon } from "@/components/ui";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import moment from "moment/moment";

const ErocontentAccess = ({ data }) => {
  const { lang, dispatch } = useLadyService();
  const { t } = useTranslation("translation");

  const formattedDate = moment.unix(data.data.updated_at).format(`DD.MM.YYYY`);

  const erocontentAccessValue = useSelector(getErocontentAccessStatus);

  return (
    <dialog
      open={!!erocontentAccessValue}
      // open={true}
      onClick={() => dispatch(setErocontentAccessStatus(false))}
      id={"erocontentAccess"}
      className="popup-form popup-form-erocontent"
    >
      {!!erocontentAccessValue ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <div
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body text-center"
      >
        <Button
          onClick={() => dispatch(setErocontentAccessStatus(false))}
          clazz={"button__close"}
        >
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner gap-4">
          <h2 className="popup-form__title mb-8 fz-22 lh-24">
            {t("paidaccess")}
          </h2>
          <div className="popup-form__fake-input popup-form__fake-input-erocontent">
            <div className={"color-green"}>
              <Icon size={"l"} clazz={"_green"} spritePath={"pants"} />
              {data.data.description}
            </div>
            {data.data.photo && data.data.photo.length > 0 ? (
              <div>
                <Icon size={"l"} spritePath={"image"} />
                {data.data.photo_count} {t("eroticophotosand")}
              </div>
            ) : null}

            {data.data.video && data.data.video.length > 0 ? (
              <div>
                <Icon size={"l"} spritePath={"video-play"} />
                {data.data.video_count} {t("videos")}
              </div>
            ) : null}

            <p className={"mb-0"}>
              {t("postedon")}: {formattedDate}
            </p>
          </div>
        </div>

        <div className="popup-form__inner">
          <div className="popup-form__fake-input">
            <p className={"p2"}>{t("accessfee")}</p>
            <span className={"title"}>{data.data.price}€</span>
          </div>
          <p>{t("paymenttransfered")}</p>
        </div>

        <div className="popup-form__inner d-flex fd-column gap-16">
          <Button
            size={"l"}
            clazz={"button--green"}
            buttonType={"submit"}
            onClick={() => dispatch(setErocontentAccessStatus())}
          >
            {t("payandwatch")}
          </Button>

          <span className={"popup-form__text title"}>
            {t("or")}{" "}
            <Link
              reloadDocument={true}
              to={`/${lang}/registration`}
              className={"color-green title td-none d-inline-block"}
            >
              {t("changetariff")}
            </Link>{" "}
            {t("freeaccess")}
          </span>
        </div>
      </div>
    </dialog>
  );
};

export default ErocontentAccess;
