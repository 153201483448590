import GirlCard from "../girlCard/GirlCard";
import { Button, Icon, Loader } from "@/components/ui";
import { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  InfoBox,
} from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import {
  getMapModel,
  getOneModelPosition,
  setMapModel,
  setOneModelPosition,
} from "@/stores/slices/mapModelSlice";
import "./mapFilter.scss";
import { Helmet } from "react-helmet";
import { getMapStatus, setStoriesMap } from "@/stores/slices/popupSlice";
import { getUserCurrentCity, getUserLang } from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";

const MyMap = () => {
  const dispatch = useDispatch();

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const defaultCenter = {
    lat: 50.086137,
    lng: 14.529199,
  };

  const currentCity = useSelector(getUserCurrentCity);
  const oneModel = useSelector(getOneModelPosition);

  const [data, setData] = useState([]);
  const [loading, isLoading] = useState(true);

  const { t } = useTranslation("translation");

  const toggleCurrentTippy = (e) => {
    const target = e.target;
    const tippyElems = document.querySelectorAll(".map-filter__tippy");
    tippyElems.forEach((item) => {
      item.classList.remove("current-tippy");
    });
    target.classList.add("current-tippy");
  };

  useEffect(() => {
    const fetchData = async () => {
      isLoading(true);

      try {
        const route = `profiles/simply/${currentCity.id}`;
        const method = "GET";
        const payload = {
          latitude: true,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.data;
          setData(result);
        }
        isLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const [isLoad, setIsLoad] = useState(true);
  const [icon, setIcon] = useState(null);
  const [map, setMap] = useState(null);

  async function updateMarker() {
    setIsLoad(true);
    let icon = await new window.google.maps.MarkerImage(
      "https://lh3.googleusercontent.com/bECXZ2YW3j0yIEBVo92ECVqlnlbX9ldYNGrCe0Kr4VGPq-vJ9Xncwvl16uvosukVXPfV=w300",
      null,
      null,
      null,
      new window.google.maps.Size(16, 16)
    );
    setIcon(icon);
    setIsLoad(false);
  }
  useEffect(() => {
    if (!map || data.length === 0) return;

    if (!oneModel) {
      const bounds = new window.google.maps.LatLngBounds();
      data.forEach((model) => {
        if (model.latitude && model.longitude) {
          bounds.extend(
            new window.google.maps.LatLng(model.latitude, model.longitude)
          );
        }
      });

      map.fitBounds(bounds);
    } else {
      const bounds = new window.google.maps.LatLngBounds();
      const modelCoors = data.filter((item) => item.slug === oneModel);
      modelCoors.forEach((model) => {
        if (model.latitude && model.longitude) {
          bounds.extend(
            new window.google.maps.LatLng(model.latitude, model.longitude)
          );
        }
      });

      map.fitBounds(bounds);
    }
    console.log(map.getZoom())
    const listener = map.addListener('idle', () => {
      if (map.getZoom() < 16) {
        map.setZoom(100);
      }
      window.google.maps.event.removeListener(listener);
    });

    updateMarker();
  }, [map, data]);

  // const [visibleBounds, setVisibleBounds] = useState();

  // useEffect(() => {
  //   if (map) {
  //     const updateBounds = () => {
  //       const bounds = map.getBounds();
  //       if (bounds) {
  //         console.log(bounds)
  //         const ne = bounds.getNorthEast();
  //         const sw = bounds.getSouthWest();
  //         setVisibleBounds({
  //           northeast: { lat: ne.lat(), lng: ne.lng() },
  //           southwest: { lat: sw.lat(), lng: sw.lng() }
  //         });
  //       }
  //     };

  //     // Добавляем слушатель события idle
  //     // map.addListener("idle", updateBounds);

  //     // Инициализируем границы
  //     updateBounds();
  //     // const listener = map.addListener("idle", updateBounds);
  //     // if (listener) {
  //     //   window.google.maps.event.clearListeners(listener);
  //     // }
  //   }
  // }, [map]);

  return !loading ? (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={12}
      center={defaultCenter}
      onLoad={(map) => setMap(map)}
    >
      {
        <MarkerClusterer gridSize={60}>
          {(clusterer) =>
            data.map((model) => (
              <>
                {model.latitude && model.longitude ? (
                  <Marker
                    icon={icon}
                    key={model.id}
                    position={{
                      lat: model.latitude,
                      lng: model.longitude,
                    }}
                    clusterer={clusterer}
                  >
                    {!isLoad && (
                      <InfoBox
                        key={model.lat}
                        defaultPosition={
                          new window.google.maps.LatLng(model.lat, model.lng)
                        }
                        options={{
                          enableEventPropagation: false,
                          boxStyle: {
                            translate: `-50% -127%`,
                            minWidth: `100px`,
                          },
                        }}
                      >
                        <div
                          onClick={(e) => {
                            dispatch(setMapModel(model.slug));
                            toggleCurrentTippy(e);
                          }}
                          className={
                            model.is_elite && !model.is_top
                              ? "map-filter__tippy map-filter__tippy--elite"
                              : !model.is_elite && model.is_top
                              ? "map-filter__tippy map-filter__tippy--top"
                              : `map-filter__tippy`
                          }
                        >
                          {`${t('from')} ${
                            model.prices.incall
                              ? model.prices.incall
                              : model.prices.outcall
                          } €/${t("hour")}`}
                        </div>
                      </InfoBox>
                    )}
                  </Marker>
                ) : (
                  <></>
                )}
              </>
            ))
          }
        </MarkerClusterer>
      }
    </GoogleMap>
  ) : (
    <Loader />
  );
};

const CurrentModel = ({ mapOpen }) => {
  const currentModelId = useSelector(getMapModel);
  const lang = useSelector(getUserLang);
  const [load, setLoad] = useState(true);

  const [oneProfile, setOneProfile] = useState(null);

  useEffect(() => {
    if (!mapOpen) {
      return () => {
        setOneProfile(null);
      };
    }
  }, [mapOpen]);

  useEffect(() => {
    const fetchData = async () => {
      setLoad(false);

      try {
        const route = `profiles/short/${currentModelId}`;
        const method = "GET";
        const payload = {
          lang,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.data;
          setOneProfile(result);
          setLoad(true);
        }
      } catch (error) {}
    };

    currentModelId && fetchData();
  }, [currentModelId]);

  const dispatch = useDispatch();

  const toggleCLoseProfile = () => {
    setOneProfile(null);
    dispatch(setMapModel(null));
    const tippyElems = document.querySelectorAll(".map-filter__tippy");
    tippyElems.forEach((item) => {
      item.classList.remove("current-tippy");
    });
  };
  if (oneProfile) {
    return (
      <div className={"map-filter__wrapper"}>
        {!load ? (
          <div className="girl-card">
            <Loader />
          </div>
        ) : (
          <>
            <Button
              size={"m"}
              square={true}
              clazz={"button--primary"}
              onClick={toggleCLoseProfile}
            >
              <Icon spritePath={"close"} size={"m"} />
            </Button>
            <GirlCard
              props={{ ...oneProfile }}
              key={oneProfile.id}
              mobile={true}
            />
          </>
        )}
      </div>
    );
  }
};

const MapFilter = () => {
  const dispatch = useDispatch();
  const mapPopupStatus = useSelector(getMapStatus);

  const onClose = () => {
    dispatch(setStoriesMap(false));
    dispatch(setMapModel(null));
    dispatch(setOneModelPosition(null));
  };

  return (
    <dialog
      open={!!mapPopupStatus}
      onClick={() => onClose()}
      id={"map"}
      className="popup-form map-filter"
    >
      {!!mapPopupStatus ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <div
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body map-filter__body"
      >
        <Button onClick={() => onClose()} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        {mapPopupStatus && <MyMap />}
        <CurrentModel mapOpen={mapPopupStatus} />
      </div>
    </dialog>
  );
};

export default MapFilter;
