import { toast } from "react-toastify";
import { ButtonLink, Icon } from "@/components/ui";
import "./toast.scss";

const showToast = ({ message, type = "default", link, variant }) => {
  const toastType = {
    video: {
      message: "Новый запрос на видео-чат",
      subMessage: "За каждую минуту видеочата вы получите 2€ ",
      link: {
        href: link,
        buttonTitle: "Посмотреть",
      },
      timeMessage: {
        tilte: "Время на подтверждение запроса",
        time: "2 минуты",
      },
    },

    default: {
      message,
    },
  };

  let variantStatus = "succes";
  let iconColor = "green";
  let spritePath = "video";
  let size = "m";
  switch (variant) {
    case "info": {
      variantStatus = "info";
      iconColor = "";
      spritePath = "info";
      size = "l";
      break;
    }

    case "error": {
      variantStatus = "error";
      iconColor = "red";
      spritePath = "error";
      size = "l";
      break;
    }

    case "succes": {
      variantStatus = "succes";
      iconColor = "green";
      spritePath = "check";
      size = "l";
      break;
    }

    default: {
      variantStatus = "succes";
      iconColor = "green";

      break;
    }
  }

  const CustomToast = () => (
    <div className={`toast${!!variantStatus ? ` ${variantStatus}` : ""}`}>
      <Icon
        size={size}
        spritePath={spritePath}
        clazz={iconColor ? `_${iconColor}` : ""}
      />
      <div className={`toast__root`}>
        <h3>{toastType[type]["message"]}</h3>
        {type !== "default" && (
          <>
            <span className="p2">{toastType[type]["subMessage"]}</span>
            <p className="toast__info p3">
              {toastType[type]["timeMessage"]["tilte"]}
              <span className="toast__try">
                {toastType[type]["timeMessage"]["time"]}
              </span>
            </p>{" "}
          </>
        )}
        {!!link && (
          <ButtonLink
            clazz={"button--green w-100 _no-underline"}
            size={"s"}
            href={toastType[type]["link"]["href"]}
            tilte={toastType[type]["link"]["buttonTitle"]}
          >
            {toastType[type]["link"]["buttonTitle"]}
          </ButtonLink>
        )}
      </div>
    </div>
  );

  const options = {
    position: "top-right",
    autoClose: !link ? 3000 : false,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    progress: undefined,
    className: "toast",
  };

  toast(<CustomToast />, options);
};

export default showToast;
