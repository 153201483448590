import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel, Select } from "@/components/ui";
import { useForm } from "react-hook-form";

const AdBalancePopup = (props) => {
  const { open, setOpen } = props;

  const { t: indi } = useTranslation("translation");

  const paymentsMethods = [
    { id: 1, icon: "visa", title: "Visa", name: "Visa" },
    { id: 2, icon: "master-card", title: "MasterCard", name: "MasterCard" },
    { id: 3, icon: "paypal", title: "PayPal", name: "PayPal" },
    { id: 4, icon: "bitcoin", title: "Bitcoin", name: "Bitcoin" },
  ];

  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    // const {} = data;

    // const fetchData = async () => {
    //   try {
    //     const route = `user/change-password`;
    //     const method = "PUT";
    //     const payload = { current_password: old_password, new_password };

    //     await makeRequest({ route, method, payload });
    //     reset();
    //     setOpen(false);
    //     showToast({
    //       message: "Пароль успешно обновлен",
    //       variant: "succes",
    //     });
    //   } catch (error) {
    //     const res = error.response;

    //     // eslint-disable-next-line default-case
    //     switch (res.status) {
    //       case 401: {
    //         showToast({
    //           message: "Неавторизован",
    //           variant: "error",
    //         });
    //         break;
    //       }
    //       case 422: {
    //         setErrorCurrent("Текущий пароль неверный");

    //         break;
    //       }
    //     }
    //   }
    // };

    // fetchData();
  };

  const handleCloseDelete = () => {
    reset();
    setOpen(false);
  };

  return (
    <dialog open={open} onClick={handleCloseDelete}>
      {!!open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        onSubmit={handleSubmit(onSubmit)}
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button onClick={handleCloseDelete} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner">
          <h3>{indi("topupbalance")}</h3>
          <InputInLabel
            id={"adBalance"}
            title={"Сумма пополнения"}
            type={"number"}
            register={{ ...register("balance") }}
            placeholder={"100€"}
          >
            Сумма пополнения
          </InputInLabel>

          <Select
            name={"paymentsMethods"}
            options={paymentsMethods}
            clazz={"button button-xs button--main"}
            clazzSvg={"select__flag"}
            arrowSize={"m"}
            type={"payment"}
            subTitle={"Способ"}
          />

          <Button
            size={"l"}
            buttonType={"submit"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            Пополнить
          </Button>
        </div>
      </form>
    </dialog>
  );
};

export default AdBalancePopup;
