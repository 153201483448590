import { Select, Checkbox, Loader } from "@/components/ui";
import { useTranslation } from "react-i18next";
import { ReviewContainer } from "./pageComponents";
import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import { IndiLayoutLk } from "../../../Layouts";

import "./reviewsPage.scss";

const ReviewsPage = () => {
  const { t } = useTranslation();
  const { t: indi } = useTranslation("indi");

  const token = localStorage.getItem("user");

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const filterNavigation = [
    { id: 1, link: "#", title: t("bynew"), value: "date" },
    { id: 2, link: "#", title: t("byrating"), value: "rating" },
  ];

  const filterNavigationMore = [
    {
      id: 1,
      title: t("new"),
      value: "new",
      checked: true,
    },
    {
      id: 2,
      title: indi("published"),
      value: "published",
      checked: false,
    },
    {
      id: 3,
      title: indi("rejected"),
      value: "rejected",
      checked: false,
    },
    {
      id: 4,
      title: indi("withoutanswer"),
      value: "withoutanswer",
      checked: false,
    },
  ];

  const Review = () => {
    switch (true) {
      case isLoading: {
        return <Loader />;
      }

      case !!data.length: {
        return data?.map((i, index) => {
          return <ReviewContainer key={index} data={i} />;
        });
      }

      default: {
        return <h3 className="text-center mt-12">У Вас еще не было отзывов</h3>;
        //перевести
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/reviews`;
        const method = "GET";

        const data = await makeRequest({ route, method });

        setData(data.data);
        setIsLoading(false);
      } catch (error) {}
    };

    token && fetchData();
  }, [token]);

  return (
    <main>
      <section className={"reviews-page__container"}>
        <h1>{indi("myreviews")}</h1>
        <div className="reviews-page__header">
          <div className="reviews-page__filter">
            {filterNavigationMore.map((i) => {
              return (
                <Checkbox
                  key={i.id}
                  id={i.id}
                  checked={i.checked}
                  title={i.title}
                  path={i.path}
                  value={i.value}
                  name={i.name}
                  // onChange={() => handleCountrySelect(code, title)}
                />
              );
            })}
          </div>

          <Select
            options={filterNavigationMore}
            leftIcon={"sort"}
            clazzSvg={"icon-m"}
            clazz={
              "button button-xs button--secondar reviews-page__filter__mobile"
            }
            arrowSize={"s"}
            sorted={true}
            name={"sort"}
          />

          <Select
            options={filterNavigation}
            leftIcon={"sort"}
            clazzSvg={"icon-m"}
            clazz={"button button-xs button--secondary"}
            arrowSize={"s"}
            sorted={true}
            name={"sort"}
          />
        </div>

        <Review />
      </section>
    </main>
  );
};

export default ReviewsPage;
