import {
  Accordion,
  Button,
  Checkbox,
  DateInput,
  Icon,
  InputInLabel,
  Select,
  Toggle,
} from "@/components/ui";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import icons_db from "./iconsDB";
import './uiKitPage.scss'
import React, {useEffect, useState} from "react";
import useLadyService from "@/services/LadyService";
import {SwiperSlide} from "swiper/react";
import GirlCard from "../../girlCard/GirlCard";
import {makeRequest} from "../../../services/makeRequest";

const UIKitPage = () => {
  const {
    lang,
  } = useLadyService();
  const [sliderData, setSliderData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `profiles?limit=6&page=1&sort=date&city=1&lang=${lang}`;
        const method = "GET";
        const payload = {
          lang,
          page: 1,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          setSliderData(data.profiles);
        }
      } catch (error) {}
    };

    lang && fetchData();
  }, [lang]);


  return (
    <main className={'ui-kit'}>
      <div className={"__container mb-48"}>
        <h1>Заголовок h1</h1>
        <h2>Заголовок h2</h2>
        <h3>Заголовок h3</h3>

        <b>Жирный текст</b>

        <p>Параграф</p>
        <br />
        <span className={"mt-20 fz-20"}>Списки:</span>

        <ul>
          <li>li список 1</li>
          <li>li список 2</li>
          <li>li список 3</li>
        </ul>

        <ol>
          <li>ol список 1</li>
          <li>ol список 2</li>
          <li>ol список 3</li>
        </ol>

        <br />

        <div className={"d-flex fd-column gap-12 width-max"}>
          <Toggle optionLabels />

          <span className={'mt-20 fz-20'}>Инпуты:</span>
          <Checkbox title={'Чекбокс'}></Checkbox>
          <Checkbox disabled title={'Чекбокс disabled'}></Checkbox>
          <InputInLabel
            type={'radio'}
            name={'radio'}
            checked={true}
          >Радиокнопка</InputInLabel>

          <InputInLabel
            type={'radio'}
            name={'radio'}
          >Радиокнопка</InputInLabel>

          <InputInLabel>
            Поле ввода
          </InputInLabel>

          <InputInLabel type={"radio"} name={"radio"}>
            Радиокнопка
          </InputInLabel>

          <InputInLabel>Поле ввода</InputInLabel>

          <DateInput>Календарь</DateInput>

          <Accordion title={"Аккордеон"}>
            <div className={"d-flex fd-column gap-10"}>
              <HashLink to="#">текст1</HashLink>
              <HashLink to="#">текст2</HashLink>
              <HashLink to="#">текст3</HashLink>
            </div>
          </Accordion>

          <span className={'mt-20 fz-20'}>Ссылки:</span>
          <Link to={'#'} className={'text-underline'}>Ссылка 1</Link>
          <Link to={'#'} className={'hover-line'}>Ссылка 2</Link>
          <Link to={'#'} className={'color-green hover-line'}>Ссылка 3</Link>

          <span className={'mt-20 fz-20'}>Кнопки:</span>
          <div className={'d-flex gap-20'}>
            <div className={'d-flex fd-column gap-10'}>
              <span className={'mt-12'}>Расцветки:</span>

              <Button
                size={"s"}
                clazz="button--black"
              >
                Black
              </Button>
              <Button size={"s"} clazz="button--primary">
                Primary
              </Button>
              <Button size={"s"} clazz="button--error">
                Error
              </Button>
              <Button size={"s"} clazz="button--secondary">
                Secondary
              </Button>

              <Button size={"s"} clazz="button--tetriary">
                Tetriary
              </Button>

              <Button size={"s"} clazz="button--green">
                Green
              </Button>

              <Button size={"s"} clazz="button--green__secondary">
                Green Secondary
              </Button>

              <Button size={"s"} clazz="button--green-accent">
                Green Accent
              </Button>

              <Button size={"s"} clazz="button_outline--black">
                Outline Black
              </Button>

              <Button size={"s"} clazz="button_outline--green">
                Outline Green
              </Button>

              <Button size={"s"} clazz="button_outline--green-accent">
                Outline Green Accent
              </Button>

              <Button size={"s"} clazz="button_outline--spec-yellow">
                Outline Spec Yellow
              </Button>

              <Button size={"s"} clazz="button_outline--spec-green">
                Outline Spec Green
              </Button>

              <Button size={"s"} clazz="button_no-fill">
                No fill
              </Button>

              <Button size={"s"} disabled clazz="button--tetriary">
                Disabled
              </Button>
            </div>

            <div className={"d-flex fd-column gap-10"}>
              <span className={"mt-12"}>Размеры:</span>

              <Button size={"xs"} clazz="button--black">
                xs
              </Button>
              <Button size={"s"} clazz="button--primary">
                s
              </Button>
              <Button size={"m"} clazz="button--error">
                m
              </Button>
              <Button size={"l"} clazz="button--secondary">
                l
              </Button>

              <span>Квадратные:</span>

              <Button size={"2xs"} clazz="button--secondary" square>
                2xs
              </Button>

              <Button size={"xs"} clazz="button--green" square>
                xs
              </Button>

              <Button size={"s"} clazz="button--primary" square>
                s
              </Button>

              <Button size={"m"} clazz="button--error" square>
                m
              </Button>

              <Button size={"l"} clazz="button--secondary" square>
                l
              </Button>
            </div>
          </div>

          <div className="ui-kit__icons">
            {icons_db.map(i => (
              <div>
                <img src={i.img} alt="#"/>
                <Icon size={'l'} spritePath={i.name}/>
                {i.name}
              </div>
            ))}
          </div>


            <div className="girl-cards">
            {sliderData.map((data) => {
              return (
                  <GirlCard props={data} />
              );
            })}
            </div>
          </div>

      </div>
    </main>
  );
};

export default UIKitPage;
