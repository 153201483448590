import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";

import showToast from "../../../../../../toast/Toast.js";

import {
  Button,
  InputInLabel,
  Select,
  Input,
  Icon,
  Checkbox,
  Loader,
} from "@/components/ui";
import { number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useLadyService from "@/services/LadyService.js";

import "./main.scss";
import { useState, useEffect, useRef, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import Map from "../../map/Map.js";
import {
  getOneProfileAddress,
  getOneProfileCoords,
  setOneProfileAddress,
  setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice.js";
import {
  getLanguagesWords,
  getNationalistWords,
  getUserCountry,
} from "@/stores/slices/userSlice.js";
import { makeRequest } from "@/services/makeRequest.js";
import debounce from "lodash.debounce";
import Helmet from "react-helmet";

const options = {
  readyTravel: [
    { id: 94, title: "Нет", value: "false" },
    { id: 93, title: "В ближние города", value: "nearby-city" },
    { id: 33, title: "По стране", value: "country" },
    { id: 32, title: "По Европе", value: "europe" },
    { id: 31, title: "По миру", value: "world" },
  ],
  ethnos: [
    { id: 99, title: "белая", value: "white" },
    { id: 98, title: "азиатская", value: "asian" },
    { id: 97, title: "африканская", value: "african" },
    { id: 96, title: "арабская", value: "arab" },
    { id: 95, title: "индийская", value: "hindu" },
    { id: 94, title: "мулатка", value: "mulatto" },
    { id: 93, title: "смешанная", value: "mix" },
    { id: 93, title: "латинская", value: "latin" },
  ],
};

const MainPopup = ({ open, data: girlData, setOpen, setData: setMainData }) => {
  const {
    name: nameData,
    nick: nickData,
    age: ageData,
    city: cityData,
    languages: languagesData,
    slug,
    skin_color,
    nationality: nationalityData,
  } = girlData;

  const dispatch = useDispatch();
  const address = useSelector(getOneProfileAddress);
  const coords = useSelector(getOneProfileCoords);
  const [selectedCity, setSelectedCity] = useState(cityData ?? "");
  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");
  const [langs, setLangs] = useState([]);
  const [langsCode, setLangsCode] = useState([]);
  const [formCity, setFormCity] = useState({
    id: girlData.city_id ?? "",
    title: cityData ?? "",
    icon: "CZ",
  });
  const langsArr = useSelector(getLanguagesWords);

  const [langsError, setLangsError] = useState("");
  const [cityError, setCityError] = useState("");

  const [phoneValue, setPhoneValues] = useState("");
  const [slugValue, setSlugValue] = useState(slug);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("user");
  const [data, setData] = useState({});
  const { phone, whatsapp, telegram } = data;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/info`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        if (data) {
          const result = data.data;
          setData(result);
        }
        setIsLoading(false);
      } catch (error) {}
    };

    token && fetchData();
  }, [token]);

  const formSchema = object().shape({
    name: string().required(t("required")),
    // nick: string().required(t("required")),
    age: number()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      )
      .nullable()
      .required(t("required"))
      .min(18, `${indi("minage")} 18`)
      .max(99, `${indi("maxage")} 99`)
      .nullable(true),
    tel: string().test("phone-is-empty", t("phonenumerrequired"), function () {
      return !!phoneValue;
    }),
  });

  const currentCountry = useSelector(getUserCountry);

  const [langPopup, setLangPopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [isAddressActive, setIsAddressActive] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
    defaultValues: { phone, whatsapp, telegram },
  });

  useEffect(() => {
    setValue("name", nameData);
    setValue("nick", nickData);
    setValue("age", ageData);
    setSelectedCity(cityData);
  }, []);

  useEffect(() => {
    if (!!langsArr.length) {
      const keys = languagesData.map((obj) => Object.keys(obj)[0]);
      const filteredLanguages = langsArr.filter((language) =>
        keys?.includes(String(language.id))
      );
      setLangs(filteredLanguages);

      const filteredLangsCode = filteredLanguages.map(
        (lang) => lang.short_name
      );
      setLangsCode(filteredLangsCode);
    }
  }, [langsArr]);

  useEffect(() => {
    if (!!currentCountry.length) {
      const filteredCity = currentCountry.filter(
        (city) => city.title === cityData
      );
      setFormCity(filteredCity[0]);
    }
  }, [currentCountry]);

  useEffect(() => {
    setIsLoading(true);
    setValue("phone", phone);
    setValue("whatsapp", whatsapp);
    setValue("telegram", telegram);
    setPhoneValues(phone);
    setIsLoading(false);
  }, [phone, setValue]);

  const validateData = () => {
    setLangsError("");
    setCityError("");
    if (langs.length === 0) {
      setLangsError("Языки обязательны");
    }
    if (!selectedCity) {
      setCityError("Город обязателен");
    }
  };

  const { lang } = useLadyService();

  const nationalsArr = useSelector(getNationalistWords);
  const transformedCountries =
    nationalsArr?.length > 0 &&
    nationalsArr.map((country) => ({
      icon: country.code.toUpperCase(),
      name: country.name,
      title: country.name,
      id: country.id,
    }));

  const [ethnos, setEthnos] = useState(skin_color ?? "");
  const [nationality, setNationality] = useState(nationalityData);
  const [readyTravel, setReadyTravel] = useState("");

  console.log("nationality", nationality);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const route = `user/profile/${slug}`;
        const method = "PUT";
        const payload = {
          lang,
          isFull: true,
          ...(!!data.whatsapp && { whatsapp: String(data.whatsapp) }),
          ...(!!data.telegram && { telegram: data.telegram }),
          name: data.name,
          // nick: nickData.substring(nickData.lastIndexOf("/") + 1),
          slug: slugValue,
          phone: data.tel ? String(data.tel) : String(phoneValue),
          age: data.age,
          languages: langs.map((item) => item.id),
          city: currentCountry.filter(
            (item) => item.title.toLowerCase() === selectedCity.toLowerCase()
          )[0].id,
          ...(!!address && address[0] && { address: address[0] }),
          ...(!!coords?.latitude && { latitude: coords?.latitude }),
          ...(!!coords?.longitude && { longitude: coords?.longitude }),
          ...(!!ethnos && { skin_color: ethnos }),
          ...(!!nationality && {
            nationality: transformedCountries.filter(
              (i) => i.name === nationality
            )[0].id,
          }),
          ...(!!readyTravel && { ready_travel: readyTravel }),
        };

        const responseData = await makeRequest({ route, method, payload });

        const keys = [
          "name",
          "phone",
          "age",
          "languages",
          "city",
          "address",
          "latitude",
          "latitude",
          "longitude",
          "skin_color",
          "nationality",
          "ready_travel",
        ];

        setMainData((prevState) => {
          for (const key of keys) {
            if (responseData.profile.hasOwnProperty(key)) {
              prevState[key] = responseData.profile[key];
            }
          }
          return { ...prevState };
        });
        setIsLoading(false);
        handleClose();
        showToast({
          message: indi("success"),
          variant: "succes",
        });
      } catch (error) {
        showToast({
          message: indi("oops"),
          variant: "error",
        });
      }
    };

    token && fetchData();
  };

  const handleLangOpen = () => {
    setLangPopup(!langPopup);
  };

  const removeLang = (code) => {
    const updateLangs = langs.filter((item) => item.short_name !== code);
    const updateLangsCode = langsCode.filter((item) => item !== code);

    setLangs(updateLangs);
    setLangsCode(updateLangsCode);
  };

  const toggleLang = (item) => {
    const lang = {
      id: Number(item.id),
      short_name: item.short_name,
      name: item.name,
    };
    const langIndex = langs.findIndex(
      (item) => item.short_name === lang.short_name
    );

    if (langIndex === -1) {
      setLangs([...langs, lang]);
      setLangsCode([...langsCode, item.short_name]);
    } else if (langIndex !== -1) {
      const updatedLangs = [...langs];
      updatedLangs.splice(langIndex, 1);
      setLangs(updatedLangs);
      const resultArr = [...langsCode];
      resultArr.splice(langIndex, 1);
      setLangsCode(resultArr);
    }
  };

  const updateValue = (e) => {
    const targetValue = e.target.value;
    setPhoneValues(targetValue);
  };

  const handleLocationClick = () => {
    setIsAddressActive(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const { latitude, longitude } = position.coords;
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
          );
          const { city, road, house_number } = response.data.address;
          dispatch(
            setOneProfileAddress(
              `${city && city}, ${road} ${
                house_number ? ", " + house_number : ""
              }`
            )
          );
          dispatch(setOneProfileCoords({ latitude, longitude }));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target) &&
      !event.target.classList.contains("create-lang")
    ) {
      setLangPopup(false);
      event.stopPropagation();
    }
  };
  const selectRef = useRef(null);

  const ErrorMessage = ({ message }) => {
    return <span class="error create-error">{message}</span>;
  };

  const [nickValue, setNickValue] = useState(nickData);

  const createSlug = useCallback(
    debounce((value) => {
      const fetchData = async () => {
        try {
          const route = `user/profile/slug/${value}`;
          const method = "GET";

          const data = await makeRequest({ route, method });
          if (data) {
            if (data.status) {
              checkNickIsAvailable(value);
              setValue("nick", value);
              setSlugValue(value);
            }
            if (data.slug) {
              checkNickIsAvailable(data.slug);
              setValue("nick", data.slug);
              setSlugValue(data.slug);
            }
          }
        } catch (error) {}
      };

      token && fetchData();
    }, 1000),
    []
  );
  const createProfileSlug = (e) => {
    createSlug(e.target.value);
  };
  const checkNick = useCallback(
    debounce((value) => {
      const fetchData = async () => {
        try {
          const route = `user/profile/check-slug/${value}`;
          const method = "GET";

          const data = await makeRequest({ route, method });
          if (data) {
            const status = data.status;
            if (!status) {
              const alternative = data.alternative;
              setError("nick", {
                message: `Такой ник уже занят, доступен ${alternative}`,
              });
            } else {
              clearErrors("nick");
            }
          }
        } catch (error) {}
      };

      token && fetchData();
    }, 750),
    []
  );

  const checkNickIsAvailable = (value) => {
    // let inputValue = e.target.value;
    let nick = value.substring(value.lastIndexOf("/") + 1);
    nick = nick.replace(/[^a-zA-Z0-9-]/g, "");
    if (nick === "lady4lovecom") {
      setNickValue("");
    } else {
      setNickValue(`https://lady4love.com/${nick}`);
    }
    checkNick(nick);
  };

  return (
    <>
      {isLoading && (
        <div className="loader__overlay">
          <Loader height={100} />
        </div>
      )}

      <dialog open={open} onClick={handleClose}>
        {open ? (
          <Helmet>
            <html className={"lock"}></html>
          </Helmet>
        ) : null}

        <form
          onSubmit={handleSubmit(onSubmit)}
          onClick={(e) => e.stopPropagation()}
          className={`popup-form__body__edit`}
        >
          <h3 className={`create-page__main--title`}>{indi("main")}</h3>
          <div className={`create-page__form main mb-0`}>
            <Button onClick={handleClose} clazz={"button__close"}>
              <Icon size={"l"} spritePath={"close"} />
            </Button>
            <InputInLabel
              type={"text"}
              id={"create-name"}
              placeholder={"Анастасия"}
              register={{ ...register("name") }}
              clazz={`_required ${errors.name && "_error"}`}
              onChange={(e) => createProfileSlug(e)}
            >
              {"Имя в анкете"}
              {errors.name && <ErrorMessage message={t("required")} />}
            </InputInLabel>

            <InputInLabel
              type={"text"}
              id={"create-nick"}
              placeholder={"https://lady4love.com/Vash-Nic"}
              register={{ ...register("nick") }}
              clazz={`_required ${errors.nick && "_error"}`}
              onChange={(e) => checkNickIsAvailable(e.target.value)}
              value={`https://lady4love.com/${slug}`}
            >
              {"Ник"}
              {errors.nick && <ErrorMessage message={errors.nick.message} />}
            </InputInLabel>
            <InputInLabel
              type={"number"}
              id={"create-age"}
              value={null}
              placeholder={"18-99"}
              register={{ ...register("age") }}
              clazz={`_required ${errors.age && "_error"}`}
            >
              {"Возраст"}
              {errors.age && <ErrorMessage message={errors.age.message} />}
            </InputInLabel>
            <InputInLabel
              type={"input"}
              id={"create-phone"}
              placeholder={"+XXX XXX XXX XXX"}
              register={{ ...register("phone") }}
              autocomplete={"phone"}
              clazz={`_required ${errors.tel && "_error"}`}
              onChange={(e) => updateValue(e)}
            >
              {"Телефон"}
              {errors.phone && <ErrorMessage message={t("required")} />}
            </InputInLabel>
            <InputInLabel
              type={"text"}
              id={"create-whatsapp"}
              placeholder={""}
              register={{ ...register("whatsapp") }}
              autocomplete={"whatsapp"}
            >
              {"Whatsapp"}
            </InputInLabel>
            <InputInLabel
              type={"text"}
              id={"create-telegram"}
              placeholder={""}
              register={{ ...register("telegram") }}
              autocomplete={"telegram"}
            >
              {"Телеграм"}
            </InputInLabel>
            <Select
              onChange={setEthnos}
              name={"create-ethnos"}
              options={options.ethnos}
              clazz={"button button-xs button--main"}
              arrowSize={"m"}
              subTitle={"Этнос (цвет кожи)"}
              defaultTitle={
                skin_color
                  ? options.ethnos.filter((i) => i.value === skin_color)[0]
                      .title
                  : ""
              }
              withoutTitle={true}
              postApi={true}
            />
            <Select
              onChange={setNationality}
              name={"create-nationalist"}
              options={transformedCountries?.length > 0 && transformedCountries}
              clazz={"button button-xs button--main create-nations"}
              clazzSvg={"select__flag"}
              arrowSize={"m"}
              isSearch={true}
              searchPlaceHolder={"Поиск по национальностям"}
              type={"flag"}
              subTitle={"Национальность"}
              defaultTitle={nationality}
              withoutTitle={true}
              postApi={true}
              withoutIcon={!nationality}
            />
            <Select
              onChange={setReadyTravel}
              name={"create-readyTravel"}
              options={options.readyTravel}
              clazz={"button button-xs button--main"}
              arrowSize={"m"}
              subTitle={"Готова к путешествиям"}
              defaultTitle={""}
              withoutTitle={true}
              postApi={true}
            />
            <div className={`create__select-lang ${langPopup && "_active"}`}>
              <Button
                clazz={`button--secondary justify-center create-lang _required ${
                  langsError ? "_error" : ""
                }`}
                size={"s"}
                onClick={() => handleLangOpen()}
              >
                {indi("languages")}
                <Icon
                  clazz={"select__arrow"}
                  size={"m"}
                  spritePath={"chevrone-down"}
                />
                {langsError && <ErrorMessage message={langsError} />}
              </Button>

              {langPopup && (
                <div
                  ref={selectRef}
                  className={`create__select-popup text-center `}
                >
                  <label for="citiesSearch" className="select__search">
                    <Input
                      type="search"
                      placeholder={"Поиск"}
                      id="citiesSearch"
                      clazz={"search__input"}
                      name={"create-cities"}
                      value={searchValue}
                      setValue={setSearchValue}
                    />
                  </label>

                  <div className="cities__search-steady">
                    <Checkbox
                      border={false}
                      flag={"GB"}
                      key={"EN"}
                      name={"Great Britain"}
                      id={"EN"}
                      value={"Great Britain"}
                      title={"EN"}
                      checked={langsCode.includes("gb")}
                      onChange={(e) =>
                        toggleLang(
                          { id: 2, short_name: "gb", name: "Английский" },
                          e
                        )
                      }
                    />
                    <Checkbox
                      border={false}
                      flag={"CZ"}
                      key={"CZ"}
                      name={"Czechia"}
                      id={"CZ"}
                      value={"Czechia"}
                      title={"CZ"}
                      checked={langsCode.includes("cz")}
                      onChange={(e) =>
                        toggleLang(
                          { id: 3, short_name: "cz", name: "Чешский" },
                          e
                        )
                      }
                    />
                    <Checkbox
                      border={false}
                      flag={"RU"}
                      key={"RU"}
                      name={"Russia"}
                      id={"RU"}
                      value={"Russia"}
                      checked={langsCode.includes("ru")}
                      title={"RU"}
                      onChange={(e) =>
                        toggleLang(
                          { id: 1, short_name: "ru", name: "Русский" },
                          e
                        )
                      }
                    />
                  </div>

                  <div className="cities__search-select">
                    {langsArr?.length > 0 &&
                    langsArr.filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    ).length > 0 ? (
                      langsArr
                        .filter((item) =>
                          item.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        )
                        .map((item) => (
                          <Checkbox
                            border={false}
                            flag={item.short_name.toUpperCase()}
                            key={item.short_name.toUpperCase()}
                            name={item.name}
                            id={item.id}
                            value={item.short_name}
                            title={item.name}
                            checked={
                              langsCode.includes(item.short_name) ? true : false
                            }
                            onChange={() => toggleLang(item)}
                          />
                        ))
                    ) : (
                      <span className="p2">{indi("nolanguages")}</span>
                    )}
                  </div>
                </div>
              )}
            </div>

            {langs.length > 0 && (
              <div className="create-lang--current">
                {langs.map((lang) => (
                  <div class="select__item" tabindex="0">
                    {lang.name}
                    <i
                      class={`icon-flag icon-flag-m ${lang.short_name.toUpperCase()}`}
                    ></i>
                    <i
                      class={`icon icon-m icon--close`}
                      onClick={() => removeLang(lang.short_name)}
                    ></i>
                  </div>
                ))}
              </div>
            )}
            <div
              className={`create-page__city-block ${cityError ? "_error" : ""}`}
            >
              <Select
                name={"city-create"}
                options={currentCountry}
                clazz={"select-city _required"}
                clazzSvg={"select__flag"}
                arrowSize={"m"}
                type={"flag"}
                postApi={formCity?.icon ? false : true}
                subTitle={indi("yourcity")}
                defaultTitle={formCity?.title}
                withoutIcon={!selectedCity}
                withoutTitle={formCity?.title ? false : true}
                setValue={setSelectedCity}
              />
              {cityError && <ErrorMessage message={cityError} />}
            </div>

            <div className="search filter__map-input">
              <span className={"filter__map-title"}>{t("youraddress")}</span>
              <label
                htmlFor="geosuggest__input-create"
                className="search__label header__search"
              >
                <Input
                  type="text"
                  placeholder="Полный адрес"
                  name="location-create"
                  id="geosuggest__input-create"
                  clazz={"header__search-input"}
                  valueFromSearch={address}
                />
              </label>
              <Button
                title={t("chekmyplace")}
                onClick={() => handleLocationClick()}
                name={"location"}
                square={true}
                buttonType={"button"}
                size={"l"}
                clazz={
                  isAddressActive
                    ? "search__icon button--tetriary active"
                    : "search__icon button--tetriary"
                }
              >
                <Icon size={"xl"} spritePath={"location"} />
              </Button>
            </div>
            <span className="create-page__noted">
              {indi("displayonsite")}
              <span className="create-page__noted-colored">
                {" "}
                +<span>35</span>% {indi("toviews")}, +<span>15</span>%{" "}
                {indi("toorders")}
              </span>
            </span>
            {selectedCity && <Map city={selectedCity} />}
            <div className="create-form__buttons">
              <Button
                size={"l"}
                square={false}
                clazz="button--secondary"
                onClick={handleClose}
              >
                Отменить
              </Button>
              <Button
                buttonType={"submit"}
                size={"l"}
                square={false}
                clazz="button--green"
              >
                Сохранить
              </Button>
            </div>
          </div>
        </form>
      </dialog>
    </>
  );
};

export default MainPopup;
