import "./connectPromotionAd.scss";

import { Button, Checkbox, DateInput } from "@/components/ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import PopupContainer from "../PopupContainer.tsx";
import MinBalancePopup from "../minBalance/MinBalancePopup";

const ConnectPromotionAdPopup = (props) => {
  const { setOpen, position, price, currentPrice, slugProfile } = props;

  const [zeroBalance, setZeroBalance] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [full, setFull] = useState(true);
  const [date, setDate] = useState(null);

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");

  const onChangeFull = () => {
    setFull((prev) => !prev);
  };

  const onChangeDate = (value) => {
    setDate(value);
    setFull(false);
  };

  const validNew = indi("valid")[0].toUpperCase() + indi("valid").slice(1);

  const dt_obj = new Date(date);
  const ts_unix = dt_obj.getTime() / 1000;

  const dateRes = full ? null : !!date ? ts_unix : null;

  const onSubmit = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `places/up/${slugProfile}`;

        const method = "POST";
        const payload = {
          price,
          ...(dateRes && { expired_at: dateRes }),
        };

        const res = await makeRequest({ route, method, payload });

        if (res.message === "balance_insufficient") {
          setIsLoading(false);
          setZeroBalance(true);
        } else {
          setIsLoading(false);
          showToast({
            message: indi("success"),
            variant: "succes",
          });
          setOpen(false);
        }
      } catch (error) {}
    };

    fetchData();
  };

  const Tilte = () => {
    return (
      <>
        {indi("promoteadto")}
        <p className="color-green mt-8">
          {position}
          {indi("th")} {t("place")}
        </p>
      </>
    );
  };

  return (
    <PopupContainer {...props} title={<Tilte />} isLoading={isLoading}>
      {zeroBalance && (
        <MinBalancePopup setOpen={setZeroBalance} open={zeroBalance} />
      )}

      <span className={"color-main text-center p2"}>{indi("adplace")}</span>

      <div className="connectad">
        <div className="connectad__day">
          <span className="p2 color-main">{indi("dailycharges")}</span>
          <span className="title_h3 mb-0 color-main w-mc">{`${price} €`}</span>
          {!!currentPrice && <span className="p2">{t("paynow")}</span>}
          {!!currentPrice && (
            <span className="title_h3 mb-0 w-mc">{`${currentPrice} €`}</span>
          )}
        </div>
        <div className="connectad__form">
          <div className="connectad__form__check">
            <span className="p2 color-main">{validNew}</span>
            <Checkbox
              border={false}
              title={indi("indefinitely")}
              onChange={onChangeFull}
              checked={full}
            />
          </div>

          <DateInput onChange={onChangeDate}>{indi("untildate")}</DateInput>
        </div>
      </div>

      <Button
        size={"l"}
        clazz={"button--green justify-center"}
        disabled={!full && !date}
        onClick={(e) => onSubmit(e)}
      >
        {indi("confirm")}
      </Button>
    </PopupContainer>
  );
};

export default ConnectPromotionAdPopup;
