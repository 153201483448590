import axios from 'axios';

const useVideoCallService = () => {
    const BASE_API_URL = `https://${process.env.REACT_APP_CALL_URL}`
    const token = localStorage.getItem("user");

    // create video call room
    function createVideoCallRoom(profile) {
        return axios.post(`${BASE_API_URL}/create`, {profile}, {headers: { Authorization: `Bearer ${token}`,},})
    }

    return {
        createVideoCallRoom,
    }
}

export default useVideoCallService