import { GoogleMap } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGmapsApiReady } from "@/stores/slices/mapModelSlice";
import {
  setOneProfileAddress,
  setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";

const Map = ({ city, setBounds }) => {
  const [map, setMap] = useState(null);
  const [render, setRender] = useState(false);
  const apiReady = useSelector(getGmapsApiReady);
  const dispatch = useDispatch();

  useEffect(() => {
    if (apiReady) {
      setRender(true);
    }
  }, [apiReady]);

  const mapStyles = {
    height: "100%",
    width: "100%",
    borderRadius: "20px",
  };
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  useEffect(() => {
    if (map) {
      const geocoder = new window.google.maps.Geocoder();

      const handleGeocodeResults = (results, status) => {
        if (status === "OK") {
          const { geometry } = results[0];
          const { location } = geometry;
          const lat = location.lat();
          const lng = location.lng();
          map.setCenter({ lat, lng });
        } else {
          console.error("Ошибка при получении координат города:", status);
          map.setCenter({ lat: 49.8175, lng: 15.473 });
        }
      };

      geocoder.geocode({ address: city }, handleGeocodeResults);
    }
  }, [map, city]);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setCoordinates({ lat, lng });
    dispatch(setOneProfileCoords({ lat, lng }));
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK" && results[0]) {
        // const cityCenter = { lat: latlng.lat, lng: latlng.lng };
        const cityCenter = new window.google.maps.LatLng(lat, lng);
        setBounds(cityCenter);
        setAddress(results[0].formatted_address);
        dispatch(setOneProfileAddress(results[0].formatted_address));
      } else {
        console.error("Ошибка при обратном геокодировании:", status);
        setAddress("");
      }
    });
  };

  return (
    <div className="create-page__map">
      {render && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={14}
          onLoad={(map) => setMap(map)}
          lang={"en"}
          onClick={handleMapClick}
        ></GoogleMap>
      )}
    </div>
  );
};

export default Map;
