import IndiHeaderLk from "../../header/IndiHeaderLk";
import Footer from "../../footer/Footer";

import Helmet from "react-helmet";
import { Outlet } from "react-router-dom";

import { Favicon } from "@/components/ui";
import { useTranslation } from "react-i18next";

const IndiLayoutLk = ({ titleParams, postDesc }) => {
  const { t } = useTranslation("translation");

  return (
    <>
      <Favicon />
      <Helmet>
        <title>{titleParams}</title>
        <meta name="description" content={postDesc} />
      </Helmet>
      <IndiHeaderLk />
      <Outlet />
      <Footer />
    </>
  );
};

export default IndiLayoutLk;
