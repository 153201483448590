import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon, Select, InputInLabel } from "@/components/ui";
import MultiRangeSlider from "multi-range-slider-react";
import bodyShape from "../../../../../../../assets/img/body_shape.svg";
import bodyShapeLight from "../../../../../../../assets/img/body_shape-light.svg";
import useLadyService from "@/services/LadyService";
import "./appearance.scss";
import showToast from "../../../../../../toast/Toast";
import { Loader } from "@/components/ui";

import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";

import { useForm } from "react-hook-form";

const options = {
  tatu: [
    { id: 92, title: "нет", value: "no" },
    { id: 91, title: "мало", value: "few" },
    { id: 90, title: "много", value: "many" },
  ],
  smoking: [
    { id: 89, title: "нет", value: "no" },
    { id: 88, title: "иногда", value: "sometimes" },
    { id: 87, title: "часто", value: "regularly" },
  ],
  breast: [
    { id: 1, title: "0(A)", value: "AA" },
    { id: 2, title: "1(A)", value: "A" },
    { id: 3, title: "2(C)", value: "B" },
    { id: 4, title: "3(C)", value: "C" },
    { id: 5, title: "4(D)", value: "D" },
    { id: 6, title: "5+(D)", value: "E" },
  ],
  penis: [
    { id: 101, title: "до 10 см", value: "A" },
    { id: 102, title: "10-15 см", value: "B" },
    { id: 103, title: "16-20 см", value: "C" },
    { id: 104, title: "21-25 см", value: "D" },
    { id: 105, title: "25+ см", value: "E" },
  ],
  hair: [
    { id: 7, title: "блондинка", value: "blonde" },
    { id: 8, title: "шатенка", value: "brown" },
    { id: 8, title: "брюнетка", value: "brunette" },
    { id: 10, title: "рыжая", value: "red" },
  ],
  hairIntim: [
    {
      id: "create-shaved",
      title: "бритые",
      value: "shaved",
    },
    {
      id: "create-haircut",
      title: "стрижка",
      value: "haircut",
    },
    {
      id: "create-intimNature",
      title: "натуральные",
      value: "natural",
    },
  ],
  eyes: [
    {
      id: "create-eyes-blue",
      title: "голубые",
      value: "blue",
    },
    {
      id: "create-eyes-green",
      title: "зеленые",
      value: "green",
    },
    {
      id: "create-eyes-brown",
      title: "карие",
      value: "brown",
    },
    {
      id: "create-eyes-grey",
      title: "серые",
      value: "gray",
    },
  ],
  leaps: [
    {
      id: "create-leaps-type",
      title: "натуральные",
      value: "natural",
    },
    {
      id: "create-leaps-type",
      title: "накачанные",
      value: "pumped_up",
    },
  ],

  gender: [
    { id: 67, title: "Я девушка", value: "female" },
    { id: 68, title: "Я парень", value: "male" },
    { id: 68, title: "Я транс", value: "trans" },
    { id: 69, title: "Я пара", value: "couple" },
  ],
};

const data = {
  breastType: [
    {
      id: "create-nature",
      title: "натуральная",
      name: "breastType",
      value: "nature",
    },
    {
      id: "create-sil",
      title: "силиконовая",
      name: "breastType",
      value: "sil",
    },
  ],
  hairType: [
    {
      id: "create-short",
      title: "короткие",
      name: "hairType",
      value: "short",
    },
    {
      id: "create-middle",
      title: "средние",
      name: "hairType",
      value: "medium",
    },
    {
      id: "create-long",
      title: "длинные",
      name: "hairType",
      value: "long",
    },
  ],
  orientation1: [
    { id: 56, title: "Гетеро", value: "straight" },
    { id: 27, title: "лесби", value: "lesbian" },
    { id: 54, title: "Би", value: "bisexual" },
  ],
  orientation2: [
    { id: 87, title: "Гетеро", value: "straight" },
    { id: 86, title: "Гомо", value: "homosexual" },
    { id: 85, title: "Би", value: "bisexual" },
  ],
  eyesType: [
    {
      id: "create-eyes-lenses",
      title: "цветные линзы",
      name: "eyes-type",
      value: "lenses",
    },
    {
      id: "create-eyes-glasses",
      title: "очки",
      name: "eyes-type",
      value: "glasses",
    },
  ],
};

const SelectWithButtons = ({
  children,
  withoutSelect = false,
  buttons,
  buttonValue,
  type = "checkbox",
  defaultValue,
}) => {
  const [selectedButton, setSelectedButton] = useState(
    type === "radio"
      ? defaultValue ?? null
      : !!defaultValue?.length
      ? defaultValue
      : []
  );

  const handleButtonClick = (value) => {
    if (type === "radio") {
      setSelectedButton(value);
      buttonValue(value);
    } else {
      const isIdAlreadySelected = selectedButton.includes(value);

      if (isIdAlreadySelected) {
        setSelectedButton((prev) => prev.filter((i) => i !== value));
        buttonValue((prev) => prev.filter((i) => i !== value));
      } else {
        setSelectedButton((prev) => [...prev, value]);
        buttonValue((prev) => [...prev, value]);
      }
    }
  };

  return (
    <div className="create-page__input-container">
      {!withoutSelect && children}
      <div className="create-page__input-buttons">
        {buttons.map((i) => {
          return (
            <label
              className={`checkbox-button${
                type === "radio"
                  ? selectedButton === i.value
                    ? " checkbox-button_checked"
                    : ""
                  : selectedButton.includes(i.value)
                  ? " checkbox-button_checked"
                  : ""
              }`}
              key={i.id}
              htmlFor={i.id}
              tabIndex={0}
            >
              <input
                onClick={() => handleButtonClick(i.value)}
                tabIndex={1}
                id={i.id}
                type={type}
                name={i.name}
                checked={
                  type === "radio"
                    ? selectedButton === i.value || selectedButton === i.id
                    : selectedButton.includes(i.value || i.id)
                }
                value={i.value}
              />
              {i.title}
            </label>
          );
        })}
      </div>
    </div>
  );
};

const CreateRange = ({ min, max, defaultValue, setData }) => {
  const [value, setValue] = useState(defaultValue ?? 0);
  const [maxValue, setMaxValue] = useState(defaultValue ?? 0);

  const handleInputChange = (e) => {
    setMaxValue(e.target.value);
  };

  const handleSliderChange = (e) => {
    setData(e.maxValue);
    setValue(e.maxValue);
    setMaxValue(e.maxValue);
  };

  return (
    <div className="create-range">
      <input type="number" value={value} onChange={handleInputChange}></input>
      <MultiRangeSlider
        min={min}
        max={max}
        maxValue={maxValue}
        minValue={0}
        step={1}
        stepOnly={true}
        className={"create-range__input"}
        ruler={false}
        label={false}
        onInput={handleSliderChange}
      />
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return <span class="error create-error">{message}</span>;
};

const AppearancePopup = (props) => {
  const { open, setOpen, data: girlData, setData } = props;

  const [isLoading, setIsLoading] = useState(false);

  const {
    slug,
    piercing: piercingData,
    silicone: siliconeData,
    height: heightData,
    weight: weightData,
    tatoo: tatooData,
    perfume: perfumeData,
    lips: lipsData,
    shoulders: shouldersData,
    hips: hipsData,
    waist: waistData,
    eyes: eyesData,
    smoking: smokingData,
    orientation: orientationData,
    gender: genderData,
    penis: penisData,
    intim_hairstyle: intim_hairstyleData,
    hair: hairData,
    hair_length: hair_lengthData,
    have_glasses: have_glassesData,
    have_lenses: have_lensesData,
    breast_size: breast_sizeData,
  } = girlData;

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const token = localStorage.getItem("user");

  const { theme, lang } = useLadyService();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onSubmit",
  });

  const [breastType, setBreastType] = useState(breast_sizeData);
  const [isBreastSil, setIsBreastSil] = useState(
    siliconeData ? "sil" : "nature"
  );
  const [hairType, setHairType] = useState(hairData);
  const [hairLength, setHairLength] = useState(hair_lengthData);
  const [hairIntim, setHairIntim] = useState(intim_hairstyleData);
  const [eyesColor, setEyesColor] = useState(eyesData);
  const [eyesType, setEyesType] = useState(() => {
    const types = [];
    if (have_glassesData) {
      types.push("glasses");
    }
    if (have_lensesData) {
      types.push("lenses");
    }
    return types;
  });
  const [lips, setLips] = useState(lipsData);
  const [tatoo, setTatoo] = useState(tatooData);
  const [piercing, setPiercing] = useState(piercingData);
  const [smoking, setSmoking] = useState(smokingData);
  const [shoulders, setShoulders] = useState(shouldersData);
  const [hips, setHips] = useState(hipsData);
  const [waist, setWaist] = useState(waistData);
  const [orientation, setOrientation] = useState(orientationData);
  const [gender, setGender] = useState(genderData);
  const [penis, setPenis] = useState(penisData);

  const onSubmit = (data, e) => {
    e.preventDefault();
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/profile/${slug}`;
        const method = "PUT";
        const payload = {
          lang,
          isFull: true,
          city: data.city,
          height: data.height,
          weight: data.weight,
          ...(!!data.perfume && { perfume: data.perfume }),
          ...(!!breastType && { breast: breastType }),
          ...(!!isBreastSil && { silicone: isBreastSil === "sil" }),
          ...(!!hairType && { hair: hairType }),
          ...(!!hairLength && { hair_length: hairLength }),
          ...(!!hairIntim && { intim_hairstyle: hairIntim }),
          ...(!!eyesColor && { eyes: eyesColor }),
          have_glasses: eyesType.includes("glasses") ? 1 : 0,
          have_lenses: eyesType.includes("lenses") ? 1 : 0,
          ...(!!lips && { lips: lips }),
          ...(!!orientation && { orientation: orientation }),
          ...(!!gender && { gender: gender }),
          ...(!!tatoo && { tatoo: tatoo }),
          ...(!!piercing && { piercing: piercing }),
          ...(!!smoking && { smoking: smoking }),
          ...(!!shoulders && { shoulders: String(shoulders) }),
          ...(!!hips && { hips: String(hips) }),
          ...(!!waist && { waist: String(waist) }),
        };

        const responseData = await makeRequest({ route, method, payload });

        const keys = [
          "healthy_at",
          "verified_at",
          "description",
          "height",
          "weight",
          "penis_size",
          "is_individual",
          "is_new",
          "tag_bdsm",
          "porn_star",
          "gender",
          "breast",
          "have_glasses",
          "have_lanses",
          "lips",
          "eyes",
          "smoking",
          "skin_color",
          "tatoo",
          "piercing",
          "hair",
          "hair_length",
          "intim_hairstyle",
          "perfume",
          "languages",
          "hips",
          "waist",
          "shoulders",
        ];

        setData((prevState) => {
          for (const key of keys) {
            if (responseData.profile.hasOwnProperty(key)) {
              prevState[key] = responseData.profile[key];
            }
          }
          return { ...prevState };
        });
        setIsLoading(false);

        handleClose();
        showToast({
          message: indi("success"),
          variant: "succes",
        });
      } catch (error) {
        showToast({
          message: indi("oops"),
          variant: "error",
        });
      }
    };

    token && fetchData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValue("height", heightData);
    setValue("weight", weightData);
    setValue("perfume", perfumeData);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loader__overlay">
          <Loader height={100} />
        </div>
      )}
      <dialog open={open} onClick={handleClose}>
        {open ? (
          <Helmet>
            <html className={"lock"}></html>
          </Helmet>
        ) : null}

        <form
          onClick={(e) => e.stopPropagation()}
          onSubmit={handleSubmit(onSubmit)}
          className={`popup-form__body__edit`}
        >
          <h3 className={`create-page__main--title mb-0`}>Внешность</h3>
          <div className="create-page__form appearance">
            <Button onClick={handleClose} clazz={"button__close"}>
              <Icon size={"l"} spritePath={"close"} />
            </Button>
            <div className="form-services__top-item">
              <SelectWithButtons
                type={"radio"}
                buttonValue={setOrientation}
                defaultValue={orientation}
                buttons={
                  gender === "female"
                    ? data.orientation1
                    : gender === "male" || gender === "trans"
                    ? data.orientation2
                    : data.orientation1
                }
              >
                <Select
                  onChange={setGender}
                  name={"create-gender"}
                  options={options.gender}
                  clazz={"button button-xs button--main"}
                  arrowSize={"m"}
                />
              </SelectWithButtons>
            </div>
            <div className="form-appearance__top-main">
              <div className="form-appearance__left">
                <div className="form-appearance__top">
                  <InputInLabel
                    type={"number"}
                    id={"create-height"}
                    value={null}
                    placeholder={"150"}
                    register={{ ...register("height") }}
                    clazz={`${errors.age && "_error"}`}
                  >
                    {"Рост, см"}
                    {errors.height && (
                      <ErrorMessage message={errors.height.message} />
                    )}
                  </InputInLabel>
                  <InputInLabel
                    type={"number"}
                    id={"create-weight"}
                    value={null}
                    placeholder={"50"}
                    register={{ ...register("weight") }}
                    clazz={`${errors.age && "_error"}`}
                  >
                    {"Вес, кг"}
                    {errors.weight && (
                      <ErrorMessage message={errors.weight.message} />
                    )}
                  </InputInLabel>
                  <SelectWithButtons
                    buttonValue={setIsBreastSil}
                    buttons={data.breastType}
                    type={"radio"}
                    defaultValue={isBreastSil}
                  >
                    <Select
                      onChange={
                        gender === "male" || gender === "trans"
                          ? setPenis
                          : setBreastType
                      }
                      name={"create-breast"}
                      options={
                        gender === "male" || gender === "trans"
                          ? options.penis
                          : options.breast
                      }
                      clazz={"button button-xs button--main"}
                      arrowSize={"m"}
                      defaultTitle={
                        gender === "male" || gender === "trans"
                          ? "Размер члена"
                          : breastType
                      }
                      withoutTitle={true}
                      postApi={true}
                      subTitle={
                        gender === "male" || gender === "trans"
                          ? "Размер члена"
                          : "Размер груди"
                      }
                    />
                  </SelectWithButtons>
                </div>
                <div className="form-appearance__bottom">
                  <SelectWithButtons
                    buttonValue={setHairLength}
                    buttons={data.hairType}
                    type={"radio"}
                    defaultValue={hair_lengthData}
                  >
                    <Select
                      onChange={setHairType}
                      name={"create-hair"}
                      options={options.hair}
                      clazz={"button button-xs button--main"}
                      arrowSize={"m"}
                      defaultTitle={hairData}
                      withoutTitle={true}
                      postApi={true}
                      subTitle={"Волосы"}
                    />
                  </SelectWithButtons>
                  <Select
                    onChange={setHairIntim}
                    name={"create-hairintim"}
                    options={options.hairIntim}
                    clazz={"button button-xs button--main"}
                    arrowSize={"m"}
                    subTitle={"Волосы в интимной зоне"}
                    defaultTitle={hairIntim}
                    withoutTitle={true}
                    postApi={true}
                  />
                  <SelectWithButtons
                    buttonValue={setEyesType}
                    buttons={data.eyesType}
                    defaultValue={eyesType}
                  >
                    <Select
                      onChange={setEyesColor}
                      name={"create-eyes"}
                      options={options.eyes}
                      clazz={"button button-xs button--main"}
                      arrowSize={"m"}
                      subTitle={"Глаза"}
                      defaultTitle={eyesColor}
                      withoutTitle={true}
                      postApi={true}
                    />
                  </SelectWithButtons>
                  <Select
                    onChange={setLips}
                    name={"create-hairintim"}
                    options={options.leaps}
                    clazz={"button button-xs button--main"}
                    arrowSize={"m"}
                    subTitle={"Губы"}
                    defaultTitle={lips}
                    withoutTitle={true}
                    postApi={true}
                  />
                </div>
              </div>
              <div className="form-appearance__right">
                <span className="mt-12">Пропорции</span>
                <img
                  src={theme === "light" ? bodyShapeLight : bodyShape}
                  alt=""
                />
                <div className="form-appearance__right-range">
                  <CreateRange
                    min={0}
                    max={140}
                    setData={setShoulders}
                    defaultValue={shouldersData}
                  />

                  <CreateRange
                    min={0}
                    max={160}
                    setData={setWaist}
                    defaultValue={waistData}
                  />
                  <CreateRange
                    min={0}
                    max={120}
                    setData={setHips}
                    defaultValue={hipsData}
                  />
                </div>
              </div>
            </div>
            <div className="form-appearance__grid">
              <Select
                onChange={setTatoo}
                name={"create-tatu"}
                options={options.tatu}
                clazz={"button button-xs button--main"}
                arrowSize={"m"}
                subTitle={"Тату"}
                defaultTitle={tatoo}
                withoutTitle={true}
                postApi={true}
              />
              <Select
                onChange={setPiercing}
                name={"create-pirsing"}
                options={options.tatu}
                clazz={"button button-xs button--main"}
                arrowSize={"m"}
                subTitle={"Пирсинг"}
                defaultTitle={piercingData}
                withoutTitle={true}
                postApi={true}
              />
              <Select
                onChange={setSmoking}
                name={"create-smoking"}
                options={options.smoking}
                clazz={"button button-xs button--main"}
                arrowSize={"m"}
                subTitle={"Курение"}
                defaultTitle={smokingData}
                withoutTitle={true}
                postApi={true}
              />
              <InputInLabel
                type={"text"}
                id={"create-perfume"}
                placeholder={"Вкусные, сладкие"}
                register={{ ...register("perfume") }}
              >
                {"Мои духи"}
              </InputInLabel>
            </div>

            <div className="create-form__buttons">
              <Button
                size={"l"}
                square={false}
                clazz="button--secondary"
                onClick={handleClose}
              >
                Отменить
              </Button>
              <Button
                buttonType={"submit"}
                size={"l"}
                square={false}
                clazz="button--green"
              >
                Сохранить
              </Button>
            </div>
          </div>
        </form>
      </dialog>
    </>
  );
};

export default AppearancePopup;
