import { useDispatch, useSelector } from "react-redux";
import {
  getOrderModelStatus,
  setOrderModelStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, DateInput, Icon, InputInLabel } from "@/components/ui";
import React, { useEffect, useState } from "react";
import { number, object } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import moment from "moment";
import "./orderModel.scss";

const OrderModel = ({ address, name, prices, slug }) => {
  const {
    incall: pricesIncall = [],
    outcall: pricesOutcall = [],
    escort: pricesEscort = [],
  } = prices;
  const { dispatch, userType, minutesToDhms } = useLadyService();

  const [date, setDate] = useState(null);
  const [price, setPrice] = useState(null);

  const [telegram, setTelegram] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);

  const [priceIncall, setPriceIncall] = useState(pricesIncall);
  const [priceOutcall, setPriceOutcall] = useState(pricesOutcall);
  const [priceEscort, setPriceEscort] = useState(pricesEscort);

  const [phoneValue, setPhoneValue] = useState("");

  const onChangeTelegram = () => {
    setTelegram((prev) => !prev);
  };

  const onChangeWhatsapp = () => {
    setWhatsapp((prev) => !prev);
  };

  const newDate = moment(date).utc().format("YYYY-MM-DD");

  const orderModelValue = useSelector(getOrderModelStatus);

  const { t } = useTranslation("translation");

  const [active, setActive] = useState({
    incall: !!priceIncall.length,
    outcall: !priceIncall.length && !!priceOutcall.length,
    escort: !priceIncall.length && !priceOutcall.length && !!priceEscort.length,
  });

  const data = {
    place: [
      {
        id: "placeOrderIncall",
        title: t("incall"),
        name: "placeOrder",
        value: "incall",
        onClick: () =>
          setActive({
            incall: true,
            outcall: false,
            escort: false,
          }),
        checked: active.incall,
        isVis: !!priceIncall.length,
      },
      {
        id: "placeOrderOutcall",
        title: t("onclient"),
        name: "placeOrder",
        value: "outcall",
        onClick: () =>
          setActive({
            incall: false,
            outcall: true,
            escort: false,
          }),
        checked: active.outcall,
        isVis: !!priceOutcall.length,
      },
      {
        id: "placeOrderEscort",
        title: t("accompaniment"),
        name: "placeOrder",
        value: "escort",
        onClick: () =>
          setActive({
            incall: false,
            outcall: false,
            escort: true,
          }),
        checked: active.escort,
        isVis: !!priceEscort.length,
      },
    ],
  };

  // const formSchema = object().shape({
  //   phone: number()
  //     .typeError("Телефон обязателен")
  //     .required("Телефон обязателен"),
  // });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    // resolver: yupResolver(formSchema),
  });

  // console.log("prices", prices);

  const onSubmit = (data, e) => {
    e.preventDefault();
    const { time, address: fromAddress } = data;

    console.log("data", data);

    // console.log("data", data);

    const combineDateTime = (date, time) => {
      const dateComponents = date.split("-");
      const timeComponents = time.split(":");

      const year = parseInt(dateComponents[0]);
      const month = parseInt(dateComponents[1]) - 1;
      const day = parseInt(dateComponents[2]);

      const hours = parseInt(timeComponents[0]);
      const minutes = parseInt(timeComponents[1]);

      return new Date(year, month, day, hours, minutes);
    };

    const combinedDateTime = combineDateTime(newDate, time);

    const fetchData = async () => {
      try {
        // const route = `bookings/${priceId}`;
        const route = `bookings`;

        const method = "POST";
        const payload = {
          book_at: combinedDateTime,
          address: fromAddress,
          phone: String(phoneValue).replace(/[" "+]/g, ""),
          is_whatsapp: whatsapp,
          is_telegram: telegram,
          prepay: price * 0.1,
        };

        console.log("payload", payload);

        await makeRequest({ route, method, payload });
      } catch (res) {
        console.log(res);
      }
    };

    fetchData();

    // reset();

    dispatch(setOrderModelStatus(false));
  };

  const onClose = () => {
    reset();
    dispatch(setOrderModelStatus(false));
  };

  const [activeRadio, setActiveRadio] = useState(prices[0]);

  const handleFung = (id) => {
    // console.log("id", id);
    setPrice(id.price);
  };

  const CheckboxTemplate = (id, name) => {
    return (
      <InputInLabel
        register={{ ...register("hours") }}
        key={id.id}
        name={name}
        id={id.id}
        clazz={"p-8"}
        value={id.price}
        type={"radio"}
        onClick={() => setPrice(id.price)}
        onChange={() => handleFung(id)}
      >
        {minutesToDhms(id.duration)}
      </InputInLabel>
    );
  };

  const onChangeDate = (value) => {
    setDate(value);
  };

  return (
    <dialog
      open={!!orderModelValue}
      // open={true}
      onClick={() => onClose()}
      id={"orderModel"}
      className="popup-form"
    >
      {!!orderModelValue ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-20 popup-order"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button onClick={() => onClose()} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <h3 className="popup-form__title mb-4">
          {t("orderonline")}
          <span className={"d-block"}>{name}</span>
        </h3>

        <div className="popup-form__inner fd-row gap-8">
          <DateInput onChange={onChangeDate} clazz={"popup-form__date"}>
            {t("date")}
          </DateInput>

          <InputInLabel
            register={{ ...register("time") }}
            id={"orderTime"}
            placeholder={"18:00"}
            type={"time"}
          >
            {t("time")}
          </InputInLabel>
        </div>

        <div className="popup-form__radio-grid gap-8">
          {!!priceIncall?.length &&
            active.incall &&
            priceIncall.map((i, index) => {
              return (
                <InputInLabel
                  register={{ ...register("hours") }}
                  key={index}
                  name={name}
                  id={i.id}
                  clazz={"p-8"}
                  value={i.price}
                  type={"radio"}
                  onClick={() => setPrice(i.price)}
                  onChange={() => handleFung(i)}
                >
                  {minutesToDhms(i.duration)}
                </InputInLabel>
              );
            })}

          {!!priceOutcall.length &&
            active.outcall &&
            prices.outcall.map((i) => CheckboxTemplate(i, "outcall"))}

          {!!priceEscort.length &&
            active.escort &&
            prices.escort.map((i) => CheckboxTemplate(i, "escort"))}
        </div>

        <div className="filter__checkbox-buttons gap-8">
          {data.place.map((i) => {
            if (i.isVis) {
              return (
                <InputInLabel
                  register={{ ...register("place") }}
                  key={i.id}
                  name={i.name}
                  id={i.id}
                  value={i.value}
                  type={"radio"}
                  size={" _big"}
                  clazz={"popup-order__radio"}
                  checked={i.checked}
                  onClick={i.onClick}
                >
                  {i.title}
                </InputInLabel>
              );
            } else {
              return null;
            }
          })}
        </div>

        {active.outcall || active.escort ? (
          <InputInLabel
            id={"orderAddress"}
            placeholder={t("fulladdress")}
            register={{ ...register("address") }}
          >
            {t("youraddress")}
          </InputInLabel>
        ) : address && !!prices.incall.length ? (
          <div className={"popup-form__inner gap-4 popup-order__address"}>
            <p className={"mb-0"}>{t("adresofmodel")}</p>
            <Link to={""} className={"color-green fz-16 td-underline"}>
              {address}
            </Link>
          </div>
        ) : null}

        <div className="popup-form__inner fd-row flex-wrap gap-10 justify-sb color-700">
          <div className="popup-form__inner">
            <p className={"mb-0"}>{t("paynow")}</p>
            <span className={"title fz-32 color-main"}>{price * 0.1} €</span>
          </div>
          <div className="popup-form__inner width-max">
            <p className={"mb-0"}>{t("remainder")}</p>
            <span className={"title fz-32 color-main"}>{price * 0.9} €</span>
          </div>

          <p className={"fz-13"}>{t("amounttranfered")}</p>
        </div>

        <div className="filter__checkbox-buttons gap-10">
          <InputInLabel
            clazz={`${errors.phone ? " error" : ""}`}
            id={"orderPhone"}
            type={"tel"}
            register={{ ...register("phone") }}
            autocomplete={"tel"}
            maskValue={phoneValue}
            setMaskValue={setPhoneValue}
          >
            {(errors.phone && (
              <span className={"input-label__error"}>
                {errors.phone.message}
              </span>
            )) || <span>{t("yourphone")}</span>}
          </InputInLabel>

          <div className={"d-flex gap-4"}>
            <InputInLabel
              clazz={"fd-row"}
              id={"orderWhatsApp"}
              type={"checkbox"}
              // register={{ ...register("is_whatsapp") }}
              onChange={onChangeWhatsapp}
              checked={whatsapp}
            >
              <Icon spritePath={"whatsapp"} size={"m"} />
            </InputInLabel>

            <InputInLabel
              clazz={"fd-row"}
              id={"orderTelegram"}
              type={"checkbox"}
              // register={{ ...register("is_telegram") }}
              onChange={onChangeTelegram}
              checked={telegram}
            >
              <Icon spritePath={"telegram"} size={"m"} />
            </InputInLabel>
          </div>
        </div>

        <Button size={"l"} buttonType={"submit"} clazz={"button--green"}>
          {t("sendpreorder")}
        </Button>
      </form>
    </dialog>
  );
};
export default OrderModel;
