import "./reviewContainer.scss";
import {
  Avatar,
  Button,
  Icon,
  InputInLabel,
  Toggle,
  RatingStars,
} from "@/components/ui";

import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useEffect } from "react";

import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";

import { makeRequest } from "@/services/makeRequest";

import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useForm } from "react-hook-form";

import moment from "moment";

const InputForm = ({ className, answer, reviewId }) => {
  const { t: indi } = useTranslation("indi");

  const formSchema = object().shape({
    [`answer${reviewId}`]: string().required("надо"),
  });

  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    const answer = data[`answer${reviewId}`];

    const fetchData = async () => {
      try {
        const route = `reviews/answer/${reviewId}`;
        const method = "POST";
        const payload = {
          answer,
        };

        await makeRequest({ route, method, payload });
      } catch (res) {
        console.log(res);
      }
    };

    fetchData();
  };

  useEffect(() => {
    setValue(`answer${reviewId}`, answer);
  }, [answer, setValue, reviewId]);

  return (
    <form
      className={className}
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit, reviewId)}
    >
      <InputInLabel
        type={"input"}
        id={`answer${reviewId}`}
        placeholder={indi("youranswer")}
        register={{ ...register(`answer${reviewId}`) }}
      >
        {indi("youranswer")}
      </InputInLabel>
      <Button
        size={"l"}
        clazz="button--secondary"
        buttonType={"submit"}
        square={true}
      >
        <Icon size={"l"} spritePath={"edit-2"} />
      </Button>
    </form>
  );
};

const ReviewContainer = ({ data }) => {
  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const {
    body,
    from,
    profile,
    answer,
    created_at,
    score,
    score_charm,
    score_quality,
    score_communication,
    score_emotions,
    score_atmosphere,
    id: reviewId,
  } = data;

  const { name: nameFrom, rating: ratingFrom, tariff } = from;

  const { data: tariffData } = tariff;

  const tariffFrom = !!tariffData.length ? tariffData[0].slug : "";

  const {
    name: nameProfile,
    photos: photoProfile,
    slug: slugProfile,
  } = profile;

  const statuses = [
    { name: t("qualityservices"), value: score_quality },
    { name: t("atmosphere"), value: score_atmosphere },
    { name: t("communication"), value: score_communication },
    { name: t("attractiveness"), value: score_charm },
    { name: t("sensibility"), value: score_emotions },
  ];

  const lang = useSelector(getUserLang);
  const navigate = useNavigate();

  const createdMoment = moment.unix(created_at).format("DD.MM.YYYY HH:mm");

  const toAd = (e) => {
    e.preventDefault();
    navigate(`/${lang}/profile/${slugProfile}`);
  };

  return (
    <div className="review__root">
      <div className="review__root__sect">
        <div className="review__root__info">
          <div className="review__root__main">
            <div className="review__root__head">
              <Toggle words />
              <div className="review__root__user__block">
                <div className="review__root__user">
                  <Avatar
                    size={"s"}
                    profileStatus={tariffFrom}
                    onlyIcon={true}
                  />
                  <span className="review__root__user__title">{nameFrom}</span>
                  <RatingStars gold={true} value={ratingFrom} readOnly={true} />
                </div>
                <span className="p3 color-main">{createdMoment}</span>
              </div>
            </div>
            <span className="p2 color-main">{body}</span>

            <InputForm
              className={"review__root__form__pc"}
              answer={answer?.body}
              reviewId={reviewId}
            />
          </div>
          <div className="review__root__ratings">
            <div className="review__root__rating__main color-main">
              <span>{indi("allrating")}</span>
              <span className="p1 color-gold-second">{score}</span>
              <RatingStars value={score} readOnly gold={true} />
            </div>
            {statuses.map((status) => {
              const { name, value } = status;

              return (
                <div className="p3 review__root__rating color-main">
                  <span className="w-mc">{name}</span>
                  <RatingStars value={value} readOnly gold={true} />
                </div>
              );
            })}
          </div>

          <InputForm
            className={"review__root__form__mobile"}
            answer={answer?.body}
            reviewId={reviewId}
          />

          <div>
            <Link
              onClick={(e) => toAd(e)}
              to={`/profile/${slugProfile}`}
              className="video__ad hover-line"
              alt="ad"
            >
              <img
                src={photoProfile[0]}
                className="video__ad__photo"
                alt="ad"
              />
              <span className="p1 mt-8 video__ad__title color-green">
                {nameProfile}
              </span>
            </Link>
          </div>
        </div>

        <span>
          {indi("reviewforpubl")}.{" "}
          <a href="/" className="green-reverse">
            {indi("reportabuse")}
          </a>
        </span>
      </div>
    </div>
  );
};

export default ReviewContainer;
