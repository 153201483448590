import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanSorted,
  setAllModels,
  setPage,
  setPageDefault,
  setSearchValue,
  setSortBy,
  setSortedModelsByFilter,
  setStories,
} from "@/stores/slices/modelsState";

import "./select.scss";
import {
  getUserCurrentCity,
  getUserLang,
  setCity,
  setCurrentCity,
  setIsDataReady,
  setLang,
} from "@/stores/slices/userSlice";
import { showFilter } from "@/stores/slices/showFilterSlice";
import { resetAll, setSearchFilter } from "@/stores/slices/filterSlice";
import { clearTitle } from "@/stores/slices/titleSlice";
import { resetAllCounters } from "@/stores/slices/counterFilterSlice";
import { useTranslation } from "react-i18next";
import useCityChange from "../../../hooks/useCityChange";
import useLanguageChange from "../../../hooks/useLanguageChange";
import Input from "../input/Input";
import { useNavigate } from "react-router-dom";

const Select = (props) => {
  const {
    type = "",
    mainClazz = "select",
    arrowSize,
    clazz,
    clazzSvg,
    options,
    name,
    subTitle = "",
    setValue = undefined,
    isSearch = false,
    searchPlaceHolder = "",
    postApi = false,
    onChange = false,
    paramsOnChange = false,
    defaultTitle = "",
    withoutIcon = false,
    hideFlag = false,
    withoutTitle = false,
  } = props;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const filterOpen = useSelector(showFilter);
  const currentCity = useSelector(getUserCurrentCity);
  const lang = useSelector(getUserLang);
  const changeCity = useCityChange();
  const changeLanguage = useLanguageChange();

  let [title, setTitle] = useState(
    defaultTitle || withoutTitle
      ? defaultTitle
      : !!options.length
      ? options[0].title
      : ""
  );
  let [searchVal, setSearchVal] = useState("");

  let [mainIcon, setMainIcon] = useState(
    !withoutIcon && !!options ? options[0].icon : null
  );
  useEffect(() => {
    if (name !== "city-create" && !postApi) {
      setTitle(options[0].title);
    }

    if (name === "city") {
      if (currentCity) {
        setTitle(currentCity.title);
        setMainIcon(currentCity.icon);
      }
    }
    if (name === "language") {
      setTitle(lang.toUpperCase());
    }
  }, [currentCity]);
  useEffect(() => {
    if (name === "city") {
      setTitle(currentCity.title);
      setMainIcon(currentCity.icon);
    }
  }, [filterOpen]);
  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target) &&
      !event.target.classList.contains("select__search")
    ) {
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  function setValues(i, paramsOnChange) {
    setTitle(i.title);
    setMainIcon(i.icon);
    !!setValue && setValue(i.title);
    if (onChange) {
      if (paramsOnChange) {
        onChange(i, paramsOnChange);
      } else {
        onChange(i.value ? i.value : i.name);
      }
    }
  }

  const handleClick = (i) => {
    setValues(i, paramsOnChange);
    if (name === "language") {
      i18n.changeLanguage(i.value);
      dispatch(setLang(i.value));
      changeLanguage(i.value);
    }
    if (name === "sort") {
      dispatch(setSortBy(i.value));
    }
    if (name === "city") {
      const pathname = window.location.pathname;
      dispatch(setPageDefault());
      dispatch(setAllModels([]));
      dispatch(setStories([]));
      dispatch(setSortedModelsByFilter(""));
      dispatch(resetAllCounters());
      if (
        pathname.includes("lk") ||
        pathname.includes("favorites") ||
        pathname.includes("profile") ||
        pathname.includes("registration")
      ) {
        navigate("/");
      }
      changeCity(i.slug);
      dispatch(
        setCurrentCity({
          id: i.id,
          title: i.title,
          icon: i.icon,
          highlight: i.highlight,
          slug: i.slug,
        })
      );

      dispatch(setCity(i.slug));
      if (!filterOpen) {
        dispatch(setAllModels([]));
      }
      dispatch(resetAll());
      dispatch(setSearchFilter(false));
      dispatch(cleanSorted());
      dispatch(setSearchValue(""));
      dispatch(setSortedModelsByFilter(""));
      dispatch(clearTitle());
      dispatch(resetAllCounters());
    }
  };

  const searchedData =
    searchVal && options
      ? options.filter((item) => {
          return item.title?.toLowerCase().includes(searchVal.toLowerCase());
        })
      : options;

  const { t } = useTranslation("translation");
  const mobilePostCity = t("incity")?.replace(/%([^%]+)%/g, "");

  return (
    <div
      className={`${mainClazz}${clazz ? ` ${clazz}` : ""}${
        isOpen ? " select_active" : ""
      }`}
      onClick={() => setIsOpen((prev) => !prev)}
      ref={selectRef}
    >
      {subTitle && <span className="select__subtitle">{subTitle}</span>}

      {name === "paymentsMethods" ? (
        <Icon
          type={"payment"}
          size={"m"}
          spritePath={mainIcon}
          clazz={`select__icon ${clazzSvg}`}
        />
      ) : null}

      {name === "sort" ? (
        <Icon
          size={"m"}
          spritePath={"sort"}
          clazz={`select__icon ${clazzSvg}`}
        />
      ) : null}

      {(name === "city" || name === "city-create" || type === "flag") &&
      (!withoutIcon || !hideFlag) ? (
        <Icon
          type={"flag"}
          size={"m"}
          spritePath={mainIcon}
          clazz={`select__icon ${clazzSvg}`}
        />
      ) : null}

      {title}

      {arrowSize ? (
        <Icon
          clazz={"select__arrow"}
          size={arrowSize}
          spritePath={"chevrone-down"}
        />
      ) : null}

      <div className="select__body">
        {isSearch && (
          <label for={`${name}-search`} className="select__search">
            <Input
              type="search"
              placeholder={searchPlaceHolder}
              id={`${name}-search`}
              clazz={"search__input"}
              name={`${name}-search`}
              value={searchVal}
              setValue={setSearchVal}
            />
          </label>
        )}
        {!isSearch &&
          !!options &&
          searchedData.map((i, index) => {
            return (
              <div
                key={index + 1}
                onClick={() => handleClick(i)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") handleClick(i);
                }}
                className={`select__item`}
              >
                {i.title || i.name}

                {i.icon && !hideFlag ? (
                  <Icon type={"flag"} spritePath={`${i.icon}`} />
                ) : (
                  ""
                )}
              </div>
            );
          })}
        {isSearch && (
          <div className="dropdown__search-select">
            {!!options &&
              searchedData.map((i, index) => {
                return (
                  <div
                    key={index + 1}
                    onClick={() => handleClick(i)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") handleClick(i);
                    }}
                    className={`select__item`}
                  >
                    {i.title || i.name}

                    {i.icon ? (
                      <Icon type={"flag"} size={"m"} spritePath={`${i.icon}`} />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  currentValue: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  setValue: PropTypes.func,
  mainIcon: PropTypes.string,
};

export default Select;
