import { useSelector } from "react-redux";
import { themeMode } from "@/stores/slices/userSlice";

import logo from "../../../assets/img/logo.svg";
import logoLight from "../../../assets/img/logo-light.svg";

const LogoSvg = (props) => {
  const { alt } = props;

  const theme = useSelector(themeMode);
  const getTheme = () => {
    switch (theme) {
      case "light":
        return logoLight;
      default:
        return logo;
    }
  };
  return <img className={"logo-img"} src={getTheme()} alt={alt} />;
};
export default LogoSvg;
