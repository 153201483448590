import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";

const ConfirmDeletePopup = (props) => {
  const { open, setOpen, onSubmit } = props;

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  return (
    <dialog open={open} onClick={handleCloseDelete}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <div
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button onClick={handleCloseDelete} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner text-center">
          <h3>Действительно отвязть?</h3>

          {/* <span className={"color-700"}>
            Это процедура необратимая. Анкета будет удалена с сервера. Вместо
            удаления советуем временно деактивировать анкету, тогда все данные
            сохранятся, просто временно не будут показываться.
          </span> */}
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {indi("cancel")}
          </Button>

          <Button
            size={"l"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={onSubmit}
          >
            {t("yes")}, {indi("disconnect")}
          </Button>
        </div>
      </div>
    </dialog>
  );
};

export default ConfirmDeletePopup;
