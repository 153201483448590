import { useEffect, useState } from "react";

const Favicon = () => {
  const favicon1 = "/favicon1.ico";
  const favicon2 = "/favicon2.ico";
  const [currentFavicon, setCurrentFavicon] = useState(favicon1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentFavicon((prevFavicon) =>
        prevFavicon === favicon1 ? favicon2 : favicon1
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [favicon1, favicon2]);

  useEffect(() => {
    const updateFavicon = () => {
      let link = document.querySelector("link[rel*='icon']");

      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }

      link.href = currentFavicon;
    };

    updateFavicon();
  }, [currentFavicon]);

  return null;
};

export default Favicon;
