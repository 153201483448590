import example1 from "../../../../../../../assets/img/examplePhotos/verExample.svg";
import { Button, Icon, UploadZone } from "@/components/ui";
import VerApprove from "../verApprove/VerApprove";
import WaitingAdmin from "../waitingAdmin/WaitingAdmin";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import "./verAds.scss";
import ExampleImage from "../exampleImage/ExampleImage";

const VerAds = ({ verRef }) => {
  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");

  const [image, setImage] = useState(null);

  return (
    <div className="verads control" ref={verRef}>
      <div className="verads__title">
        <Icon spritePath={"verify-fill"} size={"m"} clazz={"_green"} />
        <span className="title">{indi("veryourads")}</span>
      </div>
      <span className="color-main mt-12">{indi("uplyourimg")}</span>
      <a href="/" className="green mt-12">
        {indi("learnmoreabout")}
      </a>
      {!image && (
        <div className="verads__photo mt-32">
          <div className="verads__examplePhoto">
            {indi("example")}
            <img src={example1} alt={indi("example")} />
          </div>
          <UploadZone
            setData={setImage}
            mainText={indi("uploadphoto")}
            subText={indi("photodisplay")}
          />
        </div>
      )}

      {image && (
        <div className="verads__photo mt-32">
          <ExampleImage image={image} setImage={setImage} />
          <WaitingAdmin />
        </div>
      )}

      {image && (
        <div className="verads__photo mt-32">
          <ExampleImage image={image} setImage={setImage} />
          <VerApprove
            spritePath={"verify-fill"}
            textGreen={t("verification")}
            text={`${indi("forfewads")} ${"24.04.2024"}`}
          />
        </div>
      )}

      <div className={"verads__secure"}>
        <Icon size={"4xl"} spritePath={"secure"} />
        {indi("confyourimg")}
      </div>
      <span className="title verads__title mt-32">{indi("otherver")}:</span>
      <Button
        title={indi("videocall")}
        clazz={"button--primary justify-center mt-16"}
        size={"l"}
      >
        {indi("videocall")} &mdash; <span className="verads__ten">10 €</span>
      </Button>
    </div>
  );
};

export default VerAds;
