import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  plusFavoriteCounter,
  minusFavoriteCounter,
  getUserCurrentCity,
  getUserLang,
} from "@/stores/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonLink, Icon, Video } from "@/components/ui";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Helmet } from "react-helmet";
import { getStoriesStatus, setStoriesStatus } from "@/stores/slices/popupSlice";
import { getStoriesId, setStoriesId } from "@/stores/slices/modelsState";
import { useTranslation } from "react-i18next";

import "./stories.scss";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";

const StoriesComponent = (props) => {
  const {
    stories,
    favoriteButton,
    descriptionText,
    profileLink,
    storiesForOneModel = false,
  } = props;

  const [dataStories, setDataStories] = useState([]);
  const dispatch = useDispatch();
  const swiperRef = useRef(null);
  const city = useSelector(getUserCurrentCity);
  const lang = useSelector(getUserLang);

  const [page, setPage] = useState(1);

  const storiesStatus = useSelector(getStoriesStatus);
  const currentStories = useSelector(getStoriesId);
  const [isLoad, setIsLoad] = useState(false);
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (storiesStatus) {
      setDataStories(stories);
      // console.log(dataStories)
      return () => {
        dispatch(setStoriesId(null));
      };
    }
  }, [storiesStatus]);

  console.log("dataStories", dataStories);

  useEffect(() => {
    if (!storiesForOneModel) {
      const fetchData = async () => {
        setIsLoad(true);
        try {
          const route = `stories/${city.id}`;
          const method = "GET";
          const payload = {
            lang,
            page: page + 1,
          };

          const data = await makeRequest({ route, method, payload });

          if (data) {
            const result = data.stories;
            const dataMeta = data.meta;
            const totalStories = dataMeta.total;
            const currentPage = dataMeta.current_page;
            const perPage = dataMeta.per_page;

            if (perPage * currentPage < totalStories + perPage) {
              const newData = stories.concat(result);
              setDataStories(newData);
            }
            setIsLoad(false);
          }
        } catch (error) {}
      };

      currentStories > 4 && fetchData();
    }
  }, [currentStories]);

  const handleSlideChange = (swiper) => {
    const index = swiper.activeIndex;
    const previousIndex = swiper.previousIndex;
    if (index % 5 === 0 && index > previousIndex) {
      const fetchData = async () => {
        setPage((prev) => prev + 1);
        try {
          const route = `stories/${city.id}`;
          const method = "GET";
          const payload = {
            lang,
            page: page + 1,
          };

          const data = await makeRequest({ route, method, payload });

          if (data) {
            const result = data.stories;
            const dataMeta = data.meta;
            const totalStories = dataMeta.total;
            const currentPage = dataMeta.current_page;
            const perPage = dataMeta.per_page;

            if (perPage * currentPage < totalStories + perPage) {
              const newData = stories.concat(result);
              setDataStories(newData);
            }
          }
        } catch (error) {}
      };

      fetchData();
    }
  };

  const Template = (props) => {
    const {
      src,
      type = "img",
      name,
      age,
      createdAt,
      description,
      slug,
    } = props;
    const { TimeAgo } = useLadyService();
    const [timeAgo, setTimeAgo] = useState("");

    const [favorite, setFavorite] = useState(false);
    const toggleFavoriteCount = () => {
      dispatch(favorite ? minusFavoriteCounter() : plusFavoriteCounter());
      setFavorite(!favorite);
    };
    const [muted, setMuted] = useState(false);

    return (
      <div className={"stories__card"}>
        {type === ("mp4" || "webm") && (
          <Button
            square={true}
            size={"s"}
            clazz={`stories__sound${muted ? " _active" : ""}`}
            onClick={() => setMuted((state) => !state)}
          >
            <Icon size={"l"} spritePath={muted ? "volume-x" : "volume"} />
          </Button>
        )}

        {favoriteButton ? (
          <Button
            square={true}
            size={"s"}
            clazz={`girl-card__favorite${
              favorite ? " girl-card__favorite_active" : ""
            }`}
            onClick={() => toggleFavoriteCount()}
          >
            <Icon
              size={"l"}
              spritePath={favorite ? "favorite-fill" : "favorite"}
            />
          </Button>
        ) : null}

        {type === ("mp4" || "webm") ? (
          <video
            loop={true}
            playsInline={true}
            autoPlay={true}
            muted={muted}
            src={src}
          />
        ) : (
          <img src={src} alt={`${t("escortmodel")} ${name}`} />
        )}

        {descriptionText && description ? (
          <div className="stories__text color-white">
            <p>{description}</p>
          </div>
        ) : null}

        <div className="girl-card__info-wrapper">
          <div className="girl-card__age color-white">
            {age} <span>{t("years")}</span>
          </div>

          <div className="girl-card__info color-white">
            <Link title={name} className={`girl-card__name`}>
              {name}
            </Link>
            <p className={"color-white"}>
              <TimeAgo
                setTimeAgo={setTimeAgo}
                timeAgo={timeAgo}
                timestamp={createdAt}
              />
            </p>
          </div>

          {profileLink ? (
            <ButtonLink
              size={"m"}
              href={`/${lang}/profile/${slug}`}
              clazz={"button--green _no-underline"}
              onClick={() => dispatch(setStoriesStatus(false))}
            >
              {t("profuser")}
              <Icon spritePath={"chevrone-right"} size={"m"} />
            </ButtonLink>
          ) : null}
        </div>
      </div>
    );
  };

  const paramsSwiper = {
    modules: [Pagination, Navigation, Autoplay],
    centeredSlides: true,
    autoplay: {
      delay: 6000,
      pauseOnMouseEnter: true,
    },
    slidesPerView: "auto",
  };
  const onInitFunction = (swiper) => {
    swiper.update();
    swiperRef.current.swiper.slideTo(currentStories);
    console.log(swiper);
  };
  console.log("storiesStatus", storiesStatus);
  return !!storiesStatus ? (
    <dialog
      className="stories-popup"
      open={!!storiesStatus}
      onClick={() => dispatch(setStoriesStatus(false))}
    >
      {!!storiesStatus ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <Button
        square={true}
        size={"l"}
        onClick={() => dispatch(setStoriesStatus(false))}
        clazz={"button__close button--tetriary"}
      >
        <Icon size={"l"} spritePath={"close"} />
      </Button>

      {storiesStatus && dataStories.length > 0 && !isLoad ? (
        <Swiper
          ref={swiperRef}
          onSlideChange={storiesForOneModel ? null : handleSlideChange}
          {...paramsSwiper}
          onInit={(swiper) => onInitFunction(swiper)}
          className={"stories__slider"}
          navigation={{
            nextEl: ".stories__button-next",
            prevEl: ".stories__button-prev",
          }}
          pagination={{
            el: ".swiper-pagination.stories__progressbar",
            clickable: "true",
            type: "bullets",
            autoplayDisableOnInteraction: false,
            renderBullet: function () {
              return (
                "<span class=swiper-pagination-bullet>" +
                "<span class=swiper-pagination-bullet__progress-bar></span>" +
                ' <span class=swiper-pagination-bullet__autoplay style="animation-duration: 5s"></span>' +
                "</span>"
              );
            },
          }}
          onTransitionStart={function () {
            let videos = document.querySelectorAll(
              ".stories__slider .stories__slide video"
            );
            Array.prototype.forEach.call(videos, function (video) {
              video.pause();
            });
          }}
          onTransitionEnd={function (swiper) {
            swiper.update();
            let index = this.activeIndex;
            let slide =
              document.getElementsByClassName("stories__slide")[index];
            let slideVideo = slide.getElementsByTagName("video")[0];
            if (slideVideo != null || slideVideo !== undefined) {
              slideVideo.load();
            }
          }}
        >
          <button
            slot={"container-start"}
            className="swiper-button-prev stories__button-prev"
            onClick={(e) => e.stopPropagation()}
          ></button>

          {dataStories?.length > 0 &&
            dataStories.map((story, index) => {
              const regex = /([^.]+)$/;
              const extension = story.media.match(regex)[1];
              // console.log(extension)

              return (
                <SwiperSlide
                  key={index}
                  className={"stories__slide"}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Template
                    age={story.age}
                    name={story.name}
                    type={extension}
                    src={story.media}
                    slug={story.slug}
                    createdAt={story.created_at}
                    description={story.description}
                  />
                </SwiperSlide>
              );
            })}

          <button
            slot={"container-end"}
            className="swiper-button-next stories__button-next"
            onClick={(e) => e.stopPropagation()}
          ></button>

          <div
            slot={"container-end"}
            className="swiper-pagination stories__progressbar"
          ></div>
        </Swiper>
      ) : null}
    </dialog>
  ) : null;
};

export default StoriesComponent;
