import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";

const options = ["Вася", "Клиент", "Василий", "Начальник"];

const CheckPhonePopup = (props) => {
  const { open, setOpen, phoneClient } = props;

  const { t } = useTranslation("translation");

  const handleCloseDelete = () => {
    setOpen(false);
  };

  return (
    <dialog open={open} onClick={handleCloseDelete}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        method={"POST"}
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button onClick={handleCloseDelete} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner">
          <h3>
            Теги для номера телефона <p>{phoneClient}</p>
          </h3>
          <div className="popup-form__scroll__list">
            {options.map((item, index) => {
              return (
                <span className="p1 color-main" key={index}>
                  #{item}
                </span>
              );
            })}
          </div>
          <InputInLabel
            id={"tegPhoneClient"}
            title={"#Хороший человек"}
            type={"input"}
            placeholder={"#Хороший человек"}
          >
            Добавьте свой тег
          </InputInLabel>

          <Button
            size={"l"}
            buttonType={"submit"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {t("save")}
          </Button>
        </div>
      </form>
    </dialog>
  );
};

export default CheckPhonePopup;
