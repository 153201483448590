import { useState, useEffect, useRef } from "react";
import { DayPicker } from "react-day-picker";

import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";

import { ru, enUS, cs } from "date-fns/locale";

import "react-day-picker/dist/style.css";

import "./dateInput.scss";

const css = `
  .my-selected:not([disabled]) { 
    background-color: #1ca182;
    color: white;
  }
`;

const DateInput = (props) => {
  const { clazz, onChange, children, placeholder, notBeforeCurDate, range } =
    props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const lang = useSelector(getUserLang);

  let locale;

  switch (lang) {
    case "ru": {
      locale = ru;
      break;
    }

    case "cz": {
      locale = cs;
      break;
    }

    default: {
      locale = enUS;
      break;
    }
  }

  const childrenRef = useRef(null);

  const currentDate = new Date();

  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  const formattedDate = day + "." + month + "." + year;

  const handleDayClick = (date) => {
    setSelectedDate(date);
    onChange(date);
    setIsOpen((prev) => !prev);
  };

  const handleBodyClick = (event) => {
    event.stopPropagation();
  };

  const handleClickOutside = (event) => {
    if (childrenRef.current && !childrenRef.current.contains(event.target)) {
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`inputdate-label${clazz ? ` ${clazz}` : ""}`}
      onClick={() => setIsOpen((prev) => !prev)}
    >
      <label>
        {children}
        <div className={`dropdowndate${isOpen ? " dropdowndate_active" : ""}`}>
          <input
            type="text"
            value={selectedDate ? selectedDate.toLocaleDateString() : ""}
            placeholder={placeholder ?? formattedDate}
            readOnly
          />

          <div
            ref={childrenRef}
            className={`dropdowndate__body`}
            onClick={handleBodyClick}
          >
            <style>{css}</style>
            <DayPicker
              mode="range"
              captionLayout="dropdown-buttons"
              fromYear={2020}
              toYear={2090}
              selected={selectedDate}
              onDayClick={handleDayClick}
              locale={locale}
              modifiersClassNames={{
                selected: "my-selected",
              }}
            />
          </div>
        </div>
      </label>
    </div>
  );
};

export default DateInput;
