import { ButtonLink } from "@/components/ui";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import PopupContainer from "../PopupContainer.tsx";

const DiscountToHealthPopup = (props) => {
  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation();

  const liOptions = [
    <li>
      <Link
        to={"https://cz.unilabs.online/auth/register"}
        className="color-green"
        target={"_blank"}
      >
        {indi("registeron")}
      </Link>{" "}
      {t("onthe").toLowerCase()} Unilabs.online
    </li>,
    <li>
      {indi("onpage")}{" "}
      <Link
        to="https://cz.unilabs.online/sexualne-prenosne-nemoci"
        className="color-green d-inline"
        target={"_blank"}
      >
        {indi("test")}
      </Link>{" "}
      {indi("selectvar")} <span className="color-main">EXPERT</span> (
      {indi("getstatus")} <span className="color-main">{t("healthy")}</span>)
    </li>,
    <li>{indi("selectplace")}</li>,
    <li>
      {indi("basket")} <span className="color-main">STDE20-24</span>{" "}
      {indi("beforeorder")}
    </li>,
    <li>{indi("followrecomend")}</li>,
    <li>
      {indi("waitfortest")} <span className="color-green">Unilabs.online</span>
    </li>,
    <li>
      {indi("uploadtoprofil")}{" "}
      <span className="color-green">Lady4Love.com</span>
    </li>,
  ];

  return (
    <PopupContainer {...props} title={indi("qrcodediscount")} isForm={false}>
      <h3 className="title_h3">
        код: <span className="fz-32 bold">STDE20-24</span>
      </h3>

      <ol>{liOptions.map((i) => i)}</ol>
      <span className="d-flex fd-column align-center">
        {indi("donegethelth")} <p className="color-main">{t("healthy")}</p>
      </span>

      <ButtonLink
        href={"https://cz.unilabs.online"}
        size={"l"}
        target={"_blank"}
        clazz={"button--green justify-center _no-underline"}
      >
        На сайт Unilabs
      </ButtonLink>
    </PopupContainer>
  );
};

export default DiscountToHealthPopup;
