import React, { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import showToast from "../../../../../../toast/Toast";
import { Image } from "./Image";
import { Icon } from "../../../../../../ui";
import { useTranslation } from "react-i18next";

const PhotoUpload = ({ data, setData, accept, type, movePhoto, addText = false }) => {
  const {t:indi} = useTranslation('indi');

  const isDuplicate = (file, existingFiles) => {
    return existingFiles.some(
      (existingFile) =>
        existingFile.name === file.name &&
        existingFile.size === file.size &&
        existingFile.type === file.type
    );
  };
  const getValidFormatsMessage = (type) => {
    if (type === "photo") {
      return "Допустимые форматы: JPG, JPEG, PNG, WEBP";
    } else if (type === "video") {
      return "Допустимые форматы: MP4, MOV, WMV, AVI, MKV";
    } else {
      return "Допустимые форматы: JPG, JPEG, PNG, WEBP, MP4, MOV, WMV, AVI, MKV";
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = [];

      acceptedFiles.forEach(async (file) => {
        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\.\w+$/.test(file.name)) {
          return;
        }
        if (
          !file.type.startsWith(type === "photo" ? "image/" : "video/") &&
          !(type === "mixed" && (file.type.startsWith("image/") || file.type.startsWith("video/")))
        ) {
          showToast({
            message: `${getValidFormatsMessage(type)}`,
            variant: "error",
          });
          return;
        }

        if (file.size > 20 * 1024 * 1024) {
          showToast({
            message: `Максимальный размер файла: 20МБ`,
            variant: "error",
          });
          return;
        }

        const existingFiles = type === "mixed" ? [...data.images, ...data.videos] : data.files;
        if (isDuplicate(file, existingFiles)) {
          showToast({
            message: `Файл ${file.name} уже существует`,
            variant: "error",
          });
          return;
        }

        const filePreview = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        if(type === "mixed") {
          filePreview.is_ero = true
        }
        newFiles.push(filePreview);
      });

      if (newFiles.length > 0) {
        setData((prevData) => {
          if (type === "mixed") {
            const newImages = newFiles.filter(file => file.type.startsWith("image/"));
            const newVideos = newFiles.filter(file => file.type.startsWith("video/"));
            return {
              images: [...prevData.images, ...newImages],
              videos: [...prevData.videos, ...newVideos],
            };
          } else {
            return { files: [...prevData.files, ...newFiles] };
          }
        });

        
      }
    },
    [setData, type, data, showToast]
  );
  

  const removeFile = (fileName, fileType) => {
    setData((prevData) => {
      if (type === "mixed") {
        return {
          images:
            fileType === "image"
              ? prevData.images.filter((file) => file.name !== fileName)
              : prevData.images,
          videos:
            fileType === "video"
              ? prevData.videos.filter((file) => file.name !== fileName)
              : prevData.videos,
        };
      } else {
        return {
          files: prevData.files.filter((file) => file.name !== fileName),
        };
      }
    });
  };

  const imageFormats = /\.(jpeg|png|webp)$/i;
  const thumbs = (
    type === "mixed" ? [...data.images, ...data.videos] : data.files
  ).map((file, index) => (
    <div style={{"position": "relative"}}>
      <Image
        key={file?.name}
        index={index}
        photo={file?.preview}
        movePhoto={movePhoto}
        isVideo={imageFormats.test(file?.name) ? false : true}
      />
      <span
        className="icon-bg"
        onClick={(e) =>
          {
            e.stopPropagation();
            removeFile(
            file.name,
            file.type.startsWith("image/") ? "image" : "video"
          )}
        }
      >
        <Icon size={"s"} spritePath={"close"} />
      </span>
    </div>
  ));

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
  });

  return (
    <div {...getRootProps({ className: "dropzone upload__zone" })}>
      <input {...getInputProps()} multiple />
      <Icon clazz={"_green"} size={"xl"} spritePath={"plus-1"} />
      <p>{indi('addfile')}</p>
      {(type === "photo" && !data.files?.length) && addText && (
        <>
          <span className="p1 color-main mb-12">{indi('upload1')}</span>
          <span className="mb-16">После загрузки Вы сможете обрезать, повернуть и отретушировать фотографии (например, скрыть лицо)</span>
          <span className="p1 color-green text-center">{indi('openface')}</span>
        </>
      )}
      
      {!!data.files?.length && addText && <div className="main-images">
        {thumbs.slice(0, 3)}
        <span className="text-center">{indi("mainphotos")}</span>
    {/* //       {error && <span className="error text-center">{error}</span>} */}
        </div>}
      {data.files?.length > 3 && addText && <div className="second-images">{thumbs.slice(3)}</div>}
      {type === "mixed" && <div className="second-images">{thumbs.slice(3)}</div>}
      {!addText && <div className="second-images">{thumbs}</div>}
    </div>
  );
};

export default PhotoUpload;
