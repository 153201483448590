import { Button, RatingStars } from "@/components/ui";
import "./videoContainer.scss";

import { useTranslation } from "react-i18next";

const statusObj = {
  4: "approvestatus",
  6: "declinestatus",
};

const Variant = ({ status }) => {
  const { t: indi } = useTranslation("indi");

  switch (statusObj[status]) {
    case "approvestatus": {
      return (
        <>
          <span className="p1 color-green text-center">
            {indi("approvestatus")}
          </span>
          <div className={`video__status done`}>
            <span>{indi("clientrating")}</span>
            <RatingStars gold={true} value={4} />
          </div>
        </>
      );
    }

    case "declinestatus": {
      return <span className="p1 color-red-700">{indi("declinestatus")}</span>;
    }

    default: {
      return (
        <>
          <span>
            {indi("timetoaccept")} <span className="p2 color-main">49:23</span>
          </span>
          <div className="popup-form__buttons">
            <Button
              size={"m"}
              clazz={"button--green justify-center"}
              buttonType={"submit"}
            >
              {indi("confirm")}
            </Button>

            <Button size={"m"} clazz={"button--secondary justify-center"}>
              Отменнить
            </Button>
          </div>
        </>
      );
    }
  }
};

const VideoStatus = ({ status }) => {
  let classVariant;
  switch (statusObj[status]) {
    case "approvestatus": {
      classVariant = " approve";
      break;
    }

    case "declinestatus": {
      classVariant = " decline";
      break;
    }

    default: {
      classVariant = " work";
    }
  }

  return (
    <div className={`video__status${classVariant}`}>
      <Variant status={status} />
    </div>
  );
};

export default VideoStatus;
