import { useDispatch, useSelector } from "react-redux";
import {
  getForgotPasswordPhoneInputsStatus,
  setForgotPasswordPhoneInputsStatus,
  setNewPassStatus,
} from "@/stores/slices/popupSlice";
import { number, object } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import "./restorePhoneInputsPopup.scss";
import { Link } from "react-router-dom";

const RestorePhoneInputsPopup = (props) => {
  const { telephone } = props;
  const dispatch = useDispatch();
  const showForgotPasswordPhoneInputs = useSelector(
    getForgotPasswordPhoneInputsStatus
  );
  const formSchema = object().shape({
    phone: number()
      .typeError("Телефон обязателен")
      .required("Телефон обязателен"),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    console.log(data);
    reset();
  };

  const correctOTP = "1111";
  const numberOfDigits = 4;
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }

    if (newArr.filter((item) => item).length === 4) {
      dispatch(setNewPassStatus(true));
      dispatch(setForgotPasswordPhoneInputsStatus(false));
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  useEffect(() => {
    if (otp.join("") !== "" && otp.join("") !== correctOTP) {
      // setOtpError("❌ Wrong OTP Please Check Again")
    } else {
      // setOtpError(null)
    }
  }, [otp]);

  return !!showForgotPasswordPhoneInputs ? (
    <dialog
      id={"forgotPasswordPhonePopupInputs"}
      open={!!showForgotPasswordPhoneInputs}
      onClick={() => dispatch(setForgotPasswordPhoneInputsStatus(false))}
      className="popup-form forgot-phone-inputs"
    >
      {!!showForgotPasswordPhoneInputs ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        method={"post"}
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(onSubmit)}
        className="popup-form__body"
      >
        <Button
          onClick={() => dispatch(setForgotPasswordPhoneInputsStatus(false))}
          clazz={"button__close"}
        >
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <p className={"popup-form__text"}>
          Если телефон {telephone} зарегистрирован на портале, то введите код из
          СМС.
        </p>

        <div>
          {otp.map((digit, index) => (
            <input
              key={index}
              value={digit}
              maxLength={1}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
              ref={(reference) => (otpBoxReference.current[index] = reference)}
            />
          ))}
        </div>

        <Link to={"/"} className={"forgot-phone-inputs__link"}>
          Отправить код повторно
        </Link>
      </form>
    </dialog>
  ) : null;
};

export default RestorePhoneInputsPopup;
