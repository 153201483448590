import "./balancePage.scss";

import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, DateInput, Icon } from "@/components/ui";
import { AdBalancePopup } from "../../popups";
import { WithdrawBalancePopup } from "../../popups";
import { useState } from "react";

const BalancePage = () => {
  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation();

  const tabs = [
    { id: 1, name: "История операций" },
    { id: 2, name: "На очереди" },
  ];

  const [isOpenAd, setIsOpenAd] = useState(false);

  const [activeTabId, setActiveTabId] = useState(1);

  const [isOpenWithDraw, setIsOpenWithDraw] = useState(false);

  const handleOpenAd = () => {
    setIsOpenAd(true);
  };

  const handleOpenWithDraw = () => {
    setIsOpenWithDraw(true);
  };

  const handleTabClick = (tabId) => {
    setActiveTabId(tabId);
  };

  const ads = [
    {
      id: 1,
      title: "Все анкеты",
      name: "Все анкеты",
      value: "allads",
    },
  ];

  const options_balance = [
    {
      id: 1,
      title: "Поступления",
      name: "Поступления",
    },
    {
      id: 2,
      title: "Списания",
      name: "Списания",
    },
    {
      id: 3,
      title: "Доход от анкет",
      name: "Доход от анкет",
    },
    {
      id: 3,
      title: "Вывод средств",
      name: "Вывод средств",
    },
  ];

  return (
    <main>
      {isOpenAd && <AdBalancePopup open={isOpenAd} setOpen={setIsOpenAd} />}
      {isOpenWithDraw && (
        <WithdrawBalancePopup
          open={isOpenWithDraw}
          setOpen={setIsOpenWithDraw}
        />
      )}
      <section className={"balance-page__container"}>
        <h1>Баланс</h1>
        <div className="balance-page__header">
          <div className="balance-page__info">
            <div className="balance-page your__balance">
              <span className="p1">Ваш баланс:</span>
              <span className="title_h3 mb-0">2340 €</span>
            </div>

            <div className="balance-page day__balance">
              <span className="p1 color-main">Ежедневное списание:</span>
              <span className="title_h3 mb-0">35 €</span>
            </div>
          </div>

          <div className="balance-page__actions">
            <Button
              size={"l"}
              clazz={"button--green justify-center"}
              onClick={handleOpenAd}
            >
              {indi("topupbalance")}
            </Button>
            <Button
              size={"l"}
              clazz={"button--primary justify-center"}
              onClick={handleOpenWithDraw}
            >
              Вывести средтсва
            </Button>
          </div>
        </div>
        <div className="balance-page__tabs">
          {tabs.map((i, index) => {
            return (
              <div
                key={index}
                className={`balance-page__tab p2 ${
                  activeTabId === i.id ? "_active" : ""
                }`}
                onClick={() => handleTabClick(i.id)}
              >
                {i.name}
              </div>
            );
          })}
        </div>
        <div className="balance-page__select">
          <div className="balance-page__info">
            <Dropdown title={"Все анкеты"} name={"Все анкеты"} size={"s"}>
              {ads.map((i) => {
                return (
                  <Checkbox
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    title={i.title}
                  />
                );
              })}
            </Dropdown>
            <Dropdown title={"Все операции"} name={"Все операции"} size={"s"}>
              {options_balance.map((i) => {
                return (
                  <Checkbox
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    title={i.title}
                  />
                );
              })}
            </Dropdown>
          </div>
          <DateInput />
          <div className="balance-page__info">
            <div className="balance-page__updown up">
              <Icon size={"s"} spritePath={"cash-up"} clazz={"_green"} />
              <span>Доход</span>
              <span className="color-green">800€</span>
            </div>
            <div className="balance-page__updown down">
              <Icon size={"s"} spritePath={"cash-down"} clazz={"_red"} />
              <span>Расход</span>
              <span className="color-red-700">1200€</span>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BalancePage;
