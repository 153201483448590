import { Button, ButtonLink, Icon, RatingStars } from "@/components/ui";
import React, { Fragment, useEffect, useState } from "react";
import { setLang } from "@/stores/slices/userSlice";
import { Link, useParams } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import AgencyCard from "../../agencyCard/AgencyCard";
import GirlCard from "../../girlCard/GirlCard";
import ErocontentAccess from "../../popups/erocontentAccess/ErocontentAccess";
import OrderModel from "../../popups/orderModel/OrderModel";
import ScheduleTable from "../../scheduleTable/ScheduleTable";
import StoriesComponent from "../../stories/StoriesComponent";
import Appeal from "../../popups/appeal/Appeal";
import AddReview from "../../popups/addReview/AddReview";
import { useTranslation } from "react-i18next";
import PartyCard from "../../partyCard/PartyCard";
import Gallery from "../../popups/gallery/Gallery";
import useLadyService from "@/services/LadyService";
import TabSliderModel from "../../sliders/tabSlider/tabSliderModel/TabSliderModel";

import statusElite from "../../../assets/img/status/elite.webp";
import statusEliteMob from "../../../assets/img/status/elite-mob.webp";
import statusTop from "../../../assets/img/status/top.webp";
import statusTopLight from "../../../assets/img/status/top.webp";
import statusTopMob from "../../../assets/img/status/top-mob.webp";
import statusTopMobLight from "../../../assets/img/status/top-mob-light.webp";

import ModelServices from "./components/serviceContent/ModelServices";

import PrivateSlider from "../../sliders/privateSlider/PrivateSlider";
import ModelCounters from "./components/counters/ModelCounters";

import "./modelPage.scss";
import ModelPornstarCard from "./components/pornstarCard/ModelPornstarCard";
import { Helmet } from "react-helmet";
import ModelInfoContent from "./components/infoContent/ModelInfoContent";

import AppearancePopup from "../accounts/createProfile/pageComponent/stepSection/appearance/AppearancePopup";
import ServicesPopup from "../accounts/createProfile/pageComponent/stepSection/services/ServicesPopup";
import MainPopup from "../accounts/createProfile/pageComponent/stepSection/main/MainPopup";
import TarifsPopup from "../accounts/createProfile/pageComponent/stepSection/tarifs/TarifsPopup";
import { LinkWithAgencyPopup, LinkWithGirlfriend } from "../../popups";

const EditBlock = ({ setOpen, title, mb }) => {
  return (
    <div className={`d-flex fd-row gap-12 align-center mb-${mb}`}>
      <h1 className="title model-header__name text-dots mb-0">{title}</h1>
      <Button
        onClick={() => setOpen(true)}
        size={"xs"}
        clazz={"button_outline--green"}
        square={true}
      >
        <Icon spritePath={"edit-2"} size={"s"} />
      </Button>
    </div>
  );
};

const CurrentModelPage = ({ dataProps }) => {
  const {
    dispatch,
    lang,
    theme,
    windowWidth,
    userType,
    gendersTitle,
    orientations,
  } = useLadyService();

  const { t } = useTranslation("translation");

  const { t: indi } = useTranslation("indi");

  const [data, setData] = useState(dataProps);

  const {
    id = data?.id,
    name = data?.name,
    description = data?.description,
    gf = data?.gf,
    age = data?.age,
    prices = data?.prices,
    rating = data?.rating,
    reviews_count = data?.reviews_count,
    tag_booking = data?.tag_booking,
    ready_travel = data?.ready_travel,
    ready_videochat = data?.ready_videochat,
    is_top = data?.is_top,
    is_elite = data?.is_elite,
    is_escort = data?.is_escort,
    phone = data?.phone,
    telegram = data?.telegram,
    whatsapp = data?.whatsapp,
    city = data?.city,
    address = data?.address,
    distance = data?.distance,
    media = data?.media,
    ero = data?.ero,
    porn_star = data?.porn_star,
    parties = data?.parties,
    agency = data?.agency,
    stories = data?.stories,
    statistic = data?.statistic,
    work_time = data?.work_time,
    discounts = data?.discounts,
    city_id = data?.city_id,
    gender = data?.gender,
  } = data;

  const tabsBtnsContent = [{ title: indi("params") }, { title: t("services") }];

  const { profileSlug } = useParams();

  useEffect(() => {
    if (!lang) {
      const url = window.location.pathname;
      const segments = url.split("/");
      const langSegment = segments[1];
      if (langSegment && langSegment.length === 2) {
        dispatch(setLang(langSegment));
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
    ? formatPhoneNumberIntl(`${phone}`)
    : formatPhoneNumberIntl(`+${phone}`);

  const ButtonsGroupComponent = (clazz = "", size) => (
    <div className={`model__buttons ${clazz}`}>
      {tag_booking ? (
        <Button title={t("order")} size={size} clazz={"button_outline--green"}>
          <Icon spritePath={"pay-content"} size={"l"} />
          {t("order")}
        </Button>
      ) : null}

      {ready_videochat ? (
        <Button
          clazz={`button--green`}
          size={size}
          square={windowWidth < 479.98 ? true : null}
        >
          {windowWidth < 479.98 ? (
            <Icon size={"xl"} spritePath={"video"} />
          ) : (
            <>
              <Icon size={"l"} spritePath={"video"} />
              {t("videochat")}
            </>
          )}
        </Button>
      ) : null}

      <ButtonLink
        size={size}
        clazz={`button--primary td-none${!tag_booking ? " width-100" : ""}`}
        square={windowWidth < 767.98 && tag_booking ? true : null}
      >
        {windowWidth < 767.98 && tag_booking ? (
          <Icon size={"xl"} spritePath={"call"} />
        ) : (
          phoneRes
        )}
      </ButtonLink>

      {whatsapp ? (
        <ButtonLink clazz={"button--primary"} square={true} size={size}>
          <Icon size={"xl"} spritePath={"whatsapp"} />
        </ButtonLink>
      ) : null}

      {telegram ? (
        <ButtonLink clazz={"button--primary"} square={true} size={size}>
          <Icon size={"xl"} spritePath={"telegram"} />
        </ButtonLink>
      ) : null}
    </div>
  );

  const [activeContentTab, setActiveContentTab] = useState(0);

  // const [mediaPhoto, setMediaPhoto] = useState([])
  // useEffect(() => {
  // 	// setMediaPhoto(prevArr => [...prevArr, media.photo])
  // }, [media && media.photo && media.photo.length > 0])
  // function checkMedia() {
  //
  // 	setMediaPhoto()
  // }

  const scheduleData = {
    workTime: work_time,
    icon: "time-plan",
    prices,
    discounts,
  };

  const postCity = t("incity")?.replace(/%([^%]+)%/g, city.name);

  const [isOpenEditEro, setIsOpenEditEro] = useState(false);
  const [isOpenEditAgency, setIsOpenEditAgency] = useState(false);
  const [isOpenEditGf, setIsOpenEditGf] = useState(false);
  const [isOpenEditAppearance, setIsOpenEditAppearance] = useState(false);
  const [isOpenEditMain, setIsOpenEditMain] = useState(false);
  const [isOpenEditServices, setIsOpenEditServices] = useState(false);
  const [isOpenEditTarifs, setIsOpenEditTarifs] = useState(false);

  console.log("isOpenEditTarifs", isOpenEditTarifs);

  return (
    <main>
      <Helmet>
        <title>
          {`${t("escortmodel")} ${name} ${postCity}. ${
            gendersTitle[gender]
          } – ${orientations()}`}
        </title>
        <meta
          name="description"
          content={`${t("escortmodel")} ${name}${
            description ? ` - ${description.slice(0, 150)}` : ""
          }`}
        />
      </Helmet>
      {isOpenEditGf && (
        <LinkWithGirlfriend
          open={isOpenEditGf}
          setOpen={setIsOpenEditGf}
          slug={profileSlug}
          city_id={city_id}
          // defaultValue={}
        />
      )}
      {isOpenEditAgency && (
        <LinkWithAgencyPopup
          open={isOpenEditAgency}
          setOpen={setIsOpenEditAgency}
          slug={profileSlug}
          // defaultValue={}
        />
      )}
      {isOpenEditTarifs && (
        <TarifsPopup
          setData={setData}
          open={isOpenEditTarifs}
          setOpen={setIsOpenEditTarifs}
          data={data}
        />
      )}
      {isOpenEditAppearance && (
        <AppearancePopup
          setData={setData}
          open={isOpenEditAppearance}
          setOpen={setIsOpenEditAppearance}
          data={data}
        />
      )}
      {isOpenEditMain && (
        <MainPopup
          setData={setData}
          open={isOpenEditMain}
          setOpen={setIsOpenEditMain}
          data={data}
        />
      )}

      {isOpenEditServices && (
        <ServicesPopup
          setData={setData}
          open={isOpenEditServices}
          setOpen={setIsOpenEditServices}
          data={data}
        />
      )}
      <div className={"model__container model"}>
        <section className="model-header">
          <Link to={"/lk/indi/profiles"} className={"model-header__back"}>
            <Icon size={"m"} spritePath={"chevrone-left"} />{" "}
            <span>Назад к анкетам</span>
          </Link>

          <div className={`model-header__body`}>
            {!!stories.length ? (
              <button
                title={t("modelstories")}
                // onClick={() => dispatch(setStoriesStatus(true))}
                className="stories-circle__button model-header__stories"
              >
                <span className={"stories-circle__image"}>
                  {stories && stories[0].media ? (
                    <img src={stories[0].media} alt="" />
                  ) : null}
                </span>
              </button>
            ) : null}

            <div className="model-header__box">
              {(is_top || is_elite) && (
                <picture className="model-header__status">
                  <source
                    srcSet={
                      is_top
                        ? theme === "light"
                          ? statusTopLight
                          : statusTop
                        : statusElite
                    }
                    media="(min-width: 767.98px)"
                  />
                  <img
                    src={
                      is_top
                        ? theme === "light"
                          ? statusTopMobLight
                          : statusTopMob
                        : statusEliteMob
                    }
                    alt=""
                  />
                </picture>
              )}

              {/* <div className="model-header__interactions">
                <Note note={note} profileId={profileSlug} />

                {Favorite()}
              </div> */}

              <div className="model-header__box-info-edit">
                <h1 className="title model-header__name text-dots">{name}</h1>
                <div className="model-header__age">
                  <span>{age}</span> {t("years")}
                </div>
                <EditBlock
                  btnTitle={indi("edit")}
                  mb={0}
                  setOpen={setIsOpenEditMain}
                />
              </div>
            </div>

            <div className="model-header__bottom">
              {!!reviews_count && (
                <a
                  href={"#reviews"}
                  title={t("readreview")}
                  className="model-header__review model__review"
                >
                  <RatingStars gold={true} value={rating} readOnly={true} />
                  <span>
                    {reviews_count} {t("reviewses")}
                  </span>
                </a>
              )}

              {address && (
                <Link
                  to={"#"}
                  title={t("viewonmap")}
                  className="model-header__address text-underline p3 text-dots"
                >
                  <Icon spritePath={"map"} size={"s"} />
                  {address}
                </Link>
              )}
            </div>

            <div className={`model__buttons model-header__buttons`}>
              {tag_booking ? (
                <Button
                  title={t("order")}
                  size={"m"}
                  clazz={"button_outline--green"}
                >
                  <Icon spritePath={"pay-content"} size={"l"} />
                  {t("order")}
                </Button>
              ) : null}

              {ready_videochat ? (
                <Button
                  clazz={`button--green`}
                  size={"m"}
                  square={
                    windowWidth < 767.98 ||
                    (windowWidth < 1000.98 && tag_booking)
                      ? true
                      : null
                  }
                >
                  {windowWidth < 767.98 ||
                  (windowWidth < 1000.98 && tag_booking) ? (
                    <Icon size={"xl"} spritePath={"video"} />
                  ) : (
                    <>
                      <Icon size={"l"} spritePath={"video"} />
                      {t("videochat")}
                    </>
                  )}
                </Button>
              ) : null}

              <ButtonLink
                size={"m"}
                clazz={`button--primary td-none`}
                square={windowWidth < 1199.98 ? true : null}
              >
                {windowWidth < 1199.98 ? (
                  <Icon size={"xl"} spritePath={"call"} />
                ) : (
                  phoneRes
                )}
              </ButtonLink>

              {whatsapp ? (
                <ButtonLink clazz={"button--primary"} square={true} size={"m"}>
                  <Icon size={"xl"} spritePath={"whatsapp"} />
                </ButtonLink>
              ) : null}

              {telegram ? (
                <ButtonLink clazz={"button--primary"} square={true} size={"m"}>
                  <Icon size={"xl"} spritePath={"telegram"} />
                </ButtonLink>
              ) : null}
            </div>
          </div>
        </section>

        <section className="model-main">
          <div className="model-main__sliders">
            {!!media ? (
              <TabSliderModel
                name={name}
                ero={ero}
                media={media}
                clazz={"model-main"}
              />
            ) : null}

            {ero &&
            ero.data &&
            (ero.data.photo_count || ero.data.video_count) ? (
              <PrivateSlider props={{ ero }} setOpenEdit={setIsOpenEditEro} />
            ) : null}
          </div>

          {windowWidth < 1000.98 && windowWidth > 600.98 ? (
            <div className="model__info">
              <ModelInfoContent
                props={data}
                edit
                setOpen={setIsOpenEditAppearance}
              />
            </div>
          ) : null}

          {windowWidth > 1000.98 && porn_star ? (
            <ModelPornstarCard props={{ porn_star }} />
          ) : null}

          {!!gf.length ? (
            <div className={"model-card"}>
              {!!setIsOpenEditGf ? (
                <div
                  className={`d-flex fd-row gap-16 align-center mb-16 justify-sb`}
                >
                  <h3 className="mb-0">{t("girlsprofiles")}</h3>

                  <Button
                    onClick={() => setIsOpenEditGf(true)}
                    size={"xs"}
                    clazz={"button_outline--green"}
                    square={true}
                  >
                    <Icon spritePath={"edit-2"} size={"s"} />
                  </Button>
                </div>
              ) : (
                <h3>{t("girlsprofiles")}</h3>
              )}

              {gf?.map((data) => (
                <GirlCard props={data} mobile={true} />
              ))}
            </div>
          ) : null}

          <div className="model-content tabs__content">
            <div className="tabs__nav mb-8">
              {tabsBtnsContent?.map((btn, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => setActiveContentTab(index)}
                    className={`tabs__nav-btn${
                      activeContentTab === index ? " tabs__nav-btn--active" : ""
                    }`}
                    type="button"
                  >
                    {btn.title}
                  </button>
                );
              })}

              <button
                onClick={() => setActiveContentTab(2)}
                className={`tabs__nav-btn${
                  activeContentTab === 2 ? " tabs__nav-btn--active" : ""
                }`}
                type="button"
              >
                {t("reviews")}
              </button>
            </div>

            <div
              className={`tabs__panel model__info${
                activeContentTab === 0 ? " tabs__panel--active" : ""
              }`}
            >
              <ModelInfoContent
                props={data}
                edit
                setOpen={setIsOpenEditAppearance}
              />
            </div>

            <section
              className={`tabs__panel model__serfices${
                activeContentTab === 1 ? " tabs__panel--active" : ""
              }`}
            >
              <EditBlock
                title={"Услуги"}
                mb={0}
                setOpen={setIsOpenEditServices}
              />
              <ModelServices props={data} />
            </section>

            <div className="model-main__wrapper">
              <EditBlock
                title={"Тарифы, график работы"}
                mb={0}
                setOpen={setIsOpenEditTarifs}
              />
              {(prices && prices.incall && prices.incall.length > 0) ||
              (prices && prices.outcall && prices.outcall.length > 0) ||
              (prices && prices.escort && prices.escort.length > 0) ? (
                <ScheduleTable props={scheduleData} />
              ) : null}

              {(distance || address) && (
                <Button
                  size={"m"}
                  title={t("viewonmap")}
                  clazz={"button--tetriary width-100 p3 gap-12"}
                >
                  {distance && (
                    <span className="model-header__distance p3">
                      <Icon spritePath={"location"} size={"s"} />
                      {distance} {t("kmsfromyou")}
                    </span>
                  )}

                  {address && (
                    <span className="model-header__address p3">
                      <Icon spritePath={"map"} size={"s"} />
                      {address}
                    </span>
                  )}
                </Button>
              )}

              {ButtonsGroupComponent("", "l")}

              {!!gf.length || !!porn_star || !!ready_travel || !!is_escort ? (
                <div className="model__extras">
                  {gf && gf.length > 0 && (
                    <div
                      title={t("girlfriend")}
                      className={"button button-l button--tetriary"}
                    >
                      <Icon spritePath={"girlfriend"} size={"xxl"} />
                      <span className={"d-flex fd-column text-left gap-4 p1"}>
                        {t("girlfriend")}
                        <span>
                          {gf?.map((model, index) => (
                            <Link
                              key={index}
                              target={"_blank"}
                              to={`/${lang}/profile/${model.slug}`}
                              className={"p2 color-600 width-max hover-line"}
                            >
                              {model.slug}
                            </Link>
                          ))}
                        </span>
                      </span>
                    </div>
                  )}

                  {porn_star && (
                    <div
                      title={t("pornstar")}
                      className={"button button-l button--tetriary"}
                    >
                      <Icon spritePath={"star-simple"} size={"xxl"} />
                      <span className={"d-flex fd-column text-left gap-4 p1"}>
                        {t("pornstar")}
                        <Link
                          target={"_blank"}
                          to={`//${porn_star.website_link}`}
                          className={"p2 color-600 hover-line"}
                        >
                          {porn_star.website_link.substring(0, 13)}...
                        </Link>
                      </span>
                    </div>
                  )}

                  {ready_travel && (
                    <div
                      title={t("travel")}
                      className={"button button-l button--tetriary"}
                    >
                      <Icon spritePath={"travel"} size={"xxl"} />
                      <span className={"d-flex fd-column text-left gap-4 p1"}>
                        {t("travel")}
                        <span className={"p2 color-600"}>
                          {t("flyingtoeu")}
                        </span>
                      </span>
                    </div>
                  )}

                  {prices && prices.escort && (
                    <div
                      title={t("accompaniment")}
                      className={"button button-l button--tetriary"}
                    >
                      <Icon spritePath={"escort"} size={"xxl"} />
                      <span className={"d-flex fd-column text-left gap-4 p1"}>
                        {t("accompaniment")}
                        <span className={"p2 color-600"}>{t("nosex")}</span>
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <div className="model-content__cards">
          {windowWidth < 1000.98 && porn_star ? (
            <ModelPornstarCard props={{ porn_star }} />
          ) : null}

          {!!agency.length ? (
            <div className={"model-card"}>
              {!!setIsOpenEditAgency ? (
                <div
                  className={`d-flex fd-row gap-16 align-center mb-16 justify-sb`}
                >
                  <h3 className="mb-0">{t("workingforagency")}</h3>

                  <Button
                    onClick={() => setIsOpenEditAgency(true)}
                    size={"xs"}
                    clazz={"button_outline--green"}
                    square={true}
                  >
                    <Icon spritePath={"edit-2"} size={"s"} />
                  </Button>
                </div>
              ) : (
                <h3>{t("workingforagency")}</h3>
              )}

              {agency?.map((data, index) => (
                <Fragment key={index}>
                  <AgencyCard props={data} buttonColor={"button--tetriary"} />
                </Fragment>
              ))}
            </div>
          ) : null}

          {!!parties.length ? (
            <div className={"model-card"}>
              <h3>{t("nextparty")}</h3>

              {parties?.map((data) => (
                <PartyCard props={data} />
              ))}
            </div>
          ) : null}
        </div>

        {(statistic && statistic.favorites && statistic.favorites > 0) ||
        (statistic && statistic.book && statistic.book > 0) ||
        (statistic && statistic.calls && statistic.calls > 0) ? (
          <ModelCounters statistic={statistic} />
        ) : null}
      </div>

      {ero && ero.data && !(userType === "platinum") ? (
        <ErocontentAccess data={ero} />
      ) : null}

      <Appeal name={name} />

      <AddReview id={id} reviewable_type={"indi"} />

      {tag_booking ? (
        userType === "default" ? null : (
          <OrderModel
            prices={prices}
            address={address}
            name={name}
            slug={profileSlug}
          />
        )
      ) : null}

      {!!stories.length ? (
        <StoriesComponent
          stories={stories}
          descriptionText
          storiesForOneModel
        />
      ) : null}

      {(media && media.photo && media.photo.length > 0) ||
      (media && media.interior && media.interior.length > 0) ||
      (media && media.video && media.video.length > 0) ||
      (ero && ero.data && ero.data.photo && ero.data.photo.length > 0) ? (
        <Gallery name={name} media={media} ero={ero} />
      ) : null}
    </main>
  );
};

export default CurrentModelPage;
