import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { getAfterAuth, setAfterAuth } from "../../../stores/slices/popupSlice";
import { useNavigate } from "react-router-dom";
import {
    getUserType,
    getUserLang
} from "@/stores/slices/userSlice";
const AfterAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { t: indi } = useTranslation("indi");

  const afterAuth = useSelector(getAfterAuth);
  const lang = useSelector(getUserLang)
  const userType = useSelector(getUserType);
  var link = "";
  switch (userType) {
    case "indi":
        link = `/lk/indi/profiles`;
      break;
    case "agency":
        link = `/lk/agency`;
      break;
    case "user":
        link = `/lk/user`;
      break;
    default:
        link = `/`;
  }

  const onClose = () => {
    dispatch(setAfterAuth(false));
  };

  return (
    <>
      <dialog
        open={!!afterAuth}
        onClick={() => onClose()}
        id={"addReview"}
        className="popup-form"
      >
        {!!afterAuth ? (
          <Helmet>
            <html className={"lock"}></html>
          </Helmet>
        ) : null}

        <form
          method={"POST"}
          onClick={(e) => e.stopPropagation()}
          className="popup-form__body gap-16"
        >
          <Button onClick={() => onClose()} clazz={"button__close"}>
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          <h3>{"Успешная авторизация"}</h3>

          <div className="popup-form__inner mt-16">
            <Button
              size={"l"}
              buttonType={"button"}
              clazz={"button--green"}
              onClick={() => {
                dispatch(setAfterAuth(false));
            }}
            >
              {"Остаться на текущей странице"}
            </Button>
            <Button
              size={"l"}
              buttonType={"button"}
              clazz={"button--green"}
              onClick={() => {
                dispatch(setAfterAuth(false));
                navigate(`/${lang}${link}`)
            }}
            >
              {indi('toprofile')}
            </Button>
          </div>
        </form>
      </dialog>
    </>
  );
};

export default AfterAuth;
