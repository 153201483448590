import Helmet from "react-helmet";
import { Button, Checkbox, Icon, InputInLabel, Loader } from "@/components/ui";
import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import { getUserLang } from "@/stores/slices/userSlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import showToast from "../../toast/Toast";

import "./linkWithAgencyPopup.scss";

const Res = ({ isLoading, agencyOptions, value, handleAddChange, search }) => {
  switch (true) {
    case isLoading: {
      return <Loader />;
    }

    case !!agencyOptions.length: {
      return agencyOptions.map((i) => {
        const { image, id, name, slug: slugAgency } = i;

        return (
          <div key={id} className="agency-block">
            <img src={image} alt="agency" />
            <Link className="agency-block__link-to">{name}</Link>
            <Checkbox
              // radio={true}
              border={false}
              checked={slugAgency === value}
              onChange={() => handleAddChange(slugAgency)}
            />
          </div>
        );
      });
    }

    case !!search && !!agencyOptions.length: {
      return (
        <span className="w-100 text-center">
          Начните вводить название агенства
        </span>
      );
    }

    case !!search && !agencyOptions.length: {
      return (
        <span className="w-100 text-center">
          По данному запросу ничего не найдено
        </span>
      );
    }

    default: {
      return (
        <span className="w-100 text-center">
          Начните вводить название агенства
        </span>
      );
    }
  }
};

const LinkWithAgencyPopup = (props) => {
  const { open, setOpen, slug: slugProfile } = props;
  const { t: indi } = useTranslation("indi");
  const token = localStorage.getItem("user");
  const lang = useSelector(getUserLang);

  const [agencyOptions, setAgencyOptions] = useState([]);

  const [value, setValue] = useState(null);
  const [isLoading, seiIsLoading] = useState(false);

  const [search, setSearch] = useState("");

  const onClose = () => {
    setValue(null);
    setOpen(false);
  };

  const handleAddChange = (slugAgency) => {
    if (slugAgency === value) {
      setValue(null);
    } else {
      setValue(slugAgency);
    }
  };

  const handleFilter = (e) => {
    const val = e.currentTarget.value;
    setSearch(val);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        const route = `user/profile/${slugProfile}/add-agency`;
        const method = "POST";
        const payload = {
          agency: value,
        };

        await makeRequest({ route, method, payload });
        onClose();
        showToast({
          message: indi("success"),
          variant: "succes",
        });
      } catch (error) {
        showToast({
          message: indi("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        seiIsLoading(true);
        const route = `agencies`;
        const method = "GET";
        const payload = {
          lang,
          search,
        };

        const { agencies } = await makeRequest({ route, method, payload });
        setAgencyOptions(agencies);
        seiIsLoading(false);
      } catch (error) {}
    };

    if (!search) {
      setAgencyOptions([]);
    }

    !!search && token && fetchData();
  }, [token, lang, search]);

  return (
    <dialog open={open} onClick={onClose}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        onSubmit={onSubmit}
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-24"
      >
        <Button onClick={onClose} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner text-center">
          <h3>{indi("connectagency")}</h3>

          <span className={"color-main"}>{indi("linkagencyinfo")}</span>
        </div>

        <InputInLabel
          placeholder={"Поиск агенства"}
          onChange={handleFilter}
          value={search}
        />

        <div className="agency-block__content">
          <Res
            isLoading={isLoading}
            agencyOptions={agencyOptions}
            value={value}
            handleAddChange={handleAddChange}
            search={search}
          />
        </div>

        <Button
          size={"l"}
          disabled={!value}
          buttonType={"submit"}
          clazz={"button--green justify-center"}
        >
          {indi("confirm")}
        </Button>
      </form>
    </dialog>
  );
};

export default LinkWithAgencyPopup;
