import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: [],
};

export const titleSlice = createSlice({
  name: "title",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      const value = String(action.payload);
      const isSelected = state.title.includes(value);

      let updatedSelectedCheckboxes = [];
      if (!isSelected) {
        updatedSelectedCheckboxes = [...state.title, value];
      } else {
        updatedSelectedCheckboxes = state.title.filter(
            (item) => item !== value
        );
      }

      state.title = updatedSelectedCheckboxes;
    },
    setDefaultTitle: (state, action) => {
      if(state.title.length === 0) {
        const replacements = {
          "pornstar": "star",
          "elitelady": "elite",
          "vchat": "videochat",
          "booking": "onlineorder",
          "ero": "erocontent",
          "verified": "verification",
          "couple": "couples",
          "male": "guys"
      };
      const result = action.payload.map(item => replacements[item] || item);

        state.title = result;
      }
    },
    clearTitle: (state) => {
      state.title = []
    }
  },
});

export const { setTitle, setDefaultTitle, clearTitle } = titleSlice.actions;

export const getTitle = (state) => state.title.title;

export default titleSlice.reducer;
