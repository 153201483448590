import { useDispatch, useSelector } from "react-redux";
import {
  setLadyForCall,
  getCreateVideoRoomModalStatus,
  getLadyForCall,
  setVideoCallRoom,
} from "@/stores/slices/videoCallSlice";
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { useEffect } from "react";
import useVideoCallService from "@/services/VideoChatService";

import "./CreateVideoCallRoom.scss";
import { getUserID } from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CreateVideoCallRoom = () => {
  const { createVideoCallRoom } = useVideoCallService();
  const dispatch = useDispatch();

  const { t } = useTranslation("translation");

  const modalStatus = useSelector(getCreateVideoRoomModalStatus);
  const userID = useSelector(getUserID);
  const ladyForCall = useSelector(getLadyForCall);
  const createRoom = () => {
    createVideoCallRoom(ladyForCall.slug)
      .then((response) => {
        if (!response.data.status) {
          toast.warning("busy");
          return;
        }
        if (response.data.room_id) {
          dispatch(setVideoCallRoom(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    document.addEventListener("keyup", (event) => {
      if (event.isComposing || event.key === "Escape") {
        dispatch(setLadyForCall(null));
      }
    });
  }, []);

  return (
    <dialog
      open={!!modalStatus}
      onCancel={() => dispatch(setLadyForCall(null))}
      onClick={() => dispatch(setLadyForCall(null))}
      className="popup-form start-videochat"
    >
      {!!modalStatus ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <div onClick={(e) => e.stopPropagation()} className="popup-form__body">
        <Button
          onClick={() => dispatch(setLadyForCall(null))}
          clazz={"button__close"}
        >
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner">
          <h2 className="text-center">{t("startvideochat")}</h2>
          <p className={"p2 text-center"}>{t("meetmodelonline")}</p>
        </div>

        <div className="popup-form__inner">
          <div className="start-videochat__box">
            <div>
              <p className={"p2 color-green"}>{t("freewithtariff")} </p>
              <span className={"title_h3 color-green"}>10 минут</span>
            </div>
            <div>
              <p className={"p2"}>{t("after")} </p>
              <span className={"title_h3"}>
                <span className={"old-price"}>3€</span> 2€/минута
              </span>
            </div>
          </div>

          <p className={"p2 text-center"}>{t("partlytransfered")}</p>
        </div>
        <Button
          size={"l"}
          clazz={"button--green"}
          onClick={() => dispatch(createRoom)}
        >
          {" "}
          {t("call")}
        </Button>
      </div>
    </dialog>
  );
};
export default CreateVideoCallRoom;
