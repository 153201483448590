import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { HashLink } from "react-router-hash-link";
import "../button/button.scss";

const ButtonLink = (props) => {
  const {
    children,
    href,
    size,
    clazz,
    square = false,
    title,
    state,
    onClick,
    counter,
    anchor = false,
    target,
    disabled,
    ref,
  } = props;

  if (square === true) {
    return (
      <Link
        onClick={onClick}
        ref={ref}
        state={{ state }}
        to={href}
        title={title}
        data-counter={counter}
        target={target}
        disabled={disabled}
        className={`button-square button-square-${size} ${clazz ? clazz : ""}${
          counter < 1 ? " empty" : ""
        }`}
      >
        {children}
      </Link>
    );
  }
  if (anchor === true) {
    return (
      <HashLink
        ref={ref}
        smooth
        duration={500}
        to={href}
        state={{ state }}
        onClick={onClick}
        data-counter={counter}
        title={title}
        disabled={disabled}
        className={`button${size ? ` button-${size}` : ""}${
          clazz ? ` ${clazz}` : ""
        }${counter < 1 ? " empty" : ""}`}
      >
        {children}
      </HashLink>
    );
  }

  return (
    <Link
      onClick={onClick}
      ref={ref}
      state={{ state }}
      to={href}
      title={title}
      target={target}
      data-counter={counter}
      disabled={disabled}
      className={`button${size ? ` button-${size}` : ""}${
        clazz ? ` ${clazz}` : ""
      }${counter < 1 ? " empty" : ""}`}
    >
      {children}
    </Link>
  );
};

ButtonLink.propTypes = {
  square: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.string,
  buttonType: PropTypes.string,
  clazz: PropTypes.string,
  href: PropTypes.string,
};
export default ButtonLink;
