import { useTranslation } from "react-i18next";
import PopupContainer from "../PopupContainer.tsx";
import { Button } from "@/components/ui";

const MinBalancePopup = (props) => {
  const { t: indi } = useTranslation("indi");

  return (
    <>
      <PopupContainer {...props} title={indi("insuff")} isForm={false}>
        <span className="text-center p2">
          {indi("insuffonbal")} {indi("thistran")}
        </span>
        <Button
          size={"l"}
          buttonType={"submit"}
          clazz={"button--green justify-center"}
        >
          {indi("topupbalance")}
        </Button>
      </PopupContainer>
    </>
  );
};

export default MinBalancePopup;
