import { useTranslation } from "react-i18next";

import { Button, InputInLabel } from "@/components/ui";

import { object, ref, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { makeRequest } from "@/services/makeRequest";

import showToast from "../../toast/Toast";

import { useState } from "react";
import PopupContainer from "../PopupContainer.tsx";

const ChangePasswordPopup = (props) => {
  const { setOpen } = props;

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const [isLoading, setIsLoading] = useState(false);

  const [errorCurrent, setErrorCurrent] = useState("");

  const formSchema = object().shape({
    old_password: string().min(6, t("min6")).required(),
    new_password: string().min(6, t("min6")).required(),
    password_confirmation: string()
      .min(6, t("min6"))
      .required()
      .oneOf([ref("new_password")], t("passdonot")),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsLoading(true);

    const { old_password, new_password } = data;

    const fetchData = async () => {
      try {
        const route = `user/change-password`;
        const method = "PUT";
        const payload = { current_password: old_password, new_password };

        await makeRequest({ route, method, payload });
        reset();
        setOpen(false);
        showToast({
          message: indi("pasupdate"),
          variant: "succes",
        });
      } catch (error) {
        const res = error.response;

        switch (res.status) {
          case 401: {
            showToast({
              message: indi("noauth"),
              variant: "error",
            });
            break;
          }
          default:
          case 422: {
            setErrorCurrent(indi("currentpasdecline"));
            break;
          }
        }
      }
    };

    fetchData();
  };

  const handleCloseDelete = () => {
    reset();
    setOpen(false);
  };

  return (
    <PopupContainer
      onSubmit={onSubmit}
      handleCloseCustom={handleCloseDelete}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      {...props}
      title={indi("changepass")}
    >
      <InputInLabel
        clazz={`${!!errorCurrent ? " error" : ""}`}
        id={"oldPassword"}
        title={indi("oldpass")}
        type={"password"}
        placeholder={"**********"}
        register={{ ...register("old_password") }}
      />
      {!!errorCurrent && (
        <span className={"popup-form__error"}>{errorCurrent}</span>
      )}
      <InputInLabel
        clazz={`${errors.password_confirmation ? " error" : ""}`}
        id={"newPassword"}
        title={indi("newpas")}
        type={"password"}
        placeholder={"**********"}
        register={{ ...register("new_password") }}
        autocomplete={"new-password"}
      />
      <InputInLabel
        clazz={`${errors.password_confirmation ? " error" : ""}`}
        id={"confirmationNewPassword"}
        title={indi("repeatpas")}
        type={"password"}
        placeholder={"**********"}
        register={{ ...register("password_confirmation") }}
        autocomplete={"new-password"}
      />
      {errors.password_confirmation && (
        <span className={"popup-form__error"}>
          {errors.password_confirmation.message}
        </span>
      )}

      <Button
        size={"l"}
        buttonType={"submit"}
        clazz={"button--green justify-center"}
      >
        {t("save")}
      </Button>
    </PopupContainer>
  );
};

export default ChangePasswordPopup;
