import { Loader, Tippy } from "@/components/ui";
import "./connectPromotionAd.scss";
import { Tooltip } from "react-tippy";

import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";

import PopupContainer from "../PopupContainer.tsx";

import { useTranslation } from "react-i18next";

import ConnectPromotionAdPopup from "./ConnectPromotionAd";

import { viewList } from "../../pages/accounts/model/profiles/card/components/upAd/viewList";
import { Button } from "../../ui";

const PromotionTablePopup = (props) => {
  const { city, slug, position } = props;

  const [currentPosition, setCurrentPosition] = useState(position);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectPosition, setSelectPosition] = useState(null);
  const [selectPrice, setSelectPrice] = useState(null);

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");

  const [isOpenUp, setIsOpenUp] = useState(false);

  const handleOpenUp = ({ place, price }) => {
    setSelectPrice(price);
    setSelectPosition(place);
    setIsOpenUp(true);
  };

  const postCity = t("incity")?.replace(/%([^%]+)%/g, city);

  const newData = data.slice(0, currentPosition);

  const calculatePercentageValue = ({ currentPosition, position }) => {
    if (!currentPosition || !newData.length) {
      return null;
    } else {
      const mt = viewList[currentPosition - 1]["view"];

      const mp = viewList[position - 1]["view"];

      const res = Math.floor(((mp - mt) / mt) * 100);

      return res;
    }
  };

  const calculatePercentageWidth = (maxPercent, currentPercent) => {
    console.log("maxPercent", maxPercent);
    console.log("currentPercent", currentPercent);

    if (maxPercent === currentPercent) {
      return 100;
    }

    console.log(
      "currentPercent + currentPercent / (maxPercent - currentPercent)",
      currentPercent + currentPercent / (maxPercent - currentPercent)
    );

    return Math.floor(
      currentPercent + currentPercent / (maxPercent - currentPercent)
    );
  };

  const calcFirst = calculatePercentageValue({
    currentPosition,
    position: 1,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `places/${slug}`;

        const method = "GET";

        const { positions } = await makeRequest({ route, method });
        const { available, current } = positions;

        setData(available);
        setCurrentPosition(current);
        setIsLoading(false);
      } catch (error) {}
    };

    fetchData();
  }, [slug]);

  const [showTippy, setshowTippy] = useState(
    new Array(newData.length).fill(false)
  );

  const handleMouseEnter = (index) => {
    setshowTippy(showTippy.map((state, i) => (i === index ? true : false)));
  };

  const handleMouseLeave = () => {
    setshowTippy(new Array(newData.length).fill(false));
  };

  const Tilte = () => {
    return (
      <>
        {indi("currentprices")} <p>{postCity}</p>
      </>
    );
  };

  return (
    <>
      <PopupContainer
        {...props}
        title={<Tilte />}
        isForm={false}
        clazz={"gap-0 p1"}
      >
        {isOpenUp && (
          <ConnectPromotionAdPopup
            open={isOpenUp}
            setOpen={setIsOpenUp}
            position={selectPosition}
            price={selectPrice}
            currentPrice={newData[position - 1]}
            slugProfile={slug}
          />
        )}

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={"up-table__body"}>
              <div className="up-table__header">
                <div>{t("place")}</div>
                <div>{t("price")}</div>
                <div>Плюс к просмотрам</div>
              </div>
              {newData.map((item, index) => {
                const calc = calculatePercentageValue({
                  currentPosition,
                  position: index + 1,
                });

                const width = calculatePercentageWidth(calcFirst, calc);

                console.log("calc", calc);

                console.log("width", width);

                return (
                  <div
                    key={index}
                    className={
                      index + 1 === currentPosition
                        ? "up-table__row up-table__row__current"
                        : "up-table__row"
                    }
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() =>
                      handleOpenUp({ place: index + 1, price: item + 1 })
                    }
                  >
                    <div>{index + 1}</div>

                    <Tooltip
                      className={`tippy`}
                      position={"bottom-start"}
                      arrow={true}
                      sticky={true}
                      // open={showTippy?.includes(index)}
                      // trigger="click"
                      open={showTippy[index]}
                      html={
                        <div>
                          <Button>mmm</Button>
                        </div>
                      }
                    >
                      {index + 1 === currentPosition ? item : item + 1} €
                    </Tooltip>

                    <div style={{ "--percent": `${width}%` }}>
                      {index + 1 === currentPosition
                        ? "Текущая позиция"
                        : "+" + calc + " %"}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PopupContainer>
    </>
  );
};

export default PromotionTablePopup;
