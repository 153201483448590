import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserBalance,
  getUserCity,
  getUserCurrentCity,
  getUserLang,
  setReadyToOrder,
  setReadyToVideochat,
  setUserStatus,
  setUserType,
} from "@/stores/slices/userSlice";
import { useEffectWindowSize } from "../../hooks";
import {
  Button,
  ButtonLink,
  Icon,
  Toggle,
  Select,
  SwitchTheme,
  BurgerMenu,
  LogoSvg,
} from "@/components/ui";
import { cleanSorted } from "@/stores/slices/modelsState";
import { clearTitle } from "@/stores/slices/titleSlice";

import { resetAll, setSearchFilter } from "@/stores/slices/filterSlice";
import { themeMode } from "@/stores/slices/userSlice";

import logoDarkScrolled from "../../assets/img/logo-scroll--dark.svg";
import logoLightScrolled from "../../assets/img/logo-scroll--light.svg";
import "./header.scss";
import { useTranslation } from "react-i18next";
import { selectActiveTabId, setActiveTabId } from "@/stores/slices/tabSlice";
import { makeRequest } from "@/services/makeRequest";
import { getUserOrderStatus } from "@/stores/slices/userSlice";
import { getUserVideoStatus } from "@/stores/slices/userSlice";

import { useLocation } from "react-router-dom";

import showToast from "../toast/Toast";

const IndiHeaderLk = () => {
  const widthScreen = useEffectWindowSize();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector(getUserLang);
  const currentCity = useSelector(getUserCurrentCity);
  const city = useSelector(getUserCity);

  const { t } = useTranslation();
  const { t: indi } = useTranslation("indi");

  const postAltLogo = t("altlogo")?.replace(/%([^%]+)%/g, currentCity.title);

  const languages = {
    main_title: "",
    options: [
      { id: 1, link: `/en/${city}`, title: "EN", value: "en" },
      { id: 2, link: `/ru/${city}`, title: "RU", value: "ru" },
      { id: 3, link: `/cz/${city}`, title: "CZ", value: "cz" },
    ],
  };

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const [scrolledClass, setScrolledClass] = useState("");

  useEffect(() => {
    if (widthScreen > 767.98) {
      window.addEventListener("scroll", () => {
        if (window.scrollY < 1) {
          setScrolledClass("");
        } else {
          setScrolledClass(" _scroll");
        }
      });
    }
  }, [widthScreen]);

  const theme = useSelector(themeMode);

  const getThemeForLogo = () => {
    if (theme === "dark") {
      return logoDarkScrolled;
    } else {
      return logoLightScrolled;
    }
  };

  const logOut = () => {
    dispatch(setUserType("default"));
    dispatch(setUserStatus("silver"));
    localStorage.removeItem("user");
    navigate(`/`);
  };

  const handleLogo = (e) => {
    e.preventDefault();

    navigate(`/${lang}/${city}`);

    dispatch(cleanSorted());
    dispatch(resetAll());
    dispatch(clearTitle());
    dispatch(setSearchFilter(false));

    if (scrolledClass) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const isVideo = useSelector(getUserVideoStatus);
  const isRes = useSelector(getUserOrderStatus);
  const balance = useSelector(getUserBalance);

  const onChangeVideochat = async () => {
    try {
      dispatch(setReadyToVideochat(Number(!isVideo)));

      const route = `user/set-call-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isVideo),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isVideo)));
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const onChangeOrder = async () => {
    try {
      dispatch(setReadyToOrder(Number(!isRes)));
      const route = `user/set-booking-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isRes),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isRes)));
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const Logo = () => (
    <Link
      to={`/${lang}/${city}`}
      title={t("titlelogo")}
      className="logo"
      onClick={(e) => handleLogo(e)}
    >
      {scrolledClass === " _scroll" && widthScreen > 767.98 ? (
        <img src={getThemeForLogo()} alt={postAltLogo} />
      ) : (
        <LogoSvg alt={postAltLogo} />
      )}
    </Link>
  );

  const LogOutButton = (size = "xs", square = false) => (
    <Button
      onClick={() => logOut()}
      size={size}
      square={square}
      clazz="user__log-out"
    >
      <Icon size={"l"} spritePath={"logout"} />
    </Button>
  );

  const UserNotification = (size = "s") => (
    <Button
      size={widthScreen < 767.98 ? "xxs" : size}
      square={true}
      clazz={`user__notification${
        widthScreen > 767.98 ? " button--tetriary" : ""
      }`}
      counter={0}
    >
      <Icon size={"l"} spritePath={"notify"} />
    </Button>
  );

  const InstructionLink = (clazz) => (
    <ButtonLink
      href={`/guide`}
      title={t("guide")}
      size={"xs"}
      clazz={`add-form${
        clazz ? ` ${clazz}` : ""
      } button_outline--green _no-underline`}
    >
      <Icon size={"m"} spritePath={"instruction-cont"} />
      {widthScreen > 1199.98 ? t("guide") : null}
    </ButtonLink>
  );

  const DropdownLanguage = () => (
    <Select
      options={languages.options}
      clazz={"button button-xs button--main"}
      arrowSize={"s"}
      sorted={true}
      name={"language"}
    ></Select>
  );

  const topLinksTabs = [
    { id: 1, name: t("profuser"), href: "/lk/indi" },
    { id: 2, name: indi("myads"), href: "/lk/indi/profiles" },
    { id: 3, name: t("reviews"), href: "/lk/indi/reviews" },
    { id: 4, name: indi("mystat"), href: "/lk/indi/statistic" },
    { id: 5, name: t("balance"), href: "/lk/indi/balance", wallet: balance },
  ];

  const handleTabClick = (e, i) => {
    e.preventDefault();

    navigate(`/${lang}${i.href}`)
    dispatch(setActiveTabId(i.id));
  };

  const { pathname } = useLocation();

  const activeTabId = useSelector(selectActiveTabId);

  useEffect(() => {
    const activeTab = topLinksTabs.find((tab) => {
      const newUrl = tab.href.replace(/.*\/(lk)/, "$1");
      const newPathname = pathname.replace(/.*\/(lk)/, "$1");

      return newUrl === newPathname;
    });

    dispatch(setActiveTabId(activeTab?.id));
  }, [pathname]);

  const HeaderTabs = () => {
    return topLinksTabs.map((i) => {
      return (
        <Link
          data-counter={i.count}
          key={i.id}
          className={`header__tab p2${i.count < 1 ? " empty" : ""} ${
            activeTabId === i.id ? "_active" : ""
          }`}
          to={i.href}
          onClick={(e) => handleTabClick(e, i)}
        >
          {i.name}
          {i.wallet >= 0 ? (
            <span className="color-green">{i.wallet}€</span>
          ) : null}
        </Link>
      );
    });
  };

  const FloatBurgerMenu = () => {
    const headerIcons = [
      {
        id: 1,
        icon: "video",
        name: t("videochat"),
        link: "/lk/indi/videochats",
      },
      {
        id: 2,
        icon: "pay-content",
        name: indi("myorder"),
        link: "/lk/indi/reservations",
      },
      {
        id: 3,
        icon: "user-circle",
        name: t("profuser"),
        link: "/lk/indi",
      },
      {
        id: 4,
        icon: "user-id",
        name: indi("myads"),
        link: "/lk/indi/profiles",
      },
      {
        id: 5,
        icon: "star",
        name: t("reviews"),
        link: "/lk/indi/reviews",
      },
      {
        id: 6,
        icon: "stat",
        name: indi("mystat"),
        link: "/lk/indi/statistic",
      },
      {
        id: 7,
        name: t("balance"),
        link: "/lk/indi/balance",
        wallet: balance,
      },
      // {
      //   id: 8,
      //   icon: "camera",
      //   name: t("photographer"),
      //   link: "#",
      // },
      {
        id: 8,
        icon: "instruction-cont",
        name: t("guide"),
        link: `/guide`,
      },
    ];

    return (
      <div className={`users${isOpenMenu ? " _active" : ""}`}>
        <div className={"users__nav"}>
          {headerIcons.map((i) => {
            if (i.name === t("balance")) {
              return (
                <ButtonLink
                  href={i.link}
                  key={i.id}
                  title={i.name}
                  size={"l"}
                  clazz="menu__link _no-underline"
                  counter={i.counter}
                  onClick={() => setIsOpenMenu((prev) => !prev)}
                >
                  <span className={"menu__wallet"}>{i.wallet}€</span>
                  <span>{i.name}</span>
                </ButtonLink>
              );
            }

            return (
              <ButtonLink
                href={i.link}
                key={i.id}
                title={i.name}
                size={"l"}
                clazz="menu__link _no-underline"
                counter={i.counter}
                onClick={() => setIsOpenMenu((prev) => !prev)}
              >
                <Icon size={"xl"} clazz={i.clazz} spritePath={i.icon} />
                <span>{i.name}</span>
              </ButtonLink>
            );
          })}
        </div>

        <div className="users__bottom">
          <ButtonLink
            href={"/"}
            size={"m"}
            clazz={"button--tetriary td-none width-100"}
            onClick={() => setIsOpenMenu((prev) => !prev)}
          >
            {indi("backtoweb")}
          </ButtonLink>

          <Button onClick={() => logOut()} clazz="user__log-out">
            {indi("logout")}
            <Icon size={"m"} spritePath={"logout"} />
          </Button>
        </div>
      </div>
    );
  };

  const MenuNavBar = () => {
    const headerIconsMenuBar = [
      {
        id: 1,
        icon: "user-circle",
        name: t("profuser"),
        link: "/lk/indi",
      },
      {
        id: 2,
        icon: "user-id",
        name: indi("myads"),
        link: "/lk/indi/profiles",
      },
      {
        id: 3,
        icon: "star",
        name: t("reviews"),
        link: "/lk/indi/reviews",
      },
      {
        id: 4,
        icon: "stat",
        name: indi("mystat"),
        link: "/lk/indi/statistic",
      },
      {
        id: 5,
        name: t("balance"),
        link: "/lk/indi/balance",
        wallet: balance,
      },
      {
        id: 6,
        icon: "undo-left",
        name: indi("backtomain"),
        link: `/${lang}/${city}`,
      },
    ];

    return (
      <div className="menu__body">
        {headerIconsMenuBar.map((i) => {
          if (i.name === t("balance")) {
            return (
              <ButtonLink
                href={i.link}
                key={i.id}
                title={i.name}
                size={"l"}
                clazz="menu__link _no-underline"
                counter={i.counter}
              >
                <span className={"menu__wallet"}>{i.wallet}€</span>
                <span>{i.name}</span>
              </ButtonLink>
            );
          }

          return (
            <ButtonLink
              href={i.link}
              key={i.id}
              title={i.name}
              size={"l"}
              clazz="menu__link"
              counter={i.counter}
            >
              <Icon size={"l"} clazz={i.clazz} spritePath={i.icon} />
              <span>{i.name}</span>
            </ButtonLink>
          );
        })}
      </div>
    );
  };

  const UserWrapperDesktop = () => {
    return (
      <div className="user__wrapper">
        {UserNotification("s")}

        <ButtonLink size={"s"} clazz={"button--tetriary td-none"} href="/">
          {t("mainpage")}
        </ButtonLink>

        {LogOutButton()}
      </div>
    );
  };

  const ModelHeaderContent = () => {
    let variationsOfContent = () => {
      return (
        <>
          <Toggle
            link={"/lk/indi/videochats"}
            title={t("videochat")}
            counter={5}
            id={"readyToVideochat"}
            checked={!!isVideo}
            onChange={onChangeVideochat}
          />

          <Toggle
            link={"/lk/indi/reservations"}
            title={indi("myorder")}
            counter={2}
            id={"readyToOrder"}
            checked={!!isRes}
            onChange={onChangeOrder}
          />
        </>
      );
    };

    if (widthScreen > 1199.98)
      return (
        <>
          <div className="header__top">
            {/* <ButtonLink
              href={"#"}
              title={t("photographer")}
              size={"xs"}
              clazz={"add-form button_outline--green _no-underline"}
            >
              <Icon size={"m"} spritePath={"camera"} />
              {t("photographer")}
            </ButtonLink> */}

            {InstructionLink()}

            {variationsOfContent()}

            <ButtonLink
              href={"#"}
              title={t("support")}
              size={"xs"}
              clazz={"button--tetriary _no-underline"}
              counter={12}
            >
              <Icon size={"m"} spritePath={"operator"} />
              {t("support")}
            </ButtonLink>

            {DropdownLanguage()}

            <SwitchTheme type={"switch"} />
          </div>

          <div className="header__bottom">
            {Logo()}

            <div className={"header__tabs"}>{HeaderTabs()}</div>

            {UserWrapperDesktop()}
          </div>
        </>
      );

    if (widthScreen > 767.98)
      return (
        <>
          <div className="header__top">
            {Logo()}

            <div className="user__wrapper">
              {variationsOfContent()}

              {UserNotification()}

              <ButtonLink
                href={"#"}
                title={t("support")}
                size={"s"}
                clazz={"button--tetriary _no-underline"}
                counter={0}
                square={true}
              >
                <Icon size={"m"} spritePath={"operator"} />
              </ButtonLink>

              <BurgerMenu
                isOpenMenu={isOpenMenu}
                setIsOpenMenu={setIsOpenMenu}
              />
              <SwitchTheme type={"switch"} />
              {FloatBurgerMenu()}
            </div>
          </div>
        </>
      );

    return (
      <>
        <div className="header__top">
          <div className="user__wrapper">
            <BurgerMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
            {DropdownLanguage()}
          </div>

          {Logo()}

          <div className="user__wrapper">
            {UserNotification("xs")}
            {/* <ButtonLink
              href={"#"}
              title={t("support")}
              size={"xs"}
              clazz={"button--tetriary _no-underline"}
              counter={0}
              square={true}
            >
              <Icon size={"s"} spritePath={"operator"} />
            </ButtonLink> */}
            <SwitchTheme type={"switch"} />
          </div>

          {FloatBurgerMenu()}
          {MenuNavBar()}
        </div>

        <div className="header__bottom">{variationsOfContent()}</div>
      </>
    );
  };

  return (
    <header
      className={`header--type-two${
        scrolledClass && widthScreen > 767.98 ? ` ${scrolledClass}` : ""
      }`}
    >
      <div className="header__container">
        <ModelHeaderContent />
      </div>
    </header>
  );
};
export default IndiHeaderLk;
