import React from "react";
import {useTranslation} from "react-i18next";

const ModelCounters = ({statistic}) => {
  const {t} = useTranslation("translation");
  return(
    <div className="model__counters">
      {statistic.favorites && statistic.favorites > 0 ?
        <div className="model__counter">
          <span className={'p2'}>{t("infavorites")}</span>
          {statistic.favorites}
        </div>
        : null}

      {statistic.book && statistic.book > 0 ?
        <div className="model__counter">
          <span className={'p2'}>{t("prepaidorders")}</span>
          {statistic.book}
        </div>
        : null}

      {statistic.calls && statistic.calls > 0 ?
        <div className="model__counter">
          <span className={'p2'}>{t("videochats")}</span>
          {statistic.calls}
        </div>
        : null}
    </div>
  )
}
export default ModelCounters