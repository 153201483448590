import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import ScheduleTable from "../../scheduleTable/ScheduleTable";
import {
  Banner,
  Breadcrumbs,
  Button,
  ButtonLink,
  Checkbox,
  Dropdown,
  Icon,
  ShowMoreText,
  Video,
} from "@/components/ui";
import { Link, useParams } from "react-router-dom";
import Reviews from "../../reviews/Reviews";
import Slider from "../../sliders/Slider";
import { SwiperSlide } from "swiper/react";
import PartyCard from "../../partyCard/PartyCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavoriteCounterStatus,
  getUserCity,
  getUserLang,
  setLang,
  themeMode,
} from "@/stores/slices/userSlice";
import { useEffectWindowSize } from "../../../hooks";
import axios from "axios";
import Appeal from "../../popups/appeal/Appeal";
import AddReview from "../../popups/addReview/AddReview";
import { PartyAccess } from "../../popups";
import {
  setErocontentAccessStatus,
  setGalleryIndexImg,
  setGalleryStatus,
  setStoriesStatus,
} from "@/stores/slices/popupSlice";
import AgencyFilter from "../../agencyFilter/AgencyFilter";
import TabSlider from "../../sliders/tabSlider/TabSlider";
import girls_slider_db from "../../../db/girls-slider_db";
import interior_db from "../../../db/interior_db";
import StoriesComponent from "../../stories/StoriesComponent";
import { makeRequest } from "@/services/makeRequest";
import Gallery from "../../popups/gallery/Gallery";
import OrderViewing from "../../popups/orderViewing/OrderViewing";
import { setStoriesId } from "@/stores/slices/modelsState";

import "./agencyPage.scss";
import imgBanner from "../../../assets/img/agency/agency-banner.webp";
import imgBannerMobile from "../../../assets/img/agency/agency-banner-mob.webp";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";

const AgencyPage = (props) => {
  const { dispatch, lang, city, country } = useLadyService();
  const { t } = useTranslation("translation");
  const [oneAgencyData, setOneAgencyData] = useState(null);

  const { agencyId } = useParams();

  const {
    id = oneAgencyData?.id,
    slug = oneAgencyData?.slug,
    name = oneAgencyData?.name,
    address = oneAgencyData?.address,
    addresses = oneAgencyData?.addresses,
    phone = oneAgencyData?.phone,
    rating = oneAgencyData?.rating,
    description = oneAgencyData?.description,
    whatsapp = oneAgencyData?.whatsapp,
    telegram = oneAgencyData?.telegram,
    website = oneAgencyData?.website,
    profiles_statistic = oneAgencyData?.profiles_statistic,
    profiles = oneAgencyData?.profiles,
    parties = oneAgencyData?.parties,
    cover = oneAgencyData?.cover,
    image = oneAgencyData?.image,
    video = null,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `agencies/${agencyId}`;
        const method = "GET";
        const payload = {
          lang,
          city,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.data;
          setOneAgencyData(result);
          console.log(result);
        }
      } catch (error) {}
    };

    agencyId && lang && city && fetchData();
  }, [agencyId, lang, city]);

  useEffect(() => {
    console.log(oneAgencyData);
  }, [props]);

  useEffect(() => {
    if (!lang) {
      const url = window.location.pathname;
      const segments = url.split("/");
      const langSegment = segments[1];
      if (langSegment && langSegment.length === 2) {
        dispatch(setLang(langSegment));
      }
    }
  }, []);

  const breadcrumbsList = [
    {
      id: 1,
      title: t("mainpage"),
      link: "/",
    },
    {
      id: 2,
      title: `${t("agenci1")} – Чехия`,
      link: "/",
    },
    {
      id: 3,
      title: name,
      link: "",
    },
  ];

  const tabsBtns = [
    {
      title: t("ourmodels"),
      icon: "image",
      link: "photo",
    },
    {
      title: t("interior"),
      icon: "home",
      link: "interior",
    },
  ];

  return (
    <>
      <main className={"agency"}>
        <Breadcrumbs data={breadcrumbsList} clazz={"__container mb-20"} />

        <Banner
          props={{
            title: name,
            img: image,
            imgMobile: image,
            rating: rating,
            medel_total: profiles_statistic?.total,
            date: "",
            logoImg: cover,
            logoImgMobile: cover,
          }}
        />

        <div className="agency__top __container">
          <div className="agency__info">
            {description && <ShowMoreText>{description}</ShowMoreText>}
            {phone || whatsapp || telegram ? (
              <div className="agency__buttons">
                {phone ? (
                  <ButtonLink
                    size={"l"}
                    href="tel:+422589996655"
                    clazz="button--primary td-none width-100"
                  >
                    {phone}
                  </ButtonLink>
                ) : null}
                {whatsapp ? (
                  <ButtonLink
                    square={true}
                    size={"l"}
                    href={`https://wa.me/${whatsapp}`}
                    clazz="button--primary"
                  >
                    <i className="icon icon-xxl icon--whatsapp"></i>
                  </ButtonLink>
                ) : null}

                {telegram ? (
                  <ButtonLink
                    square={true}
                    size={"l"}
                    href={`https://t.me/${telegram}`}
                    clazz="button--primary"
                  >
                    <i className="icon icon-xxl icon--telegram"></i>
                  </ButtonLink>
                ) : null}
              </div>
            ) : null}

            <div className="agency__links">
              <div>
                {website ? (
                  <Link
                    to="#"
                    className="agency__site p3 icon icon--globe underline"
                  >
                    {website}
                  </Link>
                ) : null}

                <Link
                  to="mailto:info@kay-escorts.com"
                  className="agency__mail p3 icon icon--mail underline"
                >
                  info@kay-escorts.com
                </Link>
              </div>

              {address?.address && (
                <Link
                  to="#"
                  className="agency__address p3 icon icon--map underline"
                  title="Посмотреть на карте"
                >
                  <Icon type={"flag"} spritePath={"CZ"} />
                  {address.address}
                </Link>
              )}

              <Link
                to="#"
                className="agency__address p3 icon icon--map underline"
                title="Посмотреть на карте"
              >
                <Icon type={"flag"} spritePath={"CZ"} />
                Czechia, Praha, Žižkova tř. 220/15
              </Link>
            </div>
          </div>

          {/*<ScheduleTable icon={'24-7'}/>*/}
        </div>

        <section className="agency__media __container">
          <TabSlider tabs={tabsBtns} clazz={"agency-tabs"}>
            <SwiperSlide
              className={`agency-tabs__slide`}
              data-hash={tabsBtns[0].link}
            >
              {girls_slider_db.slice(0, 6).map((data, index) => {
                const result = () => {
                  dispatch(setStoriesStatus(true));
                  dispatch(setStoriesId(index));
                };
                return (
                  <img
                    src={data.photos[0]}
                    onClick={() => result()}
                    title={`Фотографии эскорт-модель ${data.name}`}
                    alt={`Фотографии эскорт-модель ${data.name}`}
                  />
                );
              })}
            </SwiperSlide>

            <SwiperSlide
              className={`agency-tabs__slide`}
              data-hash={tabsBtns[1].link}
            >
              {interior_db.slice(0, 6).map((data, index) => {
                const result = () => {
                  dispatch(setGalleryIndexImg(index));
                  dispatch(setGalleryStatus(true));
                };
                return (
                  <img
                    src={data.photos[0]}
                    onClick={() => result()}
                    title={`Фотографии эскорт-модель ${data.name}`}
                    alt={`Фотографии эскорт-модель ${data.name}`}
                  />
                );
              })}
            </SwiperSlide>
          </TabSlider>

          {video ? (
            <div className="agency__block">
              <h2 className="mb-32">AgencyPage video presentation</h2>

              <Video controls={true} src={video} />
            </div>
          ) : null}
        </section>

        <section className="agency-party __container">
          <h3>{t("upcomingparties")}</h3>

          <Slider clazz={"agency-party"} type={"agency"}>
            {parties &&
              parties.map((data) => (
                <SwiperSlide>
                  <PartyCard props={data} />
                </SwiperSlide>
              ))}

            <SwiperSlide>
              <PartyCard
                props={{
                  privat_party: true,
                }}
              />
            </SwiperSlide>
          </Slider>
        </section>

        <AgencyFilter
          props={{
            profiles_statistic: profiles_statistic,
            profiles: profiles,
          }}
        />

        <Reviews
          props={{
            clazz: "__container",
            rating: rating,
          }}
        />
      </main>

      <Appeal />

      <AddReview />

      <PartyAccess />

      {!!girls_slider_db.length && (
        <StoriesComponent
          // stories={stories}
          stories={girls_slider_db.slice(0, 8)}
          descriptionText
          favoriteButton
          profileLink
        />
      )}

      {/*<Gallery id={'Interior'} data={interior_db}/>*/}

      <OrderViewing />
    </>
  );
};

export default AgencyPage;
