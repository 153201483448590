import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserCurrentCity, getUserLang } from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";
import { getTitle } from "@/stores/slices/titleSlice";
import useDefiningCity from "../../../hooks/useDefiningCity";
import useDefiningLang from "../../../hooks/useDefiningLang";
import { setGmapsApiReady } from "@/stores/slices/mapModelSlice";

import { useLocation } from "react-router-dom";
import { getUserType } from "@/stores/slices/userSlice";

import IndiLayoutLk from "./IndiLayoutLk";
import AgencyLayoutLk from "./AgencyLayoutLk";
import Layout from "./Layout";
import PrivateRoute from "../../private-route/private-route";

const MainLayout = ({ children }) => {
  const userType = useSelector(getUserType);
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  useDefiningLang();
  useDefiningCity();
  const { t } = useTranslation("translation");

  const lang = useSelector(getUserLang);

  const currentCity = useSelector(getUserCurrentCity);
  const country = useSelector(getUserCurrentCity).country;

  const postDesc = t("description")?.replace(/%([^%]+)%/g, (match, p1) => {
    switch (p1) {
      case "City":
        return currentCity.title;
      case "Country":
        return country;
      default:
        return match;
    }
  });

  const postCity = t("incity")?.replace(
    /%([^%]+)%/g,
    currentCity.title ? currentCity.title : ""
  );
  const postTitle = t("title")?.replace(
    /%([^%]+)%/g,
    currentCity.title ? currentCity.title : ""
  );
  const title = useSelector(getTitle);
  const titleString =
    title.length > 0
      ? title
          .filter((item) => !!item)
          .map((item) => t(item))
          .join(", ")
      : "";

  const titleParams = titleString ? `${titleString} ${postCity}` : postTitle;
  const resultLang = lang === "cz" ? "cs" : lang;

  const mapLink = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA81vyhvrBl3jUTFDK6LwYNm317prcxfVo&libraries=places&language=${resultLang}`;

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = mapLink;
      script.async = true;

      script.onload = () => {
        dispatch(setGmapsApiReady(true));
      };

      document.body.appendChild(script);
    };

    loadScript();
  }, [lang]);

  if (pathname.includes("/lk") && userType === "indi") {
    return (
      <IndiLayoutLk titleParams={t('profuser')} postDesc={postDesc}>
        <PrivateRoute>{children}</PrivateRoute>
      </IndiLayoutLk>
    );
  }

  if (pathname.includes("/lk") && userType === "agency") {
    return (
      <AgencyLayoutLk titleParams={titleParams} postDesc={postDesc}>
        <PrivateRoute>{children}</PrivateRoute>
      </AgencyLayoutLk>
    );
  }

  return (
    <Layout titleParams={titleParams} postDesc={postDesc}>
      {children}
    </Layout>
  );
};

export default MainLayout;
