import { Helmet } from "react-helmet";

import "./burgerMenu.scss";
import { Button } from "../index";

const BurgerMenu = (props) => {
  const { isOpenMenu, setIsOpenMenu } = props;

  return (
    <>
      {!!isOpenMenu ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}
      <Button
        square={true}
        size={"s"}
        buttonType={"button"}
        onClick={() => setIsOpenMenu((prevState) => !prevState)}
        clazz={`icon-menu${isOpenMenu ? " _active" : ""}`}
      >
        <span></span>
      </Button>
    </>
  );
};

export default BurgerMenu;
