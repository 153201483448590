import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  models: [],
  sorted: [],
  sortedByGender: [],
  sortedByStatus: [],
  searchValue: "",
  sortBy: "date",
  page: 1,
  filterSorted: "",
  hasMorePage: true,
  notDisabledCheckbox: {},
  storiesId: null,
  modelForCall: null,
  stories: []
};

const modelsSlice = createSlice({
  name: "models",
  initialState,
  reducers: {
    filterAllModels: (state) => {
      state.models = state.models.filter(item => item)
    },
    setStories: (state, action) => {
      state.stories = action.payload
    },
    setModelForCall: (state, action) => {
      state.modelForCall = action.payload
    },
    setStoriesId: (state, action) => {
      state.storiesId = action.payload
    },
    setNotDisabledCheckbox: (state, action) => {
      state.notDisabledCheckbox = action.payload
    },
    setSearchValue: (state,action) => {
      state.searchValue = action.payload
    },
    setSortedModelsByFilter: (state, action) => {
      state.filterSorted = action.payload;
    },
    setPage: (state) => {
      state.page += 1;
    },
    setPageDefault: (state) => {
      state.page = 1;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setAllModels: (state, action) => {
      state.models = action.payload;
    },
    updateAllModels: (state, action) => {
      state.models = state.models.concat(action.payload);
    },
    setSorted: (state, action) => {
      const { payload, callback, stateName } = action.payload;
      const index = state[stateName].indexOf(payload);
      if (index !== -1) {
        state[stateName].splice(index, 1);
      } else {
        state[stateName].push(payload);
      }
      if (typeof callback === "function") {
        callback(state[stateName]);
      }
    },
    cleanSorted: (state) => {
      state.sorted = [];
      state.sortedByStatus = [];
      state.sortedByGender = [];
    },
    setDefaultSorted: (state, action) => {
      const { stateName, newArray } = action.payload;
      state[stateName] = newArray;
    },
    setHasMorePage: (state, action) => {
      state.hasMorePage = action.payload;
    },
  },
});

export const {
  filterAllModels,
  setStories,
  setModelForCall,
  setStoriesId,
  setNotDisabledCheckbox,
  setSearchValue,
  setSortedByGender,
  setSortedModelsByFilter,
  setAllModels,
  setSorted,
  setDefaultSorted,
  setSortBy,
  setPage,
  updateAllModels,
  setPageDefault,
  setHasMorePage,
  cleanSorted,
} = modelsSlice.actions;

export const getAllModels = (state) => state.models.models;
export const getAllSorted = (state) => state.models.sorted;
export const getStatusSorted = (state) => state.models.sortedByStatus;
export const getGenderSorted = (state) => state.models.sortedByGender;
export const getTypeSorted = (state) => state.models.sortBy;
export const getSearchValue = (state) => state.models.searchValue;
export const getPage = (state) => state.models.page;
export const getSortedModelsByFilter = (state) => state.models.filterSorted;
export const getHasMorePage = (state) => state.models.hasMorePage;
export const getNotDisabledCheckbox = (state) => state.models.notDisabledCheckbox;
export const getStoriesId = (state) => state.models.storiesId;
export const getModelForCall = (state) => state.models.modelForCall;
export const getStories = (state) => state.models.stories;


export default modelsSlice.reducer;