import { Icon } from "@/components/ui";
import { useState } from "react";

import "./exampleImage.scss";
import { DeletePhotoPopup } from "../../../../../../popups";

const ExampleImage = ({ image, setImage }) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const handleOpenDelete = () => {
    setIsOpenDelete(true);
  };

  const removeImg = () => {
    setImage(null);
  };

  return (
    <>
      <div className={`example__photo`}>
        <img src={image} alt={""} />
        <span className="icon-remove" onClick={handleOpenDelete}>
          <Icon size={"s"} spritePath={"close"} />
        </span>
      </div>
      <DeletePhotoPopup
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        removeImg={removeImg}
      />
    </>
  );
};

export default ExampleImage;
