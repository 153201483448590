import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { makeRequest } from "@/services/makeRequest";

import useLadyService from "@/services/LadyService";

import ModelPage from "./ModelPage";
import { Loader } from "@/components/ui";

const VariantModelPage = () => {
  const { profileSlug } = useParams();

  const [oneProfileData, setOneProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { lang } = useLadyService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const route = `profiles/${profileSlug}`;
        const method = "GET";
        const payload = {
          lang,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.data;
          setOneProfileData(result);
        }
        setIsLoading(false);
      } catch (error) {}
    };

    profileSlug && lang && fetchData();
  }, [profileSlug, lang]);

  if (isLoading) {
    return <Loader height={100} />;
  }

  return <ModelPage oneProfileData={oneProfileData} />;
};

export default VariantModelPage;
