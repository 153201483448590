import { useDispatch, useSelector } from "react-redux";
import {
  getAdFragment,
  getUserCity,
  getUserCountry,
  getUserLang,
  getUserType,
  themeMode,
} from "@/stores/slices/userSlice";
import { useEffectWindowSize } from "../hooks";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { wordEndings } from "../helper/wordEndings";
import {
  setAuthorizationStatus,
  setAviableForUsersStatus,
} from "@/stores/slices/popupSlice";

// здесь собраны самые частоиспользуемые команды, чтобы не дублировать их каждый раз
const useLadyService = () => {
  const { t } = useTranslation("translation");

  const { t: indi } = useTranslation("indi");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // получить тип пользователя
  const userType = useSelector(getUserType);

  // тема светлая/тёмная
  const theme = useSelector(themeMode);

  // получение языка
  const lang = useSelector(getUserLang);

  // получение города
  const city = useSelector(getUserCity);

  const adFragment = useSelector(getAdFragment);

  // получение страны
  const country = useSelector(getUserCountry);

  // функция для преобразования времени и форматирования строки
  function formatTimeDifference(timestamp) {
    const visitTime = moment(timestamp);

    const currentTime = moment();

    const diffInMinutes = currentTime.diff(visitTime, "minutes");
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInHours > 0 && diffInHours <= 24) {
      return `${t("online")} ${diffInHours} часов назад`;
    } else if (diffInHours > 24) {
      return `Заходила недавно`;
    } else {
      return `Заходила ${diffInMinutes} минут назад`;
    }
  }

  // форматирования даты (12.05.2024 в 14:45)
  const formattedDate = (created_at) =>
    moment.unix(created_at).format(`DD.MM.YYYY ${t("in")} HH:mm`);

  // форматирования даты (12.05.2024)
  const getDayMonthYear = (created_at) =>
    moment.unix(created_at).format(`DD.MM.YYYY`);

  // парсинг таймстемпа
  const getTimeAgo = (created_at) => {
    const secondsSinceEpoch = parseInt(created_at, 10);
    const date = moment.unix(secondsSinceEpoch);
    const now = moment();

    const diffInMinutes = now.diff(date, "minutes");
    const diffInHours = now.diff(date, "hours");

    if (diffInMinutes < 5) {
      return t("online");
    } else if (diffInMinutes < 60) {
      return `Заходила час назад`;
    } else if (diffInHours < 24) {
      return `Заходила сегодня`;
    } else if (diffInHours < 48) {
      return `Заходила два дня назад`;
    } else if (diffInHours < 72) {
      return `Заходила 3 дня назад`;
    } else if (diffInHours < 96) {
      return `Заходила 4 дня назад`;
    } else {
      return `Заходила неделю назад`;
    }
  };

  const TimeAgo = ({ timestamp, timeAgo, setTimeAgo }) => {
    const secondsSinceEpoch = parseInt(timestamp, 10);
    const date = moment.unix(secondsSinceEpoch);
    const now = moment();

    const diffInMinutes = now.diff(date, "minutes");
    const diffInHours = now.diff(date, "hours");

    const pluralize = (count, words) => {
      const cases = [2, 0, 1, 1, 1, 2];
      return words[
        count % 100 > 4 && count % 100 < 20
          ? 2
          : cases[count % 10 < 5 ? count % 10 : 5]
      ];
    };

    if (diffInMinutes < 60) {
      setTimeAgo(`${diffInMinutes} ${t("minago")}`);
    } else if (diffInHours < 24) {
      setTimeAgo(
        `${diffInHours} ${pluralize(diffInHours, [
          t("hour"),
          t("hourfew"),
          t("hourmany"),
        ])} ${t("back")}`
      );
    } else {
      setTimeAgo(`${t("daysago")}`);
    }

    return timeAgo;
  };

  // из минут в часы/дни
  const minutesToDhms = (minutes) => {
    minutes = Number(minutes);
    const d = Math.floor(minutes / (60 * 24));
    const h = Math.floor((minutes % (60 * 24)) / 60);
    const m = Math.floor(minutes % 60);

    const dDisplay =
      d > 0 ? `${d} ${wordEndings(d, [t("day"), t("days"), t("days")])} ` : "";

    const hDisplay =
      h > 0
        ? `${h} ${wordEndings(h, [
            t("hour"),
            indi("hourfew"),
            indi("hourmany"),
          ])} `
        : "";

    const mDisplay = m > 0 ? `${m} ${t("min")}` : "";

    return dDisplay + hDisplay + mDisplay;
  };

  const setAvialableForUser = () => {
    dispatch(setAuthorizationStatus(true));
    dispatch(setAviableForUsersStatus(true));
  };

  const gendersTitle = {
    male: t("man"),
    trance: t("trans"),
    couple: t("couple"),
    woman: t("woman"),
  };

  //переписать на объект
  const orientations = (orientation) => {
    switch (orientation) {
      case "homosexual":
        return t("homo");
      case "lesbian":
        return t("lesbi");
      case "bisexual":
        return t("bi");
      default:
        return t("hetero");
    }
  };

  return {
    dispatch,
    navigate,
    userType,
    theme,
    lang,
    city,
    country,
    getTimeAgo,
    gendersTitle,
    orientations,
    minutesToDhms,
    setAvialableForUser,
    formattedDate,
    formatTimeDifference,
    getDayMonthYear,
    TimeAgo,
    adFragment,
  };
};

export default useLadyService;
