import { Helmet } from "react-helmet";
import { Button, ButtonLink, Icon } from "@/components/ui";
import {
  getPartyAccessStatus,
  setPartyAccess,
} from "@/stores/slices/popupSlice";
import { useDispatch, useSelector } from "react-redux";

const PartyAccess = () => {
  const dispatch = useDispatch();
  const popupState = useSelector(getPartyAccessStatus);
  return (
    <dialog
      id={"forgotPasswordPhonePopupInputs"}
      open={!!popupState}
      onClick={() => dispatch(setPartyAccess(false))}
      className="popup-form"
    >
      {!!popupState ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <div
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button
          onClick={() => dispatch(setPartyAccess(false))}
          clazz={"button__close"}
        >
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <h3 className="popup-form__title text-center mb-0">
          Эта вечеринка только для VIP-клиентов.
        </h3>

        <ButtonLink to={"#"} size={"l"} clazz={"button--green td-none"}>
          Сменить тариф
        </ButtonLink>
      </div>
    </dialog>
  );
};

export default PartyAccess;
