import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserCity,
  getUserCurrentCity,
  getUserLang,
  setUserStatus,
  setUserType,
} from "@/stores/slices/userSlice";
import { useEffectWindowSize } from "../../hooks";

import {
  Button,
  ButtonLink,
  Icon,
  Select,
  SwitchTheme,
  BurgerMenu,
  LogoSvg,
} from "@/components/ui";

import { cleanSorted } from "@/stores/slices/modelsState";
import { clearTitle } from "@/stores/slices/titleSlice";

import { resetAll, setSearchFilter } from "@/stores/slices/filterSlice";
import { themeMode } from "@/stores/slices/userSlice";
import logoDarkScrolled from "../../assets/img/logo-scroll--dark.svg";
import logoLightScrolled from "../../assets/img/logo-scroll--light.svg";

import { useTranslation } from "react-i18next";
import { selectActiveTabId, setActiveTabId } from "@/stores/slices/tabSlice";
import { setProfileCount } from "@/stores/slices/mapModelSlice";

import "./header.scss";

const AgencyHeaderLk = () => {
  const widthScreen = useEffectWindowSize();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector(getUserLang);
  const currentCity = useSelector(getUserCurrentCity);
  const city = useSelector(getUserCity);

  const activeTabId = useSelector(selectActiveTabId);

  const { t } = useTranslation();
  const { t: indi } = useTranslation("indi");

  const postAltLogo = t("altlogo")?.replace(/%([^%]+)%/g, currentCity.title);

  const handleTabClick = (tabId) => {
    dispatch(setActiveTabId(tabId));
  };

  const languages = {
    main_title: "",
    options: [
      { id: 1, link: `/en/${city}`, title: "EN", value: "en" },
      { id: 2, link: `/ru/${city}`, title: "RU", value: "ru" },
      { id: 3, link: `/cz/${city}`, title: "CZ", value: "cz" },
    ],
  };

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const [scrolledClass, setScrolledClass] = useState("");

  useEffect(() => {
    if (widthScreen > 767.98) {
      window.addEventListener("scroll", () => {
        if (window.scrollY < 1) {
          setScrolledClass("");
        } else {
          setScrolledClass(" _scroll");
        }
      });
    }
  }, [widthScreen, window]);

  const theme = useSelector(themeMode);

  const getThemeForLogo = () => {
    if (theme === "dark") {
      return logoDarkScrolled;
    } else {
      return logoLightScrolled;
    }
  };

  const logOut = () => {
    dispatch(setUserType("default"));
    dispatch(setUserStatus("silver"));
    localStorage.removeItem("user");
    dispatch(setProfileCount(0));
    navigate(`/`);
  };

  const handleLogo = (e) => {
    e.preventDefault();

    navigate(`/${lang}/${city}`);

    dispatch(cleanSorted());
    dispatch(resetAll());
    dispatch(clearTitle());
    dispatch(setSearchFilter(false));

    if (scrolledClass) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const Logo = () => (
    <Link
      to={`/`}
      title={t("titlelogo")}
      className="logo"
      onClick={(e) => handleLogo(e)}
    >
      {scrolledClass === " _scroll" && widthScreen > 767.98 ? (
        <img src={getThemeForLogo()} alt={postAltLogo} />
      ) : (
        <LogoSvg alt={postAltLogo} />
      )}
    </Link>
  );

  const LogOutButton = (size = "xs", square = false) => (
    <Button
      onClick={() => logOut()}
      size={size}
      square={square}
      clazz="user__log-out"
    >
      <Icon size={"l"} spritePath={"logout"} />
    </Button>
  );

  const UserNotification = (size = "s") => (
    <Button
      size={widthScreen < 767.98 ? "xxs" : size}
      square={true}
      clazz={`user__notification${
        widthScreen > 767.98 ? " button--tetriary" : ""
      }`}
      counter={12}
    >
      <Icon size={"l"} spritePath={"notify"} />
    </Button>
  );

  const InstructionLink = (clazz) => (
    <ButtonLink
      href={`/guide`}
      title={t("guide")}
      size={"xs"}
      clazz={`add-form${
        clazz ? ` ${clazz}` : ""
      } button_outline--green _no-underline`}
    >
      <Icon size={"m"} spritePath={"instruction-cont"} />
      {widthScreen > 1199.98 ? t("guide") : null}
    </ButtonLink>
  );

  const DropdownLanguage = () => (
    <Select
      options={languages.options}
      clazz={"button button-xs button--main"}
      arrowSize={"s"}
      sorted={true}
      name={"language"}
    />
  );

  const topLinksTabs = {
    agency: [
      { id: 1, name: t("profuser"), href: "#" },
      { id: 2, name: indi("myads"), href: "#", count: 0 },
      { id: 3, name: t("reviews"), href: "#", count: 0 },
      { id: 3, name: indi("myoperator"), href: "#" },
      { id: 3, name: indi("myparty"), href: "#" },
      { id: 3, name: indi("mystat"), href: "#" },
      { id: 4, name: t("balance"), href: "#", wallet: 20 },
    ],
  };

  const HeaderTabs = () => {
    return topLinksTabs.agency.map((i) => {
      return (
        <Link
          data-counter={i.count}
          key={i.id}
          className={`header__tab p2${i.count < 1 ? " empty" : ""} ${
            activeTabId === i.id ? "_active" : ""
          }`}
          to={i.href}
          onClick={() => handleTabClick(i.id)}
        >
          {i.name}
          {i.wallet ? <span className="color-green"> {i.wallet}€</span> : null}
        </Link>
      );
    });
  };

  const FloatBurgerMenu = () => {
    const headerIcons = {
      agencyPage: [
        {
          id: 1,
          icon: "video",
          name: t("videochat"),
          link: "#",
        },
        {
          id: 2,
          icon: "pay-content",
          name: indi("myorder"),
          link: "#",
        },
        {
          id: 3,
          icon: "casting",
          name: indi("demos"),
          link: "#",
        },
        {
          id: 33,
          icon: "user-circle",
          name: t("profuser"),
          link: "#",
        },
        {
          id: 4,
          icon: "user-id",
          name: indi("myads"),
          link: "#",
        },
        {
          id: 5,
          icon: "star",
          name: t("reviews"),
          link: "#",
        },
        {
          id: 66,
          icon: "operator",
          name: indi("myoperator"),
          link: "#",
        },
        {
          id: 77,
          icon: "party",
          name: indi("myparty"),
          link: "#",
        },

        {
          id: 6,
          icon: "stat",
          name: indi("mystat"),
          link: "#",
        },
        {
          id: 7,
          name: t("balance"),
          link: "#",
          wallet: 20,
        },
        // {
        //   id: 8,
        //   icon: "camera",
        //   name: t("photographer"),
        //   link: "#",
        // },
        {
          id: 8,
          icon: "instruction-cont",
          name: t("guide"),
          link: `/guide`,
        },
      ],
    };

    return (
      <div className={`users${isOpenMenu ? " _active" : ""}`}>
        <div className={"users__nav"}>
          {headerIcons.agencyPage.map((i) => {
            if (i.wallet) {
              return (
                <ButtonLink
                  href={i.link}
                  key={i.id}
                  title={i.name}
                  size={"l"}
                  clazz="menu__link _no-underline"
                  counter={i.counter}
                >
                  <span className={"menu__wallet"}>{i.wallet}€</span>
                  <span>{i.name}</span>
                </ButtonLink>
              );
            }

            if (i.link) {
              return (
                <ButtonLink
                  href={i.link}
                  key={i.id}
                  title={i.name}
                  size={"l"}
                  clazz="menu__link _no-underline"
                  counter={i.counter}
                >
                  <Icon size={"xl"} clazz={i.clazz} spritePath={i.icon} />
                  <span>{i.name}</span>
                </ButtonLink>
              );
            }

            return (
              <Button
                onClick={i.onClick}
                key={i.id}
                title={i.name}
                size={"l"}
                clazz="menu__link"
                counter={i.counter}
              >
                <Icon size={"xl"} clazz={i.clazz} spritePath={i.icon} />
                <span>{i.name}</span>
              </Button>
            );
          })}
        </div>

        <div className="users__bottom">
          <ButtonLink
            href={"/"}
            size={"m"}
            clazz={"button--tetriary td-none width-100"}
          >
            {indi("backtoweb")}
          </ButtonLink>

          <Button onClick={() => logOut()} clazz="user__log-out">
            {indi("logout")}
            <Icon size={"m"} spritePath={"logout"} />
          </Button>
        </div>
      </div>
    );
  };

  const MenuNavBar = () => {
    const headerIconsMenuBar = {
      agencyPage: [
        {
          id: 1,
          icon: "user-circle",
          name: t("profuser"),
          link: "#",
        },
        {
          id: 2,
          icon: "user-id",
          name: indi("myads"),
          link: "#",
        },
        {
          id: 3,
          icon: "star",
          name: t("reviews"),
          link: "#",
        },
        {
          id: 5,
          icon: "stat",
          name: indi("mystat"),
          link: "#",
        },
        {
          id: 6,
          name: t("balance"),
          link: "#",
          wallet: 20,
        },
        // {
        //   id: 7,
        //   icon: "camera",
        //   name: t("photographer"),
        //   link: "#",
        // },
      ],
    };

    return (
      <div className="menu__body">
        {headerIconsMenuBar.agencyPage.slice(0, 6).map((i) => {
          if (i.wallet) {
            return (
              <ButtonLink
                href={i.link}
                key={i.id}
                title={i.name}
                size={"l"}
                clazz="menu__link _no-underline"
                counter={i.counter}
              >
                <span className={"menu__wallet"}>{i.wallet}€</span>
                <span>{i.name}</span>
              </ButtonLink>
            );
          }

          return (
            <Button
              onClick={i.onClick}
              key={i.id}
              title={i.name}
              size={"l"}
              clazz="menu__link"
              counter={i.counter}
            >
              <Icon size={"l"} clazz={i.clazz} spritePath={i.icon} />
              <span>{i.name}</span>
            </Button>
          );
        })}
      </div>
    );
  };

  const UserWrapperDesktop = () => {
    return (
      <div className="user__wrapper">
        {UserNotification("s")}

        <ButtonLink size={"s"} clazz={"button--tetriary td-none"} href="/">
          {t("mainpage")}
        </ButtonLink>

        {LogOutButton()}
      </div>
    );
  };

  const ModelOrAgencyHeaderContent = () => {
    let variationsOfContent = () => {
      return (
        <>
          <ButtonLink
            href={"#"}
            title={t("videochat")}
            size={"xs"}
            clazz={"button_outline--black _no-underline"}
            counter={3}
          >
            <Icon size={"m"} spritePath={"video"} />
            {t("videochat")}
          </ButtonLink>

          <ButtonLink
            href={"#"}
            title={indi("myorder")}
            size={"xs"}
            clazz={"button_outline--black _no-underline"}
            counter={2}
          >
            <Icon size={"m"} spritePath={"pay-content"} />
            {indi("myorder")}
          </ButtonLink>

          <ButtonLink
            href={"#"}
            title={indi("demos")}
            size={"xs"}
            clazz={"button_outline--black _no-underline"}
            counter={2}
          >
            <Icon size={"m"} spritePath={"casting"} />
            {indi("demos")}
          </ButtonLink>
        </>
      );
    };

    if (widthScreen > 1199.98)
      return (
        <>
          <div className="header__top">
            {/* <ButtonLink
              href={"#"}
              title={t("photographer")}
              size={"xs"}
              clazz={"add-form button_outline--green _no-underline"}
            >
              <Icon size={"m"} spritePath={"camera"} />
              {t("photographer")}
            </ButtonLink> */}

            {InstructionLink()}

            {variationsOfContent()}

            {DropdownLanguage()}

            <SwitchTheme type={"switch"} />
          </div>

          <div className="header__bottom">
            {Logo()}

            <div className={"header__tabs"}>{HeaderTabs()}</div>

            {UserWrapperDesktop()}
          </div>
        </>
      );

    if (widthScreen > 767.98)
      return (
        <>
          <div className="header__top">
            {Logo()}

            <div className="user__wrapper">
              {variationsOfContent()}

              {UserNotification()}

              <BurgerMenu
                isOpenMenu={isOpenMenu}
                setIsOpenMenu={setIsOpenMenu}
              />
              <SwitchTheme type={"switch"} />
              {FloatBurgerMenu()}
            </div>
          </div>
        </>
      );

    return (
      <>
        <div className="header__top">
          <div className="user__wrapper">
            <BurgerMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
            {DropdownLanguage()}
          </div>

          {Logo()}

          <div className="user__wrapper">
            {UserNotification("xs")}
            <SwitchTheme type={"switch"} />
          </div>

          {FloatBurgerMenu()}
          {MenuNavBar()}
        </div>

        <div className="header__bottom">{variationsOfContent()}</div>
      </>
    );
  };

  return (
    <header
      className={`header--type-two${
        scrolledClass && widthScreen > 767.98 ? ` ${scrolledClass}` : ""
      }`}
    >
      <div className="header__container">{ModelOrAgencyHeaderContent()}</div>
    </header>
  );
};
export default AgencyHeaderLk;
