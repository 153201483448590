import "./verificationAndHealthCheck.scss";

import VerAds from "../verAds/VerAds";
import VerHealth from "../verHealth/VerHealth";

import { useTranslation } from "react-i18next";

const VerificationAndHealthCheck = (props) => {
  const { healthRef, verRef } = props;

  const { t: indi } = useTranslation("indi");

  return (
    <div>
      <h3>{indi("verandhealth")}</h3>
      <div className="ver__check__main">
        <VerAds verRef={verRef} />
        <VerHealth healthRef={healthRef} />
      </div>
    </div>
  );
};

export default VerificationAndHealthCheck;
