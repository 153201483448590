import { useEffectWindowSize } from "../../../../hooks";

import {
  Appearance,
  Confirmation,
  Main,
  Media,
  Services,
  StatusElite,
  Steps,
} from "./pageComponent/index.js";
import "./createProfile.scss";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonLink } from "@/components/ui";
import { useTranslation } from "react-i18next";
import Tarifs from "./pageComponent/stepSection/tarifs/Tarifs.js";
import { VerificationAndHealthCheck } from "../model/profiles/pageComponets/index.js";
import CountriesFilter from "../model/pageComponents/countriesFilter/CountriesFilter.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice.js";

const CreateProfile = () => {
  const windowWidth = useEffectWindowSize();
  const navigate = useNavigate();
  const lang = useSelector(getUserLang);
  const [currentStep, setCurrentStep] = useState(1);
  const [availableSteps, setAvailableSteps] = useState([1]);
  
  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const verRef = useRef(null);
  const healthRef = useRef(null);
  const isSmallTablet = windowWidth < 1000.98;
  const [data, setData] = useState({});

  const token = localStorage.getItem("user");

  const [formData, setFormData] = useState({});

  const [countries, setCountries] = useState([]);

  const rootRef = useRef(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const route = `user/info`;
  //       const method = "GET";

  //       const data = await makeRequest({ route, method });
  //       if (data) {
  //         const result = data.data;
  //         setUserData(result);
  //       }
  //     } catch (error) {}
  //   };

  //   token && !!Object.values(userData) && fetchData();
  // }, [token, currentStep]);

  const backToProfiles = (e) => {
    e.preventDefault();

    navigate(`/${lang}/lk/indi/profiles`);
  };

  return (
    <main>
      {/* <Header profilePage={true} /> */}

      <section className={"profile-page__container"}>
        <div className="navigation__top">
          <h1>{indi("createnewad")}</h1>
          <ButtonLink
            clazz={`button_outline--black _no-underline`}
            size={"l"}
            href={"/lk/indi/profiles"}
            onClick={(e) => backToProfiles(e)}
          >
            {indi("cancel")}
          </ButtonLink>
        </div>

        <div ref={rootRef} className={"create-page__root"}>
          {isSmallTablet && (
            <>
              <Steps
                availableSteps={availableSteps}
                step={currentStep}
                changeStep={setCurrentStep}
              />
              <hr />
            </>
          )}
          {!isSmallTablet && (
            <Steps
              availableSteps={availableSteps}
              step={currentStep}
              changeStep={setCurrentStep}
            />
          )}
          <div className={"create-page__main"}>
            {currentStep === 1 && (
              <Main
                setAvailableSteps={setAvailableSteps}
                formData={formData}
                setFormData={setFormData}
                rootElem={rootRef.current}
                step={currentStep}
                changeStep={setCurrentStep}
                // isLoading={isLoading}
                userData={data}
                countries={countries}
                setCountries={setCountries}
                setData={setData}
              />
            )}
            {currentStep === 2 && (
              <Media
                setAvailableSteps={setAvailableSteps}
                formData={formData}
                setFormData={setFormData}
                rootElem={rootRef.current}
                step={currentStep}
                changeStep={setCurrentStep}
              />
            )}
            {currentStep === 3 && (
              <Appearance
                setAvailableSteps={setAvailableSteps}
                formData={formData}
                setFormData={setFormData}
                rootElem={rootRef.current}
                step={currentStep}
                changeStep={setCurrentStep}
              />
            )}
            {currentStep === 4 && (
              <Services
                setAvailableSteps={setAvailableSteps}
                formData={formData}
                setFormData={setFormData}
                rootElem={rootRef.current}
                step={currentStep}
                changeStep={setCurrentStep}
              />
            )}
            {currentStep === 5 && (
              <Tarifs
                setAvailableSteps={setAvailableSteps}
                formData={formData}
                setFormData={setFormData}
                rootElem={rootRef.current}
                step={currentStep}
                changeStep={setCurrentStep}
              />
            )}
            {currentStep === 6 && (
              <Confirmation
                formData={formData}
                setFormData={setFormData}
                rootElem={rootRef.current}
                step={currentStep}
                changeStep={setCurrentStep}
              />
            )}
          </div>
        </div>

        {currentStep === 6 && (
          <>
            <StatusElite />
            <VerificationAndHealthCheck verRef={verRef} healthRef={healthRef} />
            <CountriesFilter data={data} />
            <div className="create-form__buttons">
              <Button
                buttonType={"submit"}
                size={"l"}
                square={false}
                clazz="button--green button-confirmation"
              >
                {('publish')}
              </Button>
            </div>
          </>
        )}
      </section>
    </main>
  );
};

export default CreateProfile;
