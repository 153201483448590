import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "./progressBar.scss";

import { useSelector } from "react-redux";
import { themeMode } from "@/stores/slices/userSlice";

const ProgressBar = ({ percentage }) => {
  const theme = useSelector(themeMode);

  let backgroundColor;
  let textColor;
  let pathColor;
  let trailColor;

  switch (theme) {
    case "dark": {
      backgroundColor = "transparent";
      textColor = "#fff";
      pathColor = "#1ca182";
      trailColor = "transparent";
      break;
    }

    default: {
      backgroundColor = "transparent";
      textColor = "black";
      pathColor = "#0ab8b6";
      trailColor = "transparent";
    }
  }

  return (
    <div className="progress-bar">
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        background
        styles={buildStyles({
          backgroundColor,
          textColor,
          pathColor,
          trailColor,
        })}
      />
    </div>
  );
};

export default ProgressBar;
