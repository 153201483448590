import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import React from "react";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import {
  setAuthorisationAccessType,
  setAuthorizationStatus,
  setErocontentAccessStatus,
} from "@/stores/slices/popupSlice";
import { Icon, Button } from "@/components/ui";

const PrivateSlider = ({ props, setOpenEdit }) => {
  const { ero } = props;
  const { dispatch, userType } = useLadyService();

  const { t } = useTranslation("translation");

  const ShowPrivate = () => (
    <div className={"d-flex gap-8 fd-column align-center color-white"}>
      <Icon size={"m"} spritePath={"eye-off"} />
      <span className={"color-main"}>{t("show")}</span>
    </div>
  );

  const ErocontentTemplate = (data, type) => (
    <SwiperSlide
      className={"model-main__private-slide"}
      onClick={() => handleClick()}
    >
      {type === "video" ? (
        <Icon size={"m"} spritePath={"video-triangle"} />
      ) : null}

      <img src={data} alt="" />

      {ShowPrivate()}
    </SwiperSlide>
  );

  const sliderParams = {
    className: "model-main__private",
    modules: [Navigation],
    slidesPerView: 3,
    spaceBetween: 10,
    breakpoints: {
      0: {
        slidesPerView: 3,
      },
      600.98: {
        slidesPerView: 2,
      },
      767.98: {
        slidesPerView: 3,
      },
    },
    navigation: {
      nextEl: ".model-main__private_button-next",
      prevEl: ".model-main__private_button-prev",
    },
  };

  const handleClick = () => {
    if (userType === "default") {
      dispatch(setAuthorizationStatus(true));
      dispatch(setAuthorisationAccessType("ero"));
    } else {
      dispatch(setErocontentAccessStatus(true));
    }
  };

  return (
    <div className={"model-main__private-wrapper mt-48 mt-24-mob"}>
      {!!setOpenEdit ? (
        <div className={`d-flex fd-row gap-12 align-center mb-12 justify-sb`}>
          <h3 className={"mb-0"}>{t("privatephotos")}</h3>

          <Button
            onClick={() => setOpenEdit(true)}
            size={"xs"}
            clazz={"button_outline--green"}
            square={true}
          >
            <Icon spritePath={"edit-2"} size={"s"} />
          </Button>
        </div>
      ) : (
        <h3 className={"mb-20 mb-16-mob"}>{t("privatephotos")}</h3>
      )}

      <Swiper {...sliderParams}>
        <button
          slot={"container-start"}
          className="swiper-button-prev model-main__private_button-prev"
        ></button>

        {ero.data.photo && ero.data.photo.length > 0
          ? ero.data.photo.map((data) => ErocontentTemplate(data))
          : null}

        {ero.data.video && ero.data.video.length > 0
          ? ero.data.video.map((data) => ErocontentTemplate(data, "video"))
          : null}

        <button
          slot={"container-end"}
          className="swiper-button-next model-main__private_button-next"
        ></button>
      </Swiper>
    </div>
  );
};

export default PrivateSlider;
