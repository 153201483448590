import { Route, Routes } from "react-router-dom";
import {
  MainPage,
  RegistrationPage,
  TariffsPage,
  VariantModelPage,
} from "../pages";
import Echo from "laravel-echo";
import io from "socket.io-client";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useDispatch, useSelector } from "react-redux";

import {
  getUserCity,
  getUserID,
  getUserLang,
  setApiUserReady,
  setUserId,
  setCountry,
  setCurrentCity,
  setLanguagesWords,
  setNationalistWords,
  setServicesWords,
  setUserType,
  setReadyToOrder,
  setReadyToVideochat,
  setUserBalance,
  setFavoriteCounter,
} from "@/stores/slices/userSlice";
import { Suspense, useEffect, useState } from "react";
import { setProfileCount } from "@/stores/slices/mapModelSlice";
import AccountAgency from "../pages/accounts/agency/AccountAgency";
import AccountModel from "../pages/accounts/model/AccountModel";
import AgencyList from "../pages/agencyList/AgencyList";
import AgencyPage from "../pages/agency/AgencyPage";
import VideochatHistoryPage from "../pages/videochatHistoryPage/VideochatHistoryPage";
import PartyPage from "../pages/partyPage/PartyPage";
import PrivateRoute from "../private-route/private-route";
import ProfilesPage from "../pages/accounts/model/profiles/ProfilesPage";
import { makeRequest } from "@/services/makeRequest";
import CreateProfile from "../pages/accounts/createProfile/CreateProfile";
import { Loader } from "@/components/ui";
import ReviewsPage from "../pages/accounts/model/reviews/ReviewsPage";
import ReservationPage from "../pages/accounts/model/reservations/ReservationPage";
import BalancePage from "../pages/balancePage/BalancePage";
import StatPage from "../pages/accounts/model/StatPage";
import Favorite from "../pages/favorite/Favorite";
import MainLayout from "../pages/Layouts/MainLayout";
import EditModelPage from "../pages/modelPage/EditModelPage";
import UIKitPage from "../pages/uIKitPage/UIKitPage";
import Guide from "../pages/guide/Guide";
import showToast from "../toast/Toast";

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

function App() {
  const dispatch = useDispatch();

  const lang = useSelector(getUserLang);
  const city = useSelector(getUserCity);

  const { i18n } = useTranslation();

  const token = localStorage.getItem("user");
  const userId = useSelector(getUserID);

  useEffect(() => {
    if (token && userId && process.env.REACT_APP_WEBSOCKET_ENABLED) {
      window.Echo = new Echo({
        broadcaster: "socket.io",
        client: io,
        host: process.env.REACT_APP_WEBSOCKET_URL,
        auth: { headers: { Authorization: "Bearer " + token } },
        transports: ["websocket"],
      });
      window.Echo.private(`App.Models.User.${userId}`)
      .listen(
        "ErrorEvent",
        (e) => {
          showToast({
            message: e.message,
            variant: "error",
          });
        }
      )
      .listen('VideoCallEvent', (e) => {
        showToast({
          message: e.message,
          variant: "succes",
        });
      })
    }
  }, [token, userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = "user/me";
        const method = "GET";

        const { data = {} } = await makeRequest({ route, method });

        const userType = data.user_type;
        const userId = data.id;
        const video = data.accept_video;
        const order = data.accept_booking;
        const balance = Math.round(data.balance);
        const favorites = data.favorites_count;
        dispatch(setUserType(userType));
        dispatch(setUserId(userId));
        dispatch(setApiUserReady(true));
        dispatch(setReadyToOrder(order));
        dispatch(setReadyToVideochat(video));
        dispatch(setUserBalance(balance));
        dispatch(setFavoriteCounter(favorites));
      } catch (error) {}
    };

    token && fetchData();
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = "services";
        const method = "GET";
        const payload = {
          lang,
        };

        const { data = {} } = await makeRequest({ route, method, payload });
        if (!!Object.values(data).length) {
          const citiesData = data.cities;
          const languages = data.languages;
          const nationalities = data.nationalities;
          const services = data.services;

          dispatch(setLanguagesWords(languages));
          dispatch(setNationalistWords(nationalities));
          dispatch(setServicesWords(services));

          const country = Object.keys(citiesData)[0];

          const cities = citiesData[country].cities.map((city) => ({
            id: city.id,
            title: city.name,
            icon: citiesData[country].code.toUpperCase(),
            highlight: city.highlight,
            slug: city.slug,
            profiles_count: city.profiles_count,
            sort: city.sort,
          }));

          const currentCity = cities.filter((item) => {
            return item.slug === city;
          });

          dispatch(setCountry(cities));
          dispatch(
            setCurrentCity({
              id: currentCity[0].id,
              title: currentCity[0].title,
              icon: currentCity[0].icon,
              highlight: currentCity[0].highlight,
              profiles_count: currentCity[0].profiles_count,
              country: citiesData[country].name,
            })
          );
          dispatch(setProfileCount(currentCity[0].profiles_count));
        }
      } catch (error) {}
    };

    lang && fetchData();
  }, [lang, dispatch, city]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n, lang]);

  return (
    <Suspense fallback={<Loader height={100} />}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path=":lang?/:city?/:params?" element={<MainPage />} />
          <Route path=":lang/registration" element={<RegistrationPage />} />

          <Route path="guide" element={<Guide />} />

          <Route
            path=":lang?/lk/indi/create"
            element={
              <PrivateRoute>
                <CreateProfile />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/user"
            element={
              <PrivateRoute>
                <TariffsPage />
              </PrivateRoute>
            }
          />
          {/* <Route
            path=":lang?/lk/photograph"
            element={
              <PrivateRoute>
                <TariffsPage />
              </PrivateRoute>
            }
          /> */}
          <Route
            path=":lang?/lk/agency"
            element={
              <PrivateRoute>
                <AccountAgency />
              </PrivateRoute>
            }
          />

          <Route path="uikit" element={<UIKitPage />} />

          <Route path=":lang/:city/agencies" element={<AgencyList />} />

          <Route
            path=":lang/profile/:profileSlug"
            element={<VariantModelPage />}
          />

          <Route path=":lang/agency/:agencyId" element={<AgencyPage />} />

          <Route path=":lang/party/:partyId" element={<PartyPage />} />

          <Route path=":lang?/call/:room" element={<MainPage />} />

          {/* lk indi ---------------------------------------------------  */}
          <Route
            path=":lang?/lk/indi"
            element={
              <PrivateRoute>
                <AccountModel />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/videochats"
            element={
              <PrivateRoute>
                <VideochatHistoryPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/profiles"
            element={
              <PrivateRoute>
                <ProfilesPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/reviews"
            element={
              <PrivateRoute>
                <ReviewsPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/reservations"
            element={
              <PrivateRoute>
                <ReservationPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/balance"
            element={
              <PrivateRoute>
                <BalancePage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/statistic"
            element={
              <PrivateRoute>
                <StatPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":lang?/lk/indi/profile/:profileSlug/edit"
            element={
              <PrivateRoute>
                <EditModelPage />
              </PrivateRoute>
            }
          />
          {/* --------------------------------------------------- */}

          <Route path=":lang?/favorites" element={<Favorite />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
