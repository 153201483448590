import { Avatar, Button, RatingStars } from "@/components/ui";
import VideoStatus from "./VideoStatus";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CheckPhonePopup } from "../../../popups";
import moment from "moment";

import "./videoContainer.scss";

const VideoContainer = (props) => {
  const { data } = props;
  const { from, price, profile, started_at, status } = data;
  const {
    name: nameFrom,
    phone: phoneFrom,
    rating: ratingFrom,
    tariff: tariffFrom,
  } = from;

  const { name: nameProfile, photo: photoProfile, slug: slugProfile } = profile;

  const [isOpenCheckPhone, setIsOpenCheckPhone] = useState(false);
  const lang = useSelector(getUserLang);
  const navigate = useNavigate();

  const { t: indi } = useTranslation("indi");

  const statusObj = {
    4: "approvestatus",
    // 5:t('approvestatus'),
    6: "declinestatus",
  };

  const classVariant = statusObj[status] !== "approvestatus" ? " decline" : "";

  const handleOpen = () => {
    setIsOpenCheckPhone(true);
  };

  const startedAt = moment.unix(started_at).utc().format("DD.MM.YYYY HH:mm");

  const toAd = (e) => {
    e.preventDefault();
    navigate(`/${lang}/profile/${slugProfile}`);
  };

  return (
    <>
      {isOpenCheckPhone && (
        <CheckPhonePopup
          open={isOpenCheckPhone}
          setOpen={setIsOpenCheckPhone}
          phoneClient={phoneFrom}
        />
      )}
      <div className={"video__root"}>
        <div className={`video__user__main${classVariant}`}>
          <div className="video__user__block">
            <div className="video__user">
              <Avatar size={"s"} profileStatus={tariffFrom} onlyIcon={true} />
              <span className="video__user__title">{nameFrom}</span>
              <RatingStars gold={true} value={ratingFrom} readOnly={true} />
            </div>
            <span className="p3 color-main">{startedAt}</span>
          </div>
          <div className="video__user__block">
            <span className="p1 color-main">{phoneFrom}</span>
            <Button
              size={"xs"}
              buttonType={"submit"}
              clazz={"button_outline--green"}
              title={indi("crossnumber")}
              onClick={handleOpen}
            >
              {indi("crossnumber")}
            </Button>
          </div>
        </div>
        <div className={`video__info__block${classVariant}`}>
          <div className="video__price">
            <span>{indi("yougetmoney")}</span>
            <h3 className="mb-0">{price}€</h3>
          </div>
          <Link
            onClick={(e) => toAd(e)}
            to={`/profile/${slugProfile}`}
            className="video__ad hover-line"
            alt="ad"
          >
            <img
              src={
                !!photoProfile?.length || !!photoProfile ? photoProfile[0] : ""
              }
              className="video__ad__photo"
              alt="ad"
            />
            <span className="p1 mt-8 video__ad__title color-green">
              {nameProfile}
            </span>
          </Link>
        </div>
        <VideoStatus status={status} />
      </div>
    </>
  );
};

export default VideoContainer;
