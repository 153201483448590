import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import { useNavigate } from "react-router-dom";
import showToast from "../../toast/Toast";

const DeleteProfilePopup = (props) => {
  const { open, setOpen } = props;

  const { t: indi } = useTranslation("indi");

  const navigate = useNavigate();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user`;
        const method = "DELETE";

        await makeRequest({ route, method });
        navigate(`/`);
      } catch (error) {
        showToast({
          message: indi("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <dialog open={open} onClick={handleCloseDelete}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        method={"DELETE"}
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button onClick={handleCloseDelete} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner text-center">
          <h3>{indi("aredeleteprofile")}</h3>

          <span className={"color-700"}>{indi("notrecovery")}</span>
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {indi("cancel")}
          </Button>

          <Button
            size={"l"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={onSubmit}
          >
            {indi("yesdelete")}
          </Button>
        </div>
      </form>
    </dialog>
  );
};

export default DeleteProfilePopup;
