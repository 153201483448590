import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import {
  getGalleryImgIndex,
  getGalleryStatus,
  setAuthorisationAccessType,
  setAuthorizationStatus,
  setErocontentAccessStatus,
  setGalleryStatus,
} from "@/stores/slices/popupSlice";
import { Button, Icon, Video } from "@/components/ui";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";

import "./gallery.scss";

const Gallery = ({ name, media, ero }) => {
  const { dispatch, windowWidth, userType, setAvialableForUser } =
    useLadyService();
  const { t } = useTranslation("translation");

  const swiperRef = useRef(null);
  const [slideCount, setSlideCounter] = useState(1);
  const [maxSlideCountMedia, setMaxSlideCountMedia] = useState(null);
  const [maxSlideCountEro, setMaxSlideCountEro] = useState(null);
  const [maxSlideCount, setMaxSlideCount] = useState(null);

  const [mediaFiles, setMediaFiles] = useState([]);
  useEffect(() => {
    if (
      (media && media.photo && media.photo.length > 0) ||
      (media && media.video && media.video.length > 0) ||
      (media && media.interior && media.interior.length > 0)
    ) {
      setMaxSlideCountMedia(
        media.photo.length + media.video.length + media.interior.length
      );
    }

    setMediaFiles(media);
    //console.log(mediaFiles)
  }, [media, media.interior, media.photo, media.video]);

  const handleClick = (e) => {
    if (userType === "default") {
      dispatch(setAuthorizationStatus(true));
      dispatch(setAuthorisationAccessType("ero"));
    } else {
      dispatch(setErocontentAccessStatus(true));
    }
  };

  const [eroModel, setEroModel] = useState([]);

  useEffect(() => {
    if (
      (ero && ero.data && ero.data.photo && ero.data.photo.length > 0) ||
      (ero && ero.data && ero.data.video && ero.data.video.length > 0)
    ) {
      setEroModel(ero.data);
      setMaxSlideCountEro(ero.data.photo.length + ero.data.video.length);
      // console.log(eroModel)
    }
  }, [ero && ero.data && ero.data.photo, ero && ero.data && ero.data.video]);

  useEffect(() => {
    setMaxSlideCount(maxSlideCountEro + maxSlideCountMedia);
  }, [maxSlideCountEro, maxSlideCountMedia]);

  const galleryStatus = useSelector(getGalleryStatus);
  const currentGalleryImg = useSelector(getGalleryImgIndex);

  const [caption, setCaption] = useState(name);

  const MediaTemplate = ({ data, type, alt, onClick }) => {
    // узнаём какое расширение
    const regex = /([^.]+)$/;
    const extension = data.match(regex)[1];
    //console.log(data)

    return (
      <>
        {type === "ero" ? (
          <div
            onClick={() => handleClick()}
            className={"d-flex gap-8 fd-column align-center color-white"}
          >
            <Icon size={"m"} spritePath={"eye-off"} />
            <span className={"color-main"}>{t("show")}</span>
          </div>
        ) : null}

        {extension === ("mp4" || "webm") || type === "video" ? (
          <Video
            title={alt}
            onClick={(e) => e.stopPropagation()}
            src={data}
            controls={true}
          />
        ) : (
          <img
            src={data}
            onClick={(e) => e.stopPropagation()}
            title={alt}
            alt={alt}
          />
        )}
      </>
    );
  };

  return (
    <>
      {windowWidth > 767.98 ? (
        <dialog
          id="simpleGalleryPopup"
          open={!!galleryStatus}
          className="gallery"
        >
          {!!galleryStatus ? (
            <Helmet>
              <html className={"lock"}></html>
            </Helmet>
          ) : null}

          <Button
            square={true}
            size={"l"}
            onClick={() => dispatch(setGalleryStatus(false))}
            clazz={"button__close button--tetriary"}
          >
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          <span className="gallery__numbers">
            {slideCount}/{maxSlideCount}
          </span>

          {galleryStatus ? (
            <Swiper
              ref={swiperRef}
              className={"gallery__items"}
              modules={[Navigation]}
              slidesPerView={1}
              spaceBetween={10}
              onInit={() => swiperRef.current.swiper.slideTo(currentGalleryImg)}
              navigation={{
                nextEl: ".gallery__button-next",
                prevEl: ".gallery__button-prev",
              }}
              onActiveIndexChange={function (swiper) {
                let index = this.realIndex + 1;
                setSlideCounter(index);
                // setCaption(data[swiper.realIndex].name)
              }}
            >
              <button
                slot={"container-start"}
                className="swiper-button-prev gallery__button-prev"
              ></button>

              {mediaFiles && mediaFiles.photo && mediaFiles.photo.length > 0
                ? mediaFiles.photo.map((item) => (
                    <SwiperSlide
                      className={`gallery__item`}
                      onClick={() => dispatch(setGalleryStatus(false))}
                    >
                      <MediaTemplate
                        data={item}
                        alt={`${t("altphotoprofile")} ${name}`}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </SwiperSlide>
                  ))
                : null}

              {mediaFiles && mediaFiles.video && mediaFiles.video.length > 0
                ? mediaFiles.video.map((item) => (
                    <SwiperSlide
                      className={`gallery__item`}
                      onClick={() => dispatch(setGalleryStatus(false))}
                    >
                      <MediaTemplate
                        data={item}
                        type={"video"}
                        alt={`${name}`}
                      />
                    </SwiperSlide>
                  ))
                : null}

              {mediaFiles &&
              mediaFiles.interior &&
              mediaFiles.interior.length > 0
                ? mediaFiles.interior.map((item) => (
                    <SwiperSlide
                      className={`gallery__item`}
                      onClick={() => dispatch(setGalleryStatus(false))}
                    >
                      <MediaTemplate
                        data={item}
                        alt={`${t("aginterior")} ${name}`}
                      />
                    </SwiperSlide>
                  ))
                : null}

              {eroModel && eroModel.photo && eroModel.photo.length > 0
                ? eroModel.photo.map((item) => (
                    <SwiperSlide
                      className={`gallery__item model-main__private-slide`}
                      onClick={() => dispatch(setGalleryStatus(false))}
                    >
                      <MediaTemplate
                        data={item}
                        type={"ero"}
                        alt={`${t("aginterior")} ${name}`}
                      />
                    </SwiperSlide>
                  ))
                : null}

              {eroModel && eroModel.video && eroModel.video.length > 0
                ? eroModel.video.map((item) => (
                    <SwiperSlide
                      className={`gallery__item model-main__private-slide`}
                      onClick={() => dispatch(setGalleryStatus(false))}
                    >
                      <MediaTemplate
                        data={item}
                        type={windowWidth > 767.98 ? "ero" : "video"}
                        alt={`${t("aginterior")} ${name}`}
                      />
                    </SwiperSlide>
                  ))
                : null}

              <button
                slot={"container-end"}
                className="swiper-button-next gallery__button-next"
              ></button>
            </Swiper>
          ) : null}

          <span className={"gallery__caption"}>{name}</span>
        </dialog>
      ) : null}
    </>
  );
};

export default Gallery;
