import popup from  '../../../assets/img/icons/18-popup.svg';
import arrow from  '../../../assets/img/icons/arrow-up-right.svg'
import body from  '../../../assets/img/icons/body.svg'
import body2 from  '../../../assets/img/icons/body2.svg'
import bodyFill from  '../../../assets/img/icons/body-fill.svg'
import burger from  '../../../assets/img/icons/burger.svg'
import calendar from  '../../../assets/img/icons/calendar.svg'
import call from  '../../../assets/img/icons/call.svg'
import callCheck from  '../../../assets/img/icons/call-check.svg'
import callMedicine from  '../../../assets/img/icons/call-medicine.svg'
import callOutline from  '../../../assets/img/icons/call-outline.svg'
import camera from  '../../../assets/img/icons/camera.svg'
import cashDown from  '../../../assets/img/icons/cash-down.svg'
import cashUp from  '../../../assets/img/icons/cash-up.svg'
import casting from  '../../../assets/img/icons/casting.svg'
import catalog from  '../../../assets/img/icons/catalog.svg'
import change from  '../../../assets/img/icons/change.svg'
import check from  '../../../assets/img/icons/check.svg'
import checkBlack from  '../../../assets/img/icons/check-black.svg'
import checkThin from  '../../../assets/img/icons/check-thin.svg'
import checkWhite from  '../../../assets/img/icons/check-white.svg'
import checkl from  '../../../assets/img/icons/checkl.svg'
import chevrone from  '../../../assets/img/icons/chevrone-down.svg'
import chevroneLeft from  '../../../assets/img/icons/chevrone-left.svg'
import chevroneLeftBlack from  '../../../assets/img/icons/chevrone-left-black.svg'
import chevroneRight from  '../../../assets/img/icons/chevrone-right.svg'
import chevroneRightBlack from  '../../../assets/img/icons/chevrone-right-black.svg'
import chevroneUp from  '../../../assets/img/icons/chevrone-up.svg'
import close from  '../../../assets/img/icons/close.svg'
import comment from  '../../../assets/img/icons/comment.svg'
import copy from  '../../../assets/img/icons/copy.svg'
import diamond from  '../../../assets/img/icons/diamond.svg'
import diplomaVerified from  '../../../assets/img/icons/diploma-verified.svg'
import edit from  '../../../assets/img/icons/edit.svg'
import edit2 from  '../../../assets/img/icons/edit-2.svg'
import eroCoin from  '../../../assets/img/icons/ero-coin.svg'
import eroCoinLight from  '../../../assets/img/icons/ero-coin-light.svg'
import error from  '../../../assets/img/icons/error.svg'
import escort from  '../../../assets/img/icons/escort.svg'
import euro from  '../../../assets/img/icons/euro.svg'
import eye from  '../../../assets/img/icons/eye.svg'
import eyeOff from  '../../../assets/img/icons/eye-off.svg'
import favorite from  '../../../assets/img/icons/favorite.svg'
import favoriteFill from  '../../../assets/img/icons/favorite-fill.svg'
import film from  '../../../assets/img/icons/film.svg'
import filter from  '../../../assets/img/icons/filter.svg'
import filter2 from  '../../../assets/img/icons/filter2.svg'
import fix from  '../../../assets/img/icons/fix.svg'
import genderCouple from  '../../../assets/img/icons/gender-couple.svg'
import genderFemale from  '../../../assets/img/icons/gender-female.svg'
import genderMale from  '../../../assets/img/icons/gender-male.svg'
import genderTrans from  '../../../assets/img/icons/gender-trans.svg'
import geo from  '../../../assets/img/icons/geo.svg'
import girlfriend from  '../../../assets/img/icons/girlfriend.svg'
import globe from  '../../../assets/img/icons/globe.svg'
import health from  '../../../assets/img/icons/health-fill.svg'
import heartBorder from  '../../../assets/img/icons/heart-border.svg'
import home from  '../../../assets/img/icons/home.svg'
import homeMain from  '../../../assets/img/icons/home-main.svg'
import image from  '../../../assets/img/icons/image.svg'
import iconImport from  '../../../assets/img/icons/import.svg'
import incognito from  '../../../assets/img/icons/incognito.svg'
import info from  '../../../assets/img/icons/info.svg'
import ingYang1 from  '../../../assets/img/icons/ing-yang-1.svg'
import ingYang2 from  '../../../assets/img/icons/ing-yang-2.svg'
import instruction from  '../../../assets/img/icons/instruction.svg'
import instructionCont from  '../../../assets/img/icons/instruction-cont.svg'
import location from  '../../../assets/img/icons/location.svg'
import logout from  '../../../assets/img/icons/logout.svg'
import longerView from  '../../../assets/img/icons/longer-view.svg'
import mail from  '../../../assets/img/icons/mail.svg'
import map from  '../../../assets/img/icons/map.svg'
import mapPin from  '../../../assets/img/icons/map-pin.svg'
import minus from  '../../../assets/img/icons/minus.svg'
import moon from  '../../../assets/img/icons/moon.svg'
import more from  '../../../assets/img/icons/more.svg'
import navigation from  '../../../assets/img/icons/navigation.svg'
import notification from  '../../../assets/img/icons/notification.svg'
import notify from  '../../../assets/img/icons/notify.svg'
import operator from  '../../../assets/img/icons/operator.svg'
import outsideCity from  '../../../assets/img/icons/outside-city.svg'
import pants from  '../../../assets/img/icons/pants.svg'
import party from  '../../../assets/img/icons/party.svg'
import payContent from  '../../../assets/img/icons/pay-content.svg'
import payment from  '../../../assets/img/icons/payment.svg'
import person from  '../../../assets/img/icons/person.svg'
import photo from  '../../../assets/img/icons/photo.svg'
import pie from  '../../../assets/img/icons/pie-chart2.svg'
import play from  '../../../assets/img/icons/play.svg'
import plus from  '../../../assets/img/icons/plus.svg'
import plus1 from  '../../../assets/img/icons/plus-1.svg'
import profileAdd from  '../../../assets/img/icons/profile-add.svg'
import profileCard from  '../../../assets/img/icons/profile-card.svg'
import promo from  '../../../assets/img/icons/promo.svg'
import save from  '../../../assets/img/icons/save.svg'
import search from  '../../../assets/img/icons/search.svg'
import secure from  '../../../assets/img/icons/secure.svg'
import send from  '../../../assets/img/icons/send.svg'
import smallView from  '../../../assets/img/icons/small-view.svg'
import sort from  '../../../assets/img/icons/sort.svg'
import star from  '../../../assets/img/icons/star.svg'
import starFill from  '../../../assets/img/icons/star-fill.svg'
import starRating from  '../../../assets/img/icons/star-rating.svg'
import starSimple from  '../../../assets/img/icons/star-simple.svg'
import stat from  '../../../assets/img/icons/stat.svg'
import statistic from  '../../../assets/img/icons/statistic.svg'
import status from  '../../../assets/img/icons/status.svg'
import sun from  '../../../assets/img/icons/sun.svg'
import telegram from  '../../../assets/img/icons/telegram.svg'
import time from  '../../../assets/img/icons/time-plan.svg'
import trash from  '../../../assets/img/icons/trash.svg'
import travel from  '../../../assets/img/icons/travel.svg'
import triangle from  '../../../assets/img/icons/triangle.svg'
import undoLeft from  '../../../assets/img/icons/undo-left.svg'
import upload from  '../../../assets/img/icons/upload.svg'
import user from  '../../../assets/img/icons/user.svg'
import userCircle from  '../../../assets/img/icons/user-circle.svg'
import userId from  '../../../assets/img/icons/user-id.svg'
import verify from  '../../../assets/img/icons/verify.svg'
import verifyFill from  '../../../assets/img/icons/verify-fill.svg'
import video from  '../../../assets/img/icons/video.svg'
import videoPlay from  '../../../assets/img/icons/video-play.svg'
import volume from  '../../../assets/img/icons/volume.svg'
import volumeX from  '../../../assets/img/icons/volume-x.svg'
import wallet from  '../../../assets/img/icons/wallet-money.svg'
import warning from  '../../../assets/img/icons/warning.svg'
import web from  '../../../assets/img/icons/web-cam.svg'
import whatsapp from  '../../../assets/img/icons/whatsapp.svg'

const icons_db = [
  {
    img:popup,
    name: '18-popup'
  },
  {
    img:arrow,
    name: 'arrow-up-right'
  },
  {
    img:body,
    name: 'body'
  },
  {
    img:body2,
    name: 'body2'
  },
  {
    img:bodyFill,
    name: 'body-fill'
  },
  {
    img:burger,
    name: 'burger'
  },
  {
    img:calendar,
    name: 'calendar'
  },
  {
    img:call,
    name: 'call'
  },
  {
    img:callCheck,
    name: 'call-check'
  },
  {
    img:callMedicine,
    name: 'call-medicine'
  },
  {
    img:callOutline,
    name: 'call-outline'
  },
  {
    img:camera,
    name: 'camera'
  },
  {
    img:cashDown,
    name: 'cash-down'
  },
  {
    img:cashUp,
    name: 'cash-up'
  },
  {
    img:casting,
    name: 'casting'
  },
  {
    img:catalog,
    name: 'catalog'
  },
  {
    img:change,
    name: 'change'
  },
  {
    img:check,
    name: 'check'
  },
  {
    img:checkBlack,
    name: 'check-black'
  },
  {
    img:checkThin,
    name: 'check-thin'
  },
  {
    img:checkWhite,
    name: 'check-white'
  },
  {
    img:checkl,
    name: 'checkl'
  },
  {
    img:chevrone,
    name: 'chevrone-down'
  },
  {
    img:chevroneLeft,
    name: 'chevrone-left'
  },
  {
    img:chevroneLeftBlack,
    name: 'chevrone-left-black'
  },
  {
    img:chevroneRight,
    name: 'chevrone-right'
  },
  {
    img:chevroneRightBlack,
    name: 'chevrone-right-black'
  },
  {
    img:chevroneUp,
    name: 'chevrone-up'
  },
  {
    img:close,
    name: 'close'
  },
  {
    img:comment,
    name: 'comment'
  },
  {
    img:copy,
    name: 'copy'
  },
  {
    img:diamond,
    name: 'diamond'
  },
  {
    img:diplomaVerified,
    name: 'diploma-verified'
  },
  {
    img:edit,
    name: 'edit'
  },
  {
    img:edit2,
    name: 'edit-2'
  },
  {
    img:eroCoin,
    name: 'ero-coin'
  },
  {
    img:eroCoinLight,
    name: 'ero-coin-light'
  },
  {
    img:error,
    name: 'error'
  },
  {
    img:escort,
    name: 'escort'
  },
  {
    img:euro,
    name: 'euro'
  },
  {
    img:eye,
    name: 'eye'
  },
  {
    img:eyeOff,
    name: 'eye-off'
  },
  {
    img:favorite,
    name: 'favorite'
  },
  {
    img:favoriteFill,
    name: 'favorite-fill'
  },
  {
    img:film,
    name: 'film'
  },
  {
    img:filter,
    name: 'filter'
  },
  {
    img:filter2,
    name: 'filter2'
  },
  {
    img:fix,
    name: 'fix'
  },
  {
    img:genderCouple,
    name: 'gender-couple'
  },
  {
    img:genderFemale,
    name: 'gender-female'
  },
  {
    img:genderMale,
    name: 'gender-male'
  },
  {
    img:genderTrans,
    name: 'gender-trans'
  },
  {
    img:geo,
    name: 'geo'
  },
  {
    img:girlfriend,
    name: 'girlfriend'
  },
  {
    img:globe,
    name: 'globe'
  },
  {
    img:health,
    name: 'health-fill'
  },
  {
    img:heartBorder,
    name: 'heart-border'
  },
  {
    img:home,
    name: 'home'
  },
  {
    img:homeMain,
    name: 'home-main'
  },
  {
    img:image,
    name: 'image'
  },
  {
    img:iconImport,
    name: 'import'
  },
  {
    img:incognito,
    name: 'incognito'
  },
  {
    img:info,
    name: 'info'
  },
  {
    img:ingYang1,
    name: 'ing-yang-1'
  },
  {
    img:ingYang2,
    name: 'ing-yang-2'
  },
  {
    img:instruction,
    name: 'instruction'
  },
  {
    img:instructionCont,
    name: 'instruction-cont'
  },
  {
    img:location,
    name: 'location'
  },
  {
    img:logout,
    name: 'logout'
  },
  {
    img:longerView,
    name: 'longer-view'
  },
  {
    img:mail,
    name: 'mail'
  },
  {
    img:map,
    name: 'map'
  },
  {
    img:mapPin,
    name: 'map-pin'
  },
  {
    img:minus,
    name: 'minus'
  },
  {
    img:moon,
    name: 'moon'
  },
  {
    img:more,
    name: 'more'
  },
  {
    img:navigation,
    name: 'navigation'
  },
  {
    img:notification,
    name: 'notification'
  },
  {
    img:notify,
    name: 'notify'
  },
  {
    img:operator,
    name: 'operator'
  },
  {
    img:outsideCity,
    name: 'outside-city'
  },
  {
    img:pants,
    name: 'pants'
  },
  {
    img:party,
    name: 'party'
  },
  {
    img:payContent,
    name: 'pay-content'
  },
  {
    img:payment,
    name: 'payment'
  },
  {
    img:person,
    name: 'person'
  },
  {
    img:photo,
    name: 'photo'
  },
  {
    img:pie,
    name: 'pie-chart2'
  },
  {
    img:play,
    name: 'play'
  },
  {
    img:plus,
    name: 'plus'
  },
  {
    img:plus1,
    name: 'plus-1'
  },
  {
    img:profileAdd,
    name: 'profile-add'
  },
  {
    img:profileCard,
    name: 'profile-card'
  },
  {
    img:promo,
    name: 'promo'
  },
  {
    img:save,
    name: 'save'
  },
  {
    img:search,
    name: 'search'
  },
  {
    img:secure,
    name: 'secure'
  },
  {
    img:send,
    name: 'send'
  },
  {
    img:smallView,
    name: 'small-view'
  },
  {
    img:sort,
    name: 'sort'
  },
  {
    img:star,
    name: 'star'
  },
  {
    img:starFill,
    name: 'star-fill'
  },
  {
    img:starRating,
    name: 'star-rating'
  },
  {
    img:starSimple,
    name: 'star-simple'
  },
  {
    img:stat,
    name: 'stat'
  },
  {
    img:statistic,
    name: 'statistic'
  },
  {
    img:status,
    name: 'status'
  },
  {
    img:sun,
    name: 'sun'
  },
  {
    img:telegram,
    name: 'telegram'
  },
  {
    img:time,
    name: 'time-plan'
  },
  {
    img:trash,
    name: 'trash'
  },
  {
    img:travel,
    name: 'travel'
  },
  {
    img:triangle,
    name: 'triangle'
  },
  {
    img:undoLeft,
    name: 'undo-left'
  },
  {
    img:upload,
    name: 'upload'
  },
  {
    img:user,
    name: 'user'
  },
  {
    img:userCircle,
    name: 'user-circle'
  },
  {
    img:userId,
    name: 'user-id'
  },
  {
    img:verify,
    name: 'verify'
  },
  {
    img:verifyFill,
    name: 'verify-fill'
  },
  {
    img:video,
    name: 'video'
  },
  {
    img:videoPlay,
    name: 'video-play'
  },
  {
    img:volume,
    name: 'volume'
  },
  {
    img:volumeX,
    name: 'volume-x'
  },
  {
    img:wallet,
    name: 'wallet-money'
  },
  {
    img:warning,
    name: 'warning'
  },
  {
    img:web,
    name: 'web-cam'
  },
  {
    img:whatsapp,
    name: 'whatsapp'
  }
]

export default icons_db