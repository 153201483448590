import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
  Button,
  Icon,
  Dropdown,
  InputInLabel,
  ButtonLink,
} from "@/components/ui";

const options = [
  { name: "banging.cz" },
  { name: "secretgirlprague.com" },
  { name: "escorts.cz" },
];

const ImportAdPopup = (props) => {
  const { open, setOpen } = props;

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const handleCloseDelete = () => {
    setOpen(false);
  };

  return (
    <dialog open={open} onClick={handleCloseDelete}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        method={"POST"}
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button onClick={handleCloseDelete} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner">
          <div className="popup-form__inner text-center">
            <h3>{indi("importfor")}</h3>
            <p className={"color-700 p2"}>{indi("parametrs")}</p>
          </div>
          <Dropdown
            title={indi("selectweb")}
            name={"site"}
            clazz={"w-100 color-main"}
            size={"l"}
          >
            {" "}
            {options.map((i, index) => {
              return (
                <ButtonLink
                  clazz={`_no-underline justify-start`}
                  // href={i.bo}
                  title={i.name}
                  size={"xs"}
                  key={index}
                  name={i.name}
                >
                  {i.name}
                </ButtonLink>
              );
            })}
          </Dropdown>
          <InputInLabel
            type={"text"}
            // id={"create-tg"}
            placeholder={"https://ashoo.org/profile-91238523126"}
            //   register={{ ...register("tg") }}
            //   clazz={"w-100"}
            clazz={"w-100 link"}
          >
            {indi("url")}
          </InputInLabel>
          <Button
            size={"l"}
            buttonType={"submit"}
            clazz={"button--green justify-center mt-16"}
            onClick={handleCloseDelete}
          >
            {indi("import")}
          </Button>
        </div>
      </form>
    </dialog>
  );
};

export default ImportAdPopup;
