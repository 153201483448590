import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { makeRequest } from "@/services/makeRequest";

import useLadyService from "@/services/LadyService";

import { Loader } from "@/components/ui";
import CurrentModelPage from "./CurrentModelPage";

const EditModelPage = () => {
  const { profileSlug } = useParams();

  const [oneProfileData, setOneProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { lang } = useLadyService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const route = `user/profiles`;

        const method = "GET";
        const payload = {
          lang,
          slug: profileSlug,
        };

        const data = await makeRequest({ route, method, payload });

        console.log("data", data);

        if (data) {
          const result = data.profiles;

          setOneProfileData(result[0]);
        }
        setIsLoading(false);
      } catch (error) {}
    };

    profileSlug && lang && fetchData();
  }, [profileSlug, lang]);

  console.log("oneProfileData", oneProfileData);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  if (isLoading) {
    return <Loader height={100} />;
  }

  return <CurrentModelPage dataProps={oneProfileData} />;
};

export default EditModelPage;
