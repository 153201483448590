import { useLocation, useNavigate } from 'react-router-dom';

export function useLanguageChange() {
  const navigate = useNavigate();
  const location = useLocation();

  const languages = ['ru', 'en', 'cz'];

  const changeLanguage = (newLang) => {
    const { pathname } = location;
    const pathParts = pathname.split('/');
    const languageIndex = pathParts.findIndex(part => languages.includes(part));

    if (languageIndex !== -1) {
      pathParts[languageIndex] = newLang;
      const newPath = pathParts.join('/');
      navigate(newPath);
    }
  };

  return changeLanguage;
}

export default useLanguageChange;