import "./adGirlCard.scss";

import { Icon, Button, ButtonLink, RatingStars } from "@/components/ui";
import { useTranslation } from "react-i18next";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";
import { useDispatch } from "react-redux";
import { setOneModelPosition } from "@/stores/slices/mapModelSlice";
import { setStoriesMap } from "@/stores/slices/popupSlice";

const AdGirlCard = ({ data, mobile = false, status }) => {
  const {
    gender,
    clazz,
    slug,
    name,
    phone,
    telegram,
    whatsapp,
    position,
    rating,
    address,
    city,
    age,
    height,
    weight,
    breast,
    price,
    languages,
    services,
    is_online = false,
    is_individual,
    is_top,
    is_elite,
    is_escort,
    is_healthy,
    is_verified,
    verified_at,
    is_pornstar,
    is_new,
    tag_video,
    tag_private,
    tag_friends,
    tag_booking,
    tag_bdsm,
    ready_travel,
    orientation,
    main_photo,
    statistic,
  } = data;

  const { favorites } = statistic;

  const dispatch = useDispatch();

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const postAltphoto = t("altphoto")?.replace(/%([^%]+)%/g, name);

  const {
    theme,
    lang,
    windowWidth: widthScreen,
    orientations,
    gendersTitle,
  } = useLadyService();

  const [languageArray, setLanguageArray] = useState([]);

  useEffect(() => {
    if (languages.length > 0) {
      const result = languages.map((lang) => {
        const key = Object.keys(lang)[0];
        return {
          langCode: lang[key].code,
          name: lang[key].name,
        };
      });
      setLanguageArray(result);
    }
  }, []);

  const [isHover, setIsHover] = useState(false);

  const pricesGroup = () => (
    <div className="girl-adcard__prices">
      {price && price.incall ? (
        <div className="girl-adcard__price--private">
          <span>{t("incall")}</span>
          {price.incall}€
        </div>
      ) : null}

      {price && price.outcall ? (
        <div className="girl-adcard__price--escort">
          <span>{t("outcall")}</span>
          {price.outcall}€
        </div>
      ) : null}

      {!mobile && price && price.escort ? (
        <div className="girl-adcard__price--accompaniment">
          <span>{t("accompaniment")}</span>
          {price.escort}€
        </div>
      ) : null}
    </div>
  );

  let linkRef = useRef(null);

  const toggleMapOpen = (slug) => {
    dispatch(setOneModelPosition(slug));
    dispatch(setStoriesMap(true));
  };

  const cardInfo = () => (
    <div className="girl-card__info">
      <Link
        to={
          !!mobile
            ? `/lk/indi/profile/${slug}/edit`
            : `/${lang}/profile/${slug}`
        }
        ref={linkRef}
        onMouseLeave={() => setIsHover(false)}
        onMouseEnter={() => setIsHover(true)}
        title={is_online ? `${name} ${t("online")}` : `${name} ${t("offline")}`}
        className={`girl-card__name${
          is_online ? ` ${t("online")}` : ` ${t("offline")}`
        }${isHover ? " _hover" : ""}`}
      >
        <Icon
          spritePath={
            is_online
              ? "online"
              : theme !== "dark"
              ? "offline-light"
              : "offline"
          }
        />
        {name}
      </Link>

      {!!address && !!city ? (
        <Link
          to={"#"}
          title={t("onmap")}
          className="girl-card__address"
          onClick={() => toggleMapOpen(slug)}
        >
          <Icon spritePath={"map-pin"} size={"s"} />
          {!!city ? `${city}` : ""}
          {!!address ? `, ${address}` : ""}
        </Link>
      ) : !!city ? (
        <div title={t("onmap")} className="girl-card__address">
          <Icon spritePath={"map-pin"} size={"s"} />
          {city}
        </div>
      ) : address ? (
        <Link to={"#"} className="girl-card__address">
          <Icon spritePath={"map-pin"} size={"s"} />
          {address}
        </Link>
      ) : null}
    </div>
  );

  const icons = () => (
    <>
      {!!tag_video ? (
        <div title={t("video")}>
          <Icon spritePath={"video-play"} size={"l"} />
        </div>
      ) : null}

      {!!tag_friends ? (
        <div title={t("girlfriend")}>
          <Icon spritePath={"girlfriend"} size={"l"} />
        </div>
      ) : null}

      {!!tag_private ? (
        <div className={"_green"} title={t("erocontent")}>
          <Icon spritePath={"pants"} size={"l"} />
        </div>
      ) : null}

      {!!tag_booking ? (
        <div title={t("onlineorder")}>
          <Icon spritePath={"pay-content"} size={"l"} />
        </div>
      ) : null}

      {!!ready_travel ? (
        <div title={t("travel")}>
          <Icon spritePath={"travel"} size={"l"} />
        </div>
      ) : null}

      {!!is_escort ? (
        <div title={t("escort")}>
          <Icon spritePath={"escort"} size={"l"} />
        </div>
      ) : null}
    </>
  );

  const tags = () => (
    <>
      {!!is_individual ? (
        <span className={"girl-adcard__tag"} title={t("indireg")}>
          {"INDI"}
        </span>
      ) : null}

      {!!is_new ? (
        <span className={"girl-adcard__tag"} title={t("newmodel")}>
          {"NEW"}
        </span>
      ) : null}

      {!!tag_bdsm ? (
        <span className={"girl-adcard__tag"} title={t("bdsmservice")}>
          {"BDSM"}
        </span>
      ) : null}

      {!!is_pornstar ? (
        <span className={"girl-adcard__tag"} title={t("pornstar")}>
          {"STAR"}
        </span>
      ) : null}
    </>
  );

  const params = () => (
    <div className="girl-adcard__params">
      {!!height ? (
        <div className="girl-adcard__params--height">
          <span>{t("height")}</span>
          {height + " " + t("cm")}
        </div>
      ) : null}

      {!!weight ? (
        <div className="girl-adcard__params--weight">
          <span>{t("weight")}</span>
          {weight + " " + t("kg")}
        </div>
      ) : null}

      {!mobile && !!breast ? (
        <div className="girl-adcard__params--breast">
          <span>{t("breastsize")}</span>
          {breast}
        </div>
      ) : null}
    </div>
  );

  const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
    ? formatPhoneNumberIntl(`${phone}`)
    : formatPhoneNumberIntl(`+${phone}`);

  const infoIcons = () => (
    <>
      {!!verified_at ? (
        <span className={"girl-adcard__icon"} title={t("verification")}>
          <Icon spritePath={"verify-fill"} size={"l"} />
        </span>
      ) : null}

      {!!is_healthy ? (
        <span className={"girl-adcard__icon"} title={t("healthy")}>
          <Icon spritePath={"health-fill"} size={"l"} />
        </span>
      ) : null}
    </>
  );

  const setServices = Array.from(new Set(services));

  return (
    <div
      className={`girl-adcard ${!status ? "not_active" : ""}${
        position === 1 || is_top ? " girl-adcard--top" : ""
      }${is_elite ? " girl-adcard--elite" : ""}${clazz ? ` ${clazz}` : ""}${
        (widthScreen < 767.98 && widthScreen !== 0) || mobile
          ? " girl-adcard_size-s"
          : ""
      }`}
    >
      <div className={`girl-adcard__wrapper`}>
        <div
          className="girl-adcard__top"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="girl-adcard__slider-wrapper">
            {isHover && (
              <ButtonLink
                size={"s"}
                href={`/lk/indi/profile/${slug}/edit`}
                clazz={`girl-adcard__edit${mobile ? "--mobile" : ""}`}
              >
                <Icon spritePath={"edit-2"} size={"m"} />
                {indi("edit")}
              </ButtonLink>
            )}

            <Button clazz={`girl-adcard__favorite`}>
              <span className="color-green">{favorites}</span>
              <Icon size={"l"} spritePath={"favorite-fill"} />
            </Button>

            <Swiper className="girl-adcard__slider">
              <SwiperSlide
                onClick={() => linkRef.current.click()}
                className={`girl-adcard__slide${isHover ? " _hover" : ""}`}
              >
                <img src={main_photo} alt={postAltphoto} />
              </SwiperSlide>
            </Swiper>

            <div className="girl-adcard__descr">
              <div className="girl-adcard__tags-wrapper">
                {!!is_verified || !!is_healthy || !!gender ? (
                  <div className="girl-adcard__icons">
                    <span
                      className={"girl-adcard__icon"}
                      title={`${gendersTitle[gender]} — ${orientations(
                        orientation
                      )}`}
                    >
                      <Icon spritePath={`gender-${gender}`} size={"l"} />
                    </span>

                    {infoIcons()}
                  </div>
                ) : null}

                <div className="girl-adcard__tags">{tags()}</div>
              </div>

              <div className="girl-adcard__info-wrapper">
                {cardInfo()}

                <div className="girl-adcard__age">
                  {age} <span>{t("years")}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="girl-adcard__group">
            {!!height || !!weight || !!breast ? params() : null}

            <div className="girl-adcard__icons-wrapper">
              <div className="girl-adcard__tags-wrapper">
                <span
                  className={"girl-adcard__icon"}
                  title={`${gendersTitle[gender]} — ${orientations(
                    orientation
                  )}`}
                >
                  <Icon spritePath={`gender-${gender}`} size={"l"} />
                </span>

                {infoIcons()}

                {tags()}
              </div>

              <div className="girl-adcard__modes">
                {icons()}

                {!!setServices.length
                  ? setServices.map((item) => {
                      return <span key={item}>{t(`${item}`)}</span>;
                    })
                  : null}
              </div>
            </div>
            {mobile && !!languageArray.length ? (
              <>
                <div className="girl-adcard__speak">
                  <span>{t("ispeak") + ":"}</span>
                  {languageArray.map((i, index) => {
                    return (
                      <div title={i.langCode} key={index}>
                        <Icon
                          type={"flag"}
                          spritePath={`${i.langCode.toUpperCase()}`}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
            {pricesGroup()}
          </div>
        </div>
        <div className="girl-adcard__bottom">
          <div className="girl-adcard__group">
            {!!height || !!weight || !!breast ? params() : null}

            <div className="girl-adcard__modes">
              {icons()}

              {!!setServices.length
                ? setServices.map((item) => {
                    return <span key={item}>{t(`${item}`)}</span>;
                  })
                : null}
            </div>
          </div>

          <div className="girl-adcard__info-wrapper">
            <div className="girl-adcard__age">
              {age} <span>{t("years")}</span>
            </div>
            {cardInfo()}
            {mobile && <RatingStars value={rating} readOnly={true} />}
          </div>

          {!mobile ? (
            <>
              {!!rating || !!languageArray.length ? (
                <div className="girl-adcard__advantages">
                  <RatingStars
                    value={rating}
                    readOnly={true}
                    title={"отзывов"}
                  />

                  {!!languageArray.length ? (
                    <div className="girl-adcard__speak">
                      <span>{t("ispeak") + ":"}</span>
                      {languageArray.map((i, index) => {
                        return (
                          <div title={i.langCode} key={index}>
                            <Icon
                              type={"flag"}
                              spritePath={`${i.langCode.toUpperCase()}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </>
          ) : null}

          {pricesGroup()}

          <div className="girl-adcard__buttons">
            <Button size={"m"} clazz={"button--primary"}>
              {phoneRes}
            </Button>

            {!!whatsapp ? (
              <Button
                href={whatsapp}
                clazz={"button--primary"}
                square={true}
                size={"m"}
              >
                <Icon size={"xl"} spritePath={"whatsapp"} />
              </Button>
            ) : null}

            {!!telegram ? (
              <Button clazz={"button--primary"} square={true} size={"m"}>
                <Icon size={"xl"} spritePath={"telegram"} />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdGirlCard;
