import { InputInLabel, Button } from "@/components/ui";
import { useState, useEffect } from "react";

import showToast from "../../../../../toast/Toast";

import { makeRequest } from "@/services/makeRequest";

import "./infoForm.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const InfoForm = ({ data }) => {
  const { email, email_verified_at, telegram, phone, whatsapp } = data;

  const [isActive, setIsActive] = useState(false);
  const [valueLoad, setValueLoad] = useState(false);

  const [whatsappData, setWhatsappData] = useState(whatsapp);

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");

  const EmailLabel = () => {
    return !!email_verified_at ? (
      <span>Email</span>
    ) : (
      <span className="error">Email - {indi("notconfirmed")}</span>
    );
  };

  const handleChange = (e) => {
    e.preventDefault();
    setIsActive(true);
  };

  const formSchema = object().shape({
    email: string().email().required(indi("emailisnes")),
  });

  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    const { email: newEmail, telegram: newTelegram } = data;

    const fetchData = async () => {
      try {
        const route = `user`;
        const method = "PUT";
        const payload = {
          email: newEmail,
          whatsapp: String(whatsappData).replace(/[" "+]/g, ""),
          telegram: newTelegram,
          phone,
        };

        setIsActive(false);
        showToast({
          message: indi("success"),
          variant: "succes",
        });

        await makeRequest({ route, method, payload });
      } catch (error) {
        const res = error.response;

        // eslint-disable-next-line default-case
        switch (res.status) {
          case 401: {
            showToast({
              message: indi("noauth"),
              variant: "error",
            });
            break;
          }
          case 422: {
            showToast({
              message: indi("oops"),
              variant: "error",
            });
            break;
          }
        }
      }
    };

    fetchData();
  };

  useEffect(() => {
    setValueLoad(false);
    setValue("email", email);
    setValue("telegram", telegram);
    setValue("whatsapp", whatsappData);
    setValueLoad(true);
  }, [email, setValue, telegram, whatsappData]);

  return (
    <form
      className="info-form"
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputInLabel
        type={"tel"}
        id={"whatsappPhone"}
        placeholder={t("phonenumber")}
        disabled={!isActive}
        register={{ ...register("whatsapp") }}
        maskValue={whatsappData}
        setMaskValue={setWhatsappData}
        valueLoad={valueLoad}
      >
        {t("whatsapp")}
      </InputInLabel>

      <InputInLabel
        type={"input"}
        id={"telegram"}
        placeholder={"Telegram"}
        disabled={!isActive}
        register={{ ...register("telegram") }}
      >
        Telegram
      </InputInLabel>
      <InputInLabel
        type={"email"}
        id={"email"}
        placeholder={"Email"}
        disabled={!isActive}
        register={{ ...register("email") }}
      >
        <EmailLabel />
      </InputInLabel>
      {!isActive ? (
        <Button
          size={"s"}
          clazz={"button_outline--green-accent justify-center"}
          onClick={handleChange}
        >
          {indi("edit")}
        </Button>
      ) : (
        <Button
          size={"s"}
          buttonType={"submit"}
          clazz={"button_outline--green-accent justify-center"}
        >
          {t("save")}
        </Button>
      )}
    </form>
  );
};

export default InfoForm;
