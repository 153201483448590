import "./uploadZone.scss";

import { useState, useRef } from "react";
import Icon from "../icon/Icon";
import showToast from "../../toast/Toast";

const UploadZone = ({
  setData,
  mainText,
  subText,
  infoText,
  isMultiLoad = false,
  setFiles,
  fileType = "photo",
  type,
}) => {
  const [onDrag, setOnDrag] = useState(false);

  const fileInputRef = useRef(null);
  const multiResult = [];

  function loadImage(url) {
    if (!isMultiLoad) {
      let datas = [];

      datas.push(url);

      setData(datas);
    } else {
      multiResult.push(url);
      setData(multiResult);
    }
  }

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    setOnDrag(false);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setOnDrag(true);
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      if(fileType === "video") {
        if(selectedFile.size  > 20 * 1024 * 1024) {
          showToast({
            message: `Максимальный размер файла: 20МБ`,
            variant: "error",
          });
          e.target.value = "";
          return
        }
      }
      
      if (
        selectedFile && fileType === "video"
          ? selectedFile.type.startsWith("video/") &&
            [
              "video/mp4",
              "video/quicktime",
              "video/x-ms-wmv",
              "video/x-msvideo",
              "video/x-matroska",
            ].includes(selectedFile.type)
          : selectedFile.type.startsWith("image/") &&
            ["image/jpeg", "image/png", "image/webp"].includes(
              selectedFile.type
            )
      ) {
        const imageUrl = URL.createObjectURL(selectedFile);
        loadImage(imageUrl);

        if (isMultiLoad) {
          loadImage(null);
          const file = {
            selectedFile: selectedFile,
            type: type,
          };
          if(fileType === 'video') {
            file.isVideo = true
          }
          setFiles((prev) => [...prev, file]);
        }
      } else {
        showToast({
          message: `Допускаемый формат: ${
            type === "video"
              ? "MP4, MOV, WMV, AVI, MKV"
              : "jpg, jpeg, png, webp"
          }`,
          variant: "error",
        });
        e.target.value = "";
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (!isMultiLoad) {
      if (files && files.length > 0) {
        const droppedFile = files[0];
        const imageUrl = URL.createObjectURL(droppedFile);
        loadImage(imageUrl);
      }
    } else {
      if (files && files.length > 0) {
        const droppedFiles = Array.from(files);
        const length = droppedFiles.length;
        droppedFiles.forEach((file, index) => {
          
          if(fileType === "video") {
            if(file.size  > 20 * 1024 * 1024) {
              showToast({
                message: `Максимальный размер файла: 20МБ`,
                variant: "error",
              });
              e.target.value = "";
              return
            }
          }
          if (
            fileType === "video"
              ? file.type.startsWith("video/") &&
                [
                  "video/mp4",
                  "video/quicktime",
                  "video/x-ms-wmv",
                  "video/x-msvideo",
                  "video/x-matroska",
                ].includes(file.type)
              : file.type.startsWith("image/") &&
                ["image/jpeg", "image/png", "image/webp"].includes(file.type)
          ) {
            const droppedFile = files[index];
            const imageUrl = URL.createObjectURL(droppedFile);

            loadImage(imageUrl);

            if (index + 1 === length) {
              loadImage(null);
            }

            const result = {
              selectedFile: droppedFile,
              type: type,
            };
            if(fileType === 'video') {
              result.isVideo = true
            }
            setFiles((prev) => [...prev, result]);
          } else {
            showToast({
              message: `Допускаемый формат: ${
                type === "video"
                  ? "MP4, MOV, WMV, AVI, MKV"
                  : "jpg, jpeg, png, webp"
              }`,
              variant: "error",
            });
            e.target.value = "";
          }
        });
      }
    }
  };

  return (
    <div
      className={`upload__zone ${onDrag ? "drag" : ""}`}
      onClick={handleClick}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="upload__zone__main">
        <Icon clazz={"_green"} size={"xl"} spritePath={"plus-1"} />
        <span className="p1 color-main mb-12">{mainText}</span>
        {subText && <span className="text-center">{subText}</span>}
        {infoText && (
          <span className="color-green text-center">{infoText}</span>
        )}
      </div>

      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        className={"inputDisplay"}
      />
    </div>
  );
};

export default UploadZone;
