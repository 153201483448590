import {
  Breadcrumbs,
  Button,
  ButtonLink,
  Icon,
  Note,
  RatingStars,
} from "@/components/ui";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setLang } from "@/stores/slices/userSlice";
import {
  setOrderModelStatus,
  setStoriesStatus,
  setStoriesMap,
  setVideoChatMode,
  setAuthorizationStatus,
  setMapMode,
  getMapStatus,
  setAuthorisationAccessType,
} from "@/stores/slices/popupSlice";
import { Link, useParams } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { SwiperSlide } from "swiper/react";
import AgencyCard from "../../agencyCard/AgencyCard";
import Reviews from "../../reviews/Reviews";
import GirlCard from "../../girlCard/GirlCard";
import Slider from "../../sliders/Slider";
import ScheduleTable from "../../scheduleTable/ScheduleTable";
import StoriesComponent from "../../stories/StoriesComponent";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";
import PartyCard from "../../partyCard/PartyCard";
import Gallery from "../../popups/gallery/Gallery";
import useLadyService from "@/services/LadyService";
import TabSliderModel from "../../sliders/tabSlider/tabSliderModel/TabSliderModel";
import { setOneModelPosition } from "@/stores/slices/mapModelSlice";
import { setLadyForCall } from "@/stores/slices/videoCallSlice";
import { setModelForCall } from "@/stores/slices/modelsState";
import ModelPornstarCard from "./components/pornstarCard/ModelPornstarCard";
import { Helmet } from "react-helmet";
import ModelInfoContent from "./components/infoContent/ModelInfoContent";
import { fetchRanges } from "@/stores/slices/filterSlice";
import ModelServices from "./components/serviceContent/ModelServices";
import ModelFavoriteBtn from "./components/favoriteButton/ModelFavoriteBtn";
import {
  SuccessReviewMassage,
  AddReview,
  Appeal,
  OrderModel,
  ErocontentAccess,
} from "../../popups";

import statusElite from "../../../assets/img/status/elite.webp";
import statusEliteMob from "../../../assets/img/status/elite-mob.webp";
import statusTop from "../../../assets/img/status/top.webp";
import statusTopLight from "../../../assets/img/status/top.webp";
import statusTopMob from "../../../assets/img/status/top-mob.webp";
import statusTopMobLight from "../../../assets/img/status/top-mob-light.webp";

import PrivateSlider from "../../sliders/privateSlider/PrivateSlider";
import ModelCounters from "./components/counters/ModelCounters";

import "./modelPage.scss";
import ModelButtonsGroup from "./components/buttonsGroup/ModelButtonsGroup";
import SuccessAppealMassage from "../../popups/appeal/SuccessAppealMassage";

const ModelPage = ({ oneProfileData }) => {
  const {
    dispatch,
    lang,
    theme,
    windowWidth,
    userType,
    gendersTitle,
    orientations,
    getTimeAgo,
  } = useLadyService();

  const { t } = useTranslation("translation");

  const { t: indi } = useTranslation("indi");

  const mapPopupStatus = useSelector(getMapStatus);

  const {
    id = oneProfileData?.id,
    name = oneProfileData?.name,
    gender = oneProfileData?.gender,
    orientation = oneProfileData?.orientation,
    description = oneProfileData?.description,
    note = oneProfileData?.note,
    gf = oneProfileData?.gf,
    age = oneProfileData?.age,
    prices = oneProfileData?.prices,
    rating = oneProfileData?.rating,
    rating_charm = oneProfileData?.rating_charm,
    rating_quality = oneProfileData?.rating_quality,
    rating_communication = oneProfileData?.rating_communication,
    rating_emotions = oneProfileData?.rating_emotions,
    rating_atmosphere = oneProfileData?.rating_atmosphere,
    reviews_count = oneProfileData?.reviews_count,
    tag_booking = oneProfileData?.tag_booking,
    ready_travel = oneProfileData?.ready_travel,
    ready_videochat = oneProfileData?.ready_videochat,
    is_top = oneProfileData?.is_top,
    is_online = oneProfileData?.is_online,
    last_activity = oneProfileData?.last_activity,
    is_elite = oneProfileData?.is_elite,
    is_escort = oneProfileData?.is_escort,
    phone = oneProfileData?.phone,
    telegram = oneProfileData?.telegram,
    whatsapp = oneProfileData?.whatsapp,
    city = oneProfileData?.city,
    address = oneProfileData?.address,
    distance = oneProfileData?.distance,
    media = oneProfileData?.media,
    ero = oneProfileData?.ero,
    porn_star = oneProfileData?.porn_star,
    parties = oneProfileData?.parties,
    agency = oneProfileData?.agency,
    stories = oneProfileData?.stories,
    statistic = oneProfileData?.statistic,
    reviews = oneProfileData?.reviews,
    work_time = oneProfileData?.work_time,
    discounts = oneProfileData?.discounts,
    cityId = oneProfileData?.city.id,
  } = oneProfileData;

  const breadcrumbsList = [
    {
      id: 1,
      title: t("mainpage"),
      link: "/",
    },
    {
      id: 2,
      title: city.name,
      link: `/${lang}/${city.slug}`,
    },
    {
      id: 3,
      title: name,
      link: "",
    },
  ];

  const tabsBtnsContent = [
    { title: t("appearance") },
    { title: t("services") },
  ];

  const { profileSlug } = useParams();

  useEffect(() => {
    if (!lang) {
      const url = window.location.pathname;
      const segments = url.split("/");
      const langSegment = segments[1];
      if (langSegment && langSegment.length === 2) {
        dispatch(setLang(langSegment));
      }
    }
  }, []);

  useEffect(() => {
    if (cityId) {
      dispatch(fetchRanges(cityId));
    }
  }, []);

  const [sliderData, setSliderData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `profiles?limit=8&page=1&sort=date&city=${cityId}&lang=${lang}`;
        const method = "GET";
        const payload = {
          lang,
          page: 1,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          setSliderData(data.profiles);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      } catch (error) {}
    };

    lang && fetchData();
  }, [lang]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const toggleMapOpen = (slug) => {
    dispatch(setOneModelPosition(slug));
    dispatch(setStoriesMap(true));
  };

  const LastVisit = () => (
    <span
      className={`model-header__last-visit p3${
        is_online ? " _online" : " _offline"
      }`}
    >
      <Icon
        clazz={"color-green"}
        size={"xs"}
        spritePath={is_online ? "online" : "offline"}
        title={is_online ? `${name} ${t("online")}` : `${name} ${t("offline")}`}
      />
      {getTimeAgo(last_activity)}
    </span>
  );

  const [activeContentTab, setActiveContentTab] = useState(0);

  const postCity = t("incity")?.replace(/%([^%]+)%/g, city.name);

  const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
    ? formatPhoneNumberIntl(`${phone}`)
    : formatPhoneNumberIntl(`+${phone}`);

  return (
    <main>
      <Helmet>
        <title>
          {`${t("escortmodel")} ${name} ${postCity}. ${
            gendersTitle[gender]
          } – ${orientations(orientation)}`}
        </title>
        <meta
          name="description"
          content={`${t("escortmodel")} ${name}${
            description ? ` - ${description.slice(0, 150)}` : ""
          }`}
        />
      </Helmet>
      <div className={"model__container model"}>
        <section className="model-header">
          <Breadcrumbs
            data={breadcrumbsList}
            clazz={"model-header__breadcrumbs"}
          />

          <div
            className={`model-header__body${
              !!stories.length ? "" : " no-stories"
            }`}
          >
            {!!stories.length ? (
              <button
                title={t("modelstories")}
                onClick={() => dispatch(setStoriesStatus(true))}
                className="stories-circle__button model-header__stories"
              >
                <span className={"stories-circle__image"}>
                  {stories && stories[0].media ? (
                    <img src={stories[0].media} alt="" />
                  ) : null}
                </span>
              </button>
            ) : null}

            <div className="model-header__box">
              {(!!is_top || !!is_elite) && (
                <picture className="model-header__status">
                  <source
                    srcSet={
                      !!is_top
                        ? theme === "light"
                          ? statusTopLight
                          : statusTop
                        : statusElite
                    }
                    media="(min-width: 767.98px)"
                  />
                  <img
                    src={
                      !!is_top
                        ? theme === "light"
                          ? statusTopMobLight
                          : statusTopMob
                        : statusEliteMob
                    }
                    alt=""
                  />
                </picture>
              )}

              <div className="model-header__interactions">
                <Note note={note} profileId={profileSlug} />

                <ModelFavoriteBtn slug={profileSlug} />
              </div>

              <div className="model-header__box-info">
                <h1 className="title model-header__name text-dots">{name}</h1>
                <div className="model-header__age">
                  <span>{age}</span> {t("years")}
                </div>
              </div>
              {windowWidth < 767.98 && LastVisit()}
            </div>

            {(!!reviews_count ||
              !!distance ||
              !!address ||
              !!last_activity) && (
              <div className="model-header__bottom">
                {windowWidth > 767.98 && LastVisit()}

                {!!reviews_count && (
                  <a
                    href={"#reviews"}
                    title={t("readreview")}
                    className="model-header__review model__review"
                  >
                    <RatingStars gold={true} value={rating} readOnly={true} />
                    <span>
                      {reviews_count} {t("reviewses")}
                    </span>
                  </a>
                )}

                {!!distance && (
                  <span className="model-header__distance p3">
                    <Icon spritePath={"location"} size={"s"} />
                    {distance} {t("kmsfromyou")}
                  </span>
                )}

                {!!address && (
                  <Link
                    to={"#"}
                    title={t("viewonmap")}
                    onClick={() => toggleMapOpen(profileSlug)}
                    className="model-header__address text-underline p3 text-dots"
                  >
                    <Icon spritePath={"map"} size={"s"} />
                    {address}
                  </Link>
                )}
              </div>
            )}

            <div className={`model__buttons model-header__buttons`}>
              {!!tag_booking ? (
                <Button
                  title={t("order")}
                  onClick={() => {
                    dispatch(setOrderModelStatus(true));
                    dispatch(setAuthorisationAccessType("order"));
                  }}
                  size={"m"}
                  clazz={"button_outline--green"}
                >
                  <Icon spritePath={"pay-content"} size={"l"} />
                  {t("order")}
                </Button>
              ) : null}

              {!!ready_videochat ? (
                <Button
                  onClick={() => {
                    if (userType !== "default") {
                      dispatch(setLadyForCall(oneProfileData));
                    } else {
                      dispatch(setVideoChatMode(true));
                      dispatch(setAuthorizationStatus(true));
                      dispatch(setModelForCall(oneProfileData));
                    }
                    if (mapPopupStatus) {
                      dispatch(setMapMode(true));
                      dispatch(setStoriesMap(false));
                      dispatch(setModelForCall(oneProfileData));
                    }
                  }}
                  clazz={`button--green`}
                  size={"m"}
                  square={
                    windowWidth < 767.98 ||
                    (windowWidth < 1000.98 && !!tag_booking)
                      ? true
                      : null
                  }
                >
                  {windowWidth < 767.98 ||
                  (windowWidth < 1000.98 && !!tag_booking) ? (
                    <Icon size={"xl"} spritePath={"video"} />
                  ) : (
                    <>
                      <Icon size={"l"} spritePath={"video"} />
                      {t("videochat")}
                    </>
                  )}
                </Button>
              ) : null}

              {!!phone && (
                <ButtonLink
                  href={`tel:${phone}`}
                  size={"m"}
                  clazz={`button--primary td-none`}
                  square={windowWidth < 1199.98}
                >
                  {windowWidth < 1199.98 ? (
                    <Icon size={"xl"} spritePath={"call"} />
                  ) : (
                    phoneRes
                  )}
                </ButtonLink>
              )}

              {!!whatsapp ? (
                <ButtonLink
                  href={`https://wa.me/${whatsapp}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${profileSlug}`}
                  clazz={"button--primary"}
                  square={true}
                  size={"m"}
                  target={"_blank"}
                >
                  <Icon size={"xl"} spritePath={"whatsapp"} />
                </ButtonLink>
              ) : null}

              {!!telegram ? (
                <ButtonLink
                  href={`https://t.me/MissMichelleVIP?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${profileSlug}`}
                  clazz={"button--primary"}
                  square={true}
                  size={"m"}
                  target={"_blank"}
                >
                  <Icon size={"xl"} spritePath={"telegram"} />
                </ButtonLink>
              ) : null}
            </div>
          </div>
        </section>

        <section className="model-main">
          <div className="model-main__sliders">
            {!!media ? (
              <TabSliderModel
                name={name}
                ero={ero}
                media={media}
                clazz={"model-main"}
              />
            ) : null}

            {ero &&
            ero.data &&
            (ero.data.photo_count || ero.data.video_count) ? (
              <PrivateSlider props={{ ero }} />
            ) : null}
          </div>

          {windowWidth < 1000.98 && windowWidth > 600.98 ? (
            <div className="model__info">
              <ModelInfoContent props={oneProfileData} />
            </div>
          ) : null}

          {windowWidth > 1000.98 && !!porn_star ? (
            <ModelPornstarCard props={{ porn_star }} />
          ) : null}

          {!!gf.length ? (
            <div className={"model-card"}>
              <h3>{t("girlsprofiles")}</h3>
              {gf.map((data, index) => {
                return <GirlCard props={data} mobile={true} key={index} />;
              })}
            </div>
          ) : null}

          <div className="model-content tabs__content">
            <div className="tabs__nav mb-8">
              {tabsBtnsContent.map((btn, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => setActiveContentTab(index)}
                    className={`tabs__nav-btn${
                      activeContentTab === index ? " tabs__nav-btn--active" : ""
                    }`}
                    type="button"
                  >
                    {btn.title}
                  </button>
                );
              })}

              <button
                onClick={() => setActiveContentTab(2)}
                className={`tabs__nav-btn${
                  activeContentTab === 2 ? " tabs__nav-btn--active" : ""
                }`}
                type="button"
              >
                {t("reviews")}
              </button>
            </div>

            <div
              className={`tabs__panel model__info${
                activeContentTab === 0 ? " tabs__panel--active" : ""
              }`}
            >
              <ModelInfoContent props={oneProfileData} />
            </div>

            <section
              className={`tabs__panel model__serfices${
                activeContentTab === 1 ? " tabs__panel--active" : ""
              }`}
            >
              <ModelServices props={oneProfileData} />
            </section>

            <div
              className={`tabs__panel model__reviews${
                activeContentTab === 2 ? " tabs__panel--active" : ""
              }`}
            >
              <Reviews
                props={{
                  rating,
                  rating_atmosphere,
                  rating_charm,
                  rating_communication,
                  rating_emotions,
                  rating_quality,
                  reviews_count,
                  reviews,
                  name,
                }}
              />
            </div>

            <div className="model-main__wrapper">
              {(prices && prices.incall && prices.incall.length > 0) ||
              (prices && prices.outcall && prices.outcall.length > 0) ||
              (prices && prices.escort && prices.escort.length > 0) ? (
                <ScheduleTable
                  props={{
                    workTime: work_time,
                    icon: "time-plan",
                    prices,
                    discounts,
                  }}
                />
              ) : null}

              {(!!distance || !!address) && (
                <Button
                  size={"m"}
                  title={t("viewonmap")}
                  onClick={() => toggleMapOpen(profileSlug)}
                  clazz={"button--tetriary width-100 p3 gap-12"}
                >
                  {!!distance && (
                    <span className="model-header__distance p3">
                      <Icon spritePath={"location"} size={"s"} />
                      {distance} {t("kmsfromyou")}
                    </span>
                  )}

                  {!!address && (
                    <span className="model-header__address p3">
                      <Icon spritePath={"map"} size={"s"} />
                      {address}
                    </span>
                  )}
                </Button>
              )}

              <ModelButtonsGroup size={"l"} props={oneProfileData} />

              {!!gf.length || !!porn_star || !!ready_travel || !!is_escort ? (
                <div className="model__extras">
                  {!!gf.length && (
                    <div
                      title={t("girlfriend")}
                      className={"button button-l button--tetriary"}
                    >
                      <Icon spritePath={"girlfriend"} size={"xxl"} />
                      <span className={"d-flex fd-column text-left gap-4 p1"}>
                        {t("girlfriend")}
                        <span>
                          {gf.map((model, index) => {
                            return (
                              <Link
                                key={index}
                                target={"_blank"}
                                to={`/${lang}/profile/${model.slug}`}
                                className={"p2 color-600 width-max hover-line"}
                              >
                                {model.slug}
                              </Link>
                            );
                          })}
                        </span>
                      </span>
                    </div>
                  )}

                  {!!porn_star && (
                    <div
                      title={t("pornstar")}
                      className={"button button-l button--tetriary"}
                    >
                      <Icon spritePath={"star-simple"} size={"xxl"} />
                      <span className={"d-flex fd-column text-left gap-4 p1"}>
                        {t("pornstar")}
                        <Link
                          target={"_blank"}
                          to={`//${porn_star.website_link}`}
                          className={"p2 color-600 hover-line"}
                        >
                          {porn_star.website_link.substring(0, 13)}...
                        </Link>
                      </span>
                    </div>
                  )}

                  {ready_travel && (
                    <div
                      title={t("travel")}
                      className={"button button-l button--tetriary"}
                    >
                      <Icon spritePath={"travel"} size={"xxl"} />
                      <span className={"d-flex fd-column text-left gap-4 p1"}>
                        {t("travel")}
                        <span className={"p2 color-600"}>
                          {t("flyingtoeu")}
                        </span>
                      </span>
                    </div>
                  )}

                  {prices && prices.escort && (
                    <div
                      title={t("accompaniment")}
                      className={"button button-l button--tetriary"}
                    >
                      <Icon spritePath={"escort"} size={"xxl"} />
                      <span className={"d-flex fd-column text-left gap-4 p1"}>
                        {t("accompaniment")}
                        <span className={"p2 color-600"}>{t("nosex")}</span>
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <div className="model-content__cards">
          {windowWidth < 1000.98 && porn_star ? (
            <ModelPornstarCard props={{ porn_star }} />
          ) : null}

          {!!agency.length ? (
            <div className={"model-card"}>
              <h3>{t("workingforagency")}</h3>
              {agency.map((data, index) => {
                return (
                  <Fragment key={index}>
                    <AgencyCard props={data} buttonColor={"button--tetriary"} />
                  </Fragment>
                );
              })}
            </div>
          ) : null}

          {!!parties.length ? (
            <div className={"model-card model-card__parties"}>
              <h3>{t("nextparty")}</h3>
              <div className={"model-card__wrapper"}>
                {parties.map((data, index) => {
                  return <PartyCard props={data} key={index} />;
                })}
              </div>
            </div>
          ) : null}
        </div>

        {(statistic && statistic.favorites && statistic.favorites > 0) ||
        (statistic && statistic.book && statistic.book > 0) ||
        (statistic && statistic.calls && statistic.calls > 0) ? (
          <ModelCounters statistic={statistic} />
        ) : null}
      </div>

      <section className={"new-girls__container"}>
        <h2 className="title_h1 mb-24">
          {t("topmodels")} {postCity}
        </h2>

        <Slider clazz={"new-girls"}>
          {sliderData.map((data) => {
            return (
              <SwiperSlide key={data.id}>
                <GirlCard props={data} />
              </SwiperSlide>
            );
          })}
        </Slider>
      </section>

      {ero && ero.data && userType !== "platinum" ? (
        userType === "default" ? null : (
          <ErocontentAccess data={ero} />
        )
      ) : null}

      <Appeal name={name} />
      <SuccessAppealMassage />
      <AddReview id={id} reviewable_type={"indi"} />
      <SuccessReviewMassage />

      {tag_booking ? (
        userType === "default" ? null : (
          <OrderModel
            prices={prices}
            address={address}
            name={name}
            slug={profileSlug}
          />
        )
      ) : null}

      {!!stories.length ? (
        <StoriesComponent
          stories={stories}
          descriptionText
          storiesForOneModel
        />
      ) : null}

      {(media && media.photo && media.photo.length > 0) ||
      (media && media.interior && media.interior.length > 0) ||
      (media && media.video && media.video.length > 0) ||
      (ero && ero.data && ero.data.photo && ero.data.photo.length > 0) ? (
        <Gallery name={name} media={media} ero={ero} />
      ) : null}
    </main>
  );
};

export default ModelPage;
