import topLadyLight from "../../../../../assets/img/status/top-light.webp";
import topLady from "../../../../../assets/img/status/top.webp";
import eliteLady from "../../../../../assets/img/status/elite.webp";
import { Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";

const GuideTagsRU = () => {
  const { theme, userType } = useLadyService();

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");
  const { t: guide } = useTranslation("guide");

  const startPriceForElite = guide("i-eliteladydescr")?.replace(
    /%([^%]+)%/g,
    "200"
  );

  let data = {
    indi_and_agency: [
      {
        title: "TopLady",
        text: guide("i-topladydescr"),
        hash_link: "#questionnairePosition",
        img: theme === "light" ? topLadyLight : topLady,
      },
      {
        title: "Elite Lady",
        text: startPriceForElite,
        hash_link: "#statusElite",
        img: eliteLady,
      },
      {
        title: t("verification"),
        text: guide("i-verifydescr"),
        hash_link: "#verify",
        icon: "verify-fill",
        iconClass: "green",
      },
      {
        title: t("healthy"),
        text: guide("i-healtydescr"),
        hash_link: "#healthControl",
        icon: "health-fill",
        iconClass: "green",
      },
      {
        title: t("erocontent"),
        text: guide("i-erodescr"),
        hash_link: "#eroContent",
        icon: "pants",
        iconClass: "green",
      },
      {
        title: t("onlineorder"),
        text: guide("i-erodescr"),
        icon: "pay-content",
        iconClass: "green",
      },
      {
        title: t("videochat"),
        text: guide("i-videochatdescr"),
        icon: "video",
        iconClass: "green",
      },
      {
        title: indi("modelpreviews"),
        text: guide("i-previewdescr"),
        icon: "casting",
        iconClass: "green",
      },
      {
        title: "INDI",
        text: guide("i-indidescr"),
        tag: true,
      },
      {
        title: "STAR",
        text: guide("i-stardescr"),
        tag: true,
      },
      {
        title: "NEW",
        text: guide("i-newdescr"),
        tag: true,
      },
      {
        title: "BDSM",
        text: guide("i-bdsmdescr"),
        tag: true,
      },
      {
        title: t("video"),
        text: guide("i-videodescr"),
        icon: "video-play",
      },
      {
        title: t("girlfriend"),
        text: guide("i-gfdescr"),
        icon: "girlfriend",
      },
      {
        title: t("travel"),
        text: guide("i-traveldescr"),
        icon: "travel",
      },
      {
        title: t("accompaniment"),
        text: guide("i-escortdescr"),
        icon: "escort",
      },
    ],
  };

  const [content, setContent] = useState(data.indi_and_agency);

  const showCurrentContentByUserType = () => {
    switch (userType) {
      case "indi":
        return setContent(data.indi_and_agency);
    }
  };

  useEffect(() => {
    showCurrentContentByUserType();
  }, [userType]);

  return (
    <div className="guide__items">
      {content.map((i, index) => {
        let exeption =
          i.title === t("erocontent") ? (
            <p className={"p3 color-600"}>
              *за исключением клиентов с профилем Platinum
            </p>
          ) : null;

        return (
          <div key={index} className="guide__item">
            <div className="guide__item-top">
              {i.img && <img src={i.img} alt={i.title} />}

              {i.icon && (
                <span
                  className={`guide__icon${
                    i.iconClass ? ` ${i.iconClass}` : ""
                  }`}
                  title={i.title}
                >
                  <Icon spritePath={i.icon} size={"l"} />
                </span>
              )}

              {i.tag ? <span className={"guide__tag"}>{i.title}</span> : null}

              <span className={"title_h3"}>{i.title}</span>
            </div>

            <p>
              <b>{i.title}</b> – {i.text}.
              {i.hash_link && (
                <>
                  {" "}
                  <HashLink to={i.hash_link}>{t("moreinfo")}...</HashLink>
                </>
              )}
            </p>

            {exeption}
          </div>
        );
      })}
    </div>
  );
};

export default GuideTagsRU;
