import "./steps.scss";

import { useTranslation } from "react-i18next";
import { Fragment } from "react";



const Steps = ({changeStep, step, availableSteps}) => {
  const { t } = useTranslation("translation");
  const { t:indi } = useTranslation("indi");

  const stepsArray = [
    {
      step: "1",
      text: indi('main'),
    },
    {
      step: "2",
      text: indi('photosandvid'),
    },
    {
      step: "3",
      text: t('appearance'),
    },
    {
      step: "4",
      text: t('services'),
    },
    {
      step: "5",
      text: indi('workhours'),
    },
    {
      step: "6",
      text: indi('confirmation'),
    },
  ];

  const handleStep = (index) => {
    changeStep(index + 1)
  }

  return (
    <div className={"steps__root"}>
      <div className="steps__progressbar">
        <span className="steps__progressbar-text">
          {indi('fullon')}<span className="progressbar">90%</span>
        </span>
        <div className="progressbar-value">
          <span className="progressbar-current" style={{width: "90%"}}></span>
        </div>
      </div>
      <div className={"steps__main"}>
        {stepsArray.map((item, index) => (
          <Fragment key={index}>
            <button onClick={() => handleStep(index)} className={`step ${step === index + 1 ? "_active" : ""} ${!availableSteps.includes(index + 1) ? "_disabled" : ""}`}>
              <span className="step__current">{item.step}</span>
              <span className="step__text">{item.text}</span>
            </button>
            {index !== stepsArray.length - 1 && <hr />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Steps;
