import {
  setForgotPasswordStatus,
  setForgotPasswordPhoneStatus,
  setForgotPasswordPhoneInputsStatus,
  getForgotPasswordPhoneStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { string, object } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import RestorePhoneInputsPopup from "./RestorePhoneInputsPopup";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";

const RestorePasswordPopup = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("translation");

  const forgotPassPhoneState = useSelector(getForgotPasswordPhoneStatus);
  const formSchema = object().shape({
    phone: string().test(
      "phone-is-empty",
      t("phonenumerrequired"),
      function () {
        return !!phoneValue.replace("+", "");
      }
    ),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [telephone, setTelephone] = useState();
  const [phoneValue, setPhoneValue] = useState("");

  const onSubmit = (data, e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `auth/password/reset`;
        const method = "POST";
        const payload = {
          identity: phoneValue.replace(/[" "+]/g, ""),
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          dispatch(setForgotPasswordPhoneInputsStatus(true));
          reset();
        }
      } catch (error) {}
    };
    fetchData();
    // let result = getValues("phone");
    // setTelephone(result);
    //dispatch(setForgotPasswordPhoneStatus(false))
  };

  const onClose = () => {
    reset();
    dispatch(setForgotPasswordPhoneStatus(false));
  };

  return (
    <>
      {!!forgotPassPhoneState && (
        <dialog
          id={"forgotPasswordPhonePopup"}
          open={!!forgotPassPhoneState}
          onClick={() => onClose()}
          className="popup-form forgot-email-popup"
        >
          {!!forgotPassPhoneState ? (
            <Helmet>
              <html className={"lock"}></html>
            </Helmet>
          ) : null}

          <form
            method={"post"}
            onClick={(e) => e.stopPropagation()}
            onSubmit={handleSubmit(onSubmit)}
            className="popup-form__body"
          >
            <Button
              onClick={() => dispatch(setForgotPasswordPhoneStatus(false))}
              clazz={"button__close"}
            >
              <Icon size={"l"} spritePath={"close"} />
            </Button>

            <p className={"popup-form__text"}>Введите номер телефона</p>
            <div className="popup-form__inner">
              <InputInLabel
                clazz={`${errors.phone ? " error" : ""}`}
                id={"forgotPassPhone"}
                type={"tel"}
                maskValue={phoneValue}
                setMaskValue={setPhoneValue}
                register={{ ...register("phone") }}
                autocomplete={"tel"}
              >
                {(errors.phone && (
                  <span className={"input-label__error"}>
                    {errors.phone.message}
                  </span>
                )) || <span>Телефон</span>}
              </InputInLabel>

              <Button buttonType={"submit"} size={"l"} clazz={"button--green"}>
                {t("getsmscode")}
              </Button>
            </div>
          </form>
        </dialog>
      )}

      <RestorePhoneInputsPopup telephone={telephone} />
    </>
  );
};

export default RestorePasswordPopup;
