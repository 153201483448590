import { useSelector } from "react-redux";
import { getAllSorted } from "@/stores/slices/modelsState";
import { isFilterSearch, filterPlace } from "@/stores/slices/filterSlice";
import { useEffect, useState } from "react";
import { Icon, LogoSvg } from "@/components/ui";
import { useTranslation } from "react-i18next";

import "./aboutService.scss";

const AboutService = () => {
  const valueNavigation = useSelector(getAllSorted);
  const valuePlace = useSelector(filterPlace);
  const isFilterValue = useSelector(isFilterSearch);
  const [isRenderElem, setIsRenderElem] = useState(true);

  const { t } = useTranslation("translation");

  const arrayLady = [t("aboutp1"), t("aboutp2"), t("aboutp3")];

  const iconsMap = [
    { spritePath: "web-cam", name: t("add-1") },
    { spritePath: "body-fill", name: t("add-2") },
    { spritePath: "health-fill", name: t("add-3") },
    { spritePath: "incognito", name: t("add-4") },
    { spritePath: "notification", name: t("add-5") },
    { spritePath: "star-fill", name: t("add-6") },
  ];

  useEffect(() => {
    const isRes =
      !!valueNavigation.length || !!valuePlace.length || isFilterValue;
    setIsRenderElem(!isRes);
  }, [valueNavigation, isFilterValue, valuePlace]);

  if (isRenderElem) {
    return (
      <div className={"about__container"}>
        <div className="about__description">
          <span className={"about__undertitle"}>
            {t("aboutservice")} Lady4Love
          </span>
          <span className="title_h1">
            The Best Choice<span>4</span>You
          </span>
          {arrayLady.map((i, index) => {
            return (
              <p key={index}>
                Lady<span>4</span>Love — {i}
              </p>
            );
          })}
        </div>

        <div className="about__benefits p1">
          <span className="title">{t("addpref")}</span>
          {iconsMap.map((item, index) => {
            const { spritePath, name } = item;
            return (
              <span className="about__item" key={index}>
                <Icon size={"l"} spritePath={spritePath} />
                {name}
              </span>
            );
          })}
        </div>

        <div className="about__slogan">
          <LogoSvg alt={t("sensiblechoice")} />
          <span>{t("sensiblechoice")}</span>
        </div>
      </div>
    );
  }
};

export default AboutService;
