import "./adCard.scss";

import { Icon, Button, Toggle, ButtonLink, ProgressBar } from "@/components/ui";
import { AdGirlCard, UpAd } from "./components";

import {
  getUserOrderStatus,
  getUserVideoStatus,
  setReadyToVideochat,
  setReadyToOrder,
} from "@/stores/slices/userSlice";

import { useSelector } from "react-redux";

import { useEffectWindowSize } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";

import { makeRequest } from "@/services/makeRequest";
import showToast from "../../../../../toast/Toast";

import { useState } from "react";
import {
  ConfirmDeletePopup,
  DeleteApPopup,
  LinkWithAgencyPopup,
  LinkWithGirlfriend,
} from "../../../../../popups";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import useLadyService from "@/services/LadyService";

// const Stat = ({ spritePath, content }) => {
//   return (
//     <div className="stat">
//       <Icon size={"s"} spritePath={spritePath} clazz={"_gray"} />
//       {content}
//     </div>
//   );
// };

const AdCard = ({ data, view, setData, setCount }) => {
  const {
    slug,
    ready_videochat,
    tag_booking,
    is_enabled: statusAd,
    agency,
    gf,
    filled_to,
    address,
    current_position,
    is_elite,
    is_top,
    city_id,
    city,
    name,
  } = data;

  const windowWidth = useEffectWindowSize();
  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation();

  const { lang } = useLadyService();

  const [call, setCall] = useState(ready_videochat);
  const [booking, setBooking] = useState(tag_booking);
  const [status, setStatus] = useState(statusAd);

  const [isOpenAgency, setIsOpenAgency] = useState(false);
  const [isOpenGirlfriend, setIsOpenGirlfriend] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [isOpenRemoveGf, setIsOpenRemoveGf] = useState(false);
  const [isOpenRemoveAgency, setIsOpenRemoveAgency] = useState(false);

  const [gfData, setGfData] = useState(gf);
  const [agencyData, setAgencyData] = useState(agency);

  const isVideo = useSelector(getUserVideoStatus);
  const isRes = useSelector(getUserOrderStatus);

  const dispatch = useDispatch();

  let standart;
  let viewCard;

  switch (true) {
    case windowWidth > 1251: {
      standart = view === "standart" ? "" : " small";
      viewCard = view === "standart";

      break;
    }

    default: {
      viewCard = false;
      standart = " small";
    }
  }

  const handleAddAgency = () => {
    setIsOpenAgency(true);
  };

  const handleAddGirlfriend = () => {
    setIsOpenGirlfriend(true);
  };

  const handleOpenDelete = () => {
    setIsOpenDelete(true);
  };

  const handleOpenDeleteAgency = () => {
    setIsOpenRemoveAgency(true);
  };

  const handleOpenDeleteGf = () => {
    setIsOpenRemoveGf(true);
  };

  const handleRemoveAgency = async () => {
    try {
      const route = `user/profile/${slug}/remove-agency`;

      const method = "DELETE";

      await makeRequest({ route, method });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
      setAgencyData([]);
      setIsOpenRemoveAgency(false);
    } catch (error) {
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const handleRemoveGirlfriend = async () => {
    try {
      const route = `user/profile/${slug}/remove-friend`;

      const method = "DELETE";

      const payload = { friend: gfData[0].id };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
      setGfData([]);
      setIsOpenRemoveGf(false);
    } catch (error) {
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const onChangeCall = async () => {
    try {
      const route = `user/profile/${slug}/set-call-status`;
      const method = "PUT";
      const payload = {
        status: !call,
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
      setCall((prev) => !prev);
    } catch (error) {
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const onChangeBook = async () => {
    try {
      const route = `user/profile/${slug}/set-booking-status`;
      const method = "PUT";
      const payload = {
        status: !booking,
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
      setBooking((prev) => !prev);
    } catch (error) {
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const onChangeStatus = async () => {
    try {
      const route = `user/profile/${slug}/set-status`;
      const method = "PUT";
      const payload = {
        status: !status,
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
      setStatus((prev) => !prev);
    } catch (error) {
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const onChangeVideochat = async () => {
    try {
      dispatch(setReadyToVideochat(Number(!isVideo)));

      const route = `user/set-call-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isVideo),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isVideo)));
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  const onChangeOrder = async () => {
    try {
      dispatch(setReadyToOrder(Number(!isRes)));
      const route = `user/set-booking-status`;
      const method = "PUT";
      const payload = {
        status: Number(!isRes),
      };

      await makeRequest({ route, method, payload });

      showToast({
        message: indi("success"),
        variant: "succes",
      });
    } catch (error) {
      dispatch(setReadyToVideochat(Number(!isRes)));
      showToast({
        message: indi("oops"),
        variant: "error",
      });
    }
  };

  return (
    <>
      <div className={`adcard-main${standart}`}>
        <AdGirlCard data={data} mobile={!viewCard} status={status} />

        <div className={`adcard-main__root${standart}`}>
          <div className="adcard-main__header">
            <div className={"panel"}>
              <ProgressBar percentage={filled_to} />
              <ButtonLink
                href={`/lk/indi/profile/${slug}/edit`}
                clazz={"button--secondary"}
                square={true}
                size={"xs"}
              >
                <Icon size={"xs"} spritePath={"edit-2"} />
              </ButtonLink>
              <Button clazz={"button--secondary"} square={true} size={"xs"}>
                <Icon size={"xs"} spritePath={"copy"} />
              </Button>
            </div>
            <Toggle onChange={onChangeStatus} checked={status} />
          </div>

          <div className={`adcard-main__toogle ${!status ? "not_active" : ""}`}>
            <div className="mr-auto">
              <Toggle
                titleDefault={t("videochat")}
                onChange={onChangeCall}
                checked={call}
                disabled={!status || !isVideo}
              />

              {!isVideo && (
                <div className="d-flex fd-column gap-4 mt-8">
                  <span className={"color-700 fz-13"}>
                    {indi("globalblock")}
                  </span>
                  <Button
                    onClick={onChangeVideochat}
                    size={"s"}
                    clazz={`button_outline--black`}
                  >
                    {indi("on")}
                  </Button>
                </div>
              )}
            </div>
            <div class="vertical-line"></div>

            <div className="ml-auto">
              <Toggle
                titleDefault={t("onlineorder")}
                onChange={onChangeBook}
                checked={booking}
                disabled={!status || !isRes}
              />

              {!isRes && (
                <div className="d-flex fd-column gap-4 mt-8">
                  <span className={"color-700 fz-13"}>
                    {indi("globalblock")}
                  </span>
                  <Button
                    onClick={onChangeOrder}
                    clazz={`button_outline--black`}
                    size={"s"}
                  >
                    {indi("on")}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <UpAd
            status={status}
            address={address}
            current_position={current_position}
            is_elite={is_elite}
            is_top={is_top}
            city={city}
            slug={slug}
            name={name}
          />
          {/* <span className="title_h3 mb-0">Статистика за 30 дней</span>
        <div className="adcard__statistic">
          <Stat
            spritePath={"eye"}
            content={
              <span>
                {indi("views")} <span className="color-main">239</span>
              </span>
            }
          />

          <Stat
            spritePath={"call-outline"}
            content={
              <span>
                {indi("contacts")} <span className="color-main">239</span>
              </span>
            }
          />
          <Stat
            spritePath={"cash-up"}
            content={
              <span>
                {indi("income")} <span className="color-green">+3450</span>
              </span>
            }
          />
          <Stat
            spritePath={"cash-down"}
            content={
              <span>
                {indi("expenses")} <span className="color-main">-220</span>
              </span>
            }
          />
        </div>
        <a href="/" className="green text-right">
          подробная статистика
        </a> */}

          <div
            className={`adcard-main__link ${
              !status ? "not_active" : ""
            } mt-auto`}
          >
            <span className="color-700">
              {!!agencyData.length
                ? indi("managedbyagency")
                : indi("connectagency")}{" "}
              {!!agencyData.length && (
                <Link
                  to={`/${lang}/agency/${agencyData[0].slug}`}
                  className="green text-right"
                  hre
                >
                  {agencyData[0].name}
                </Link>
              )}
            </span>
            <Button
              size={"s"}
              clazz={"button_outline--green-accent"}
              disabled={!status}
              onClick={
                !!agencyData.length ? handleOpenDeleteAgency : handleAddAgency
              }
            >
              {!!agencyData.length ? indi("disconnect") : indi("addad")}
            </Button>
          </div>

          <>
            <hr />
            <div className={`adcard-main__link ${!status ? "not_active" : ""}`}>
              <span className="color-700">
                {!!gfData.length ? indi("connectedgf") : indi("connectgf")}{" "}
                {!!gfData.length && (
                  <Link
                    to={`/${lang}/profile/${gfData[0].slug}`}
                    className="green text-right"
                    hre
                  >
                    {gfData[0].name}
                  </Link>
                )}
              </span>
              <Button
                size={"s"}
                clazz={"button_outline--green-accent"}
                disabled={!status}
                onClick={
                  !!gfData.length ? handleOpenDeleteGf : handleAddGirlfriend
                }
              >
                {!!gfData.length ? indi("disconnect") : indi("addad")}
              </Button>
            </div>
          </>
          <Button
            type="button"
            clazz={"button--error width-fit"}
            size={"s"}
            onClick={handleOpenDelete}
          >
            {indi("deletead")}
          </Button>
        </div>
      </div>
      {isOpenAgency && (
        <LinkWithAgencyPopup
          open={isOpenAgency}
          setOpen={setIsOpenAgency}
          slug={slug}
        />
      )}
      {isOpenGirlfriend && (
        <LinkWithGirlfriend
          open={isOpenGirlfriend}
          setOpen={setIsOpenGirlfriend}
          slug={slug}
          city_id={city_id}
        />
      )}
      {isOpenDelete && (
        <DeleteApPopup
          open={isOpenDelete}
          setOpen={setIsOpenDelete}
          profileSlug={slug}
          setData={setData}
          setCount={setCount}
        />
      )}
      {isOpenRemoveGf && (
        <ConfirmDeletePopup
          onSubmit={handleRemoveGirlfriend}
          open={isOpenRemoveGf}
          setOpen={setIsOpenRemoveGf}
        />
      )}
      {isOpenRemoveAgency && (
        <ConfirmDeletePopup
          onSubmit={handleRemoveAgency}
          open={isOpenRemoveAgency}
          setOpen={setIsOpenRemoveAgency}
        />
      )}
    </>
  );
};

export default AdCard;
