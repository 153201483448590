import useLadyService from "@/services/LadyService";
import { Accordion, Button, Icon } from "@/components/ui";
import React, { useEffect, useRef, useState } from "react";

import { HashLink } from "react-router-hash-link";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AttentionBox from "../components/AttentionBox/AttentionBox";
import topLadyLight from "../../../../assets/img/status/top-light.webp";
import topLady from "../../../../assets/img/status/top.webp";
import eliteLady from "../../../../assets/img/status/elite.webp";
import img1 from "../../../../assets/img/pick-profile/img1.webp";
import img2 from "../../../../assets/img/pick-profile/img2.webp";
import img3 from "../../../../assets/img/pick-profile/img3.webp";
import GuideTagsRU from "../components/tags/GuideTagsRU";
import verifyPicture from "../../../../assets/img/examplePhotos/verExample.svg";
import unilabs from "../../../../assets/img/unilabs-origin.svg";
import healtyPicture from "../../../../assets/img/examplePhotos/verExamplePlus2.svg";
import howToEarnImg from "../../../../assets/img/guide/indi/ru/how-to-earn.webp";
import orderFromSiteImg from "../../../../assets/img/guide/indi/ru/order-from-site.webp";
import deactivateProfileImg from "../../../../assets/img/guide/indi/ru/deactivate-profile.webp";
import agencyImg from "../../../../assets/img/guide/indi/ru/agency.webp";

const GuideIndi = () => {
  const { lang, theme, city, windowWidth } = useLadyService();

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");
  const { t: guide } = useTranslation("guide");
  const [show, setShow] = useState(false);

  const [scrolledClass, setScrolledClass] = useState("");
  useEffect(() => {
    if (windowWidth < 767.98) {
      window.addEventListener("scroll", () => {
        if (window.scrollY < 140) {
          setScrolledClass("");
        } else {
          setScrolledClass(" _fixed");
        }
      });
    }
  }, [windowWidth, window]);

  const containerRef = useRef(null);
  // const {ref, inView} = useInView({
  //   /* Optional options */
  //   root: containerRef.current,
  //   rootMargin: '-30px 0px 0px 0px',
  //   threshold: 1,
  // });

  const statusElitePrice = guide("i-elitetext")?.replace(/%([^%]+)%/g, "15");
  const countCustomersPerMonth = guide("i-customersmonth")?.replace(
    /%([^%]+)%/g,
    "30 000"
  );
  const countDaysForVerification = guide("i-testresults")?.replace(
    /%([^%]+)%/g,
    "90"
  );
  const minutsForVideoChat = guide("i-vchatduration")?.replace(
    /%([^%]+)%/g,
    "2"
  );
  const changeCityPerPeriod = guide("i-changecityper")?.replace(
    /%([^%]+)%/g,
    "10"
  );

  return (
    <main className="guide-page">
      {!!show ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <section className="guide__container">
        <h1 className={"mb-24 mb-16-mob"}>{guide("i-h1indi")}</h1>

        <Button
          clazz={`button--tetriary guide__button-heading${scrolledClass}`}
          size={"s"}
          onClick={() => setShow((prev) => !prev)}
        >
          <Icon size={"l"} spritePath={"instruction-cont"} />
          {guide("i-contents")}
        </Button>

        <p>
          {guide("i-greetings")}{" "}
          <Link to={"/"} className={"green"}>
            Lady4Love
          </Link>{" "}
          {guide("i-greetingstext")}
        </p>

        <div
          className="guide__body"
          // ref={containerRef}
        >
          {/*<div*/}
          {/*  ref={ref}*/}
          {/*   className={`accordion guide__accordion-main${inView ? ' guide__accordion_fixed' : ''}`}>*/}
          {/*  <button*/}
          {/*    className={`accordion-button${show ? " _active" : ""}`}*/}
          {/*    */}
          {/*    type="button">*/}
          {/*    <span className={'text-dots'}>*/}
          {/*      Как сделать свои анкеты максимально эффектными и эффективными*/}
          {/*    </span>*/}
          {/*    <Icon size={"m"} clazz={"chevrone"} spritePath={"chevrone-down"}/>*/}
          {/*  </button>*/}
          {/*</div>*/}

          <aside className={`p1${show ? " _active" : ""}${scrolledClass}`}>
            {windowWidth > 767.98 && (
              <span>
                <Icon size={"l"} spritePath={"instruction-cont"} />
                {guide("i-contents")}
              </span>
            )}
            <Accordion
              open
              clazz={"guide__accordion p2"}
              title={guide("i-maxeffective")}
            >
              <HashLink onClick={() => setShow(false)} to={`#maxViews`}>
                {guide("i-offermaxviews")}
              </HashLink>
              <HashLink onClick={() => setShow(false)} to={`#mediaContent`}>
                {guide("i-mediacontent")}
              </HashLink>
              <HashLink
                onClick={() => setShow(false)}
                to={`#questionnairePosition`}
              >
                {guide("i-positionpromo")}
              </HashLink>
              <HashLink onClick={() => setShow(false)} to={`#statusElite`}>
                {guide("i-markaselite")} Elite
              </HashLink>
              <HashLink onClick={() => setShow(false)} to={`#statuses`}>
                {guide("i-statusesobtain")}
              </HashLink>
              <HashLink onClick={() => setShow(false)} to={`#verify`}>
                {t("verification")} {indi("myads")}
              </HashLink>
              <HashLink onClick={() => setShow(false)} to={`#healthControl`}>
                {t("healthy")}
              </HashLink>
              <HashLink onClick={() => setShow(false)} to={`#specials`}>
                {guide("i-otherfeatures")}
              </HashLink>
            </Accordion>

            <Accordion
              open={windowWidth > 767}
              clazz={"guide__accordion p2"}
              title={guide("i-howearmoney")}
            >
              <HashLink onClick={() => setShow(false)} to="#eroCoins">
                {guide("i-erocoinandpay")}
              </HashLink>
              <HashLink onClick={() => setShow(false)} to="#videochat">
                {t("videochat")}
              </HashLink>
              <HashLink onClick={() => setShow(false)} to="#onlineOrder">
                {t("onlineorder")}
              </HashLink>
              <HashLink onClick={() => setShow(false)} to="#eroContent">
                {t("erocontent")}
              </HashLink>
            </Accordion>

            <Accordion
              open={windowWidth > 767}
              clazz={"guide__accordion p2"}
              title={guide("i-rulesandinfo")}
            >
              <HashLink onClick={() => setShow(false)} to="#statistic">
                {guide("i-detailedstat")}
              </HashLink>
              <HashLink onClick={() => setShow(false)} to="#yourContacts">
                {guide("i-yourcontacts")}: Email, WhatsApp, Telegram
              </HashLink>
              {/*НЕ ЗАБУДЬ ДОБАВИТЬ ЛИНК*/}
              <HashLink onClick={() => setShow(false)} to="#yourContacts">
                {guide("i-uniquefeatures")}
              </HashLink>
            </Accordion>
          </aside>

          <div className="guide__content">
            {/*<HowToEffective/>*/}
            <h2>{guide("i-maxeffective")}</h2>

            <h3 id={"maxViews"}>{guide("i-offermaxviews")}</h3>
            <p>
              {guide("i-createfewads")}. <b>{guide("i-postadsfree")}</b>{" "}
              {guide("i-werecommend")}
            </p>

            <AttentionBox type={"important"}>
              <p>{guide("i-truthfulinfo")}</p>
            </AttentionBox>

            <p>{guide("i-filloutthead")}</p>

            <p className={"guide__box"}>
              *{guide("i-importfromsites")}:{" "}
              <Link
                target={"_blank"}
                className={"green"}
                to={`//eurogirlsescort.com`}
              >
                eurogirlsescort.com,
              </Link>
              {` `}
              <Link target={"_blank"} className={"green"} to={`//banging.cz`}>
                banging.cz,
              </Link>
              {` `}
              <Link target={"_blank"} className={"green"} to={`//escorts.cz`}>
                escorts.cz,
              </Link>
              {` `}
              <Link
                target={"_blank"}
                className={"green"}
                to={`//secretgirlprague.com`}
              >
                secretgirlprague.com
              </Link>
            </p>

            <AttentionBox type={"advice"}>
              <ul>
                <li>{guide("i-completedads")}</li>
                <li>{guide("i-includedetails")}</li>
                <li>{guide("i-loadbeatyphoto")}</li>
                <li>{guide("i-ifhaveshortvideo")}</li>
                <li>{guide("i-sexualdescribe")}</li>
                <li>{guide("i-fillmessengers")}</li>
                <li>{guide("i-pinninglocation")}</li>
              </ul>
            </AttentionBox>

            <p className={"guide__box gap-8"}>
              {guide("i-contactmoderator")}{" "}
              <Link to={"#"} className={"green d-inline"}>
                {guide("i-moderator")}
              </Link>
            </p>

            <h3 id="mediaContent">
              {guide("i-mediacontent")}: {guide("i-contentmedia")}
            </h3>

            <p>{guide("i-photosandvideos")}</p>

            <AttentionBox type={"advice"}>
              <ul>
                <li>
                  {guide("i-uploadero")}{" "}
                  <HashLink to={"#eroContent"}>{t("moreinfo")}</HashLink>
                </li>
                <li>
                  {guide("i-photoeditstart")}{" "}
                  <HashLink to={"#videochat"}>{t("videochat")}</HashLink>{" "}
                  {guide("i-photoeditend")}
                </li>
                <li>
                  {guide("i-portfolio")}{" "}
                  <Link to={`/${lang}/photogapher`} className={"green"}>
                    {guide("i-ourphotograph")}
                  </Link>
                  .
                </li>
              </ul>
            </AttentionBox>

            <AttentionBox type={"important"}>
              <p>
                {" "}
                {guide("i-pesonalresp")}{" "}
                <Link to={"rules"} target={"_blank"} className={"green"}>
                  {guide("i-portalules")}
                </Link>
                , {guide("i-notsanction")}. {t("verification")} {t("or")}{" "}
                {t("healthy")} – {guide("i-toget")}.{" "}
              </p>
              <p>
                {indi("pesonalresp")}{" "}
                <Link to={"rules"} target={"_blank"} className={"green"}>
                  {guide("i-portalules")}
                </Link>
                , {indi("notsanction")}
                <br />«{t("verification")}» {t("or")} «{t("healthy")}» –{" "}
                {guide("i-importantstatuses")}
              </p>
            </AttentionBox>

            <p>{guide("i-storiesrelease")}</p>

            <h3 id={"questionnairePosition"}>{guide("i-positionpromo")}</h3>
            <p>
              {guide("i-sortedprofiles")}{" "}
              <Link to={"#"}>{indi("currentprices")}</Link>{" "}
              {guide("i-sortedprofiles2")}{" "}
              <Link to={"/lk/indi/profiles"}>{indi("myadsall")}</Link>{" "}
              {guide("i-auctionprice")}
            </p>

            <div className="guide__flexbox">
              <p>{guide("i-yourposition")} TopLady.</p>
              <div className="guide__status">
                <img
                  src={theme === "light" ? topLadyLight : topLady}
                  alt="Top Lady"
                />
              </div>
            </div>

            <AttentionBox type={"advice"}>
              <ul>
                <li>
                  {guide("i-lookatpositions")}{" "}
                  <Link
                    to={"/lk/indi/profiles"}
                    // onClick={() => dispatch()}
                  >
                    {guide("i-yourcards")}
                  </Link>
                  , {guide("i-beingtop")}
                </li>
                <li>
                  {guide("i-promotion")}{" "}
                  <Link to={"/lk/indi/statistic"}>
                    {guide("i-detailstat")}.
                  </Link>
                </li>
              </ul>
            </AttentionBox>

            <h3 id="statusElite">{t("Status")} Elite Lady</h3>

            <p>{statusElitePrice}</p>

            <div className={"guide__flexbox"}>
              <p>
                {guide("i-elitedescription")}{" "}
                <Link
                  target={"_blank"}
                  to={`/${lang}/${city}/elitelady`}
                  className={"green"}
                >
                  https://lady4love.com/elitelady
                </Link>
                , {guide("i-elitedescription")}{" "}
              </p>
              <Link
                target={"_blank"}
                to={`/${lang}/${city}/elitelady`}
                className="guide__status"
              >
                <img src={eliteLady} alt="Elite Lady" />
              </Link>
            </div>

            <div className={"guide__profile-cards p3"}>
              <div className={"guide-card"}>
                <div className="guide-card__img">
                  <img src={img1} alt="" />
                </div>

                <p>{guide("i-eliteframed")}</p>

                <div className={"fake-checkbox"}>
                  <div className={"fake-checkbox__icon"}>
                    <Icon spritePath={"check"} size={"xs"} />
                  </div>
                  <p className={"fake-checkbox__text"}>
                    {guide("i-morecustomers")}
                  </p>
                </div>
              </div>

              <div className={"guide-card p3"}>
                <div className="guide-card__img">
                  <img src={img2} alt="" />
                </div>

                <p>{guide("i-signstatus")}</p>

                <div className={"fake-checkbox"}>
                  <div className={"fake-checkbox__icon"}>
                    <Icon spritePath={"check"} size={"xs"} />
                  </div>
                  <p className={"fake-checkbox__text"}>
                    {guide("i-wealthyorders")}
                  </p>
                </div>
              </div>

              <div className={"guide-card p3"}>
                <div className="guide-card__img">
                  <img src={img3} alt="" />
                </div>
                <p>
                  {guide("i-onlyelite")}{" "}
                  <Link to={`/${lang}/elitelady`}>
                    https://lady4love.com/elitelady
                  </Link>
                  , {guide("i-vipclients")}
                </p>
                <div className={"fake-checkbox"}>
                  <div className={"fake-checkbox__icon"}>
                    <Icon spritePath={"check"} size={"xs"} />
                  </div>
                  <p className={"fake-checkbox__text"}>
                    {countCustomersPerMonth}
                  </p>
                </div>
              </div>
            </div>

            <h3 id="statuses">{guide("i-statusesobtain")}:</h3>

            <GuideTagsRU />

            <h3 id="verify">
              <span className={"guide__icon green"} title={t("verified1")}>
                <Icon spritePath={"verify-fill"} size={"l"} />
              </span>
              {t("verification")}
            </h3>
            <p>
              {t("verification")} – {guide("i-verifytext")}.
            </p>

            <div className="guide__box guide__box--verify">
              <div>
                <span className={"color-600"}>{guide("i-procedure")}:</span>
                <p>{guide("i-verifyprocess")} </p>
                <b>{guide("i-photoadmin")}</b>
                <p>{guide("i-moderrequest")}.</p>
                <p>
                  {guide("i-altmethod")} – <b>{guide("i-moderatorcall")}</b> (
                  {guide("i-payfunction")})
                </p>
              </div>

              <div className="guide__box-img">
                <span className={"p2"}>{indi("example")}</span>
                <img src={verifyPicture} alt="" />
              </div>
            </div>

            <div className={"guide__box guide__box--discount"}>
              <h3 id="healthControl">
                <span className={"guide__icon green"}>
                  <Icon
                    title={t("healthy")}
                    spritePath={"health-fill"}
                    size={"l"}
                  />
                </span>
                {t("healthy")}
              </h3>

              <p>
                {t("healthy")} – {guide("i-healthytext")}.
              </p>

              <Link
                to={"//cz.unilabs.online/sexualne-prenosne-nemoci-expert"}
                target={"_blank"}
                className="guide__box-img guide__inilabs"
              >
                <div className={"d-flex gap-10 align-center"}>
                  <img src={unilabs} alt="" />
                  <span>-20%</span>
                </div>
                <p className={"p3"}>{guide("i-discountprice")}</p>
              </Link>
            </div>

            <div className="guide__box guide__box--verify">
              <div>
                <span className={"color-600"}>{guide("i-procedure")}:</span>
                <p>{guide("i-verifyperson")}.</p>
                <b>{guide("i-photoadmin")}</b>
                <p>{countDaysForVerification}.</p>
              </div>

              <div className="guide__box-img">
                <span className={"p2"}>{indi("example")}</span>
                <img src={healtyPicture} alt="" />
              </div>
            </div>

            <h3 id="specials">{guide("i-otherfeatures")}:</h3>
            <p>
              <b>{t("services")}</b> – {guide("i-servicestext")}.
            </p>
            <p>
              <b>{guide("i-welcoming")}</b> – {guide("i-welcomingtext")}.
            </p>
            <p>
              <b>{guide("i-tariffsettings")}</b> –{" "}
              {guide("i-tariffsettingstext")}.
            </p>
            <p>
              <b>{guide("i-workdiscount")}</b> – {guide("i-workdiscounttext")}.
            </p>

            {/*//////////////////////////////////////////////////////////////////////////*/}

            <h2 id="howToEarn">{guide("i-howearmoney")}</h2>

            <p>{guide("i-freepost")}.</p>

            <p>
              {guide("i-onfunction")}{" "}
              <Link to={"/lk/indi/videochats"} target={"_blank"}>
                «{t("videochat")}»
              </Link>{" "}
              {indi("and")}{" "}
              <Link to={"/lk/indi/reservations"} target={"_blank"}>
                «{guide("i-onlineorder")}»
              </Link>{" "}
              - {guide("i-readytoorder")}.
            </p>

            <img
              className={"img-contain b-radius-24"}
              src={howToEarnImg}
              alt=""
            />

            <h3 id="eroCoins">
              <span className={"guide__icon"}>
                <Icon spritePath={"erocoin"} size={"l"} />
              </span>
              EroCoins {indi("and")} {guide("i-payonportal")}
            </h3>

            <p>
              {guide("i-cointext1")} <b>EroCoin</b>. {guide("i-cointext2")}.
            </p>
            <p>{guide("i-coinconvert")}.</p>

            <p>{guide("i-coinsell")}.</p>
            <p>
              {guide("i-coindeposit")}:
              <br />
              <ul>
                <li>{guide("i-banktransit")} (IBAN).</li>
                <li>{guide("i-bankcard")}.</li>
                <li>PayPal</li>
                <li>{guide("i-crypto")}.</li>
              </ul>
            </p>

            <p>{guide("i-withdraw")}.</p>

            <AttentionBox type={"important"}>
              <p>{guide("i-profit")}.</p>
            </AttentionBox>

            <h3 id="videochat">
              <span className={"guide__icon green"}>
                <Icon spritePath={"video"} size={"l"} />
              </span>
              {t("videochat")}
            </h3>
            <p>
              <b>{t("videochat")}</b> – {guide("i-vidchattext")}{" "}
              {guide("i-youget")} {t("from")} 3 {indi("until")} 5 EroCoin.
            </p>

            <div className="guide__box _column">
              <span>{guide("i-procedure")}:</span>
              <p>
                {minutsForVideoChat}{" "}
                <b>
                  {guide("i-youget")} {t("from")} 3 {indi("until")} 5 EroCoin{" "}
                  {guide("i-onyourwallet")},
                </b>{" "}
                {guide("i-allowsearn")}.
              </p>
            </div>

            <h3 id="onlineOrder">
              <span className={"guide__icon green"}>
                <Icon spritePath={"pay-content"} size={"l"} />
              </span>
              {t("onlineorder")}
            </h3>

            <p>
              <b>{t("onlineorder")}</b> – {guide("i-alloworder")}.
            </p>

            <div className="guide__box _column">
              <span>{guide("i-procedure")}:</span>
              <p>
                {guide("i-notification")} <b>{guide("i-orderarrow")}</b>{" "}
                {guide("i-ordergiarantie")}.
              </p>
            </div>

            <h3 id="eroContent">
              <span className={"guide__icon green"}>
                <Icon spritePath={"pants"} size={"l"} />
              </span>
              {t("erocontent")}
            </h3>
            <p>
              <b>{t("erocontent")}</b> - {guide("i-erodownload")}.
            </p>

            <div className="guide__box _column">
              <span>{guide("i-procedure")}:</span>
              <p>
                {guide("i-eromedia")}. <b>{guide("i-eroprovit")}</b>
              </p>
            </div>

            <AttentionBox type={"important"}>
              <p>
                {guide("i-eroprovit")} <b>{guide("i-only")}</b>{" "}
                {guide("i-eroprovit2")}.
              </p>
            </AttentionBox>

            {/*//////////////////////////////////////////////////////////////////////////*/}

            <h2>{guide("i-rulesandinfo")}</h2>

            <h3 id="statistic">{guide("i-detailstath3")}</h3>
            <p>
              {guide("i-viewcount")} <b>{guide("i-detailstattext")}.</b>
              <br />
              <br />
              {guide("i-graph")}.{" "}
              <Link target={"_blank"} to={"/lk/indi/statistic"}>
                {guide("i-gotostat")}
              </Link>
            </p>

            <h3 id="yourContacts">
              Email, WhatsApp {t("and")} Telegram {t("i-inyour")} {t("profile")}
            </h3>
            <p>
              WhatsApp {t("and")} Telegram – {guide("i-socialtext")}.
            </p>
            <p>{guide("i-hideemail")}.</p>
            <AttentionBox type={"important"}>
              <p>
                {guide("i-phonetext")}{" "}
                <Link to={"#"}>{guide("i-phonesupport")}</Link>{" "}
                {guide("i-phonechange")}.
              </p>
            </AttentionBox>

            {/*//////////////////////////////////////////////////////////////////////////*/}

            <h2>{guide("i-uniquefeatures")}</h2>

            <h3 id="orderFromSite">{guide("i-exportourside")}</h3>
            <p>{guide("i-scratch")}.</p>

            <img
              className={"img-contain b-radius-24"}
              src={orderFromSiteImg}
              alt=""
            />

            <h3 id="changeCity" className={"mb-16"}>
              {guide("i-changecity")}
            </h3>
            <p>{guide("i-changecitytext")}.</p>
            <AttentionBox type={"important"}>
              <p>{changeCityPerPeriod}.</p>
            </AttentionBox>

            <h3 id="closeCountry">{guide("i-closecounry")}</h3>
            <p>{guide("i-closecheck")}.</p>

            <h3 id="deactivateProfile">{guide("i-offprofile")}</h3>
            <p>{guide("i-offprofiletext")}.</p>
            <img
              className={"img-contain b-radius-24"}
              src={deactivateProfileImg}
              alt=""
            />

            <AttentionBox type={"important"}>
              <p>{guide("i-offprofilepromo")}.</p>
            </AttentionBox>

            <h3 id="checkPhone">{indi("crossnumber")}</h3>
            <p>
              {guide("i-checknumtext1")}{" "}
              <Link
                to={"//numbuster.com/"}
                target={"_blank"}
                className={"green"}
              >
                Numbuster.
              </Link>{" "}
              {guide("i-checknumres")}.
            </p>

            <div className={"guide__box--friend-agency"}>
              <h3 id="friendContact">{guide("i-gfconnect")}</h3>
              <p>{guide("i-gfconnecttext")}.</p>

              <h3 id="agency">{guide("i-agencyget")}</h3>
              <p>{guide("i-agencygettext")}.</p>

              <img
                className={"img-contain b-radius-24 guide__img--agency"}
                src={agencyImg}
                alt=""
              />
            </div>

            <h3 id="photoCatalog">{guide("i-photograps")}</h3>
            <p>
              {guide("i-photograpstext")}{" "}
              <Link className={"green"} to={"#"}>
                {guide("i-photograpscat")}.
              </Link>
            </p>

            <h3 id="photoCatalog">{guide("i-reviews")}</h3>
            <p>
              {guide("i-reviewstext")}.{" "}
              <Link to={"/lk/indi/reviews"}>{guide("i-gotoreviews")}</Link>
            </p>

            <h3 id="deleteProfile">{guide("i-deleteprofile")}</h3>
            <p>
              {guide("i-deleteprofiletext")}.{" "}
              <Link to={"/lk/indi"}>{guide("i-deleteprofile")}</Link>
            </p>

            <AttentionBox type={"important"}>
              <p>{guide("i-deletewarning")}.</p>
            </AttentionBox>
          </div>
        </div>
      </section>
    </main>
  );
};

export default GuideIndi;
