import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon/Icon";

import "./select.scss";

import { useTranslation } from "react-i18next";
import Input from "../input/Input";
import Checkbox from "../checkbox/Checkbox";

const Select = (props) => {
  const {
    type = "",
    mainClazz = "select",
    arrowSize,
    clazz,
    clazzSvg,
    options,
    name,
    subTitle = "",
    setValue = undefined,
    isSearch = false,
    searchPlaceHolder = "",
    postApi = false,
    onChange = false,
    paramsOnChange = false,
    defaultTitle = "",
    withoutIcon = false,
    withoutTitle = false,
    currentActiveIndex,
    setActive,
    data,
    activeCountry,
    setActiveCountry
  } = props;

  const transformedArray = data.flatMap((item) =>
    Object.entries(item).map(([code, { name }]) => ({ code, name }))
  );

  const toggleCountry = (code) => {
    setIsOpen(true);
    setActiveCountry(code);
    setCode(code.toUpperCase())
  };
  const { t: indi } = useTranslation("indi");

  let [title, setTitle] = useState(
    defaultTitle || withoutTitle
      ? defaultTitle
      : !!options.length
      ? options[0].title
      : ""
  );

  const [code, setCode] = useState("CZ")

  useEffect(() => {
    setTitle(defaultTitle)
  }, [defaultTitle])

  let [searchVal, setSearchVal] = useState("");

  let [mainIcon, setMainIcon] = useState(
    !withoutIcon && !!options ? options[0].icon : ""
  );

  const [isOpen, setIsOpen] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  function setValues(i, paramsOnChange) {
    setTitle(i.name);
    setMainIcon(code);
    !!setValue && setValue(i.name);
    if (onChange) {
      if (paramsOnChange) {
        onChange(i, paramsOnChange);
      } else {
        onChange(i.value ? i.value : i.name);
      }
    }
  }

  const handleClick = (i, e) => {
    e.stopPropagation();

    setValues(i, paramsOnChange);
    setActive(i.id);
    setIsOpen(false);
  };

  const toggleOpen = (e) => {
    if (e.target.classList.contains("select_active")) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const searchedData =
    searchVal && !!data.length
      ? data[0][activeCountry].cities.filter((item) => {
          return item.name?.toLowerCase().includes(searchVal.toLowerCase());
        })
      : data[0] ? data[0][activeCountry]?.cities : [];

  const highlightIndex = searchedData.findIndex((item) => item.highlight === 2);
  const highlightedItem = searchedData.splice(highlightIndex, 1)[0];

  searchedData.sort((a, b) => a.name.localeCompare(b.name));
  
  searchedData.unshift(highlightedItem);

  return (
    <div
      tabIndex={0}
      className={`${mainClazz}${clazz ? ` ${clazz}` : ""}${
        isOpen ? " select_active" : ""
      }`}
      onClick={(e) => toggleOpen(e)}
      ref={selectRef}
    >
      {subTitle && <span className="select__subtitle">{subTitle}</span>}

      {name === "paymentsMethods" ? (
        <Icon
          type={"payment"}
          size={"m"}
          spritePath={mainIcon}
          clazz={`select__icon ${clazzSvg}`}
        />
      ) : null}

      {name === "sort" ? (
        <Icon
          size={"m"}
          spritePath={"sort"}
          clazz={`select__icon ${clazzSvg}`}
        />
      ) : null}

      {(name === "city" || name === "city-create" || type === "flag") &&
      !withoutIcon ? (
        <Icon
          type={"flag"}
          size={"m"}
          spritePath={mainIcon}
          clazz={`select__icon ${clazzSvg}`}
        />
      ) : null}

      {title}

      <Icon
        clazz={"select__arrow"}
        size={arrowSize}
        spritePath={"chevrone-down"}
      />

      <div className="select__body select-country">
        <div className="select__body-left">
          {!!transformedArray.length &&
            transformedArray.map((i) => {
              return (
                <div
                  onClick={() => toggleCountry(i.code)}
                  className={`checkbox _no-border`}
                >
                  <Icon
                    type={"flag"}
                    size={"m"}
                    spritePath={i.code.toUpperCase()}
                  />
                  <span>{i.name}</span>
                </div>
              );
            })}
        </div>
        <div className="select__body-right">
          <label for={`${name}-search`} className="select__search mb-8">
            <Input
              type="search"
              placeholder={indi("searchfield")}
              id={`${name}-search`}
              clazz={"search__input"}
              name={`${name}-search`}
              value={searchVal}
              setValue={setSearchVal}
            />
          </label>
          {!!data.length && !!searchedData?.length &&
            searchedData.map((i, index) => {
              return (
                <div
                key={index + 1}
                onClick={(e) => handleClick(i, e)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") handleClick(i, e);
                }}
                className={`select__item ${i.highlight === 2 ? "fz-18" : i.highlight === 1 ? "fz-16" : ""}`}
              >
                {i.highlight === 2 || i.highlight === 1 ? <b>{i.title || i.name}</b> : i.title || i.name}
              </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  currentValue: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  setValue: PropTypes.func,
  mainIcon: PropTypes.string,
};

export default Select;
