import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";

const DeletePhotoPopup = (props) => {
  const { open, setOpen, removeImg } = props;

  const { t: indi } = useTranslation("indi");

  const handleCloseDelete = () => {
    setOpen(false);
  };

  return (
    <dialog open={open} onClick={handleCloseDelete}>
      {open ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <form
        onClick={(e) => e.stopPropagation()}
        className="popup-form__body gap-16"
      >
        <Button onClick={handleCloseDelete} clazz={"button__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>

        <div className="popup-form__inner text-center">
          <h3>{indi("suredelete")}</h3>

          <span className={"color-700"}>{indi("statnotapproved")}</span>
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l"}
            clazz={"button--secondary justify-center"}
            onClick={handleCloseDelete}
          >
            {indi("cancel")}
          </Button>

          <Button
            size={"l"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={removeImg}
          >
            {indi("yesdelete")}
          </Button>
        </div>
      </form>
    </dialog>
  );
};

export default DeletePhotoPopup;
