import { createSlice } from "@reduxjs/toolkit";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const result = localStorage.getItem("mode");
const theme = result ? result : "dark";

const favorites = JSON.parse(localStorage.getItem('favorites'))?.length;

const initialState = {
  balance: 0,
  userType: "default",
  userStatus: "silver",
  apiUserReady: false,
  videochat: false,
  videochatCamera: false,
  favoriteCounter: favorites ? favorites : 0,
  mode: theme,
  userId: null,
  lang: "",
  city: "",
  readyToVideochat: 1,
  readyToOrder: 1,
  country: [],
  currentCity: {},
  mainWords: {},
  servicesWords: {},
  languagesWords: {},
  nationalistWords: {},
  isDataReady: false,
  adFragment: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setApiUserReady: (state, action) => {
      state.apiUserReady = action.payload;
    },
    setIsDataReady: (state, action) => {
      state.isDataReady = action.payload;
    },
    setReadyToVideochat: (state, action) => {
      state.readyToVideochat = action.payload !== 1 ? 0 : 1;
    },
    setReadyToOrder: (state, action) => {
      state.readyToOrder = action.payload !== 1 ? 0 : 1;
    },
    setServicesWords: (state, action) => {
      state.servicesWords = action.payload;
    },
    setLanguagesWords: (state, action) => {
      state.languagesWords = action.payload;
    },
    setAdFragment: (state, action) => {
      state.adFragment = action.payload;
    },
    setNationalistWords: (state, action) => {
      state.nationalistWords = action.payload;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setCurrentCity: (state, action) => {
      state.currentCity = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setVideochatStatus: (state, action) => {
      state.videochat = action.payload;
    },

    setVideochatCamera: (state, action) => {
      state.videochatCamera = action.payload;
    },

    plusFavoriteCounter: (state) => {
      state.favoriteCounter += 1;
    },

    minusFavoriteCounter: (state) => {
      state.favoriteCounter -= 1;
    },
    setFavoriteCounter: (state,action) => {
      state.favoriteCounter = action.payload
    },

    toggleTheme: (state) => {
      localStorage.setItem(
        "mode",
        `${(state.mode = state.mode === "dark" ? "light" : "dark")}`
      );
    },
    setUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
    setTheme: (state, action) => {
      state.mode = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserBalance: (state, action) => {
      state.balance = action.payload;
    },
  },
});

export const {
  setFavoriteCounter,
  setApiUserReady,
  setIsDataReady,
  setReadyToVideochat,
  setReadyToOrder,
  setNationalistWords,
  setLanguagesWords,
  setAdFragment,
  setServicesWords,
  setMainWords,
  setLang,
  setCity,
  setCountry,
  setVideochatStatus,
  setVideochatCamera,
  plusFavoriteCounter,
  minusFavoriteCounter,
  setTheme,
  setUserType,
  setUserId,
  setUserStatus,
  toggleTheme,
  setCurrentCity,
  setUserBalance,
} = userSlice.actions;

export const themeMode = (state) => state.user.mode;

export const getVideochatStatus = (state) => state.user.videochat;

export const getVideochatCameraStatus = (state) => state.user.videochatCamera;

export const getFavoriteCounterStatus = (state) => state.user.favoriteCounter;

export const getUserID = (state) => state.user.userId;

export const getUserType = (state) => state.user.userType;

export const getApiUserReady = (state) => state.user.apiUserReady;

export const getUserStatus = (state) => state.user.userStatus;

export const getUserCountry = (state) => state.user.country;

export const getUserLang = (state) => state.user.lang;

export const getUserVideoStatus = (state) => state.user.readyToVideochat;

export const getUserOrderStatus = (state) => state.user.readyToOrder;

export const getUserCity = (state) => state.user.city;

export const getUserBalance = (state) => state.user.balance;

export const getUserCurrentCity = (state) => state.user.currentCity;

export const getMainWords = (state) => state.user.mainWords;

export const getServicesWords = (state) => state.user.servicesWords;

export const getLanguagesWords = (state) => state.user.languagesWords;

export const getAdFragment = (state) => state.user.adFragment;

export const getNationalistWords = (state) => state.user.nationalistWords;

export const getIsDataReady = (state) => state.user.isDataReady;

export default userSlice.reducer;
