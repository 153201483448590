import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getSearchValue, setSearchValue } from "@/stores/slices/modelsState";
import { Button, Icon, Input } from "../index";

import "./input.scss";
import { hideShort } from "@/stores/slices/showFilterSlice";
import { useEffectWindowSize } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const InputNick = (props) => {
  const navigate = useNavigate();

  const {
    type,
    placeholder,
    id,
    clazz,
    autocomplete = "off",
    name,
    scrolledClass = "",
  } = props;

  const dispatch = useDispatch();
  const windowWidth = useEffectWindowSize();

  const { t } = useTranslation("translation");

  const searchValue = useSelector(getSearchValue);

  const [value, setValue] = useState(searchValue);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
  };
  const handleClick = () => {
    if (scrolledClass) {
      scrollToTop();
    }
    const pathname = window.location.pathname;

    if (
      pathname.includes("lk") ||
      pathname.includes("favorites") ||
      pathname.includes("profile") ||
      pathname.includes("registration")
    ) {
      navigate("/");
    }
    dispatch(setSearchValue(value));
    if (windowWidth < 767.98) {
      dispatch(hideShort());
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (scrolledClass) {
        scrollToTop();
      }
      if (windowWidth < 767.98) {
        dispatch(hideShort());
      }
      const pathname = window.location.pathname;

      if (
        pathname.includes("lk") ||
        pathname.includes("favorites") ||
        pathname.includes("profile") ||
        pathname.includes("registration")
      ) {
        navigate("/");
      }
      dispatch(setSearchValue(value));
    }
    if (event.key === "Escape") {
      setValue("");
      dispatch(setSearchValue(""));
    }
  };

  useEffect(() => {
    return () => {
      setValue("");
    };
  }, []);

  return (
    <div className="search">
      <label htmlFor={id} className="search__label header__search--label">
        <input
          autoComplete={autocomplete}
          className={`search__input ${clazz ? clazz : ""}`}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          id={id}
        />

        <Button
          square={true}
          buttonType={"button"}
          size={"xs"}
          clazz="search__icon"
          onClick={() => handleClick()}
        >
          <Icon size={"m"} spritePath={"search"} />
        </Button>
      </label>
      {value && (
        <Button
          title={t("reset")}
          onClick={() => {
            setValue("");
            dispatch(setSearchValue(""));
          }}
          clazz="search__close"
        >
          <Icon size={"m"} spritePath={"close"} />
        </Button>
      )}
      <Button
        square={true}
        buttonType={"button"}
        size={"xs"}
        clazz="search__icon"
        onClick={() => handleClick()}
      >
        <Icon size={"m"} spritePath={"search"} />
      </Button>
    </div>
  );
};

export default InputNick;
