import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Geosuggest from "@ubilabs/react-geosuggest";
import { LoadScript } from "@react-google-maps/api";
import "./input.scss";
import { showFilter } from "@/stores/slices/showFilterSlice";
import {
  filterAddress,
  setAddressValue,
  setRadiusCoords,
  toggleSelected,
} from "@/stores/slices/filterSlice";
import { setRadiusCoordsCount } from "@/stores/slices/counterFilterSlice";
import { getUserLang } from "@/stores/slices/userSlice";
import { getGmapsApiReady } from "@/stores/slices/mapModelSlice";
import {
  getOneProfileAddress,
  setOneProfileAddress,
  setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";

const AddressInput = ({ clazz, placeholder, name, value, bounds }) => {
  const dispatch = useDispatch();
  const apiReady = useSelector(getGmapsApiReady);
  const [renderElem, setRenderElem] = useState(false);

  useEffect(() => {
    if (apiReady) {
      setRenderElem(true);
    }
  }, [apiReady]);

  // useEffect(() => {
  //   if(bounds.lat) {
  //     setRenderElem(true);
  //   }
  // }, [bounds])

  const lang = useSelector(getUserLang);

  const onSuggestSelect = (suggest) => {
    if (suggest) {
      if (name === "location") {
        dispatch(setAddressValue(suggest.description));
        dispatch(
          toggleSelected({
            value: suggest.description,
            name: "radius",
          })
        );
        dispatch(
          setRadiusCoords({
            latitude: suggest.location.lat,
            longitude: suggest.location.lng,
          })
        );
        dispatch(
          setRadiusCoordsCount({
            latitude: suggest.location.lat,
            longitude: suggest.location.lng,
          })
        );
      }
      if (name === "location-create") {
        dispatch(setOneProfileAddress(suggest.description));
        dispatch(
          setOneProfileCoords({
            latitude: suggest.location.lat,
            longitude: suggest.location.lng,
          })
        );
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const value = event.target.value;
      console.log(value);
    }
  };

  return (
    renderElem && (
      <Geosuggest
        initialValue={value.length > 0 ? value[0] : ""}
        inputClassName={`input ${clazz ? clazz : ""}`}
        onKeyDown={handleKeyDown}
        onSuggestSelect={onSuggestSelect}
        placeholder={placeholder}
        country={lang}
        queryDelay={100}
        // location={bounds}
        // radius={400000}
      />
    )
  );
};

const Input = (props) => {
  const {
    type,
    placeholder,
    value,
    setValue,
    id,
    clazz,
    autocomplete = "off",
    name,
    onClose = "",
    valueFromSearch = "",
    bounds,
  } = props;

  const [inputValue, setInputValue] = useState(value);

  const handleReset = () => {
    setInputValue("");
    setValue("");
  };

  useEffect(() => {
    if (onClose) {
      setInputValue("");
    }
  }, [onClose]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setValue(value);
  };

  if (name === "location" || name === "location-create") {
    return (
      <AddressInput
        bounds={bounds}
        placeholder={placeholder}
        clazz={clazz}
        name={name}
        value={valueFromSearch}
      />
    );
  }

  return (
    <>
      <input
        autoComplete={autocomplete}
        className={`input ${clazz ? clazz : ""}`}
        type={type}
        name={name}
        value={inputValue}
        placeholder={placeholder ? placeholder : ""}
        onChange={handleChange}
        id={id}
      />
      {inputValue && (
        <i class="icon icon-m icon--close" onClick={handleReset}></i>
      )}
    </>
  );
};

Input.propTypes = {
  type: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default Input;
