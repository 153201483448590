import "./verApprove.scss";

import { Icon } from "@/components/ui";
import { useTranslation } from "react-i18next";

const VerApprove = ({ spritePath, textGreen, text }) => {
  const { t: indi } = useTranslation("indi");

  return (
    <div className="approve__block">
      <Icon spritePath={spritePath} size={"4xl"} clazz={"_green"} />
      <span className="color-green mt-12">
        {textGreen} <span className="color-main">{indi("valid")}</span>
      </span>
      <span className="p2 mt-4 color-main">{text}</span>
    </div>
  );
};

export default VerApprove;
