import { Icon, ShowMoreText, Button } from "@/components/ui";
import React, { Fragment } from "react";
import bodyShapeLight from "../../../../../assets/img/body_shape-light.svg";
import bodyShape from "../../../../../assets/img/body_shape.svg";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";
import ModelIcons from "../icons/ModelIcons";

const ModelInfoContent = ({ props, edit = false, setOpen }) => {
  const {
    healthy_at,
    verified_at,
    description,
    height,
    weight,
    penis_size,
    is_individual,
    is_new,
    tag_bdsm,
    porn_star,
    gender,
    breast,
    have_glasses,
    have_lanses,
    lips,
    eyes,
    smoking,
    nationality,
    skin_color,
    tatoo,
    piercing,
    hair,
    hair_length,
    intim_hairstyle,
    perfume,
    languages,
    hips,
    waist,
    shoulders,
  } = props;

  const { t } = useTranslation("translation");
  const { windowWidth, theme } = useLadyService();

  return (
    <>
      {edit && (
        <div className={`d-flex fd-row gap-12 align-center mb-12`}>
          <h1 className="title model-header__name text-dots mb-0">Параметры</h1>
          <Button
            onClick={() => setOpen(true)}
            size={"xs"}
            clazz={"button_outline--green"}
            square={true}
          >
            <Icon spritePath={"edit-2"} size={"s"} />
          </Button>
        </div>
      )}
      {windowWidth < 1000.98 && (healthy_at || verified_at) ? (
        <ModelIcons verified_at={verified_at} healthy_at={healthy_at} />
      ) : null}
      <div className="girl-card__tags model__tags">
        {is_individual && (
          <span className={"girl-card__tag"} title={t("indireg")}>
            {"INDI"}
          </span>
        )}
        {is_new && (
          <span className={"girl-card__tag"} title={t("newmodel")}>
            {"NEW"}
          </span>
        )}
        {tag_bdsm && (
          <span className={"girl-card__tag"} title={t("bdsmservice")}>
            {"BDSM"}
          </span>
        )}
        {porn_star && (
          <span className={"girl-card__tag"} title={t("pornstar")}>
            {"STAR"}
          </span>
        )}
      </div>

      {description ? <ShowMoreText>{description}</ShowMoreText> : null}

      <div className="model__params">
        <span className={"model__param"}>
          {t("height")}{" "}
          <span>
            {height} {t("cm")}
          </span>
        </span>
        <span className={"model__param"}>
          {t("weight")}{" "}
          <span>
            {weight} {t("kg")}
          </span>
        </span>
        {!!penis_size && (gender === "male" || "trance") ? (
          <span className={"model__param"}>
            {t("penissize")}{" "}
            <span>
              {penis_size} {t("cm")}
            </span>
          </span>
        ) : null}
        {!!breast && (
          <span className={"model__param"}>
            {t("breastsize")} <span>{breast}</span>
          </span>
        )}
        {!!have_glasses && (
          <span className={"model__param"}>
            {t("glasses")} <span>{t("yes")}</span>
          </span>
        )}
        {!!have_lanses && (
          <span className={"model__param"}>
            {t("lenses")} <span>{t("yes")}</span>
          </span>
        )}
        {!!lips ? (
          <span className={"model__param"}>
            {t("lips")} <span>{t(lips)}</span>
          </span>
        ) : null}
        {!!eyes && (
          <span className={"model__param"}>
            {t("eyes")} <span>{t(eyes)}</span>
          </span>
        )}
        {!!smoking && (
          <span className={"model__param"}>
            {t("smoking")}{" "}
            <span>{smoking === "regularly" ? t("rarely") : t(smoking)}</span>
          </span>
        )}
        {!!nationality && (
          <span className={"model__param"}>
            {t("nationality")} <span>{t(nationality)}</span>
          </span>
        )}
        {!!skin_color && (
          <span className={"model__param"}>
            {t("ethnicity")} <span>{t(skin_color)}</span>
          </span>
        )}
        {!!tatoo && (
          <span className={"model__param"}>
            {t("tattoos")} <span>{t(tatoo)}</span>
          </span>
        )}
        {!!piercing && (
          <span className={"model__param"}>
            {t("piercing")} <span>{t(piercing)}</span>
          </span>
        )}
        {(!!hair || !!hair_length) && (
          <span className={"model__param"}>
            {t("hair")}{" "}
            <span>
              {hair === "blonde" ? `${t("blond")},` : hair && t(hair)}{" "}
              {hair_length && t(hair_length)}
            </span>
          </span>
        )}
        {!!intim_hairstyle && (
          <span className={"model__param"}>
            {t("pubichair")} <span>{t(intim_hairstyle)}</span>
          </span>
        )}
      </div>

      <div className={"model__info-bottom"}>
        <div className={"model__info-box"}>
          {!!perfume && (
            <span className={"model__param"}>
              {t("myperfume")} <span>{perfume}</span>
            </span>
          )}

          <div className={"model__param"}>
            {t("ispeak") + ":"}
            <div className="model__lang">
              <div className="girl-card__speak">
                {!!languages.length &&
                  languages.map((lang, index) => {
                    const key = Object.keys(lang)[0];

                    return (
                      <Fragment key={index}>
                        <Icon
                          type={"flag"}
                          title={lang[key].name}
                          spritePath={lang[key].code.toUpperCase()}
                        />
                        {lang[key].code.toUpperCase()}
                      </Fragment>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        {!!hips && !!waist && !!shoulders ? (
          <div className="body-shape">
            <span className="body-shape--shoulders">{shoulders}</span>
            <span className="body-shape--waist">{waist}</span>
            <span className="body-shape--hips">{hips}</span>
            <img src={theme === "light" ? bodyShapeLight : bodyShape} alt="" />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ModelInfoContent;
