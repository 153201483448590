import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  getFilterMiniPopupStatus,
  getFilterPopupStatus,
  setFilterMiniPopupStatus,
} from "@/stores/slices/filterPopupSlice";

import "./notifications.scss";
import { getUserType } from "@/stores/slices/userSlice";
import {
  setAuthorizationStatus,
  setFilterMode,
} from "@/stores/slices/popupSlice";
import { useTranslation } from "react-i18next";

const SaveFilterForm = ({ onSubmitForm }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("translation");

  const userType = useSelector(getUserType);
  const isLogin = userType === "default" ? false : true;

  const formSchema = object().shape({
    name: string().required("Имя обязательно"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [saveText, setSaveText] = useState(false);
  const onSubmit = (data) => {
    setSaveText(true);
    reset();
    setTimeout(() => {
      onSubmitForm(false);
      dispatch(setFilterMiniPopupStatus(false));
    }, 3000);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="input-label__wrapper notifications__safe-filter"
      >
        <InputInLabel
          clazz={`${errors.name ? " _error" : ""}`}
          id={"loginRegistration"}
          type={"text"}
          placeholder={"Мой фильтр 1"}
          register={{ ...register("name") }}
          autocomplete={"off"}
        >
          {(errors.name && (
            <span className={"input-label__error"}>{errors.name.message}</span>
          )) || <span>{t("filtername")}</span>}
        </InputInLabel>

        <Button
          size={"l"}
          buttonType={isLogin ? "submit" : "button"}
          onClick={() => {
            !isLogin && dispatch(setFilterMode(true));
            !isLogin && dispatch(setAuthorizationStatus(true));
          }}
          clazz={"button--primary"}
        >
          {" "}
          {t("save")}
        </Button>
      </form>
      {saveText ? (
        <p className={"notifications__text"}>Фильтр сохранён</p>
      ) : (
        <p className={"notifications__text"}>
          Сохранённые фильтры доступны в <Link to={"/"}>профиле</Link>
        </p>
      )}
    </>
  );
};

const Notifications = () => {
  const dispatch = useDispatch();
  const userType = useSelector(getUserType);

  const { t } = useTranslation("translation");

  const statusFullPopup = useSelector(getFilterPopupStatus);
  const statusMiniPopup = useSelector(getFilterMiniPopupStatus);

  const formSchema = object().shape({
    email: string().email().required("Почта обязательна"),
    text: string(),
  });
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [email, setEmail] = useState();
  const onSubmit = (data, e) => {
    e.preventDefault();
    let result = getValues("email");
    setEmail(result);
    reset();
    setTimeout(() => {
      setIsActive(false);
      dispatch(setFilterMiniPopupStatus(false));
    }, 3000);
  };
  const [isActive, setIsActive] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (statusFullPopup && statusMiniPopup) {
      setIsActive(true);
    }
  }, [statusFullPopup]);

  // useEffect(() => {
  //     if (isActive) {
  //         const timeout = setTimeout(() => {
  //             setIsActive(false);
  //         }, 5000);
  //         setTimeoutId(timeout);
  //     }
  // }, [isActive]);

  // const handleMouseLeave = () => {
  //     if (timeoutId) {
  //         const timeout = setTimeout(() => {
  //             setIsActive(false);
  //         }, 5000);
  //         setTimeoutId(timeout);
  //     }
  // }

  // const handleMouseEnter = () => {
  //     if (timeoutId) {
  //         clearTimeout(timeoutId);
  //     }
  // }
  const handleClick = () => {
    setIsActive(true);
  };
  return (
    <>
      {statusMiniPopup && (
        <Button
          size={"s"}
          onClick={() => handleClick()}
          clazz="button--tetriary notifications__main-button user__save"
        >
          <span>{t("savesub")}</span>
          <Icon size={"m"} spritePath={"save"} />
        </Button>
      )}
      {isActive ? (
        <div
          className={`popup-form__body notifications${
            isActive ? " _show" : ""
          }`}
          // onMouseEnter={() => handleMouseEnter()}
          // onMouseLeave={() => handleMouseLeave()}
        >
          <Button
            onClick={() => {
              setIsActive(false);
              dispatch(setFilterMiniPopupStatus(false));
            }}
            clazz={"button__close"}
          >
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          <span className="title_h3 popup-form__title">
            Сохранить набор фильтров
          </span>

          <SaveFilterForm onSubmitForm={setIsActive} />

          <form
            className={"notifications__subscribe"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <span className="title_h3 popup-form__title">
              {t("notifyaboutnew")}
            </span>
            <label
              htmlFor="saveFilterText"
              className={`input-label popup-form__textarea p1`}
            >
              <span>{t("notificationmessage")}</span>
              <textarea
                {...register("text")}
                placeholder={t("wenotifyaboutnew")}
                id={"saveFilterText"}
              />
            </label>

            <InputInLabel
              clazz={`${errors.email ? " _error" : ""}`}
              id={"subscribeFilterEmail"}
              type={"email"}
              placeholder={"example@google.com"}
              register={{ ...register("email") }}
              autocomplete={"email"}
            >
              {(errors.email && (
                <span className={"input-label__error"}>
                  {errors.email.message}
                </span>
              )) || <span>{t("youremail")}</span>}
            </InputInLabel>

            <Button size={"l"} buttonType={"submit"} clazz={"button--green"}>
              {" "}
              {t("subscribe")}
            </Button>
            {email ? (
              <p className={""}>
                На {email} отправлена ссылка для подтверждения подписки.
                Пожалуйста, проверьте почту и подтвердите получение уведомлений.
              </p>
            ) : null}
          </form>
        </div>
      ) : null}
    </>
  );
};
export default Notifications;
