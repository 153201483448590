import { useCallback, useEffect, useRef, useState } from "react";
import "./media.scss";
import Tubs from "./Tubs";
import { number, object, string } from "yup";

import { Button, Icon, InputInLabel } from "../../../../../../ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "lodash.debounce";
import axios from "axios";
import ReactPlayer from "react-player";
import { Photo } from "./Photo";
import { Video } from "./Video";
import { Interior } from "./Interior";
import { Ero } from "./Ero";
import baseUrl from "../../../../../../../services/apiConfig";
import { useTranslation } from "react-i18next";

const Media = ({
  changeStep,
  step,
  rootElem,
  formData : userFormData,
  setFormData,
  setAvailableSteps,
}) => {
  const formSchema = object().shape({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const token = localStorage.getItem("user");

  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");

  const ImportantBlock = () => {
    return (
      <div className="attention-box important">
        <span className="error">{indi("important") + "!"}</span>
        <span>{indi("pesonalresp")}</span>
        <a target="_blank" href="/ru/guide/rules" className="green">
          {indi("portalules")}
        </a>
        <span>{indi("notsanction")}</span>
        <br></br>
        <div className="important-icons">
          <span className={"girl-card__icon"} title={t("verification")}>
            <Icon spritePath={"verify-fill"} size={"l"} />
            {t("verification")}
          </span>
          <span className="ml-6">{t("or")}</span>
          <span className={"girl-card__icon ml-6 mr-6"} title={t("healthy")}>
            <Icon spritePath={"health-fill"} size={"l"} />
            {t("healthy")}
          </span>
        </div>
          <span>{indi("toget")}</span>
      </div>
    );
  };

  const urlParams = window.location.hash.substring(1);
  const allowedValues = ["photo", "video", "interior", "ero"];
  const [visualElem, setVisualElem] = useState(
    allowedValues.includes(urlParams) ? urlParams : "photo"
  );

  const eroPriceRef = useRef(null)

  const uploadDataToServer = async (data, type) => {
  
    const uploadFileChunks = async (file) => {

      const chunkSize = 20 * 1024 * 1024; // 20 МБ
      const totalChunks = Math.ceil(file.size / chunkSize);
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min((i + 1) * chunkSize, file.size);
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append('file', chunk);
        formData.append('file_name', file.name);
        formData.append('chunk_number', i + 1);
        formData.append('total_chunks', totalChunks);
        if (type === 'mixed') {
          formData.append('additional_param', 'value');
        }
        try {
          await axios.post(
            baseUrl + `user/profiles/${userFormData?.slug}/media`,
            formData,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          );
        } catch (error) {
          console.error('Chunk upload error:', error);
        }
      }
    };
  
    try {
      if (type === 'mixed') {
      } else {
        data.files.forEach(file => {
          if (file.size > 20 * 1024 * 1024) {
            uploadFileChunks(file);
          } else {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('file_name', file.name);
            axios.post(
              baseUrl + `user/profiles/${userFormData?.slug}/media`,
              formData,
              {
                headers: {
                  Authorization: token ? `Bearer ${token}` : "",
                },
                params: {
                  is_interior: type === 'interior' ? true : false,
                  is_ero: type === 'ero' ? true : false,
                  ...(type === "ero" && { price: Number(priceForEro) }),
                  ...(type === "ero" && { description: descForEro }),
                },
              }
            );
          }
        });
      }
  
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

  const loadData = () => {
    setErrorPrice(false);
    if(visualElem === 'photo') {
      uploadDataToServer(dataPhoto, "photo")
      setFormData((prevData) => ({
        ...prevData,
        photo: dataPhoto,
      }));
    }
    if(visualElem === 'video') {
      uploadDataToServer(dataVideo, "video");
      setFormData((prevData) => ({
        ...prevData,
        video: dataVideo,
      }));
    }
    if(visualElem === 'interior') {
      uploadDataToServer(dataInterior, "interior");
      setFormData((prevData) => ({
        ...prevData,
        interior: dataInterior,
      }));
    }
    if (
      visualElem === "ero" &&
      (!!dataEroPhoto.files.length ||
        !!dataEroVideo.files.length) &&
      !priceForEro
    ) {
      setErrorPrice(true);
      eroPriceRef.current.scrollIntoView({
        behavior: "smooth",
      });
      return;
    }
    if(visualElem === 'ero') {
      uploadDataToServer(dataEroPhoto, "ero");
      uploadDataToServer(dataEroVideo, "ero");
      setFormData((prevData) => ({
        ...prevData,
        eroPhoto:dataEroPhoto,
        eroVideo: dataEroVideo,
        price: priceForEro,
        desc: descForEro,
        isMediaStepComplete: true
      }));
    }
  }
  
  const toggleTub = (direction, name) => {
    setErrorPrice(false);
    if(name === 'photo') {
      setDisabledTub([]);
      uploadDataToServer(dataPhoto, "photo")
      setFormData((prevData) => ({
        ...prevData,
        photo: dataPhoto,
      }));
    }
    if(name === 'video') {
      uploadDataToServer(dataVideo, "video")
      setFormData((prevData) => ({
        ...prevData,
        video:dataVideo,
      }));
    }
    if(name === 'interior') {
      uploadDataToServer(dataInterior, "interior")
      setFormData((prevData) => ({
        ...prevData,
        interior: dataInterior,
      }));
    }
    if (
      name === "ero" &&
      (!!dataEroPhoto.files.length ||
        !!dataEroVideo.files.length) &&
      !priceForEro
    ) {
      setErrorPrice(true);
      eroPriceRef.current.scrollIntoView({
        behavior: "smooth",
      });
      return;
    }
    if(name === 'ero') {
      uploadDataToServer(dataEroPhoto, "ero");
      uploadDataToServer(dataEroVideo, "ero");
      setFormData((prevData) => ({
        ...prevData,
        eroPhoto:dataEroPhoto,
        eroVideo: dataEroVideo,
        price: priceForEro,
        desc: descForEro,
        isMediaStepComplete: true
      }));
    }

    if (name !== "ero") {
      const currentIndex = allowedValues.indexOf(visualElem);

      let nextIndex;
      if (direction === "forward") {
        nextIndex = (currentIndex + 1) % allowedValues.length;
      } else if (direction === "back") {
        nextIndex =
          (currentIndex - 1 + allowedValues.length) % allowedValues.length;
      } else {
        return;
      }
      setVisualElem(allowedValues[nextIndex]);
    }


    if (name !== "ero") {
      rootElem.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      setAvailableSteps((prev) => [...prev, 3]);
      changeStep((prev) => prev + 1);
      rootElem.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const hashIndex = currentUrl.indexOf("#");
    const newUrl =
      hashIndex !== -1 ? currentUrl.slice(0, hashIndex) : currentUrl;
    window.history.replaceState({}, "", newUrl);
  }, [visualElem]);

  // const [images, setImages] = useState(formData?.images ? formData.images : []);
  const [dataPhoto, setDataPhoto] = useState(userFormData.photo ? userFormData.photo : { files: [] });
  const [dataVideo, setDataVideo] = useState(userFormData.video ? userFormData.video :{ files: [] });
  const [dataInterior, setDataInterior] = useState(userFormData.interior ? userFormData.interior :{ files: [] });
  const [dataEroPhoto, setDataEroPhoto] = useState(userFormData.eroPhoto ? userFormData.eroPhoto :{ files: [] });
  const [dataEroVideo, setDataEroVideo] = useState(userFormData.eroVideo ? userFormData.eroVideo :{ files: [] });

  const [priceForEro, setPriceFOrEro] = useState(
    userFormData?.price ? userFormData.price : 0
  );
  const [descForEro, setDescForEro] = useState(
    userFormData?.desc ? userFormData.desc : ""
  );

  const [errorPrice, setErrorPrice] = useState(false);
  const [inputValue, setInputValue] = useState(["", "", ""]);
  const [videoUrl, setVideoUrl] = useState(["", "", ""]);
  const [videoExists, setVideoExists] = useState([false, false, false]);

  const [disabledTub, setDisabledTub] = useState(
    !userFormData?.isMediaStepComplete ? ["video", "interior", "ero"] : []
  );

  const removeEroVideo = (index) => {
    setVideoUrl((prevStates) => {
      const newState = [...prevStates];
      newState[index] = "";
      return newState;
    });
    setVideoExists((prevStates) => {
      const newState = [...prevStates];
      newState[index] = false;
      return newState;
    });
    setInputValue((prevStates) => {
      const newState = [...prevStates];
      newState[index] = "";
      return newState;
    });
  };

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    setInputValue((prevStates) => {
      const newState = [...prevStates];
      newState[index] = value;
      return newState;
    });
    tryToGetVideo(value, index);
  };

  const backStep = () => {
    changeStep((prev) => prev - 1);
  };

  const tryToGetVideo = useCallback(
    debounce(async (value, index) => {
      if (value) {
        const exists = await checkVideoExists(value);
        if (exists) {
          setVideoUrl((prevStates) => {
            const newState = [...prevStates];
            newState[index] = value;
            return newState;
          });
          setVideoExists((prevStates) => {
            const newState = [...prevStates];
            newState[index] = true;
            return newState;
          });
        } else {
          setVideoExists((prevStates) => {
            const newState = [...prevStates];
            newState[index] = false;
            return newState;
          });
        }
      }
    }, 1000),
    []
  );

  const checkVideoExists = async (url) => {
    const result = await axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
      })
      .catch((error) => {
        return false;
      });
    return result;
  };
  const fakeArray = Array.from({ length: 3 }, (_, index) => index);
  return (
    <>
      <h2>{indi("photosandvid")}</h2>
      <form
        className={`create-page__form photo`}
      >
        <div className="create-page__tubs tabs__nav mb-16">
          <Tubs
            disabledTub={!userFormData.isMediaStepComplete && disabledTub}
            setVisualContent={setVisualElem}
            visualContent={visualElem}
            notToggle={errorPrice}
            onChange={(e) => loadData(e)}
          />
        </div>
        {visualElem === "ero" && <span>{indi("uploadero")}</span>}
        <Photo
          show={visualElem}
          data={dataPhoto}
          uploadDataToServer={uploadDataToServer}
          setData={setDataPhoto}
        />
        <Video
          show={visualElem}
          data={dataVideo}
          setData={setDataVideo}
        />
        <Interior
          show={visualElem}
          data={dataInterior}
          setData={setDataInterior}
        />
        <Ero
          show={visualElem}
          data={dataEroPhoto}
          setData={setDataEroPhoto}
          dataVideo={dataEroVideo}
          setDataVideo={setDataEroVideo}
          setPrice={setPriceFOrEro}
          eroPrice={priceForEro}
          eroPriceRef={eroPriceRef}
          setDesc={setDescForEro}
          eroDesc={descForEro}
          errorPrice={errorPrice}
          setError={setErrorPrice}
        />
        {/* {visualElem !== "ero" && <ErrorBlock content={indi("warning")} />} */}
        {visualElem !== "ero" && <ImportantBlock></ImportantBlock>}
        {visualElem !== "ero" && (
          <>
            <h3>PornoStar</h3>
            <div className="d-flex gap-12 fd-column">
            <span>{indi("getporno")}</span>
            <div className="create-page__eroLinks">
              {fakeArray.map((index) => (
                <div className="eroLink">
                  <InputInLabel
                    type={"text"}
                    id={`create-erolink-${index}`}
                    placeholder={"https://pornhub.com/eroero99"}
                    register={{ ...register(`erolink-${index}`) }}
                    onChange={(e) => handleInputChange(e, index)}
                    value={inputValue[index]}
                  >
                    {indi("linktovid")?.replace(/%([^%]+)%/g, index + 1)}
                  </InputInLabel>
                  {videoExists[index] && (
                    <div className="currentVideo">
                      <ReactPlayer
                        url={videoUrl[index]}
                        controls={true}
                        width="340px"
                        height="190px"
                        style={{ borderRadius: "12px", overflow: "hidden" }}
                      />
                      <span
                        className="icon-bg"
                        onClick={() => removeEroVideo(index)}
                      >
                        <Icon size={"s"} spritePath={"close"} />
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
            </div>
          </>
        )}
        <div className="create-form__buttons">
          {visualElem === "photo" && (
            <Button
              size={"l"}
              square={false}
              clazz="button--secondary"
              onClick={() => backStep()}
            >
              {indi("back")}
            </Button>
          )}
          {visualElem !== "photo" && (
            <Button
              size={"l"}
              square={false}
              clazz="button--secondary"
              onClick={() => toggleTub("back", visualElem)}
            >
              {indi("back")}
            </Button>
          )}
          <Button
            buttonType={"button"}
            size={"l"}
            square={false}
            clazz="button--green"
            onClick={() => toggleTub("forward", visualElem)}
            // disabled={!!!images.length}
            disabled={!!!dataPhoto.files.length}
            
          >
            {indi("continue")}
          </Button>
          {/* {visualElem === "ero" && (
            <Button
              buttonType={"button"}
              size={"l"}
              square={false}
              clazz="button--green"
              
            >
              {indi("continue")}
            </Button>
          )} */}
          {/* {visualElem === "ero" && (
            <Button
              buttonType={"submit"}
              size={"l"}
              square={false}
              clazz="button--green"
            >
              {indi("continue")}
            </Button>
          )} */}
        </div>
      </form>
    </>
  );
};

export default Media;
