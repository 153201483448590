import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonLink,
  Checkbox,
  Icon,
  RatingStars,
} from "@/components/ui";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import {
  plusFavoriteCounter,
  minusFavoriteCounter,
} from "@/stores/slices/userSlice";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  getMapStatus,
  setAuthorizationStatus,
  setMapMode,
  setStoriesMap,
  setVideoChatMode,
} from "@/stores/slices/popupSlice";
import { setModelForCall } from "@/stores/slices/modelsState";
import { setOneModelPosition } from "@/stores/slices/mapModelSlice";
import { setLadyForCall } from "@/stores/slices/videoCallSlice";
import { useTranslation } from "react-i18next";

import {
  minusSelectedCount,
  plusSelectedCount,
} from "@/stores/slices/agencyFilterSlice";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";

import "./girlCard2.scss";

const GirlCard = ({ props, big = false, checkbox = false, clazz }) => {
  const {
    gender,
    orientation,
    id,
    slug,
    name,
    phone,
    telegram,
    whatsapp,
    position,
    rating,
    address,
    city,
    age,
    height,
    weight,
    breast,
    prices,
    languages,
    services,
    is_online,
    is_individual,
    is_top,
    is_elite,
    is_escort,
    is_healthy,
    is_verified,
    is_pornstar,
    is_new,
    tag_video,
    tag_private,
    tag_friends,
    tag_booking,
    tag_bdsm,
    ready_travel,
    count_photos,
    count_videos,
    ready_videochat,
    photos,
    is_favorite,
  } = props;

  const { t } = useTranslation("translation");

  const {
    theme,
    lang,
    windowWidth,
    userType,
    dispatch,
    orientations,
    gendersTitle,
  } = useLadyService();

  const postAltphoto = t("altphoto")?.replace(/%([^%]+)%/g, name);

  const localeFavoriteSlug = localStorage.getItem("favorites") || [];
  const token = localStorage.getItem("user");

  const [favorite, setFavorite] = useState(
    token ? is_favorite : localeFavoriteSlug.includes(slug)
  );

  const mapPopupStatus = useSelector(getMapStatus);

  const toggleMapOpen = (slug) => {
    dispatch(setOneModelPosition(slug));
    dispatch(setStoriesMap(true));
  };

  const toggleFavoriteCount = (slug) => {
    if (favorite) {
      if (token) {
        const fetchData = async () => {
          try {
            const route = `user/favorites/remove/${slug}`;
            const method = "DELETE";

            const data = await makeRequest({ route, method });
          } catch (error) {}
        };
        fetchData();
      } else {
        let favorites = JSON.parse(localStorage.getItem("favorites")) || [];

        favorites = favorites.filter((item) => item !== slug);

        localStorage.setItem("favorites", JSON.stringify(favorites));
      }
    } else {
      if (token) {
        const fetchData = async () => {
          try {
            const route = `user/favorites/add/${slug}`;
            const method = "POST";

            const data = await makeRequest({ route, method });
          } catch (error) {}
        };

        fetchData();
      } else {
        const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
        if (!favorites.includes(slug)) {
          favorites.push(slug);
        }
        localStorage.setItem("favorites", JSON.stringify(favorites));
      }
    }

    dispatch(favorite ? minusFavoriteCounter() : plusFavoriteCounter());
    setFavorite(!favorite);
  };

  const [clicked, setClicked] = useState(false);

  let linkRef = useRef(null);

  const [isHover, setIsHover] = useState(false);

  const setServices = Array.from(new Set(services));

  const uniqueId = uuidv4();

  const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
    ? formatPhoneNumberIntl(`${phone}`)
    : formatPhoneNumberIntl(`+${phone}`);

  const [checked, setChecked] = useState();
  useEffect(() => {
    // dispatch(checked ? plusSelectedCount() : minusSelectedCount())
    if (checked && checked === true) {
      dispatch(plusSelectedCount());
    }
    if (!checked && checked === false) {
      dispatch(minusSelectedCount());
    }
  }, [checked]);

  const cardInfo = () => (
    <div className="girl-card__info">
      <Link
        to={`/${lang}/profile/${slug}`}
        ref={linkRef}
        onMouseLeave={() => setIsHover(false)}
        onMouseEnter={() => setIsHover(true)}
        title={is_online ? `${name} ${t("online")}` : `${name} ${t("offline")}`}
        className={`girl-card__name${isHover ? " _hover" : ""}`}
      >
        <Icon
          spritePath={
            is_online
              ? "online"
              : theme !== "dark"
                ? "offline-light"
                : "offline"
          }
        />
        {name}
      </Link>

      {!!address && !!city ? (
        <Link
          to={"#"}
          title={t("onmap")}
          className="girl-card__address"
          onClick={() => toggleMapOpen(slug)}
        >
          <Icon spritePath={"map-pin"} size={"xs"} />
          {!!city ? `${city}` : ""}
          {!!address ? `, ${address}` : ""}
        </Link>
      ) : !!city ? (
        <div title={t("onmap")} className="girl-card__address">
          <Icon spritePath={"map-pin"} size={"xs"} />
          {city}
        </div>
      ) : address ? (
        <Link to={"#"} className="girl-card__address">
          <Icon spritePath={"map-pin"} size={"xs"} />
          {address}
        </Link>
      ) : null}
    </div>
  );

  const infoIcons = () => (
    <>
      {!!is_verified ? (
        <span className={"girl-card__icon"} title={t("verification")}>
          <Icon spritePath={"verify-fill"} size={"xl"} />
        </span>
      ) : null}

      {!!is_healthy ? (
        <span className={"girl-card__icon"} title={t("healthy")}>
          <Icon spritePath={"health-fill"} size={"xl"} />
        </span>
      ) : null}
    </>
  );

  const swiperSlideTemplate = (photos) => (
    <SwiperSlide
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
      onClick={() => linkRef.current.click()}
      className={`girl-card__slide${isHover ? " _hover" : ""}`}
    >
      <img src={photos} alt={postAltphoto} />
    </SwiperSlide>
  );

  const paramTemplate = (translateKey, param, decimal) => (
    <span className={"girl-card__param"}>
      <span>{t(translateKey)}</span>
      {`${param} ${decimal ? t(decimal) : ""}`}
    </span>
  );

  const priceTemplate = (clazz, translateKey, price) => (
    <span className={`girl-card__price--${clazz}`}>
      <span>{t(translateKey)}</span>
      {price}€
    </span>
  );

  const iconsTemplate = (translateKey, icon) => {
    let clazz = translateKey === "erocontent" ? "color-green" : "";
    return (
      <Icon
        clazz={clazz}
        title={t(translateKey)}
        spritePath={icon}
        size={"m"}
      />
    );
  };

  const tagsTemplate = (tag, translateKey) => (
    <span className={"tag"} title={t(translateKey)}>
      {tag}
    </span>
  );

  return (
    <div
      className={`girl-card${
        position === 1 || is_top ? " girl-card--top" : ""
      }${is_elite ? " girl-card--elite" : ""}${clazz ? ` ${clazz}` : ""}${
        (windowWidth < 767.98 && windowWidth !== 0) || big
          ? " girl-card__big"
          : ""
      }`}
    >
      <div className={`girl-card__wrapper`}>
        <Swiper
          className="girl-card__slider"
          title={postAltphoto}
          nested={true}
          modules={[Scrollbar]}
          spaceBetween={10}
          scrollbar={{
            hide: false,
            el: `.swiper-scrollbar_${uniqueId}`,
            draggable: true,
            dragSize: 32,
          }}
        >
          {checkbox ? (
            <Checkbox
              id={`check_${id}`}
              readOnly={true}
              onChange={() => setChecked(!checked)}
            />
          ) : null}

          <Button
            clazz={`girl-card__favorite`}
            onClick={() => toggleFavoriteCount(slug)}
          >
            <Icon
              size={"m"}
              spritePath={favorite ? "favorite-fill" : "favorite"}
            />
          </Button>

          {photos?.length > 0 && photos[0] ? (swiperSlideTemplate(photos[0])) : null}
          {photos?.length > 0 && photos[1] ? (swiperSlideTemplate(photos[1])) : null}
          {photos?.length > 0 && photos[2] ? (swiperSlideTemplate(photos[2])) : null}

          <SwiperSlide className={"girl-card__slide"}>
            <div className="girl-card__box">
              {count_photos ? (
                <div className="girl-card__info-box">
                  <Icon spritePath={"image"} size={"m"} />
                  <span>{count_photos + " " + t("photo")}</span>
                </div>
              ) : null}

              {count_videos ? (
                <div className="girl-card__info-box">
                  <Icon spritePath={"video-play"} size={"m"} />
                  <span>
                    {count_videos} {t("videofotograph")}
                  </span>
                </div>
              ) : null}

              {tag_private ? (
                <div className="girl-card__info-box">
                  <Icon spritePath={"pants"} size={"m"} />
                  <span>{t("erocontent")}</span>
                </div>
              ) : null}

              <ButtonLink
                clazz={"button--green"}
                href={`/${lang}/profile/${slug}`}
                size={"xs"}
              >
                <span>{t("goto")}</span> {t("toad")}
              </ButtonLink>
            </div>
          </SwiperSlide>

          <div slot={"container-end"} className="girl-card__descr">
            <div className="girl-card__tags-wrapper">
              {!!is_verified || !!is_healthy || !!gender ? (
                <>
                  <Icon
                    title={`${gendersTitle[gender]} — ${orientations(
                      orientation
                    )}`}
                    clazz={"girl-card__icon"}
                    spritePath={`gender-${!!gender ? gender : "female"}`}
                    size={"xl"}
                  />

                  {infoIcons()}
                </>
              ) : null}

              {!!is_individual ? tagsTemplate("INDI", "indireg") : null}
              {!!is_new ? tagsTemplate("NEW", "newmodel") : null}
              {!!tag_bdsm ? tagsTemplate("BDSM", "bdsmservice") : null}
              {!!is_pornstar ? tagsTemplate("STAR", "pornstar") : null}
            </div>

            <div className={`swiper-scrollbar swiper-scrollbar_${uniqueId}`}>
              {photos?.length === 1 ? <span></span> : null}
              {photos?.length === 2 ? (
                <>
                  <span></span>
                  <span></span>
                </>
              ) : null}
              {photos?.length === 3 ? (
                <>
                  <span></span>
                  <span></span>
                  <span></span>
                </>
              ) : null}

              <div className="swiper-scrollbar__bar"></div>
            </div>
          </div>
        </Swiper>

        <div className="girl-card__info-wrapper">
          <div className="girl-card__age">
            {age} <span>{t("years")}</span>
          </div>

          {cardInfo()}
        </div>

        <div className="girl-card__group">
          {!!height || !!weight || !!breast ? (
            <div className="girl-card__params">
              {!!height ? paramTemplate("height", height, "cm") : null}
              {!!weight ? paramTemplate("weight", weight, "kg") : null}
              {!!breast ? paramTemplate("breastsize", breast) : null}
            </div>
          ) : null}

          <div className="girl-card__modes">
            {!!is_verified || !!is_healthy || !!gender ? (
              <>
                <Icon
                  title={`${gendersTitle[gender]} — ${orientations(
                    orientation
                  )}`}
                  clazz={"girl-card__icon"}
                  spritePath={`gender-${!!gender ? gender : "female"}`}
                  size={"xl"}
                />

                {infoIcons()}
              </>
            ) : null}
            {!!is_individual ? tagsTemplate("INDI", "indireg") : null}
            {!!is_new ? tagsTemplate("NEW", "newmodel") : null}
            {!!tag_bdsm ? tagsTemplate("BDSM", "bdsmservice") : null}
            {!!is_pornstar ? tagsTemplate("STAR", "pornstar") : null}

            {!!tag_video ? iconsTemplate("video", "video-play") : null}
            {!!tag_friends ? iconsTemplate("girlfriend", "girlfriend") : null}
            {!!tag_private ? iconsTemplate("erocontent", "pants") : null}
            {!!tag_booking ? iconsTemplate("onlineorder", "pay-content") : null}
            {!!ready_travel ? iconsTemplate("travel", "travel") : null}
            {!!is_escort ? iconsTemplate("escort", "escort") : null}

            {!!setServices.length
              ? setServices.map((item) => {
                return <span key={item}>{item}</span>;
              })
              : null}
          </div>
        </div>

        {!!rating || !!languages.length ? (
          <div className="girl-card__advantages">
            {!!rating ? <RatingStars value={rating} readOnly={true} /> : null}

            {!!languages.length ? (
              <div className="girl-card__speak">
                <span>{t("ispeak") + ":"}</span>
                {languages.map((lang, index) => {
                  const key = Object.keys(lang)[0];

                  return (
                    <Icon
                      key={index}
                      title={lang[key].name}
                      type={"flag"}
                      spritePath={lang[key].code.toUpperCase()}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="girl-card__prices">
          {prices && prices.incall ? (priceTemplate('private', 'incall', prices.incall)) : null}
          {prices && prices.outcall ? (priceTemplate('escort', 'outcall', prices.outcall)) : null}
          {prices && prices.escort ? (priceTemplate('accompaniment', 'accompaniment', prices.escort)) : null}
        </div>

        <div className="girl-card__buttons">
          {!!ready_videochat ? (
            <Button
              onClick={() => {
                if (userType !== "default") {
                  dispatch(setLadyForCall(props));
                } else {
                  dispatch(setVideoChatMode(true));
                  dispatch(setAuthorizationStatus(true));
                  dispatch(setModelForCall(props));
                }
                if (mapPopupStatus) {
                  dispatch(setMapMode(true));
                  dispatch(setStoriesMap(false));
                  dispatch(setModelForCall(props));
                }
              }}
              clazz={"button--green"}
              size={"m"}
            >
              <Icon size={"l"} spritePath={"video"} />
              {t("videochat")}
            </Button>
          ) : null}

          {!!whatsapp ? (
            <ButtonLink
              href={`https://wa.me/${whatsapp}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${slug}`}
              clazz={"button--primary"}
              square={true}
              target={"_blank"}
              size={"m"}
            >
              <Icon size={"xl"} spritePath={"whatsapp"} />
            </ButtonLink>
          ) : null}

          {(!!ready_videochat && !!telegram && !!whatsapp) ||
          (!!ready_videochat && !!whatsapp) ||
          (!!ready_videochat && !!telegram) ||
          !!ready_videochat ? (
            <ButtonLink
              href={`tel:${phone}`}
              square={true}
              clazz={"button--primary"}
              size={"m"}
            >
              <Icon size={"xl"} spritePath={"call"} />
            </ButtonLink>
          ) : clicked ? (
            <ButtonLink
              href={`tel:${phone}`}
              size={"m"}
              clazz={"button--primary"}
            >
              {phoneRes}
            </ButtonLink>
          ) : (
            <Button
              size={"m"}
              onClick={() => setClicked(true)}
              clazz={`button--primary`}
            >
              {t("shownumber")}
            </Button>
          )}

          {!!telegram ? (
            <ButtonLink
              href={`https://t.me/${telegram}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${slug}`}
              clazz={"button--primary"}
              square={true}
              target={"_blank"}
              size={"m"}
            >
              <Icon size={"xl"} spritePath={"telegram"} />
            </ButtonLink>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GirlCard;
