import { useEffect, useRef, useState } from "react";
import { number, object, string } from "yup";

import {
  Button,
  Checkbox,
  Icon,
  InputInLabel,
  ScheduleInput,
  Select,
} from "@/components/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import "./tarifs.scss";
import { makeRequest } from "@/services/makeRequest";
import { useTranslation } from "react-i18next";
import { useEffectWindowSize } from "../../../../../../../hooks";

const Tarifs = ({
  setAvailableSteps,
  changeStep,
  step,
  rootElem,
  formData,
  setFormData,
}) => {
  const { t } = useTranslation("translation");
  const { t: indi } = useTranslation("indi");
  const formSchema = object().shape({});

  const timeOption = [
    { title: `15 ${t("min")}`, duration: "15", value: "15" },
    { title: `30 ${t("min")}`, duration: "30", value: "30" },
    { title: `1 ${t("hour")}`, duration: "60", value: "1" },
    { title: `2 ${indi("hourfew")}`, duration: "120", value: "2" },
    { title: `3 ${indi("hourfew")}`, duration: "180", value: "3" },
    { title: `4 ${indi("hourfew")}`, duration: "240", value: "4" },
    { title: `6 ${indi("hourmany")}`, duration: "360", value: "6" },
    { title: `8 ${indi("hourmany")}`, duration: "480", value: "8" },
    { title: `12 ${indi("hourmany")}`, duration: "720", value: "12" },
    { title: `1 ${t("day")}`, duration: "1440", value: "1" },
    { title: `2 ${t("days")}`, duration: "2880", value: "2" },
    { title: `5 ${t("days")}`, duration: "7200", value: "5" },
    { title: `7 ${t("days")}`, duration: "10080", value: "7" },
    { title: `14 ${t("days")}`, duration: "20160", value: "14" },
  ];

  const dataDays = [
    { title: t("mon") },
    { title: t("tue") },
    { title: t("wed") },
    { title: t("thu") },
    { title: t("fri") },
    { title: t("sat") },
    { title: t("sun") },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const token = localStorage.getItem("user");

  useEffect(() => {
    console.log(formData);
    if (!!formData) {
      if (formData.discount) {
        setValue("discount", formData.discount[0]?.discount);
        setValue("discount-happy", {
          description: formData.discount[0]?.description,
          discount: formData.discount[0]?.discount,
          from: formData.discount[0]?.from,
          to: formData.discount[0]?.to,
        });

        setValue("discount-usual", formData.discount[1]?.discount);
        setValue("discount-us", {
          description: formData.discount[1]?.description,
          discount: formData.discount[1]?.discount,
        });
        setValue("regular", formData.discount[1]?.description);
      }
    }
  }, []);

  const onSubmit = (data) => {
    console.log(data)
    const workTimeResult = Object.values(data).filter(
      (item) => item?.start_time
    );
    const happyDiscount = {
      // ...getValues("discount-happy"),
      discount: data.discount,
      start_time: data.workTime_discount_from,
      end_time: data.workTime_discount_to,
      description: "Happy Hours",
      id: 1,
    };

    console.log(happyDiscount)

    const usualDiscount = {
      ...getValues("discount-us"),
      id: 2,
    };

    const fetchData = async () => {
      try {
        let idCounter = 1;

        const result = tarifsRow.reduce((acc, curr) => {
          for (const [key, value] of Object.entries(curr.price)) {
            if (value !== "") {
              let type;
              switch (key) {
                case "incall":
                  type = "1";
                  break;
                case "outcall":
                  type = "2";
                  break;
                case "out":
                  type = "3";
                  break;
                default:
                  break;
              }
              acc.push({
                id: idCounter++,
                duration: curr.duration,
                type,
                price: value,
              });
            }
          }
          return acc;
        }, []);
        const route = `user/profile/${formData.slug}`;

        const method = "PUT";
        const payload = {
          prices: result,
          work_time: workTimeResult,
          discount: [],
        };
        if (happyDiscount.discount || usualDiscount.discount) {
          payload.discount = [];

          if (happyDiscount.discount) {
            payload.discount.push({
              ...happyDiscount,
              description: "Happy Hours",
              id: 1,
            });
          }

          if (usualDiscount.discount) {
            payload.discount.push({
              ...usualDiscount,
              id: 2,
            });
          }
        }

        const dataResult = await makeRequest({ route, method, payload });
        if (dataResult.status) {
          setAvailableSteps((prev) => [...prev, 6]);
          changeStep((prev) => prev + 1);
          setFormData((prevData) => ({
            ...prevData,
            ...payload,
          }));

          rootElem.scrollIntoView({
            behavior: "smooth",
          });
        }
      } catch (error) {}
    };

    token && fetchData();
  };
  const arrActiveIndex =
    formData.prices?.length > 0 &&
    formData?.prices
      .map((i) => i.type)
      .map((item) => {
        if (item === "1") {
          return "incall";
        }
        if (item === "2") {
          return "outcall";
        }
        if (item === "3") {
          return "out";
        }
        return item;
      });
  const formDataPrice =
    arrActiveIndex.length > 0
      ? arrActiveIndex.filter((item, index) => {
          return arrActiveIndex.indexOf(item) === index;
        })
      : [];

  const [activeCheckbox, setActiveCheckbox] = useState(
    formDataPrice.length > 0 ? formDataPrice : []
  );
  const backStep = () => {
    changeStep((prev) => prev - 1);
  };
  const validateData = () => {};

  const TarifsContainer = ({ children, title }) => {
    return (
      <div className="form-tarifs__root">
        <span className="form-tarifs__title">{title}</span>
        {children}
      </div>
    );
  };

  const ButtonTablaHead = ({ title, subTitle, value, data, setData }) => {
    const toggleChange = (name) => {
      const langIndex = data.findIndex((item) => item === name);

      if (langIndex === -1) {
        setData([...data, name]);
      } else if (langIndex !== -1) {
        const updatedActive = [...data];
        updatedActive.splice(langIndex, 1);
        setData(updatedActive);
      }
    };


    return (
      <Checkbox
        title={title}
        clazz={""}
        subTitle={subTitle}
        value={value}
        checked={data.includes(value)}
        onClick={() => toggleChange(value)}
      />
    );
  };

  const arrForTarifs =
    formData.prices?.length > 0 &&
    formData.prices.reduce((acc, item) => {
      const existingItem = acc.find((i) => i.duration === item.duration);

      if (existingItem) {
        if (item.type === "1") {
          existingItem.price.incall = item.price;
        }
        if (item.type === "2") {
          existingItem.price.outcall = item.price;
        }
        if (item.type === "3") {
          existingItem.price.out = item.price;
        }
      } else {
        acc.push({
          id: item.id,
          duration: item.duration,
          price: {
            incall: item.type === "1" ? item.price : "",
            outcall: item.type === "2" ? item.price : "",
            out: item.type === "3" ? item.price : "",
          },
        });
      }

      return acc;
    }, []);

  const [tarifsRow, setTarifsRow] = useState(
    arrForTarifs.length > 0
      ? arrForTarifs
      : [
          {
            id: 1,
            duration: "60",
            price: {
              incall: "",
              outcall: "",
              out: "",
            },
          },
        ]
  );

  const removeTarif = (value) => {
    const newRows = tarifsRow.filter((item) => item.duration !== value);
    setTarifsRow(newRows);
  };

  const addTarif = () => {
    const maxOptionLength = timeOption.length;
    if (tarifsRow.length < maxOptionLength);
    setTarifsRow((prev) => [
      ...prev,
      {
        id: tarifsRow.length + 1,
        duration: "",
        price: {
          incall: "",
          outcall: "",
          out: "",
        },
      },
    ]);
  };

  const arrForActiveIndex24 =
    formData.work_time?.length > 0
      ? formData.work_time
          .filter((item) => item.is24)
          .map((item) => item.weekday - 1)
      : [];
  const arrForActiveIndex =
    formData.work_time?.length > 0
      ? formData.work_time
          .filter((item) => !item.is24)
          .map((item) => item.weekday - 1)
      : [];

  const [isActiveIndex24, setIsActiveIndex24] = useState(arrForActiveIndex24);
  const [isActiveIndex, setIsActiveIndex] = useState(arrForActiveIndex);

  const TimeRow = ({ day, index, data, setData, data24, setData24 }) => {
    const [isIndexActive, setIsIndexActive] = useState(
      disabledRowArr.includes(index)
    );

    const [isChecked, setIsChecked] = useState(!!data.includes(index));
    const [is24Checked, setIs24Checked] = useState(!!data24.includes(index));

    // const defaultTimeStart = getValues(`workTime-${index + 8}`)?.start_time;
    // const defaultTimeEnd = getValues(`workTime-${index + 8}`)?.end_time

    // const [timeValue, setTimeValue] = useState({
    //   start: defaultTimeStart ? defaultTimeStart : "",
    //   end: defaultTimeEnd ? defaultTimeEnd : "",
    // });

    const handleTime = (e, type) => {
      // const timeFrom = getValues(`time-from-${index + 1}`);
      // const timeTo = getValues(`time-to-${index + 1}`);

      if (!!isChecked) {
        const timeFrom = getValues(`workTime-${index + 1}`)?.start_time;
        const timeTo = getValues(`workTime-${index + 1}`)?.end_time;

        if (type === "from") {
          setValue(`workTime-${index + 1}`, {
            id: Number(index + 1),
            weekday: index + 1,
            // start_time: timeValue.start,
            // end_time: timeValue.end,
            start_time: e.target.value,
            end_time: timeTo,
            is24: false,
          });
        } else {
          setValue(`workTime-${index + 1}`, {
            id: Number(index + 1),
            weekday: index + 1,
            start_time: timeFrom,
            end_time: e.target.value,
            is24: false,
          });
        }
      }
    };

    const handleClearsErrors = () => {
      const isErrorTo = errors[`timeTo${index + 1}`];
      const isErrorFrom = errors[`timeFrom${index + 1}`];
      if (!!isErrorTo) {
        clearErrors(`timeTo${index + 1}`);
      }
      if (!!isErrorFrom) {
        clearErrors(`timeFrom${index + 1}`);
      }
    };
    const handleAllDay = (index, checked) => {
      disabledRow(index);
      setValue(`workTime-${index + 1}`, {
        is24: checked,
        weekday: index + 1,
        start_time: "00:00",
        end_time: "23:59",
        id: index + 1,
      });

      const oldValue = [...data24];

      if (oldValue.includes(index)) {
        const newArr = oldValue.filter((i) => {
          return i !== index;
        });
        setData24(newArr);
      } else {
        oldValue.push(index);
        setData24(oldValue);
      }

      const oldIndex = [...data];

      if (!checked) {
        const newArr = oldIndex.filter((i) => i !== index);
        setData(newArr);
      } else {
        oldIndex.push(index);
        setData(oldIndex);
      }
    };

    const checkTime = () => {
      const timeFrom = getValues(`workTime-${index + 1}`)?.start_time;
      const timeTo = getValues(`workTime-${index + 1}`)?.end_time;
      if (!!isChecked && !!!is24Checked) {
        setTimeout(() => {
          if (timeFrom && !timeTo) {
            setError(`timeTo${index + 1}`, "empty-field");
          }
          if (!timeFrom && timeTo) {
            setError(`timeFrom${index + 1}`, "empty-field");
          }
          if (!timeFrom && !timeTo) {
            setError(`timeFrom${index + 1}`, "empty-field");
            setError(`timeTo${index + 1}`, "empty-field");
          }
        }, 750);
      }
    };

    const toggleActive = (index) => {
      const oldValue = [...data];

      if (oldValue.includes(index)) {
        const newArr = oldValue.filter((i) => i !== index);
        setValue(`workTime-${index + 1}`, {
          start_time: "",
          end_time: "",
        });
        setData(newArr);
      } else {
        oldValue.push(index);
        setData(oldValue);
      }
    };

    return (
      <div
        className="form-tarifs__worktimes--days-item"
        key={index}
        onMouseLeave={() => checkTime()}
        onMouseEnter={() => handleClearsErrors()}
      >
        <Checkbox
          title={day.title}
          disabled={isIndexActive}
          checked={isChecked}
          onClick={() => !!!isIndexActive && toggleActive(index)}
        />
        {/* <ScheduleInput
          defStart={!!formData.work_time?.length ? formData.work_time.filter(i => i.id === index + 1)[0]?.start_time : timeValue.start}
          defEnd={!!formData.work_time?.length? formData.work_time.filter(i => i.id === index + 1)[0]?.end_time : timeValue.end}
          onChange={handleTime}
          setValue={setTimeValue}
          clazz={!isChecked ? "disabled" : ""}
        ></ScheduleInput> */}
        <InputInLabel
          id={`orderTimeFrom${index + 1}`}
          placeholder={"11:00"}
          type={"time"}
          disabled={!!!isChecked}
          register={{ ...register(`workTime-${index + 1}.start_time`) }}
          clazz={errors[`timeFrom${index + 1}`] ? "error" : ""}
          onChange={(e) => handleTime(e, "from")}
        >
          {t('from')}
        </InputInLabel>
        <InputInLabel
          id={`orderTimeTo${index + 1}`}
          placeholder={"18:00"}
          type={"time"}
          disabled={!!!isChecked}
          register={{ ...register(`workTime-${index + 1}.end_time`) }}
          clazz={errors[`timeTo${index + 1}`] ? "error" : ""}
          onChange={(e) => handleTime(e, "to")}
        >
          {indi('until')}
        </InputInLabel>
        <Checkbox
          title={!isMobile && indi("nonstop")}
          disabled={false}
          checked={is24Checked}
          icon={"time-plan"}
          iconSize={"xxxl"}
          onClick={() => handleAllDay(index, !isIndexActive)}
        />
      </div>
    );
  };

  const formDataArrFullDay =
    formData.work_time?.length > 0
      ? formData.work_time.filter((i) => i.is24).map((item) => item.id - 1)
      : [];
  const [disabledRowArr, setDisabledRowArr] = useState(formDataArrFullDay);

  const chooseAll = () => {
    if (disabledRowArr.length === 7) {
      setDisabledRowArr([]);
      setIsActiveIndex24([]);
      setIsActiveIndex([])
    } else {
      setDisabledRowArr([0, 1, 2, 3, 4, 5, 6]);
      setIsActiveIndex24([0, 1, 2, 3, 4, 5, 6]);
      const fakeArray = Array.from({ length: 7 }, (_, index) => index);
      fakeArray.forEach((item, index) => {
        setValue(`workTime-${index + 1}`, {
          is24: true,
          weekday: index + 1,
          start_time: "00:00",
          end_time: "23:59",
          id: index + 1,
        });
      });
      setIsActiveIndex([0,1,2,3,4,5,6])
    }
  };
  const disabledRow = (index) => {
    if (disabledRowArr.includes(index)) {
      setDisabledRowArr(disabledRowArr.filter((item) => item !== index));
    } else {
      setDisabledRowArr((prev) => [...prev, index]);
    }
  };
  const windowWidth = useEffectWindowSize();

  const isMobile = windowWidth < 767.98;
  const isTable = windowWidth < 1200;

  const TableHeadRow = ({ data, setData, dataRow }) => {
    const [value, setValue] = useState(!!dataRow.length ? dataRow : []);
    const changeValue = (val, duration, typePrice) => {
      const updatedValue = [...value];

      const currentItem = updatedValue.filter(
        (item) => item.duration === duration
      );
      if (!!currentItem.length) {
        currentItem[0].price[typePrice] = Number(val);
        setValue(updatedValue);
      }
    };

    const toggleTime = (value, item) => {
      const index = tarifsRow.findIndex((obj) => obj.id === item.id);
      if (index !== -1) {
        const updatedTarifsRow = [...tarifsRow];

        updatedTarifsRow[index] = {
          ...updatedTarifsRow[index],
          duration: value.duration,
        };
        setTarifsRow(updatedTarifsRow);
      }
    };

    const enablePrice = (type) => {
      if (type === "incall" && !data.includes("incall")) {
        setData((prev) => [...prev, "incall"]);
      }
      if (type === "outcall" && !data.includes("outcall")) {
        setData((prev) => [...prev, "outcall"]);
      }
      if (type === "out" && !data.includes("out")) {
        setData((prev) => [...prev, "out"]);
      }
    };
    return tarifsRow.map((item, index) => (
      <div className="tarifs-row">
        <Select
          name={"tarifs-time"}
          options={timeOption.filter(
            (i) => !tarifsRow.map((item) => item.duration).includes(i.duration)
          )}
          defaultTitle={
            tarifsRow[index].duration
              ? timeOption.filter((i) => i.duration === item.duration)[0].title
              : ""
          }
          clazz={`select-city`}
          arrowSize={"m"}
          withoutTitle={true}
          postApi={true}
          onChange={toggleTime}
          paramsOnChange={item}
        />
        <InputInLabel
          type={"text"}
          id={`create-tarifs-incall--${item.duration}`}
          placeholder={""}
          disabled={!data.includes("incall")}
          value={value[index]?.price.incall}
          onClick={() => enablePrice("incall")}
          onChange={(e) => changeValue(e.target.value, item.duration, "incall")}
        >
          {t("price")}
        </InputInLabel>
        <InputInLabel
          type={"text"}
          id={`create-tarifs-outcall--${item.duration}`}
          placeholder={""}
          disabled={!data.includes("outcall")}
          onClick={() => enablePrice("outcall")}
          value={value[index]?.price.outcall}
          onChange={(e) =>
            changeValue(e.target.value, item.duration, "outcall")
          }
        >
          {t("price")}
        </InputInLabel>
        <InputInLabel
          type={"text"}
          id={`create-tarifs-out--${item.duration}`}
          placeholder={""}
          disabled={!data.includes("out")}
          onClick={() => enablePrice("out")}
          value={value[index]?.price.out}
          onChange={(e) => changeValue(e.target.value, item.duration, "out")}
        >
          {t("price")}
        </InputInLabel>
        <div
          className="tarifs-row-trash"
          onClick={() => removeTarif(item.duration)}
        >
          <Icon size={"xl"} spritePath={"trash"} />
          {isMobile && <span className="error">Удалить</span>}
        </div>
      </div>
    ));
  };

  const ScheduleDiscount = () => {
    // const [timeDiscount, setTimeDiscount] = useState({
    //   start: !!formData && formData.discount ? formData.discount[0]?.from : "",
    //   end: !!formData && formData.discount ? formData.discount[0]?.to : "",
    // });

    // const handleTimeDiscount = () => {
    //   const oldValue = getValues("discount-happy");
    //   setValue("discount-happy", {
    //     discount: (oldValue || {}).discount || 0,
    //     from: timeDiscount.start,
    //     to: timeDiscount.end,
    //   });
    // };

    const handleChange = (e) => {
      const oldValue = getValues("discount-happy");

      setValue("discount-happy", {
        discount: e.target.value,
        from: (oldValue || {}).from || "",
        to: (oldValue || {}).to || "",
      });
    };

    return (
      <div className="form-tarifs__discounts">
        <span className="form-tarifs__title">
          {t('discount')}
          <span>Happy Hours</span>
        </span>
        <InputInLabel
          type={"number"}
          id={"create-discount"}
          placeholder={"10%"}
          onChange={(e) => handleChange(e)}
          register={{ ...register("discount") }}
        >
          {indi("discountamount")}
        </InputInLabel>
        <div className="form-tarifs__discounts-ofday">
          <span>{indi("valid")}</span>
          <div className="d-flex gap-12">
              <InputInLabel
              id={`orderTimeFrom-discount`}
              placeholder={"11:00"}
              type={"time"}
              register={{ ...register(`workTime_discount_from`)}}
            >
              {t('from')}
            </InputInLabel>
            <InputInLabel
              id={`orderTimeTo-discount`}
              placeholder={"18:00"}
              type={"time"}
              register={{ ...register(`workTime_discount_to`)}}
            >
              {indi('until')}
            </InputInLabel>
          </div>
          {/* <ScheduleInput
            defStart={
              !!formData && formData.discount ? formData.discount[0]?.from : ""
            }
            defEnd={
              !!formData && formData.discount ? formData.discount[0]?.to : ""
            }
            onChange={handleTimeDiscount}
            setValue={setTimeDiscount}
          ></ScheduleInput> */}
          
        </div>
      </div>
    );
  };

  return (
    <>
      <h2>{indi('tariffs') + " " + t('and') + " " + indi('workhours')}</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`create-page__form tarifs`}
      >
        <TarifsContainer title={indi('tariffs')}>
          <div className="form-tarifs__table-head">
            <span>{t("time")}</span>
            <ButtonTablaHead
              setData={setActiveCheckbox}
              data={activeCheckbox}
              value={"incall"}
              // title={"Incall"}
              subTitle={isTable ? "" : `${t('incall')}`}
            />
            <ButtonTablaHead
              setData={setActiveCheckbox}
              data={activeCheckbox}
              value={"outcall"}
              // title={"Outcall"}
              subTitle={isTable ? "" : `${t('outcall')}`}
            />
            <ButtonTablaHead
              setData={setActiveCheckbox}
              data={activeCheckbox}
              value={"out"}
              title={t('acompany')}
              subTitle={isTable ? "" : `${"(" + t('nosex').toLowerCase() + ")"}`}
            />
          </div>
          <div className="form-tarifs__table-body">
            <TableHeadRow
              dataRow={tarifsRow}
              data={activeCheckbox}
              setData={setActiveCheckbox}
            />
          </div>
          <Button
            size={"l"}
            clazz={"button_outline--green-accent"}
            onClick={() => addTarif()}
          >
            {indi('addtariff')}
          </Button>
        </TarifsContainer>
        <div className="form-tarifs__worktimes">
          <div className="form-tarifs__worktimes--days">
            <div className="form-tarifs__worktimes--days-head">
              <span className="form-tarifs__title">{indi('workhours')}</span>
              <Checkbox
                title={indi('selectall')}
                disabled={false}
                checked={disabledRowArr.length === 7}
                onClick={() => chooseAll()}
              />
            </div>
            <div className="form-tarifs__worktimes--days-body">
              {dataDays.map((day, index) => (
                <TimeRow
                  data={isActiveIndex}
                  setData={setIsActiveIndex}
                  data24={isActiveIndex24}
                  setData24={setIsActiveIndex24}
                  day={day}
                  index={index}
                />
              ))}
            </div>
          </div>
          <TarifsContainer title={indi('discounts')}>
            <ScheduleDiscount happyDiscount />
            <div className="form-tarifs__discounts">
              <InputInLabel
                type={"text"}
                id={"create-regular"}
                placeholder={t("regulars")}
                onChange={(e) => {
                  const oldValue = getValues("discount-us");
                  setValue("discount-us", {
                    discount: (oldValue || {}).discount || 0,
                    description: e.target.value,
                  });
                }}
                register={{ ...register("regular") }}
              >
                {indi("discountname")}
              </InputInLabel>
              <InputInLabel
                type={"number"}
                id={"create-discount-usual"}
                placeholder={"10%"}
                onChange={(e) => {
                  const oldValue = getValues("discount-us");
                  setValue("discount-us", {
                    description: (oldValue || {}).description || "",
                    discount: e.target.value,
                  });
                }}
                register={{ ...register("discount-usual") }}
              >
                {indi("discountamount")}
              </InputInLabel>
            </div>
          </TarifsContainer>
        </div>
        <div className="create-form__buttons">
          <Button
            size={"l"}
            square={false}
            clazz="button--secondary"
            onClick={() => backStep()}
          >
            {indi("back")}
          </Button>
          <Button
            onClick={() => validateData()}
            buttonType={"submit"}
            size={"l"}
            square={false}
            clazz="button--green"
          >
            {indi("continue")}
          </Button>
        </div>
      </form>
    </>
  );
};

export default Tarifs;
