import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUserLang } from "@/stores/slices/userSlice";
import { Avatar, Button, RatingStars } from "@/components/ui";

import ResStatus from "./ResStatus";
import moment from "moment";
import { makeRequest } from "@/services/makeRequest";
import { wordEndings } from "../../../../../../helper/wordEndings";
import { CheckPhonePopup } from "../../../../../popups";
import { useState } from "react";

import "./resContainer.scss";

const ResContainer = (props) => {
  const { data } = props;
  const {
    from,
    price: priceFrom,
    profile,
    book_at,
    status,
    prepay,
    created_at,
    address,
    id: resId,
  } = data;

  const {
    name: nameFrom,
    phone: phoneFrom,
    rating: ratingFrom,
    tariff: tariffFrom,
  } = from;

  const { duration, price, type } = priceFrom;

  const { name: nameProfile, photo: photoProfile, slug: slugProfile } = profile;

  const [isOpenCheckPhone, setIsOpenCheckPhone] = useState(false);
  const lang = useSelector(getUserLang);
  const navigate = useNavigate();

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation();

  const statusObj = {
    0: "work",
    1: "approve",
    2: "decline",
  };

  const typeObj = {
    0: "outcall",
    1: "incall",
    2: "onclient",
  };

  const classVariant = statusObj[status] === "decline" ? " decline" : "";

  const handleOpen = () => {
    setIsOpenCheckPhone(true);
  };

  const bookAt = moment.unix(book_at).utc().format("DD.MM.YYYY HH:mm");
  const startAt = moment.unix(created_at).utc().format("DD.MM.YYYY HH:mm");
  const bookAtDate = moment.unix(book_at).utc().format("DD.MM.YYYY");
  const bookAtMin = moment.unix(book_at).utc().format("HH:mm");

  const durRes =
    duration >= 60
      ? `${t("onthe")} ` +
        duration / 60 +
        " " +
        wordEndings(duration / 60, [
          t("hour"),
          indi("hourfew"),
          indi("hourmany"),
        ])
      : `${t("onthe")}  ${duration} ${t("minit")}`;

  const durResMobile =
    duration >= 60
      ? duration / 60 +
        " " +
        wordEndings(duration / 60, [
          t("hour"),
          indi("hourfew"),
          indi("hourmany"),
        ])
      : `${duration} ${t("minit")}`;

  const toAd = (e) => {
    e.preventDefault();
    navigate(`/${lang}/profile/${slugProfile}`);
  };

  const handleStatus = (status, resId) => {
    const fetchData = async () => {
      try {
        const route = `bookings/${resId}/${status}`;
        const method = "PUT";

        await makeRequest({ route, method });
      } catch (error) {}
    };

    fetchData();
  };

  return (
    <>
      {isOpenCheckPhone && (
        <CheckPhonePopup
          open={isOpenCheckPhone}
          setOpen={setIsOpenCheckPhone}
          phoneClient={phoneFrom}
        />
      )}
      <div className={"res__root"}>
        <div className={`res__user__main${classVariant}`}>
          <div className="res__user__block">
            <div className="res__user">
              <Avatar size={"s"} profileStatus={tariffFrom} onlyIcon={true} />
              <span className="res__user__title">{nameFrom}</span>
              <RatingStars gold={true} value={ratingFrom} readOnly={true} />
            </div>
            <span className="p3 color-main">{startAt}</span>
          </div>
          <div className="res__user__block">
            <span className="p1 color-main">{phoneFrom}</span>
            <Button
              size={"xs"}
              buttonType={"submit"}
              clazz={"button_outline--green"}
              title={indi("crossnumber")}
              onClick={handleOpen}
            >
              {indi("crossnumber")}
            </Button>
          </div>
        </div>
        <div className={`res__info__block${classVariant}`}>
          <div className={`res__info__block__root${classVariant}`}>
            <div className={`res__info__block__money${classVariant}`}>
              <div className="res__price">
                <span>{indi("dateandtime")}</span>
                <span className="p1 color-main res__book">{bookAt}</span>

                <div className="res__bookmob">
                  <span className="p1 color-main">{bookAtMin}</span>
                  <span className="p1 color-main">{bookAtDate}</span>
                </div>
              </div>
              <div className="res__price">
                <div className="res__var">
                  <span className="res__var__type">
                    {t(`${typeObj[type]}`)}
                  </span>
                  <span className="p1 color-main res__dur">{durRes}</span>
                  <span className="p1 color-main res__durmob">
                    {durResMobile}
                  </span>
                </div>
                {address && (
                  <a href="/" title="City" className="res__var__city">
                    {address}
                  </a>
                )}
              </div>
            </div>
            <div className={`res__info__block__money${classVariant}`}>
              <div className="res__price">
                <span>{t("prepayment")}</span>
                <h3 className="mb-0">{prepay}€</h3>
              </div>
              <div className="res__price">
                <span>{t("remainder")}</span>
                <h3 className="mb-0">{price}€</h3>
              </div>
            </div>
          </div>
          <Link
            onClick={(e) => toAd(e)}
            to={`/profile/${slugProfile}`}
            className="res__ad hover-line"
            alt="ad"
          >
            <img src={photoProfile} className="res__ad__photo" alt="ad" />
            <span className="p1 mt-8 res__ad__title color-green">
              {nameProfile}
            </span>
          </Link>
        </div>
        <ResStatus status={status} handleStatus={handleStatus} resId={resId} />
      </div>
    </>
  );
};

export default ResContainer;
