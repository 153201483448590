import { plural } from "../../helper/plural";
import {
  Button,
  ButtonLink,
  CheckboxNavigation,
  Dropdown,
  Icon,
  Skeleton,
  Select,
  InputNick,
  SelectH1,
} from "@/components/ui";
import { useEffectWindowSize } from "../../hooks";
import React, { useEffect, useState } from "react";
import GirlCard from "../girlCard/GirlCard";
import axios from "axios";
import StoriesSlider from "../sliders/storiesSlider/StoriesSlider";
import { useDispatch, useSelector } from "react-redux";
import {
  hideShort,
  show,
  showFilter,
  showShort,
  showShortFilter,
} from "@/stores/slices/showFilterSlice";
import { Helmet } from "react-helmet";

import "./navigation.scss";
import {
  filterAllModels,
  getAllModels,
  getGenderSorted,
  getHasMorePage,
  getSearchValue,
  getSortedModelsByFilter,
  getStatusSorted,
  getStories,
  setDefaultSorted,
  setNotDisabledCheckbox,
  setPage,
  setSortedModelsByFilter,
} from "@/stores/slices/modelsState";
import {
  getPage,
  getTypeSorted,
  setAllModels,
  setHasMorePage,
  updateAllModels,
} from "@/stores/slices/modelsState";
import { getTitle, setDefaultTitle } from "@/stores/slices/titleSlice";
import {
  defaultRangeState,
  extractKeysAndNumbers,
  fetchRanges,
  filterGender,
  filterPlace,
  filterStatus,
  isFilterSearch,
  rangeAgeState,
  rangeChange,
  rangePriceState,
  rangeState,
  setDefaultRange,
} from "@/stores/slices/filterSlice";
import Notifications from "../notifications/Notifications";
import { setFilterMiniPopupStatus } from "@/stores/slices/filterPopupSlice";
import { getResultFilterString } from "@/stores/slices/counterFilterSlice";
import { getProfilesCount } from "@/stores/slices/mapModelSlice";
import { setStoriesMap } from "@/stores/slices/popupSlice";
import {
  getIsDataReady,
  getUserCity,
  getUserCountry,
  getUserCurrentCity,
  getUserLang,
  setCity,
  setLang,
  setVideochatStatus,
} from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";
import baseUrl from "@/services/apiConfig";
import { useNavigate } from "react-router-dom";

const Navigation = ({ isDataEmpty, setIsDataEmpty }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("translation");
  const token = localStorage.getItem("user");

  const filterNavigationMore = {
    main_title: t("more"),
    second_title: t("smartfilter"),
    options: [
      {
        id: 11,
        title: t("indi"),
        name: "status",
        value: "indi",
        checked: false,
        forDisabled: "statusIndividual",
        translateKey: "indi",
      },
      {
        id: 22,
        title: t("elite"),
        name: "status",
        value: "elitelady",
        checked: false,
        forDisabled: "statusElite",
        translateKey: "elite",
      },
      {
        id: 33,
        title: t("star"),
        name: "status",
        value: "pornstar",
        checked: false,
        forDisabled: "statusPornstar",
        translateKey: "star",
      },
      {
        id: 44,
        title: t("online"),
        name: "status",
        value: "online",
        checked: false,
        forDisabled: "statusOnline",
        translateKey: "online",
      },
      {
        id: 66,
        title: t("new"),
        name: "status",
        value: "new",
        checked: false,
        forDisabled: "statusNew",
        translateKey: "new",
      },

      {
        id: 160,
        title: t("videochat"),
        name: "status",
        value: "vchat",
        checked: false,
        forDisabled: "tagVideochat",
        translateKey: "videochat",
      },
      {
        id: 180,
        title: t("onlineorder"),
        name: "status",
        value: "booking",
        checked: false,
        forDisabled: "tagBooking",
        translateKey: "onlineorder",
      },
      {
        id: 181,
        title: t("erocontent"),
        name: "status",
        value: "ero",
        checked: false,
        forDisabled: "tagEro",
        translateKey: "erocontent",
      },
      {
        id: 55,
        title: t("verification"),
        name: "status",
        value: "verified",
        checked: false,
        forDisabled: "statusVerification",
        translateKey: "verification",
      },
      {
        id: 77,
        title: t("healthy"),
        name: "status",
        value: "healthy",
        checked: false,
        forDisabled: "statusHealthControl",
        translateKey: "healthy",
      },
      {
        id: 88,
        title: t("couples"),
        name: "gender",
        value: "couple",
        path: "couple",
        checked: false,
        forDisabled: "genderCouple",
        translateKey: "couples",
      },
      {
        id: 99,
        title: t("bdsm"),
        name: "status",
        value: "bdsm",
        checked: false,
        forDisabled: "statusBDSM",
        translateKey: "bdsm",
      },
      {
        id: 130,
        title: t("trans"),
        name: "gender",
        value: "trans",
        path: "trance",
        checked: false,
        forDisabled: "genderTrans",
        translateKey: "trans",
      },
      {
        id: 120,
        title: t("guys"),
        name: "gender",
        value: "male",
        path: "guys",
        checked: false,
        forDisabled: "genderMale",
        translateKey: "guys",
      },
      // {
      //   id: 140,
      //   title: t("agency"),
      //   name: "status",
      //   value: "agency",
      //   isLink: true,
      // },
    ],
  };

  const filterNavigation = [
    { id: 1, link: "#", title: t("bynew"), value: "date" },
    { id: 2, link: "#", title: t("byprice"), value: "price" },
    { id: 3, link: "#", title: t("byrating"), value: "rating" },
  ];

  const currentCity = useSelector(getUserCurrentCity);
  const currentCountry = useSelector(getUserCountry);

  const windowWidth = useEffectWindowSize();

  const morePage = useSelector(getHasMorePage);
  const models = useSelector(getAllModels);
  const [searchCount, setSearchCount] = useState("");
  const lang = useSelector(getUserLang);

  const removeWords = ["couple", "trance", "guys"];

  useEffect(() => {
    if (currentCity.id) {
      dispatch(fetchRanges(currentCity.id));
    }
  }, [currentCity.id]);

  useEffect(() => {
    const urlParams = window.location.pathname;
    const segments = urlParams.split("/");
    const thirdSegment = segments[3];
    if (thirdSegment) {
      dispatch(setDefaultTitle(thirdSegment.split("-")));
    }
  }, []);

  useEffect(() => {
    const urlParams = window.location.pathname;
    if (urlParams.includes("videochat")) {
      dispatch(setVideochatStatus(true));
    }
    const segments = urlParams.split("/");
    const thirdSegment = segments[3];
    // const navigationParams = segments.slice(3);

    // if (navigationParams.length > 0) {
    //   dispatch(
    //     setDefaultSorted({
    //       stateName: "sorted",
    //       newArray: navigationParams,
    //     })
    //   );
    //   dispatch(
    //     setDefaultSorted({
    //       stateName: "sortedByStatus",
    //       newArray: navigationParams.filter(
    //         (item) => !removeWords.includes(item)
    //       ),
    //     })
    //   );
    //   const newValueFOrGender = navigationParams
    //     .filter((item) => removeWords.includes(item))
    //     .map((word) => {
    //       switch (word) {
    //         case "couple":
    //           return "couple";
    //         case "trance":
    //           return "trans";
    //         case "guys":
    //           return "male";
    //         default:
    //           return null;
    //       }
    //     });
    //   dispatch(
    //     setDefaultSorted({
    //       stateName: "sortedByGender",
    //       newArray: newValueFOrGender,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     setDefaultSorted({
    //       stateName: "sorted",
    //       newArray: [],
    //     })
    //   );
    //   dispatch(
    //     setDefaultSorted({
    //       stateName: "sortedByStatus",
    //       newArray: [],
    //     })
    //   );
    //   dispatch(
    //     setDefaultSorted({
    //       stateName: "sortedByGender",
    //       newArray: [],
    //     })
    //   );
    // }
    if (thirdSegment) {
      dispatch(
        setDefaultSorted({
          stateName: "sorted",
          newArray: thirdSegment.split("-"),
        })
      );
      dispatch(
        setDefaultSorted({
          stateName: "sortedByStatus",
          newArray: thirdSegment
            .split("-")
            .filter((item) => !removeWords.includes(item)),
        })
      );
      const newValueFOrGender = thirdSegment
        .split("-")
        .filter((item) => removeWords.includes(item))
        .map((word) => {
          switch (word) {
            case "couple":
              return "couple";
            case "trance":
              return "trans";
            case "guys":
              return "male";
            default:
              return null;
          }
        });
      dispatch(
        setDefaultSorted({
          stateName: "sortedByGender",
          newArray: newValueFOrGender,
        })
      );
    } else {
      dispatch(
        setDefaultSorted({
          stateName: "sortedByStatus",
          newArray: [],
        })
      );
      dispatch(
        setDefaultSorted({
          stateName: "sortedByGender",
          newArray: [],
        })
      );
      dispatch(
        setDefaultSorted({
          stateName: "sorted",
          newArray: [],
        })
      );
    }
  }, [window.location.pathname]);

  const [isLoading, setIsLoading] = useState(false);

  const shortFilterOpen = useSelector(showShortFilter);
  const title = useSelector(getTitle);

  const [requestDataReady, setRequestDataReady] = useState(false);

  const sortedStatusValue = useSelector(getStatusSorted);
  const sortedGenderValue = useSelector(getGenderSorted);
  const sortBy = useSelector(getTypeSorted);
  const page = useSelector(getPage);
  const sortedModels = useSelector(getSortedModelsByFilter);
  const isFilterSelected = useSelector(isFilterSearch);
  const statusFilterValue = useSelector(filterStatus);
  const genderFilterValue = useSelector(filterGender);
  const filterValue = useSelector(showFilter);
  const placeValue = useSelector(filterPlace);
  const priceValue = useSelector(rangePriceState);
  const ageValue = useSelector(rangeAgeState);
  const defaultRangeValue = useSelector(defaultRangeState);
  const rangeValue = useSelector(rangeState);
  const searchByName = useSelector(getSearchValue);
  const [rangeReady, setRangeReady] = useState(false);
  // const [isDataEmpty, setIsDataEmpty] = useState(false);
  const isBigFilterShow = useSelector(showFilter);

  function allValueIsNull(obj) {
    for (let key in obj) {
      if (obj[key].min !== 0 && obj[key].max !== 0) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (allValueIsNull(defaultRangeValue)) {
      dispatch(
        rangeChange({
          name: "price",
          min: defaultRangeValue.price.min,
          max: defaultRangeValue.price.max,
        })
      );
      dispatch(
        rangeChange({
          name: "age",
          min: defaultRangeValue.age.min,
          max: defaultRangeValue.age.max,
        })
      );
      dispatch(
        rangeChange({
          name: "height",
          min: defaultRangeValue.height.min,
          max: defaultRangeValue.height.max,
        })
      );
      dispatch(
        rangeChange({
          name: "weight",
          min: defaultRangeValue.weight.min,
          max: defaultRangeValue.weight.max,
        })
      );
    }
  }, [defaultRangeValue]);

  useEffect(() => {
    if (ageValue.max !== 0 && priceValue.max !== 0) {
      setRangeReady(true);
    }
  }, [rangeValue, ageValue, priceValue]);

  useEffect(() => {
    if (
      sortedStatusValue &&
      sortedGenderValue &&
      sortBy !== null &&
      page !== null
    ) {
      setRequestDataReady(true);
    }
  }, [sortedStatusValue, sortedGenderValue, sortBy, page]);

  const isShortFilterShow = useSelector(showShortFilter);
  const valueResultFilter = useSelector(getResultFilterString);
  const [filtredString, setFiltredString] = useState([]);

  useEffect(() => {
    if (
      // requestDataReady &&
      !filterValue &&
      !isShortFilterShow &&
      !isBigFilterShow &&
      currentCity.id
      // rangeReady &&
      // !!Object.values(currentCity).length
    ) {
      const paramsPlace =
        placeValue.length > 0 ? `&place=${placeValue.join(",")}` : "";

      const paramsPage = `page=${page}`;
      const searchValue = searchByName && `&search=${searchByName}`;

      const paramsPrice =
        priceValue.min > defaultRangeValue.price.min ||
        priceValue.max < defaultRangeValue.price.max ||
        paramsPlace
          ? `=${priceValue.min},${priceValue.max}`
          : "";
      const paramsAge =
        (ageValue.min > defaultRangeValue.age.min ||
          ageValue.max < defaultRangeValue.age.max) &&
        ageValue.max !== 0
          ? `&age=${ageValue.min},${ageValue.max}`
          : "";

      const resultPrice = `${
        paramsPlace.includes("incall") ? `&price_in${paramsPrice}` : ""
      }${paramsPlace.includes("outcall") ? `&price_out${paramsPrice}` : ""}${
        paramsPlace.length === 0 &&
        (rangeValue.price.min > defaultRangeValue.price.min ||
          rangeValue.price.max < defaultRangeValue.price.max)
          ? `&price${paramsPrice}`
          : ""
      }`;

      const paramsSortBy = sortBy ? sortBy : "";

      const paramsSortedByStatusValue =
        sortedStatusValue.length && !isFilterSelected > 0
          ? "&" + sortedStatusValue.map((item) => item + "=1").join("&")
          : statusFilterValue.length > 0 && isFilterSelected
          ? "&" + statusFilterValue.map((item) => item + "=1").join("&")
          : "";

      const paramsSortedByGenderValue =
        sortedGenderValue.length && !isFilterSelected > 0
          ? "&gender=" + sortedGenderValue.join(",")
          : genderFilterValue.length > 0 && isFilterSelected
          ? "&gender=" + genderFilterValue.join(",")
          : "";

      const params = `?${paramsPage}&sort=${paramsSortBy}${resultPrice}${paramsAge}${paramsPlace}${
        sortedModels ? `&${sortedModels}` : ""
      }${paramsSortedByStatusValue}${paramsSortedByGenderValue}${searchValue}`;

      if (page > 1) {
        dispatch(updateAllModels([...Array(12)]));
      }

      // if(page === 1) {
      //   setIsLoading(false)
      // }

      axios
        .request({
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
          method: "GET",
          url:
            baseUrl +
            "profiles" +
            params +
            `&city=${currentCity.id}&lang=${lang}`,
        })
        .then((response) => {
          const data = response.data.profiles;
          const dataMeta = response.data.meta;
          const perPage = dataMeta.per_page;
          const totalProfiles = dataMeta.total;
          const currentPage = dataMeta.current_page;
          const isLoadMore =
            perPage * currentPage < totalProfiles ? true : false;

          if (page === 1) {
            dispatch(setAllModels(data));
            dispatch(setHasMorePage(isLoadMore));
          } else {
            dispatch(updateAllModels(data));
            dispatch(filterAllModels());
            dispatch(setHasMorePage(isLoadMore));
          }
          if (data.length === 0) {
            setIsDataEmpty(true);
          } else {
            setIsDataEmpty(false);
          }

          const result = extractKeysAndNumbers(response.data.filters);
          dispatch(setNotDisabledCheckbox(result));
          setIsLoading(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    isBigFilterShow,
    placeValue,
    searchByName,
    ageValue,
    priceValue,
    sortedModels,
    sortedGenderValue,
    sortedStatusValue,
    sortBy,
    page,
    statusFilterValue,
    genderFilterValue,
    currentCity.id,

    // rangeChange,
    // rangeReady,
    // currentCity,
  ]);

  const postCity = t("incity")?.replace(
    /%([^%]+)%/g,
    currentCity.title ? currentCity.title : " "
  );

  const mobilePostCity = t("incity")?.replace(/%([^%]+)%/g, "");

  const postTitle = t("h1main")?.replace(
    /%([^%]+)%/g,
    currentCity.title ? currentCity.title : " "
  );

  const postTitleMobile = t("h1main")?.replace(/%([^%]+)%/g, "");

  const titleString =
    title.length > 0
      ? title
          .filter((item) => !!item)
          .map((item) => t(item))
          .join(", ")
      : "";

  const titleParams = !!titleString ? `${titleString} ${postCity}` : postTitle;
  const mobileTitleParams = !!titleString
    ? `${titleString} ${mobilePostCity}`
    : postTitleMobile;

  useEffect(() => {
    if (isShortFilterShow) {
      const resultUrl = `${baseUrl}profiles/count?city=${currentCity.id}`;

      const searchValue = searchByName && `&search=${searchByName}`;

      const paramsSortedByStatusValue =
        sortedStatusValue.length && !isFilterSelected > 0
          ? "&" + sortedStatusValue.map((item) => item + "=1").join("&")
          : statusFilterValue.length > 0 && isFilterSelected
          ? "&" + statusFilterValue.map((item) => item + "=1").join("&")
          : "";

      const paramsSortedByGenderValue =
        sortedGenderValue.length && !isFilterSelected > 0
          ? "&gender=" + sortedGenderValue.join(",")
          : genderFilterValue.length > 0 && isFilterSelected
          ? "&gender=" + genderFilterValue.join(",")
          : "";

      setFiltredString(
        paramsSortedByStatusValue + paramsSortedByGenderValue + searchValue
      );

      const params = `?${paramsSortedByStatusValue}${paramsSortedByGenderValue}${
        valueResultFilter.length > 0 ? valueResultFilter : ""
      }${searchValue}`;

      axios
        .request({
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
          method: "GET",
          url: resultUrl + params,
        })
        .then((response) => {
          setSearchCount(response.data.count);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    searchByName,
    sortedStatusValue,
    isShortFilterShow,
    sortedGenderValue,
    sortedStatusValue,
    statusFilterValue,
    valueResultFilter,
  ]);
  const navigate = useNavigate();

  const toAgency = (e) => {
    e.preventDefault();

    navigate(`/${lang}/agencies`);
  };

  const skeletons = [...Array(12)].map((_, index) => <Skeleton key={index} />);

  const checkboxNavTemplate = (i) => (
    <CheckboxNavigation
      key={i.id}
      id={i.id}
      checked={i.checked}
      title={i.title}
      path={i.path}
      value={i.value}
      name={i.name}
      filter_disabled={i.forDisabled}
      isLink={i.isLink}
      translateKey={i.translateKey}
    />
  );

  const shortFilter = () => (
    <>
      {!!shortFilterOpen ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}
      <form className={`short-filter${shortFilterOpen ? " _active" : ""}`}>
        <Button onClick={() => dispatch(hideShort())} clazz={"filter__close"}>
          <Icon size={"l"} spritePath={"close"} />
        </Button>
        <div className="short-filter__body">
          <InputNick
            type="search"
            placeholder={t("modelname")}
            id="headerSearch"
            clazz={"search__input"}
            name={"nickname"}
          />

          <div className="short-filter__group">
            {filterNavigationMore.options.map((i) => {
              return checkboxNavTemplate(i);
            })}
          </div>

          <Button
            size={"l"}
            clazz={"button--tetriary short-filter__button"}
            onClick={() => dispatch(show())}
          >
            {t("allfilters")}
            <Icon size={"l"} spritePath={"filter"} />
          </Button>
        </div>
        <div className="short-filter__box">
          <Button
            onClick={() => {
              dispatch(hideShort());
              dispatch(setSortedModelsByFilter(filtredString.slice(1)));
              dispatch(setFilterMiniPopupStatus(true));
            }}
            buttonType={"button"}
            size={"m"}
            clazz="button--green"
            type="submit"
          >
            {`${t("show")} ${searchCount} ${plural("ru", searchCount)}`}
          </Button>
        </div>
      </form>
    </>
  );

  const profilesCountValue = useSelector(getProfilesCount);

  return (
    <section className="navigation__container">
      <div className={"navigation__top"}>
        {windowWidth > 767.98 ? (
          <>
            <h1>{!isFilterSelected ? titleParams : t("matching")}</h1>

            <Notifications />

            <ButtonLink
              onClick={() => {
                profilesCountValue && dispatch(setStoriesMap(true));
              }}
              clazz={`button_outline--black _no-underline ${
                profilesCountValue === 0 ? "_empty-map" : ""
              }`}
              size={"s"}
              disabled={isDataEmpty}
            >
              {t("onmap")} <Icon size={"m"} spritePath={"map"} />
            </ButtonLink>
          </>
        ) : (
          <>
            <h1>
              {!isFilterSelected ? mobileTitleParams : t("matching")}

              <SelectH1
                options={
                  !!currentCountry.length ? currentCountry : [currentCity]
                }
                clazz={windowWidth < 767.98 ? "select-city--word" : ""}
              />

              {/*<Select*/}
              {/*  name={"city"}*/}
              {/*  options={*/}
              {/*    !!currentCountry.length ? currentCountry : [currentCity]*/}
              {/*  }*/}
              {/*  clazz={windowWidth < 767.98 ? "select-city--word" : ""}*/}
              {/*  clazzSvg={"select__flag"}*/}
              {/*  withoutIcon*/}
              {/*  hideFlag*/}
              {/*  selectInH1*/}
              {/*/>*/}
            </h1>

            <div className={"navigation__top-wrapper"}>
              <ButtonLink
                anchor={true}
                onClick={() => {
                  profilesCountValue && dispatch(setStoriesMap(true));
                }}
                square={true}
                href={"#models-map"}
                clazz={`button_outline--black _no-underline ${
                  profilesCountValue === 0 ? "_empty-map" : ""
                }`}
                size={"s"}
              >
                <Icon spritePath={"map"} />
              </ButtonLink>

              <Notifications />
            </div>
          </>
        )}
      </div>

      <div className="navigation__bottom">
        <nav className="navigation__nav">
          {filterNavigationMore.options.map((i) => checkboxNavTemplate(i))}

          <Dropdown
            title={filterNavigationMore.main_title}
            size={"xs"}
            clazzWrapper={"navigation__more"}
          >
            {filterNavigationMore.options.map((i) => checkboxNavTemplate(i))}

            <ButtonLink
              onClick={(e) => toAgency(e)}
              href={`/agency`}
              size={"s"}
              title={t("agency")}
              clazz={"button_outline--black"}
            >
              {t("agency")}
            </ButtonLink>
          </Dropdown>

          {windowWidth < 767.98 ? (
            <>
              <Button
                clazz={"button--secondary navigation__mobile-filter"}
                size={"xs"}
                onClick={() => dispatch(showShort())}
              >
                <Icon size={"m"} spritePath={"filter2"} />
                {t("smartfilter")}{" "}
                <Icon size={"s"} spritePath={"chevrone-down"} />
              </Button>
              {shortFilter()}
            </>
          ) : null}
        </nav>

        <Select
          options={filterNavigation}
          mainIcon={"sort"}
          clazzSvg={"icon-m"}
          clazz={"button button-xs button--secondary"}
          arrowSize={"s"}
          sorted={true}
          name={"sort"}
        ></Select>
      </div>

      <div className="grid-cards">
        {isLoading ? <StoriesSlider /> : null}
        {isLoading &&
          !isDataEmpty &&
          models.map((data, index) => {
            console.log("data", data);
            return !!data ? (
              <GirlCard big={true} props={data} size={"m"} key={data.id} />
            ) : (
              <Skeleton key={index}></Skeleton>
            );
          })}
        {isDataEmpty && <h2 className="navigation__empty">{t("notfound")}</h2>}
        {!isLoading && skeletons}
      </div>
      {morePage && (
        <Button
          type="button"
          onClick={() => dispatch(setPage())}
          clazz={"navigation__show-more button--green"}
          size={"l"}
        >
          {t("showmore")}
        </Button>
      )}
    </section>
  );
};

export default Navigation;
