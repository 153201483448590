import {createSlice} from '@reduxjs/toolkit';
import {useParams} from "react-router-dom";

const initialState = {
    ladyForCall: null,
    videoCallCamera: null,
    videoCallRoom: null,
    videoCallFreeTime: null,
    videoCallTime: null,
    videoCallStatus: null
};

const videoCallSlice = createSlice({
    name: 'videoCall',
    initialState,
    reducers: {
        setVideoCallCamera: (state, action) => {
            state.videoCallCamera = action.payload
        },
        setLadyForCall: (state, action) => {
            state.ladyForCall = action.payload
        },
        setVideoCallRoom: (state, action) => {
            console.log('payload', action.payload)
            state.videoCallRoom = action.payload.room_id
            state.videoCallFreeTime = parseInt(action.payload.free_time)
            state.videoCallTime = parseInt(action.payload.time)
            state.videoCallPrice = parseInt(action.payload.price ?? 2)
        },
        setVideoCallStatus: (state, action) => {
            state.videoCallStatus = action.payload
        },
        cancelVideoCall: (state) => {
            state.ladyForCall = null
            state.videoCallRoom = null
            state.videoCallStatus = null
            state.videoCallFreeTime = null
            state.videoCallTime = null
        }

    },
});


export const {
    setVideoCallCamera,
    setLadyForCall,
    setVideoCallRoom,
    cancelVideoCall,
} = videoCallSlice.actions;

function convertSeconds(s) {
    let m = Math.trunc(s / 60) + ''
    s = (s % 60) + ''
    return m.padStart(2, 0) + ':' + s.padStart(2, 0)
}

export const getCreateVideoRoomModalStatus = (state) => state.videoCall.ladyForCall !== undefined && state.videoCall.ladyForCall !== null
export const getVideoCallModalStatus = (state) => state.videoCall.videoCallRoom !== undefined && state.videoCall.videoCallRoom !== null
export const getVideoCallStatus = (state) => state.videoCall.videoCallStatus
export const getVideoCallRoom = (state) => state.videoCall.videoCallRoom
export const getLadyForCall = (state) => state.videoCall.ladyForCall
export const getVideoCallCamera = (state) => state.videoCall.videoCallCamera
export const getVideoCallMaxTime = (state) => convertSeconds(state.videoCall.videoCallTime + state.videoCall.videoCallFreeTime)
export const getVideoCallFreeTime = (state) => convertSeconds(state.videoCall.videoCallFreeTime)

export default videoCallSlice.reducer;