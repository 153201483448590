import React, { useEffect, useState } from "react";
import { Icon, ShowMoreText } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";
import ModelIcons from "../icons/ModelIcons";

const ModelServices = ({ props }) => {
  const {
    services,
    healthy_at,
    verified_at,
    for_couples,
    for_men,
    for_women,
    for_groups,
    gender,
    preferences,
    orientation,
  } = props;

  const { t } = useTranslation("translation");

  const { gendersTitle, orientations } = useLadyService();

  const [sortedCategories, setSortedCategories] = useState([]);

  useEffect(() => {
    const sorted = [...services].sort((a, b) => a.category_id - b.category_id);
    setSortedCategories(sorted);
  }, [services]);

  console.log("for_men", for_men);
  console.log("for_women", for_women);
  console.log("for_groups", for_groups);
  console.log("for_couples", for_couples);

  return (
    <>
      {(healthy_at || verified_at) && (
        <ModelIcons verified_at={verified_at} healthy_at={healthy_at} />
      )}

      <h2
        className={"title color-main fz-22 fz-18-mob d-flex align-center gap-8"}
      >
        <span
          className={"girl-card__icon"}
          title={`${gendersTitle[gender]} — ${orientations(orientation)}`}
        >
          <Icon
            spritePath={`gender-${gender ? gender : "female"}`}
            size={"m"}
          />
        </span>
        {gendersTitle[gender]} — {orientations(orientation)}
      </h2>

      {!!for_couples || !!for_men || !!for_women || !!for_groups ? (
        <h3 className={"mb-0"}>
          <span className={"color-600"}>{t("servicesfor")}:</span>{" "}
          {for_men ? t("men") : null}
          {for_women ? `, ${t("women")}` : null}
          {for_couples ? `, ${t("couples")}` : null}
          {for_groups ? `, ${t("groups")}` : null}
        </h3>
      ) : null}

      {!!services.length
        ? sortedCategories.map((service) => {
            return (
              <>
                <span className={"fz-18 fz-16-mob d-block color-main"}>
                  {service.category_name}
                </span>

                <ul className="model__serfices-box fz-15 fz-13-mob color-main">
                  {service.services.map((item, index) => {
                    let clazz = item.tag
                      ? { className: `${item.tag ? " _active" : ""}` }
                      : null;

                    let price = item.additional_cost;

                    return (
                      <li {...clazz} key={index}>
                        {item.name}

                        {item.additional_cost && (
                          <span className={"color-600"}>
                            {item.additional_cost &&
                              ` +${price.substring(0, price.length - 3)} €`}
                            {item.note && (
                              <>
                                {" "}
                                <br /> {item.note}
                              </>
                            )}
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          })
        : null}

      {preferences ? <ShowMoreText>{preferences}</ShowMoreText> : null}
    </>
  );
};
export default ModelServices;
