import { useDispatch, useSelector } from "react-redux";
import {
  getAppealStatus,
  setAddReviewStatus,
  setAppealStatus,
  setSuccessAddReviewStatus,
  setSuccessAppealStatus,
} from "@/stores/slices/popupSlice";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { useTranslation } from "react-i18next";
import SuccessAppealMassage from "./SuccessAppealMassage";
import { useForm } from "react-hook-form";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";

const Appeal = (props) => {
  const dispatch = useDispatch();
  const AppealValue = useSelector(getAppealStatus);

  const { t } = useTranslation("translation");

  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    // const fetchData = async () => {
    //   try {
    //     const route = `reviews`;
    //     const method = "PUT";
    //     const payload = {
    //       ...data,
    //     };
    //
    //     await makeRequest({ route, method, payload });
    //     showToast({
    //       message: t('successreview'),
    //       variant: "succes",
    //     });
    //   } catch (error) {
    //     showToast({
    //       message: indi("oops"),
    //       variant: "error",
    //     });
    //   }
    // };

    // fetchData();
    reset();
    dispatch(setSuccessAppealStatus(true));
    dispatch(setAppealStatus(false));
  };

  return (
    <>
      <dialog
        open={!!AppealValue}
        onClick={() => dispatch(setAppealStatus(false))}
        id={"addAppeal"}
        className="popup-form"
      >
        {!!AppealValue ? (
          <Helmet>
            <html className={"lock"}></html>
          </Helmet>
        ) : null}

        <form
          method={"POST"}
          onClick={(e) => e.stopPropagation()}
          className="popup-form__body gap-16"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Button
            onClick={() => dispatch(setAppealStatus(false))}
            clazz={"button__close"}
          >
            <Icon size={"l"} spritePath={"close"} />
          </Button>

          <div className="popup-form__inner text-center">
            <h3>{t("complain")}</h3>
            <p className={"color-600 p2 mb-0"}>{t("complaintreview")}</p>
          </div>

          <div className="popup-form__inner">
            <div className={"popup-form__inner text-center gap-4"}>
              <p className={"color-600 fz-13 mb-0"}>{t("modelname")}</p>
              <span className={"color-green p1"}>{props.name}</span>
            </div>

            <InputInLabel
              register={{ ...register("body") }}
              type={"textarea"}
              id={"appealText"}
              placeholder={t("incidentoccur")}
            >
              {t("complaintreason")}
            </InputInLabel>

            <Button size={"l"} buttonType={"submit"} clazz={"button--green"}>
              {t("send")}
            </Button>
          </div>
        </form>
      </dialog>

      <SuccessAppealMassage />
    </>
  );
};

export default Appeal;
