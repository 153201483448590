import React, { useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import {
  minusFavoriteCounter,
  plusFavoriteCounter,
} from "@/stores/slices/userSlice";
import { Button, Icon } from "@/components/ui";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";

const ModelFavoriteBtn = ({ slug }) => {
  const [favorite, setFavorite] = useState(false);

  const { dispatch } = useLadyService();

  const { t: indi } = useTranslation("indi");

  const toggleFavoriteCount = () => {
    const token = localStorage.getItem("user");

    if (favorite) {
      if (token) {
        const fetchData = async () => {
          try {
            const route = `user/favorites/remove/${slug}`;
            const method = "DELETE";

            const data = await makeRequest({ route, method });
          } catch (error) {}
        };
        fetchData();
      } else {
        let favorites = JSON.parse(localStorage.getItem("favorites")) || [];

        favorites = favorites.filter((item) => item !== slug);

        localStorage.setItem("favorites", JSON.stringify(favorites));
      }
    } else {
      if (token) {
        const fetchData = async () => {
          try {
            const route = `user/favorites/add/${slug}`;
            const method = "POST";

            const data = await makeRequest({ route, method });
          } catch (error) {}
        };

        fetchData();
      } else {
        const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
        if (!favorites.includes(slug)) {
          favorites.push(slug);
        }
        localStorage.setItem("favorites", JSON.stringify(favorites));
      }
    }

    dispatch(favorite ? minusFavoriteCounter() : plusFavoriteCounter());
    setFavorite(!favorite);
  };

  return (
    <Button
      title={indi("favorites")}
      size={"xs"}
      square={true}
      clazz={`button_outline--black${
        favorite ? " girl-card__favorite_active" : ""
      }`}
      onClick={() => toggleFavoriteCount()}
    >
      <Icon size={"m"} spritePath={favorite ? "favorite-fill" : "favorite"} />
    </Button>
  );
};

export default ModelFavoriteBtn;
