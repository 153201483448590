import {
  ButtonLink,
  Icon,
  Button,
  InputInLabel,
  Dropdown,
} from "@/components/ui";

import "./addHeader.scss";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";

const options = [
  { name: "banging.cz" },
  { name: "secretgirlprague.com" },
  { name: "escorts.cz" },
];

const AddHeader = ({ handleOpenImport }) => {
  const navigate = useNavigate();
  const lang = useSelector(getUserLang);

  const { t: indi } = useTranslation("indi");
  const { t } = useTranslation("translation");

  const toCreateProfile = (e) => {
    e.preventDefault();

    navigate(`/${lang}/lk/indi/create`);
  };

  return (
    <div className="add__root">
      <ButtonLink
        href={"/lk/indi/create"}
        clazz={"button--green _no-underline"}
        size="l"
        onClick={(e) => toCreateProfile(e)}
      >
        <Icon spritePath={"plus"} size={"l"} />
        <span className="p1 add__add">{t("add")}</span>
        <span className="p1 add__addad">{indi("addad")}</span>
      </ButtonLink>
      <div className="add__right">
        <span className={"p2 color-main add__text"}>
          {indi("importfor")}, <p>{indi("parametrs")}</p>
        </span>

        <Dropdown
          title={indi("selectweb")}
          name={"site"}
          clazz={"add__select"}
          size={"l"}
        >
          {options.map((i, index) => {
            return (
              <ButtonLink
                clazz={`_no-underline justify-start`}
                // href={i.bo}
                title={i.name}
                size={"xs"}
                key={index}
                name={i.name}
              >
                {i.name}
              </ButtonLink>
            );
          })}
        </Dropdown>

        <InputInLabel
          type={"text"}
          // id={"create-tg"}
          placeholder={"https://ashoo.org/profile-91238523126"}
          //   register={{ ...register("tg") }}
          //   clazz={"w-100"}
          clazz={"add__link"}
        >
          {indi("url")}
        </InputInLabel>

        <Button
          clazz={"add__import__modal button--primary"}
          size={"l"}
          onClick={handleOpenImport}
        >
          <Icon spritePath={"import"} size={"l"} />
          <span className="p1">{indi("import")}</span>
        </Button>

        <Button clazz={"add__import button--primary"} size={"l"}>
          <Icon spritePath={"import"} size={"l"} />
          <span className="p1">{indi("import")}</span>
        </Button>
      </div>
    </div>
  );
};

export default AddHeader;
