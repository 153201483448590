import { Swiper, SwiperSlide } from "swiper/react";
import { ButtonLink } from "@/components/ui";
import { useSelector } from "react-redux";
import AgencyCard from "../../agencyCard/AgencyCard";
import { getAllSorted } from "@/stores/slices/modelsState";
import { filterPlace, isFilterSearch } from "@/stores/slices/filterSlice";
import { useEffect, useState } from "react";

import "./BestCityAgencySlider.scss";
import Slider from "../Slider";
import { useTranslation } from "react-i18next";
import {
  getUserCity,
  getUserCurrentCity,
  getUserLang,
} from "@/stores/slices/userSlice";
import { showFilter } from "@/stores/slices/showFilterSlice";
import { Navigation } from "swiper/modules";
import { makeRequest } from "@/services/makeRequest";
const BestCityAgencySlider = () => {
  const valueNavigation = useSelector(getAllSorted);
  const isFilterValue = useSelector(isFilterSearch);
  const valuePlace = useSelector(filterPlace);
  const [isRenderElem, setIsRenderElem] = useState(true);
  const country = useSelector(getUserCurrentCity).country;
  const cityId = useSelector(getUserCurrentCity).id;
  const lang = useSelector(getUserLang);
  const city = useSelector(getUserCity);
  const isBigFilterShow = useSelector(showFilter);

  const [page, setPage] = useState(1);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `agencies`;
        const method = "GET";
        const payload = {
          page,
          lang,
          city_id: cityId,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.agencies;
          setData(result);
        }
      } catch (error) {}
    };

    lang && cityId && !isBigFilterShow && fetchData();
  }, [cityId, lang]);

  const { t } = useTranslation("translation");

  const postH2main2 = t("h2main2").replace(/%([^%]+)%/g, country);

  useEffect(() => {
    if (valueNavigation.length > 0 || valuePlace.length > 0 || isFilterValue) {
      setIsRenderElem(false);
    } else {
      setIsRenderElem(true);
    }
  }, [valueNavigation, isFilterValue]);

  if (isRenderElem) {
    return (
      <div className={"best-agency__container"}>
        <h2 className="title_h1">{postH2main2}</h2>

        <Slider type={"agency"}>
          {data.length > 0 &&
            data.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <AgencyCard props={item} />
                </SwiperSlide>
              );
            })}
        </Slider>

        <ButtonLink
          href={`/${lang}/${city}/agencies`}
          clazz={"button--secondary best-agency__button td-none"}
          size={"l"}
        >
          {t("allagencies")}
        </ButtonLink>
      </div>
    );
  }
};

export default BestCityAgencySlider;
