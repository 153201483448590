import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PhotoUpload from "../UploadZone";

export const Interior = ({ data, setData, show }) => {
  const movePhoto = (dragIndex, hoverIndex) => {
    const draggedPhoto = data.files[dragIndex];
    const newPhotos = [...data.files];
    newPhotos.splice(dragIndex, 1);
    newPhotos.splice(hoverIndex, 0, draggedPhoto);
    setData({ files: newPhotos });
  };
  return (
    <DndProvider backend={HTML5Backend}>
        {show === "interior" && (
          <PhotoUpload
            movePhoto={movePhoto}
            data={data}
            setData={setData}
            accept={{
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/png': ['.png'],
              'image/webp': ['.webp'],
            }}
            type="photo"
            addText={false}
          />
        )}
      </DndProvider>
  );
};
