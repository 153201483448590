import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: [],
  coords: {
    latitude: 0,
    longitude: 0,
  }
};

export const oneProfileSlice = createSlice({
  name: "oneProfile",
  initialState,
  reducers: {
    setOneProfileAddress: (state, action) => {
      state.address = [action.payload]
    },
    setOneProfileCoords: (state, action) => {
      const {latitude, longitude} = action.payload
      state.coords = {
        latitude,
        longitude
      }
    },
  },
});

export const { setOneProfileAddress, setOneProfileCoords } = oneProfileSlice.actions;

export const getOneProfileAddress = (state) => state.oneProfile.address;
export const getOneProfileCoords = (state) => state.oneProfile.coords;

export default oneProfileSlice.reducer;
