import { useTranslation } from "react-i18next";
import { Button, RatingStars } from "@/components/ui";

import "./resContainer.scss";

const statusObj = {
  0: "work",
  1: "approve",
  2: "decline",
};

const Variant = ({ status, handleStatus, resId }) => {
  const { t: indi } = useTranslation("indi");

  switch (statusObj[status]) {
    case "approve": {
      return (
        <>
          <span className="p1 color-green text-center">
            {indi("approvestatus")}
          </span>
          <div className={`res__status done`}>
            <span>{indi("clientrating")}</span>
            <RatingStars gold={true} value={4} />
          </div>
        </>
      );
    }

    case "decline": {
      return <span className="p1 color-red-700">{indi("declinestatus")}</span>;
    }

    default: {
      return (
        <>
          <span>
            До истечения запроса <span className="p2 color-main">49:23</span>
          </span>
          <div className={`res__status__actions`}>
            <Button
              size={"m"}
              clazz={"button--green justify-center"}
              onClick={() => handleStatus("confirm", resId)}
            >
              Подтвердить
            </Button>

            <Button
              size={"m"}
              clazz={"button--secondary justify-center"}
              onClick={() => handleStatus("cancel", resId)}
            >
              Отменнить
            </Button>
          </div>
        </>
      );
    }
  }
};

const ResStatus = ({ status, handleStatus, resId }) => {
  let classVariant;
  switch (statusObj[status]) {
    case "approve": {
      classVariant = " approve";
      break;
    }

    case "decline": {
      classVariant = " decline";
      break;
    }

    default: {
      classVariant = " work";
    }
  }

  return (
    <div className={`res__status${classVariant}`}>
      <Variant
        status={status}
        classVariant={classVariant}
        handleStatus={handleStatus}
        resId={resId}
      />
    </div>
  );
};

export default ResStatus;
