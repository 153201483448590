import { ButtonLink, Icon } from "@/components/ui";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllSorted } from "@/stores/slices/modelsState";
import { filterPlace, isFilterSearch } from "@/stores/slices/filterSlice";
import { useTranslation } from "react-i18next";
import { getUserCurrentCity, getUserLang } from "@/stores/slices/userSlice";
import { makeRequest } from "@/services/makeRequest";
import { useNavigate } from "react-router-dom";

import "./benefitsBlock.scss";

const benefitsMap = [
  { spritePath: "diploma-verified", name: "freepostingpromo" },
  { spritePath: "wallet-money", name: "earnchatsandero" },
  { spritePath: "profile-card", name: "severalprofiles" },
  { spritePath: "statistic", name: "detailedstatistics" },
  { spritePath: "photo", name: "photocatalogue" },
  { spritePath: "call-check", name: "freebackcheck" },
];

const BenefitsBlock = () => {
  const navigate = useNavigate();

  const valueNavigation = useSelector(getAllSorted);
  const isFilterValue = useSelector(isFilterSearch);
  const valuePlace = useSelector(filterPlace);
  const [isRenderElem, setIsRenderElem] = useState(true);

  const lang = useSelector(getUserLang);
  const cityId = useSelector(getUserCurrentCity).id;

  const [profiles, setProfiles] = useState(0);
  const [verified, setVerified] = useState(0);
  const [online, setOnline] = useState(0);
  const [visits, setVisits] = useState(0);

  const isRes =
    valueNavigation.length > 0 || valuePlace.length > 0 || isFilterValue;

  const addSpacesEveryThreeDigits = (number) => {
    const numberString = number.toString();
    const digitsArray = numberString.split("");

    const resultArray = [];

    for (let i = 0; i < digitsArray.length; i++) {
      resultArray.push(digitsArray[i]);

      if (i % 3 === 0 && i !== digitsArray.length - 1) {
        resultArray.push(" ");
      }
    }

    return resultArray.join("");
  };

  const { t } = useTranslation("translation");

  const words = t("freeposting").split(" ");
  const lastIndex = words?.length - 1;
  const firstPart = words?.slice(0, lastIndex).join(" ");
  const lastWord = words[lastIndex];

  const toRegistration = (e) => {
    e.preventDefault();
    navigate(`/${lang}/registration#indi`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `statistic/${cityId}`;
        const method = "GET";

        const { data = {} } = await makeRequest({ route, method });

        if (!!Object.values(data).length) {
          setProfiles(data.profiles);
          setVerified(data.verified);
          setOnline(data.online);
          setVisits(data.visits);
        }
      } catch (error) {}
    };

    cityId && fetchData();
  }, [cityId]);

  useEffect(() => {
    setIsRenderElem(!isRes);
  }, [isRes]);

  if (isRenderElem) {
    return (
      <div className={"benefits__container"}>
        <div className="benefits__content">
          <div className="benefits__star">
            <p>{firstPart}</p>
            <span className={"title"}>{lastWord}</span>
          </div>
          <div className="benefits__inner">
            <span className="title_h2">{t("registernow")}</span>

            <p>{t("citychange")}</p>

            <ButtonLink
              href={`/registration`}
              size={"m"}
              clazz={"button--green _no-underline"}
              onClick={(e) => toRegistration(e)}
            >
              <Icon size={"l"} spritePath={"plus"} />
              {t("addprofile")}
            </ButtonLink>
          </div>

          <div className="benefits__items">
            {benefitsMap.map((item, index) => {
              const { spritePath, name } = item;
              return (
                <div className="benefits__item" key={index}>
                  <Icon size={"l"} spritePath={spritePath} />
                  <p>{t(name)}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="benefits__counters">
          <div>
            {t("postedprofiles") + ":"}{" "}
            <span className="benefits__counter--questionnaire-make">
              {profiles}
            </span>
          </div>
          <div>
            {t("verified") + ":"}{" "}
            <span className="benefits__counter--verify">{verified}</span>
          </div>
          <div>
            {t("online") + ":"}{" "}
            <span className="benefits__counter--online">{online}</span>
          </div>
          <div>
            {t("visitorsperday") + ":"}{" "}
            <span className="benefits__counter--visitors">
              {addSpacesEveryThreeDigits(visits)}
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default BenefitsBlock;
