import { ButtonLink } from "@/components/ui";
import React from "react";
import { useTranslation } from "react-i18next";

const ModelPornstarCard = ({ props }) => {
  const { porn_star } = props;
  const { t } = useTranslation("translation");
  return (
    <div className={"model-card ero-card"}>
      <h3>{t("pornstar")}</h3>

      <div className={"agency-card"}>
        <div className="agency-card__img">
          <iframe allowFullScreen src={porn_star.video_link}></iframe>
        </div>
        <ButtonLink
          href={porn_star.website_link}
          size={"m"}
          clazz={`button--tetriary width-100 hover-line`}
        >
          {porn_star.website_link}
        </ButtonLink>
      </div>
    </div>
  );
};
export default ModelPornstarCard;
